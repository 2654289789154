import { useEffect, useState } from "react";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Stack,
  TextField,
  InputAdornment
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { ExerciseList } from "./ExerciseList";
import { PlanList } from "./PlanList";

export const TrainingPlanRoute = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [activeView, setActiveView] = useState('exercises');
  const [exerciseModalOpen, setExerciseModalOpen] = useState(false);
  const [trainingPlanOpen, setTrainingPlanOpen] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }
  }, [user]);

  const checkUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const shouldOpenModal = urlParams.get('openExerciseModal');
    const shouldOpenWorkoutBuilder = urlParams.get('openWorkoutBuilder');
    
    if (shouldOpenModal === 'true') {
      if(activeView!=='exercises'){
        setActiveView('exercises');
      }
      setExerciseModalOpen(true);
      urlParams.delete("openExerciseModal");
      window.history.replaceState({}, "", `${location.pathname}?${urlParams}`);
      return true;
    }
    
    if (shouldOpenWorkoutBuilder === 'true') {
      setActiveView('plans');
      setExerciseModalOpen(false);
      setTrainingPlanOpen(true);
      urlParams.delete("openWorkoutBuilder");
      window.history.replaceState({}, "", `${location.pathname}?${urlParams}`);
      return true;
    }
    
    return false;
  };

  // Effect to check URL parameters both on mount and when location changes
  useEffect(() => {
    if (!user) {
      return;
    }
    
    checkUrlParams();
  }, [user, location]);

  const handleViewChange = (view) => {
    setActiveView(view);
    // Reset modal states when switching views
    if (view === 'exercises') {
      setTrainingPlanOpen(false);
    } else {
      setExerciseModalOpen(false);
    }
  };

  return (
    <>
      <PageContainer padding={"0!important"} backgroundColor={(exerciseModalOpen || trainingPlanOpen) ? "#FBFBFB!important" : "#FBFBFB!important"}>
        
        <Box py={4} sx={{
          width:"100%",
          border:"1px solid #E3E2E0",
          background: (exerciseModalOpen || trainingPlanOpen) ? "#FBFBFB" : "#FBFBFB",
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          
        }}>
          <Box sx={{ 
            display: 'flex', 
            gap:{xs:2,sm:"20px",md:"20px",lg:"20px"},
            background: (exerciseModalOpen || trainingPlanOpen) ? "#FBFBFB" : "#FBFBFB", 
            mb: 0,
            justifyContent:"center",
          }}>
            <Button
              variant={activeView === 'exercises' ? 'contained' : 'outlined'}
              onClick={() => handleViewChange('exercises')}
              sx={{
                borderRadius: '20px',
                textTransform: 'none',
                px:"12px",
                py: 0.5,
                boxShadow:"none",
                fontSize: '14px',
                backgroundColor: activeView === 'exercises' ? '#E9FFCE' : 'transparent',
                border: "1px solid #525A65",
                color: activeView === 'exercises' ? '#0F0F0F' : '#0F0F0F',
                '&:hover': {
                  backgroundColor: activeView === 'exercises' ? "#E9FFCE" : "#unset",
                  border: "1px solid #0F0F0F",
                }
              }}
            >
              Exercises
            </Button>
            <Button
              variant={activeView === 'plans' ? 'contained' : 'outlined'}
              onClick={() => handleViewChange('plans')}
              sx={{
                borderRadius: '20px',
                textTransform: 'none',
                px:"12px",
                py: 0.5,
                boxShadow:"none",
                fontSize: '14px',
                backgroundColor: activeView === 'plans' ? '#E9FFCE' : 'transparent',
                border: "1px solid #525A65",
                color: activeView === 'plans' ? '#0F0F0F' : '#0F0F0F',
                '&:hover': {
                  backgroundColor: activeView === 'plans' ? "#E9FFCE" : "#unset",
                  border:  "1px solid #0F0F0F",                }
              }}

            >
              Workouts
              </Button>
          </Box>
      
        </Box>
        <Box sx={{
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          background: (exerciseModalOpen || trainingPlanOpen) ? "#EEEEEE" : "#fff",
        }}>
        {activeView === 'exercises' && (
          <ExerciseList user={user} onModalStateChange={setExerciseModalOpen} />
        )}
        {activeView === 'plans' && (
          <PlanList user={user} onModalStateChange={setTrainingPlanOpen} trainingPlanOpen={trainingPlanOpen} setTrainingPlanOpen={setTrainingPlanOpen} />
        )}
        </Box>
      </PageContainer>
    </>
  );
};



