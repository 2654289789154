export const addnewquestionstyle = {
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "30px",
  fontSize: "12px",
  ".MuiSvgIcon-root": {
    fontSize: "12px !important",
    paddingLeft: "2px",
  },
  "&:hover": {
    color: "#525A65",
    backgroundColor: "unset"
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px"
  },
}

export const invitestyle = {
  color: "#02BD36",
  border: "#02BD36 solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "20px",
  fontWeight: "500",
  fontSize: "16px",
  marginTop: "0px",
  padding: "9px 16.5px !important",
  "&:hover": {

  },
  "&.Mui-disabled": {
    color: "#B7B7B7",
    border: "#B7B7B7 solid 1px",
    backgroundColor: "#F5F5F5",
    opacity: 0.7,
  },
}

export const close = {
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  padding: "13px",
  width: "48px",
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  height: "48px",
  borderRadius: "100% !important",
  minWidth: "37px !important",
  fontSize: "12px",
  border: "0px !important",
  lineHeight: "1",
  backgroundColor: "#EEEEEE",
  "&:hover": {
    color: "#525A65",
    backgroundColor: "#dddddd"
  },
  ".MuiButton-icon": {
    margin: "0px !important"
  }
}