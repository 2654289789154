import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  ListItem,
  Paper,
  Typography,
  List,
  ListItemText,
  Grow,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { supabase } from "../../services/supabase";
import { getPlanByIdClient } from "../../services/query";

// Convert time from 24-hour format to 12-hour format
const convertTo12HourFormat = (time24) => {
  if (!time24) return "";
  
  // Parse the time string (expecting format like "14:30")
  const [hours, minutes] = time24.split(':').map(part => parseInt(part, 10));
  
  if (isNaN(hours) || isNaN(minutes)) return time24;
  
  // Convert to 12-hour format
  const period = hours >= 12 ? 'pm' : 'am';
  const hours12 = hours % 12 || 12; // Convert 0 to 12 for 12 AM
  
  return `${hours12}${period} `;
};

export const CardDayAtheletesPlan = ({
  athlete,
  OnchangeEvents,
  onChangeDateTable,
  curDate,
  onDrop,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null); // State to track the selected day
  const [Plans, setPlans] = useState([]); // State to track the selected day
  const [animationComplete, setAnimationComplete] = useState(false);

  const updateDateDisplay = () => {
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to Sunday of the current week
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6); // Set to Saturday of the current week
    const monthName = startOfWeek
      .toLocaleString("default", { month: "long" })
      .toUpperCase();
    const startDay = startOfWeek.getDate();
    const endDay = endOfWeek.getDate();
    return `${monthName} ${startDay}-${endDay}`;
  };

  const handlePrevClick = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 7);
    setCurrentDate(newDate);
    
    // Set selected day to same weekday in the new week
    const newSelectedDate = new Date(newDate);
    if (selectedDay) {
      const currentSelectedDate = new Date(selectedDay);
      newSelectedDate.setDate(newDate.getDate() + currentSelectedDate.getDay() - newDate.getDay());
    }
    
    const formattedNewDate = newSelectedDate.toLocaleString("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    
    setSelectedDay(formattedNewDate);
    onChangeDateTable(formattedNewDate);
  };

  const handleNextClick = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 7);
    setCurrentDate(newDate);
    
    // Set selected day to same weekday in the new week
    const newSelectedDate = new Date(newDate);
    if (selectedDay) {
      const currentSelectedDate = new Date(selectedDay);
      newSelectedDate.setDate(newDate.getDate() + currentSelectedDate.getDay() - newDate.getDay());
    }
    
    const formattedNewDate = newSelectedDate.toLocaleString("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    
    setSelectedDay(formattedNewDate);
    onChangeDateTable(formattedNewDate);
  };

  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

  const fetchPlans = async () => {
    const plans = await Promise.all(
      athlete.events.map((event) =>
        getPlanByIdClient(supabase, event.plan_client_id).then((plan) => ({
          plan: plan.data,
          calendarId: athlete.calendars[0].payload.calendarId,
          resource: event.payload,
          eventId: event.id,
          events: event,
          date: new Date(event.date).getDate(),
          month: new Date(event.date).getMonth(),
          EventDate: event.date,
          is_single_exercise: event.is_single_exercise,
        }))
      )
    );
    // Sort plans by created_at (newest first)
    const sortedPlans = plans.sort((a, b) => {
      const dateA = new Date(a?.events?.created_at || a?.plan?.[0]?.created_at || 0);
      const dateB = new Date(b?.events?.created_at || b?.plan?.[0]?.created_at || 0);
      return dateB - dateA; // Newest first
    });
    setPlans(sortedPlans);
  };

  const daysOfWeek = Array.from({ length: 7 }, (_, index) => {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + index);
    const dayEvents = Plans.filter(
      (event) =>
        event.date === date.getDate() && event.month === date.getMonth()
    ).sort((a, b) => {
      // Sort by created_at (newest first) within each day
      const dateA = new Date(a?.events?.created_at || a?.plan?.[0]?.created_at || 0);
      const dateB = new Date(b?.events?.created_at || b?.plan?.[0]?.created_at || 0);
      return dateB - dateA; // Newest first
    });
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleString("en-US", options);
    const dayName = date.toLocaleString('en-US', { weekday: 'short' }).toUpperCase();
    return {
      date: date.getDate(),
      dayName: dayName,
      tableDate: formattedDate,
      events: dayEvents,
      selected: selectedDay === formattedDate,
      fullDate: new Date(date), // Add full date for drag and drop
    };
  });

  useEffect(() => {
    fetchPlans();
    const today = curDate ? new Date(curDate) : new Date();
    const formattedToday = today.toLocaleString("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    onChangeDateTable(formattedToday);
    setSelectedDay(formattedToday);
    
    // Reset animation state when date changes
    setAnimationComplete(false);
    const timer = setTimeout(() => {
      setAnimationComplete(true);
    }, 1000); // Give enough time for all animations to complete
    
    return () => clearTimeout(timer);
  }, [athlete, currentDate]);

  useEffect(() => {
    const selectedEvents =
      daysOfWeek.find((day) => day.tableDate === selectedDay)?.events || [];
    OnchangeEvents(selectedEvents);
  }, [Plans, selectedDay, OnchangeEvents]);

  const handleDayClick = (dayDate, TableDate) => {
    setSelectedDay(TableDate);
    onChangeDateTable(TableDate);
    const selectedevents =
      daysOfWeek.find((day) => day.date === dayDate)?.events || [];
    OnchangeEvents(selectedevents);
  };

  // Handle drop on a day
  const handleDrop = (e, day) => {
    e.preventDefault();
    e.currentTarget.classList.remove('drag-over');
    const planData = e.dataTransfer.getData("plan");
    if (planData && onDrop) {
      const plan = JSON.parse(planData);
      // Add the drop target date to the plan object
      plan.dropTargetDate = day.fullDate;
      // Add timestamp to track when this drop occurred
      plan.dropTimestamp = new Date().getTime();
      onDrop(plan, day.fullDate);
    }
  };

  // Handle dragover to allow drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
    e.currentTarget.classList.add('drag-over');
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        paddingTop: "3px",
        flexDirection: "column",
        alignItems: "center",
        background:"#fff",
        height:"100%",
      }}
    >
  <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background:"#eee",
        height:"100%",
        py:"28px",
        pb:"21.5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: "16px",
          gap:"13px"
        }}
      >
        <IconButton onClick={handlePrevClick}>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="27.5" y="27.5" width="27" height="27" rx="13.5" transform="rotate(180 27.5 27.5)" fill="#EEEEEE" stroke="#525A65"/>
<path d="M17 18.8757L12.2094 14L17 9.1243L15.8953 8L11.1047 12.8757L10 14L15.8953 20L17 18.8757Z" fill="#0F0F0F"/>
</svg>

        </IconButton>
        <Typography 
          component="div" 
          sx={{ 
            textAlign: 'center', 
            fontSize:"16px",
            color:"#0F0F0F",
            fontWeight:"500",
          }}
        >
          {updateDateDisplay()}
        </Typography>
        <IconButton onClick={handleNextClick}>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="27" height="27" rx="13.5" fill="#EEEEEE" stroke="#525A65"/>
<path d="M11 9.1243L15.7906 14L11 18.8757L12.1047 20L16.8953 15.1243L18 14L12.1047 8L11 9.1243Z" fill="#0F0F0F"/>
</svg>


        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexGrow: 1,
            gap: {xs:"5px",md:"13px",lg:"13px",xl:"12.65px"},
            mx: "1.39%",
          }}
        >
          {daysOfWeek.map((day, index) => (
            <Grow
              key={index}
              in={true}
              style={{ transformOrigin: '0 0 0' }}
              timeout={500 + (index * 100)}
            >
              <Paper
                onClick={() => handleDayClick(day.date, day.tableDate)} // Handle day click
                onDrop={(e) => handleDrop(e, day)}
                onDragOver={handleDragOver}
                onDragLeave={(e) => {
                  e.preventDefault();
                  e.currentTarget.classList.remove('drag-over');
                }}
                sx={{
                  width: {
                    xs: "calc(100% / 7)",
                    sm: "calc(100% / 7)",
                    md: "calc(100% / 7)",
                    lg: "calc(100% / 7)",
                    xl: "calc(100% / 7)",
                  },
              
                  height: {
                    xs: "110px",
                    sm: "120px",
                    md: "135px",
                    lg: "147px"
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  borderRadius: "12px",
                  cursor: "pointer",
                  overflow: "auto",
                  fontWeight:"700!important",
                  background:"#fff",
                  color:
                    selectedDay === null
                      ? "#525A65"
                      : day.selected
                      ? "#0F0F0F"
                      : "#525A65",
                  border:
                    selectedDay === null
                      ? "none"
                      : day.selected
                      ? "1px solid #02BD36"
                      : "none",
                  boxShadow: "unset",
                  "&:hover": {
                    border: "1px solid #02BD36",
              
                  },

                  "&.drag-over": {
                    borderColor: "#02BD36",
                    borderWidth: "3px",
                    borderStyle: "dashed",
                    backgroundColor: "#E9FFCE",
                    
                    transform: "scale(1.05)",
                    transition: "all 0.2s ease-in-out",
                    boxShadow: "0 5px 15px rgba(2, 189, 54, 0.3)",
                  }
                }}
              >
                <Box pl={1.5} pt={1}>
               
                  <Typography
                  sx={{
                    fontSize:{xs:"12px",md:"18px"}
  
                  }}
                    fontWeight={"700 !important"}
                    component="div"
                  >
                    {day.date}
                  </Typography>
                  <Typography
                  sx={{
                    fontSize:{xs:"12px",md:"14px"}
                    
                  }}
                    color={selectedDay === day.tableDate ? "#0F0F0F" : "#525A65"}
                    component="div"
                  >
                    {day.dayName}
                  </Typography>
                  <List
                    sx={{
                      pt: 1,
                      display:{xs:"flex",md:"unset"},
                      flexDirection:"row",
                      flexWrap:"wrap"
                      
                    }}
                  >
                    {day.events.map((event, idx) => (
                      <ListItem
                        key={idx}
                        sx={{ padding: "0 !important", width: "fit-content" }}
                      >
                        <ListItemText
                          sx={{ margin: "0px !important" }}
                          primary={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                              <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="8" cy="8" r="8" fill="#02BD36"/>
                                </svg>
                              </Box>
                              <Typography
                                variant="caption"
                                component="div"
                                sx={{
                                  display: { xs: 'none', sm: 'none', md: 'block' },
                                  backgroundColor: event.plan[0]?.start_time ? "#FFFFFF" : "#02BD36",
                                  color: event.plan[0]?.start_time ? "#000000" : "#fff",
                                  fontWeight:"500",
                                  borderRadius: "30px",
                                  px: 1,
                                  px: event.plan[0]?.start_time ? 0 : 1,

                                  fontSize: "10px",
                                  mt: 0.5,
                                  mr: 1,
                                }}
                              >
                                {event.plan[0]?.start_time && (
                                  <>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle', marginRight: '3px' }}>
                                      <circle cx="8" cy="8" r="8" fill="#02BD36"/>
                                    </svg>
                                  </>
                                )}
                                <span
                                style={{
                                  fontWeight:"700",
                                  fontSize:"10px",
                                  color:"#0F0F0F",
                                }}
                                >
                                  
                                  {convertTo12HourFormat(event.plan[0]?.start_time)}
                                  </span> 
                                {event.plan[0]?.name.length > 19
                                  ? event.plan[0].name.substring(0, 15) + "..."
                                  : event.plan[0]?.name}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Paper>
            </Grow>
          ))}
        </Box>
      </Box>
    </Box>
    </Box>
  );
};