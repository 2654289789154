import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemText,
  ListSubheader,
  Paper,
  Autocomplete,
  TextField,
  Typography,
  Modal,
  Popper,
  Menu,
  MenuItem,
} from "@mui/material";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  Close,
  Delete,
  Edit,
  Mail,
  Phone,
  WarningRounded,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { Navigate, replace, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCalendar } from "../../providers/CalendarProvider";
import { getAthleteName } from "../../utils/selectors";
import {
  deleteAthlete,
  getAthleteProfile,
  getCalendarsByCoachIdAthleteId,
  getPlanByIdPublic,
  insertCalendar,
  upsertAthlete,
  getAthletesByCoachId,
  getPlanByIdClient,
} from "../../services/query";
import { getTimeZone } from "../../utils/calendar";
import { CreateEventModal } from "../../components/CreateEventModal/CreateEventModal";
import { GetAthleteStatus, PaymentDialog } from "../../components/AthletesTable/AthletesTable";
import { Controller, useForm } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers";
import Grid from "@mui/material/Grid";
import { ClientDetailTraining } from "../../components/ClientDetailTraining/ClientDetailTraining";
import { CardDayAtheletesPlan } from "../../components/ClientDetailTraining/CardDaysAtheletesPlan";
import { SuccessIcon } from "../Dashboard/Dashboard.component";
import AthleteInviteModal from "../../components/AthleteInvitationModal/AthleteInvitationModal";
import Forms from "../../components/Forms";
import { IconClose } from "../../components/ExerciseModal/ExerciseModal";
import WelcomeModal from "../../components/WelcomeModal/WelcomeModal";
import AthleteDetail from "./AthleteDetail";

const EditModal = ({ editModalOpen, setEditModalOpen, athlete, onSuccess }) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      first_name: athlete.first_name,
      last_name: athlete.last_name,
      city: athlete.city,
      country: athlete.country,
      phone_number: athlete.phone_number,
      dob: new Date(athlete.dob),
    },
  });

  const supabase = useSupabase();
  const {user} =useAuth()

  const onSubmit = handleSubmit(async (data) => {
    await upsertAthlete(supabase, {
      id: athlete.id,
      first_name: data.first_name,
      last_name: data.last_name,
      full_name: `${data.first_name} ${data.last_name}`,
      city: data.city,
      country: data.country,
      phone_number: data.phone_number,
      dob: data.dob,
    });
    setEditModalOpen(false);
    onSuccess && onSuccess();
  });

  return (
    <Dialog
      onClose={() => setEditModalOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={editModalOpen}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Edit Athlete
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setEditModalOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("first_name")}
              label="First Name"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("last_name")}
              label="Last Name"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("city")}
              label="City"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("country")}
              label="Country"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("phone_number")}
              label="Phone Number"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ maxWidth: 400, mt: 2, mb: 2 }}>
            <Controller
              control={control}
              name="dob"
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <MobileDatePicker
                    label="Date of Birth"
                    format="yyyy-MM-dd"
                    value={field.value && new Date(field.value)}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    fullWidth
                  />
                );
              }}
            />
          </Box>
          <Button type="submit">Submit</Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export const AthleteRoute = () => {
  const [modals, setModals] = useState({
    contact: false,
    event: false,
    edit: false,
    payment: false,
    delete: false,
    invite: false,
    welcome: false
  });
  const [athlete, setAthlete] = useState(null);
  const [inviteLoading, setInviteLoading] = useState(true);

  const params = useParams();
  const supabase = useSupabase();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [calendars, setCalendars] = useState([]);
  const { createCalendar, getCalendar, gapiInited, createAcl } = useCalendar();
  const [createCalendarLoading, setCreateCalendarLoading] = useState(false);
  const [AllAthlete, setAllAthlete] = useState([]);
  const [SelectedEvents, setSelectedEvents] = useState([]);
  const [ChangeDateTable, setChangeDateTable] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const [activeView, setActiveView] = useState('training');
  const [isDeleting, setIsDeleting] = useState(false);
  const [droppedPlan, setDroppedPlan] = useState(null);
  const [droppedDate, setDroppedDate] = useState(null);
  const [showAddTraining, setShowAddTraining] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [loading,setLoading] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [resendError, setResendError] = useState("");
  const [resendSuccess, setResendSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [athleteLoading,setAthleteLoading]=useState(true)
  const location = useLocation();
  const getEventDate = (event_id) => {
    if (!athlete) {
      return;
    }
    const event = athlete.events.find((e) => e.id === event_id);
    return event.date;
  };
  useEffect(() => {
    if (!user) {
      return;
    }
    const fetchAthletes = async () => {
      try {
        setLoading(true);
        await getAthletes();
      } catch (error) {
        console.error("Error fetching athletes:", error);
      } finally {
        setLoading(false);
        setInviteLoading(false)
      }
    };
    
    fetchAthletes();
  }, [user?.id]);

  useEffect(() => {
    if (user?.id) {
      // Check if this is the user's first time
      if (user.is_first_login) {
        setModals(prev => ({ ...prev, welcome: true }));
      }
    }
  }, [user?.id, user?.is_first_login]);
 
  // Add this near your other useState declarations at the top of the component
  const [hasHandledInviteParam, setHasHandledInviteParam] = useState(false);
  
  // Replace the problematic useEffect
  useEffect(() => {
    if (!user || hasHandledInviteParam || athleteLoading) {
      return;
    }
    const checkInviteModal = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const shouldInviteModal = urlParams.get('openInviteModal');
    
    if (shouldInviteModal === 'true') {
      const { data, error } = await supabase.functions.invoke("st-sub-status");
      console.log("data",data)
      console.log("athletes",athletes)
      console.log("user",user)
      if (user.free_account || data?.isSubscribed || athletes.length < 3) {
        setModals((prev) => ({
          ...Object.fromEntries(Object.keys(prev).map((key) => [key, false])),
          invite: true,
        }));
          } else {
            console.log("payment")
        setModals(prev => ({ ...prev, payment: true }));
      }
      
      // Open the invite modal
    
      // Mark that we've handled this parameter
      setHasHandledInviteParam(true);
      
      // Remove the parameter from the URL without triggering a new history entry
      urlParams.delete("openInviteModal");
      const newUrl = window.location.pathname + (urlParams.toString() ? `?${urlParams.toString()}` : '');
      window.history.replaceState(null, "", newUrl);
    }
  }
  checkInviteModal()
  }, [user?.id, hasHandledInviteParam,athleteLoading]); // Removed location.search to avoid re-triggering
  const getAthletes = async() => {
    setAthleteLoading(true)

    try {
      const { data } = await getAthletesByCoachId(supabase, user.id);
      setAthletes(data || []);
      if (data && data.length > 0) {
        // Check if data has nested athletes property
        const processedData = data[0].athletes ? data.map(a => a.athletes) : data;
        // Filter out any null or undefined values and ensure each athlete has required fields
        const validAthletes = processedData.filter(athlete => 
          athlete && athlete.id && (athlete.first_name || athlete.last_name)
        );
        setAllAthlete(validAthletes);
     
      }
    } catch (error) {
      console.error("Error fetching athletes:", error);
      setAllAthlete([])
    }finally{
      setAthleteLoading(false)
    }
  };
  
  const reloadCalendars = () =>
    getCalendarsByCoachIdAthleteId(supabase, user.id, params.id).then(
      (response) => {
        if (response && response.data) {
          setCalendars(response.data);
        } else {
          setLoading(true)
          setCalendars([]);
        }
      }
    ).catch((error) => {
      console.error("Error loading calendars:", error);
      setCalendars([]);
    });
  useEffect(() => {
    if (!user) {
      return;
    }
    reloadCalendars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    if (AllAthlete.length === 1 && params.id === "default") {
      navigate(`/athlete/${AllAthlete[0].id}`);
        setAthlete(AllAthlete[0]);
        setSelectedEvents([]);
        setChangeDateTable(null);
        getCalendarsByCoachIdAthleteId(supabase, user.id, AllAthlete[0].id).then(
          (calendars) => {
            setCalendars(calendars.data);
          }
        );
    }
  }, [AllAthlete, params.id, navigate]);
  const createAthleteCalendar = async (user) => {
    setCreateCalendarLoading(true);
    try {
      const calendarData = {
        summary: `${user.full_name} / ${getAthleteName(
          athlete
        )} (C+ Calendar) `,
        description: `Coach+ Training Calendar for ${getAthleteName(athlete)}`,
        timeZone: getTimeZone(),
      };
      const calendar = await createCalendar(calendarData);
      const the_calendar = await getCalendar(calendar.id);
      await createAcl(calendar.id, athlete.email);

      const sbCalendar = {
        gcal_id: calendar.id,
        payload: the_calendar,
        enabled: true,
        public_url: `https://calendar.google.com/calendar/embed?src=${decodeURIComponent(
          calendar.id
        )}&ctz=${decodeURIComponent(calendar.timeZone)}`,
        athlete_id: params.id,
        coach_id: user.id,
        time_zone: calendarData.timeZone,
      };
      await insertCalendar(supabase, sbCalendar);

      // Create notification for calendar creation
      await supabase.from("notifications").insert({
        title: "New Calendar",
        message: `Calendar created for <strong>${athlete.full_name}</strong>
        `,
        action: "new_calendar",
        user_id: user.id,
        athlete_id: athlete.id,
        isRead: false,
        related_link:`https://app.coachplus.club/athlete/${athlete.id}`
      });

      await fetchAthlete();
      setShowSuccess(true);
      setCreateCalendarLoading(false);
    } catch (e) {
      console.error(e);
      setCreateCalendarLoading(false);
    }
  };

  const [checkInElements, setCheckInElements] = useState([]);

  const fetchAthlete = async () => {
    try {
      if (params.id === "default") {
        return;
      }
      const { data: athletes } = await getAthleteProfile(supabase, params.id);
      if (athletes && athletes.length > 0) {
        setAthlete(athletes[0]);
      }
      const { data: allAthlete } = await getAthletesByCoachId(supabase, user?.id);
      if (allAthlete) {
        // Ensure each athlete has a unique ID and filter out any undefined or null values
        const validAthletes = allAthlete
          .map(a => a.athletes)
          .filter(athlete => athlete && athlete.id);
        setAllAthlete(validAthletes);
      }
    } catch (error) {
      console.error("Error fetching athlete:", error);
    }
  };

  useEffect(() => {
    if (gapiInited) {
      fetchAthlete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gapiInited]);

  useEffect(() => {
    const fetchData = async () => {
      const elements = await renderCheckIns();
      setCheckInElements(elements);
    };
    if (athlete) {
      fetchData();
    }
  }, [athlete]);
  const oldestResponse = athlete?.onboarding_form_response?.length > 0 
    ? (athlete.onboarding_form_response.find(response => response.type === "onboarding") ||
       athlete.onboarding_form_response.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))[0])
    : null;
  const isWaitingOnboarding =
    athlete && oldestResponse && oldestResponse.status !== "completed";

  const renderCheckIns = async () => {
    const checkInElements = await Promise.all(
      athlete.check_ins.map(async (c, i) => {
        // Get the event ID from the check-in
        const checkInEventId = c.event_id;

        // Find the corresponding event in athlete.events
        const matchingEvent = athlete.events.find(
          (event) => event.id === checkInEventId
        );

        // Check if a matching event was found
        if (matchingEvent) {
          const eventDate = getEventDate(checkInEventId);
          const date = new Date(eventDate);
          const options = { weekday: "short", month: "short", day: "numeric" };
          const formattedDate = date.toLocaleDateString("en-US", options);

          const { data: plan } = await getPlanByIdClient(
            supabase,
            matchingEvent.plan_client_id
          );

          return (
            <React.Fragment key={i}>
              <Box pl={3} pt={2} pr={3}>
                <ListItemText
                  sx={{
                    ".css-mbfek": {
                      marginTop: "14px",
                    },
                    ".css-yb0lig": {
                      display: "flex",
                    },
                  }}
                  primary={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          backgroundColor: " #013B12",
                          minHeight: "25px",
                          borderRadius: "30px",
                          display: "flex",
                          justifyContent: "center",
                          color: "#02BD36",
                          fontSize: "12px",
                          alignItems: "center",
                          padding: "0px 11px",
                          width: "fit-content",
                        }}
                      >
                        {formattedDate}
                      </div>
                      <div
                        style={{
                          minHeight: "25px",
                          display: "flex",
                          justifyContent: "center",
                          color: "#013B12",
                          fontSize: "14px",
                          alignItems: "center",
                          width: "fit-content",
                          marginLeft: "10px",
                        }}
                      >
                        {plan[0].name}
                      </div>
                    </div>
                  }
                  secondary={
                    <div
                      style={{
                        backgroundColor: "#E9FFCE",
                        color: "#525A65",
                        fontWeight: "400 !important",
                        fontSize: "12px",
                        display: "flex",
                        padding: "1px 16px",
                        alignItems: "center",
                        width: "fit-content",
                        borderTopLeftRadius: "16px",
                        borderBottomRightRadius: "16px",
                        borderTopRightRadius: "16px",
                        lineHeight: "1 !important",
                        marginTop: "13px",
                        marginLeft: "15px",
                      }}
                    >
                      <pre>{c.feedback ? c.feedback : "Not answered yet"}</pre>
                    </div>
                  }
                />
              </Box>
            </React.Fragment>
          );
        }
      })
    );

    return checkInElements;
  };

  useEffect(() => {
    if (!user) return;

    // We already fetch athletes in getAthletes() which is called in another useEffect
    // This prevents duplicate fetching and potential race conditions
    // If you need to keep this separate fetch, uncomment the code below
    /*
    setLoading(true);
    getAthletesByCoachId(supabase, user.id)
      .then(({ data }) => {
        if (data) {
          // Ensure each athlete has a unique ID and filter out any undefined or null values
          const processedData = data[0].athletes ? data.map(a => a.athletes) : data;
          const validAthletes = processedData
            .filter(athlete => athlete && athlete.id && (athlete.first_name || athlete.last_name));
          console.log("Setting athletes from useEffect:", validAthletes);
          setAllAthlete(validAthletes);
        }
      })
      .catch((error) => {
        console.error("Error fetching athletes:", error);
      })
      .finally(() => {
        setLoading(false);
      });
    */
  }, [user, supabase]);
  // Effect to initialize border radius when component mounts
  useEffect(() => {
    const input = document.querySelector('.athlete-autocomplete .MuiInputBase-root');
    if (input) {
      input.style.borderRadius = '20px';
    }
  }, []);

  // Effect to handle border radius when dropdown state changes
  useEffect(() => {
    const input = document.querySelector('.athlete-autocomplete .MuiInputBase-root');
    if (input) {
      if (isDropdownOpen) {
        input.style.borderBottomLeftRadius = '0px';
        input.style.borderBottomRightRadius = '0px';
        input.style.borderTopLeftRadius = '20px';
        input.style.borderTopRightRadius = '20px';
      } else {
        input.style.borderRadius = '20px';
      }
    }
  }, [isDropdownOpen]);
  const resendHandle = async(id)=>{
    try {
      // Get the current form response
      const { data: formResponse, error: formError } = await supabase
        .from('onboarding_form_response')
        .select('lastResend')
        .eq('id', id)
        .single();

      if (formError) {
        setResendError("Failed to check last resend time");
        return;
      }

      // Check if last resend was within 10 minutes
      if (formResponse.lastResend) {
        const lastResendTime = new Date(formResponse.lastResend);
        const tenMinutesAgo = new Date(Date.now() - 10 * 60 * 1000);
        
        if (lastResendTime > tenMinutesAgo) {
          const waitMinutes = Math.ceil((lastResendTime - tenMinutesAgo) / 60000);
          setResendError(`Please wait ${waitMinutes} minutes before resending`);
          return;
        }
      }

      // Send the welcome message
      await supabase.functions.invoke("welcome-wp", {
        body: {
          phone: athlete.phone_number.startsWith('+') ? athlete.phone_number : `+${athlete.phone_number}`,
          onboarding_url: `https://app.coachplus.club/forms/${id}`,
          coach_name: user.full_name,
          client_name: `${athlete.first_name} ${athlete.last_name}`,
        },
      });

      // Update lastResend timestamp
      const { error: updateError } = await supabase
        .from('onboarding_form_response')
        .update({ lastResend: new Date().toISOString() })
        .eq('id', id);

      if (updateError) {
        setResendError("Failed to update resend time");
        return;
      }

      setResendSuccess(true);
      setResendError("");
      
      // Clear success message after 3 seconds
      setTimeout(() => {
        setResendSuccess(false);
      }, 3000);

    } catch (error) {
      setResendError("Failed to resend invitation");
    }
  }
  if(modals.invite){
 return( <AthleteInviteModal
onSuccess={getAthletes}
open={modals.invite}
handleClose={() => setModals(prev => ({ ...prev, invite: false }))}
/>)
}

  if(athleteLoading){
    return(
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          backgroundColor: '#FBFBFB',
          gap: 2,
        }}
      >
        <CircularProgress 
          size={40}
          thickness={4}
          sx={{
            color: '#02BD36',
            '& .MuiCircularProgress-circle': {
              strokeLinecap: 'round',
            }
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Bricolage Grotesque',
            fontSize: '16px',
            color: '#525A65',
            fontWeight: 400,
            letterSpacing: '0.15px'
          }}
        >
          Loading client details...
        </Typography>
      </Box>
    );
  }

  // Add a new function to reset drop state completely 
  const resetDropState = () => {
    console.log("Resetting drop state");
    setDroppedPlan(null);
    setDroppedDate(null);
    setShowAddTraining(false);
  };

  // Then update the onAddTrainingClose prop usage
  const handleAddTrainingClose = () => {
    resetDropState();
  };

  return (
    <div style={{padding:"0!important",background:"#fff",minHeight:"100vh",
      paddingTop:"0!important",

    }}>
      <Box sx={{background:"#fff"}}>
   <PaymentDialog
        open={modals.payment}
        handleClose={() => {
          setModals(prev => ({ ...prev, payment: false }));
        }}
      />
  
      
      <WelcomeModal
        open={modals.welcome}
        onClose={() => setModals(prev => ({ ...prev, welcome: false }))}
      />
      
      {modals.event && (
        <CreateEventModal
          open={modals.event}
          handleClose={() => setModals(prev => ({ ...prev, event: false }))}
          onSuccess={() => {
            reloadCalendars();
          }}
          athleteId={params.id}
        />
      )}
      {modals.edit && (
        <EditModal
          editModalOpen={modals.edit}
          setEditModalOpen={(value) => setModals(prev => ({ ...prev, edit: value }))}
          athlete={athlete}
          onSuccess={() => fetchAthlete()}
        />
      )}
      <Dialog
        onClose={() => setModals(prev => ({ ...prev, contact: false }))}
        aria-labelledby="customized-dialog-title"
        open={modals.contact}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Feedback for {new Date(modals.contact?.created_at).toLocaleDateString()}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setModals(prev => ({ ...prev, contact: false }))}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            {modals.contact?.feedback || "No feedback provided"}
          </Typography>
        </DialogContent>
      </Dialog>
      <PageContainer paddingTop="17px!important" >
        <Grid  sx={{position:"relative",display:"flex",justifyContent:"center",width:"100%",background:"#fff"}}>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              width:"100%",
              flexDirection:"column",
              gap:"14px",
              background:"#fff",


            }}
          >
        
            <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",
            
              gap:"12px"
              
            }}>
        <Autocomplete
          key="athlete-autocomplete"
          options={AllAthlete || []}
          loading={loading}
          noOptionsText="You don't have any clients yet..."
          value={athlete}
          onChange={(event, value) => {
            // If the value is our special "invite" option
            if (value && value.id === "invite-new-client") {
              // Check subscription status before opening the modal
              const checkSubscriptionStatus = async () => {
                try {
                  setInviteLoading(true);
                  const { data, error } = await supabase.functions.invoke("st-sub-status");
                  
                  if (error) {
                    console.error("Error checking subscription status:", error);
                    // If there's an error checking subscription, allow inviting anyway
                    setModals(prev => ({ ...prev, invite: true }));
                    return;
                  }

                  // Open payment modal instead of invite modal if:
                  // 1. User does NOT have a free account AND
                  // 2. User is NOT subscribed AND
                  // 3. User already has 3 or more athletes
                  if (!user.free_account && !data?.isSubscribed && athletes.length >= 3) {
                    setModals(prev => ({ ...prev, payment: true }));
                  } else {
                    setModals(prev => ({ ...prev, invite: true }));
                  }
                } catch (error) {
                  console.error("Error in invite option handler:", error);
                  // If there's an error, allow inviting anyway
                  setModals(prev => ({ ...prev, invite: true }));
                } finally {
                  setInviteLoading(false);
                }
              };
              
              checkSubscriptionStatus();
              return;
            }
            
            if (value?.id) {
              navigate(`/athlete/${value.id}`, { replace: true });
              setAthlete(value);
              setSelectedEvents([]);
              setChangeDateTable(null);
              getCalendarsByCoachIdAthleteId(supabase, user.id, value.id).then(
                (calendars) => {
                  setCalendars(calendars.data);
                }
              );
            }
            // When a value is selected, immediately close the dropdown
            if (value) {
              setIsDropdownOpen(false);
            }
          }}
          getOptionLabel={(option) => {
            if (!option) return "";
            if (option.id === "invite-new-client") return "+ Invite a new client";
            const firstName = option.first_name || "";
            const lastName = option.last_name || "";
            return `${firstName} ${lastName}`.trim();
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionKey={(option) => option?.id || Math.random()}
          filterOptions={(options, state) => {
            const { inputValue } = state;
            const input = inputValue.toLowerCase().trim();
            
            let filteredOptions = options;
            if (input) {
              filteredOptions = options.filter(option => {
                if (!option) return false;
                const firstName = (option.first_name || "").toLowerCase();
                const lastName = (option.last_name || "").toLowerCase();
                const fullName = `${firstName} ${lastName}`.toLowerCase();
                return firstName.includes(input) || 
                        lastName.includes(input) || 
                        fullName.includes(input);
              });
            }
            
            // Add the invite option at the BEGINNING of the list
            const inviteOption = { id: "invite-new-client" };
            return [inviteOption, ...filteredOptions];
          }}
          renderOption={(props, option) => {
            if (option.id === "invite-new-client") {
              return (
                <li
                  {...props}
                  key="invite-new-client"
                  style={{
                    fontFamily: 'Bricolage Grotesque',
                    fontStyle: 'normal',
                    fontWeight: "600",
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: "#02BD36", // Change color on hover
                    borderBottom: '1px solid #CECECE',
                    paddingY:"7px",
                    height:"36px"
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                
             
                >
                  + Invite a new client
                </li>
              );
            }
            return <li {...props}>{`${option.first_name || ''} ${option.last_name || ''}`.trim()}</li>;
          }}
          PopperComponent={(props) => (
            <Popper
              {...props}
              style={{
                width: "328px !important",
                maxWidth: "100% !important"
              }}
              modifiers={[
                {
                  name: 'matchWidth',
                  enabled: true,
                  phase: 'beforeWrite',
                  requires: ['computeStyles'],
                  fn: ({ state }) => {
                    state.styles.popper.width = `${state.rects.reference.width}px`;
                  },
                  effect: ({ state }) => {
                    state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
                  }
                }
              ]}
            />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select your client..."
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      pointerEvents: "none",
                      padding:"0",
                      margin:"0",
                      width:"10px",
                      height:"16px"
                    }}
                  >
                    <svg
                      width="10"
                      height="16"
                      viewBox="0 0 10 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ 
                        transform: isDropdownOpen ? 'rotate(270deg)' : 'rotate(90deg)',
                        transition: 'transform 0.2s ease-in-out'
                      }}
                    >
                      <path
                        d="M1.5 1L8.5 8L1.5 15"
                        stroke="#0F0F0F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                ),
                sx: {
                  padding: "6px 15px!important",
                  "& input": {
                    position: "relative",
                  },
                  "& input::placeholder": {
                    position: "relative",
                    color:"#0F0F0F"
                  },
                  "&.Mui-focused input::placeholder": {
                    
                
                  },
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": { border: "none" },
                    "&:hover fieldset": { border: "none" },
                    "&.Mui-focused fieldset": { border: "none" },
                  },
                },
              }}
            />
          )}
          PaperComponent={(props) => (
            <Paper
              {...props}
              sx={{
                backgroundColor: "#EEEEEE",
                marginTop: "-1px",
                border: "1px solid #000",
                borderTop: "none",
                borderBottomLeftRadius: "24px",
                borderBottomRightRadius: "24px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
                width: "100% !important",
                maxWidth: "328px !important",
                left: "0 !important",
                textAlign:"left",

              }}
            />
          )}
          ListboxProps={{
            sx: {
              padding: "0px",
              maxHeight: "250px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#525A65",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#0F0F0F",
              },
              "& .MuiAutocomplete-option": {
                color: "#525A65",
                fontSize: "14px",
                borderBottom: "1px solid #CECECE",
                padding: "7px 24px",
                fontWeight:"600",
                textAlign:"left",
                margin: "0px",
                width: "calc(100% - 40px)",
                marginLeft: "20px",
                marginRight: "20px",
                paddingLeft:"0",
                "&:last-child": {
                  borderBottom: "none",
                },
                "&.Mui-focused": {
                  backgroundColor: "transparent !important",
                },
                "&[aria-selected='true']": {
                  backgroundColor: "transparent !important",
                  color: "#000 !important",
                  fontWeight: "500 !important",
                },
                "&:hover": {
                  backgroundColor: "transparent !important",
                  fontWeight: "800 !important",
                },
              },
            },
          }}
          className="athlete-autocomplete"
          onOpen={() => {
            setIsDropdownOpen(true);
          }}
          onClose={() => {
            setIsDropdownOpen(false);
          }}
          sx={{
            width: {xs:"270px",md:"320px"},
            maxWidth: {xs:"270px",md:"320px"},
            maxHeight: "48px",
            
            ".MuiInputBase-root": {
              borderRadius: "20px",
              border: "1px solid",
              borderColor: isDropdownOpen ? "#000" : "#000", 
              backgroundColor: "#EEEEEE",
            },
            ".MuiInputBase-root.Mui-focused": {
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              "& .MuiInputBase-input": {
                "&::placeholder": {
                  paddingBottom: "5px",
                  border:"none"
                }
              }
            },
            ".MuiInputBase-root.MuiInputBase-root-MuiAutocomplete-input": {
              borderRadius: "20px",
            },
            ".MuiInputBase-input": {
              fontSize: "12px",
              padding: "14px 24px",
              color: "#0F0F0F",
              background:"#EEEEEE!important",
              fontWeight:"600",
              "&::placeholder": {
                opacity: 1,
                color: "#0F0F0F",
                fontWeight:"600"
              }
            },
            "& .MuiAutocomplete-popper .MuiAutocomplete-option": {
              backgroundColor: "transparent !important",
            },
            "& .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']": {
              backgroundColor: "transparent !important",
              color: "#000 !important",
              fontWeight: "500 !important",
            },
            "& .MuiAutocomplete-popper .MuiAutocomplete-option.Mui-focused": {
              backgroundColor: "transparent !important",
            },
            "& .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
              backgroundColor: "transparent !important",
              color: "#000 !important",
              fontWeight: "500 !important",
            }
          }}
        />
    <Box sx={{ position: 'relative',width:"48px",height:"48px",display:"flex",alignItems:"center",justifyContent:"center" }}>
      <Button 
        onClick={(event) => athlete && setAnchorEl(event.currentTarget)}
        aria-controls="athlete-options-menu"
        aria-haspopup="true"
        disabled={!athlete}
      >
        {!athlete?
           <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="#EEEEEE" stroke="#D9D9D9"/>
          <circle cx="16" cy="24" r="2" fill="#D9D9D9"/>
          <circle cx="24" cy="24" r="2" fill="#D9D9D9"/>
          <circle cx="32" cy="24" r="2" fill="#D9D9D9"/>
        </svg> 
:
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="#EEEEEE" stroke="#525865"/>
<circle cx="16" cy="24" r="2" fill="#0F0F0F"/>
<circle cx="24" cy="24" r="2" fill="#0F0F0F"/>
<circle cx="32" cy="24" r="2" fill="#0F0F0F"/>
</svg>
}

     
      </Button>
      <Menu
        id="athlete-options-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            backgroundColor: '#EEEEEE',
            border: '1px solid #525A65',
            borderRadius: "20px",
            width: "100%",
            maxWidth: "200px",
            cursor:"pointer",
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            '& .MuiMenuItem-root:hover': {
              backgroundColor: 'unset',
              fontWeight: "500",
            }
          }
        }}
      >
        <MenuItem 
          onClick={() => {
            setAnchorEl(null);
            setModals(prev => ({ ...prev, contact: true }));
          }}
          disabled={!athlete}
          sx={{ 
            minWidth: '150px',
            fontSize: "14px",
            color: "#525A65",
            fontWeight:"500",
            lineHeight:"17px",
            pb:"10px",
            position: 'relative',
            "&:hover": {
              backgroundColor: "transparent !important",
              fontWeight:"600!important",
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: '16px',
              width: 'calc(100% - 32px)',
              borderBottom: '1px solid #CECECE',
            }
          }}
        >
          Contact Info
        </MenuItem>
        <MenuItem 
          onClick={() => {
            setAnchorEl(null);
            if (athlete && athlete.phone_number) {
              window.location.href = `tel:${athlete.phone_number}`;
            }
          }}
          disabled={!athlete || !athlete.phone_number}
          sx={{ 
            minWidth: '150px',
            fontSize: "14px",
            color: "#525A65",
            fontWeight:"500",
            py:"10px",

            position: 'relative',
            "&:hover": {
              backgroundColor: "transparent !important",
              fontWeight:"600!important",
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: '16px',
              width: 'calc(100% - 32px)',
              borderBottom: '1px solid #CECECE',
            }
          }}
        >
          Give a call
        </MenuItem>
        <MenuItem 
          onClick={() => {
            setAnchorEl(null);
            if (athlete && athlete.email) {
              window.location.href = `mailto:${athlete.email}`;
            }
          }}
          disabled={!athlete || !athlete.email}
          sx={{ 
            minWidth: '150px',
            fontSize: "14px",
            color: "#525A65",
            py:"10px",

            fontWeight:"500",
            position: 'relative',
            "&:hover": {
              backgroundColor: "transparent !important",
              fontWeight:"600!important",
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: '16px',
              width: 'calc(100% - 32px)',
              borderBottom: '1px solid #CECECE',
            }
          }}
        >
          Send an email
        </MenuItem>
        <MenuItem 
          onClick={() => {
            setAnchorEl(null);
            setModals(prev => ({ ...prev, delete: true }));
          }}
          disabled={!athlete}
          sx={{ 
            minWidth: '150px', 
            fontSize: "14px",
            fontWeight:"500",
                    color: '#EC0B43',
            pt:"10px",
            position: 'relative',
            "&:hover": {
              backgroundColor: "transparent !important",
             fontWeight:"600!important",
            
            },
          }}
        >
          Delete Client
        </MenuItem>
      </Menu>
    </Box>
    </Box>
    <Box sx={{ display: 'flex', gap: "3.8%", position: 'relative',width:"100%",justifyContent:"center",margin:"0",border:"none"}}>
      <Button 
        onClick={() => setActiveView('training')}
        sx={{
          '& svg circle, & svg rect, & svg path': {
            fill: (athlete&&(activeView === 'training' ? '#02BD36' : '#D9D9D9'))
          },
          position: 'relative',
          pb: 1.5,
          mb: -0.5,
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '4px',
            borderRadius:"20px",

            backgroundColor: (athlete&&activeView === 'training' ? '#02BD36' : 'transparent'),
            zIndex: 2
          }
        }}
      >
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5.84947" cy="5.84947" r="5.84947" fill="#D9D9D9"/>
          <rect y="14.2969" width="11.6989" height="11.6989" rx="2" fill="#D9D9D9"/>
          <path d="M24.8212 11.6989H15.4693C15.0616 11.6989 14.6875 11.4882 14.4743 11.1458C14.261 10.8034 14.2423 10.3706 14.4294 10.0056L19.1053 0.598305C19.5019 -0.199435 20.7999 -0.199435 21.1964 0.598305L25.8724 10.0056C26.0594 10.3706 26.037 10.8034 25.8275 11.1458C25.618 11.4882 25.2439 11.6989 24.8325 11.6989H24.8212Z" fill="#D9D9D9"/>
          <rect x="23.6523" y="14.3008" width="3.30889" height="13.2356" rx="1.65444" transform="rotate(45 23.6523 14.3008)" fill="#D9D9D9"/>
          <rect x="25.9922" y="23.6602" width="3.30889" height="13.2356" rx="1.65444" transform="rotate(135 25.9922 23.6602)" fill="#D9D9D9"/>
        </svg>
      </Button>
      <Button 
        onClick={() => setActiveView('forms')}
        sx={{
          '& svg path': {
            fill: athlete&&(activeView === 'forms' ? '#02BD36' : '#D9D9D9')
          },
          position: 'relative',
          pb: 1.5,
          mb: -0.5,
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            borderRadius:"20px",
            height: '4px',
            backgroundColor: athlete&&(activeView === 'forms' ? '#02BD36' : 'transparent'),
            zIndex: 2
          }
        }}
      >
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0C2.23858 0 0 2.23858 0 5V21C0 23.7614 2.23858 26 5 26H21C23.7614 26 26 23.7614 26 21V5C26 2.23858 23.7614 0 21 0H5ZM6.49687 6.5C5.7789 6.5 5.19687 7.08203 5.19687 7.8C5.19687 8.51797 5.7789 9.1 6.49687 9.1H19.4969C20.2148 9.1 20.7969 8.51797 20.7969 7.8C20.7969 7.08203 20.2148 6.5 19.4969 6.5H6.49687ZM6.49687 11.7C5.7789 11.7 5.19687 12.282 5.19687 13C5.19687 13.718 5.7789 14.3 6.49687 14.3H19.4969C20.2148 14.3 20.7969 13.718 20.7969 13C20.7969 12.282 20.2148 11.7 19.4969 11.7H6.49687ZM5.19687 18.2C5.19687 17.482 5.7789 16.9 6.49687 16.9H11.6969C12.4148 16.9 12.9969 17.482 12.9969 18.2C12.9969 18.918 12.4148 19.5 11.6969 19.5H6.49687C5.7789 19.5 5.19687 18.918 5.19687 18.2Z" fill="#D9D9D9"/>
        </svg>
      </Button>
          <Divider sx={{ 
            position: 'absolute', 
           bottom:"-4px",
           right:"0",
           left:"0",
            border:"1px solid ",
            width: '120%',
            borderColor: 'rgba(0, 0, 0, 0.12)',
            zIndex: 1
          }}/>
        
    </Box>

 
          </Grid>
          {/* <Grid
            item
            xs={6}
            sx={{
              paddingTop: "32px !important",
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => (window.location.href = `mailto:${athlete.email}`)}
              type="button"
              sx={buttonStyle2}
            >
              Send an email
            </Button>
            <Button
              onClick={() =>
                (window.location.href = `tel:${athlete.phone_number}`)
              }
              type="button"
              sx={buttonStyle}
            >
              Give a call
            </Button>
            <Button
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to delete this athlete?"
                  )
                ) {
                  deleteAthlete(supabase, athlete.id).then(() => navigate("/"));
                }
              }}
              type="button"
              sx={buttonStyle3}
            >
              Delete Client
            </Button>
          </Grid> */}

        </Grid>
      
      
        <Paper
          sx={{
            mb: 4,
            backgroundColor: "transparent",
            borderRadius: "24px",
            border: "none",
            display: athlete && athlete?.calendars.length > 0 ? "none" : "",
          }}
          variant="outlined"
        >
          <Box sx={{ paddingY: 3 }}>
            {isWaitingOnboarding && (
              <Box display="flex" alignItems="center" mb={1} flexDirection="column" sx={{
                height:"40vh",
                justifyContent:"center"
              }}>
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight="900"
                    sx={{ mr: 2, mb: 1 }}
                  >
                  </Typography>
                  {/* {isWaitingOnboarding && ( */}
                  <Button
                  sx={{
                    color:"#525A65",
                    fontSize:"20px",
                    textTransform:"none",
                    textAlign:"center"
                  }}
                    target="_blank"
                    href={`/forms/${athlete.onboarding_form_response[0].id}`}
                  >
Invitation sent! Awaiting approval.                  </Button>
                  <Box sx={{
                    mt:"14%",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    gap:"15px",
                    color:"#02BD36"
                  }}>
                  Need to resend the invitation?
                  <Button
                    onClick={()=>resendHandle(athlete.onboarding_form_response[0].id)}
                    sx={{
                      color:"#0F0F0F",
                      textTransform:"none",
                      background:"#EEEEEE",
                      border:"1px solid #525A65",
                      borderRadius:"16px",
                      padding:"5px 12px",
                    }}
                  >
                    Resend
                  </Button>
                  {resendError && (
                    <Typography 
                      sx={{ 
                        color: "#d32f2f",
                        fontSize: "12px",
                        textAlign: "center",
                        mt: 1
                      }}
                    >
                      {resendError}
                    </Typography>
                  )}
                  {resendSuccess && (
                    <Typography 
                      sx={{ 
                        color: "#02BD36",
                        fontSize: "12px",
                        textAlign: "center",
                        mt: 1
                      }}
                    >
                      Invitation resent successfully!
                    </Typography>
                  )}
                  </Box>
                  {/* )} */}
                </Box>

                {/* <IconButton
                  size="small"
                  href={`tel:${athlete.phone_number}`}
                  sx={{ mr: 1, marginLeft: "auto" }}
                >
                  <Phone />
                </IconButton>
                <IconButton size="small" href={`mailto:${athlete.email}`}>
                  <Mail />
                </IconButton>
                <IconButton size="small" onClick={() => setEditModal(true)}>
                  <Edit />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this athlete?"
                      )
                    ) {
                      deleteAthlete(supabase, athlete.id).then(() =>
                        navigate("/")
                      );
                    }
                  }}
                >
                  <Delete />
                </IconButton> */}
              </Box>
            )}

            {!isWaitingOnboarding && (
              <Box
                sx={{
                }}
              >
                {athlete && athlete.calendars.length === 0 && activeView !== 'forms' && (
                  <Typography variant="subtitle2">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      mb={3}
                      gap="10px"
                    >
                      <Box
                        sx={{
                          fontFamily: "'Bricolage Grotesque'",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "20px",
                          lineHeight: "19px",
                          color: "#525A65",
                          textAlign:"center",
                          paddingLeft: "4px",
                          mt:"5%"
                        }}
                      >
                        <Typography
                        
                        sx={{color:"#02BD36",fontSize:"20px"}}
                        >
                        Your client onboarded on CoachPlus.

                        </Typography>
                        <Typography
                        mt={3}
                        fontSize="20px"
                        >

                       Now, let's create a calendar for your client to create plans <br /> and manage their training schedule.
                        </Typography>
                      </Box>

                      <Button
                        onClick={() => createAthleteCalendar(user)}
                        color="success"
                        sx={{
                          fontFamily: "Bricolage Grotesque",
                          mt: 3,
                          ml: 0,
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "14px",
                          textTransform:"none",
                          background:"#EEEEEE",
                          borderRadius: "16px",
                          padding: "7px 11px",
                          border: "1px solid #525A65",
                          color: "#0F0F0F",
                        }}
                        disabled={createCalendarLoading}
                      >
                        Create Calendar
                      </Button>
                      {createCalendarLoading && (
                        <CircularProgress
                          size={20}
                          sx={{ ml: 2 }}
                          color="info"
                        />
                      )}
                    </Box>
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Paper>
        
        {!athlete&&(
              <PageContainer>
              <Box 
                sx={{ 
                  padding:{xs:"10px",md:"unset"},
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  textAlign: 'center',
                  gap:"25px",
                  mt:2
                }}
              >
                <Typography sx={{
                  color:"#02BD36",
                  fontSize:{xs:"28px",md:"32px"},
                  fontWeight:"600",
                  lineHeight:"30px",
                  animation: 'pulseZoom 2s ease-in-out',
                  '@keyframes pulseZoom': {
                    '0%': { transform: 'scale(0.9)' },
                    '50%': { transform: 'scale(1.1)' },
                    '100%': { transform: 'scale(1)' }
                  }
                }}>
                  Hold on!
                </Typography>
                <Typography sx={{
                  fontSize:{xs:"16px",md:"20px"},
                  color:"525A65",
                  lineHeight:"30px"
                }}>
                  It seems like you haven't selected a client yet. Please choose a <Box sx={{width:"auto",height:"auto",display:{xs:"none",sm:"unset"}}}> <br /></Box>
                  client to manage workouts and progress.
                </Typography>
              
              </Box>
            </PageContainer>
        )}
        {!isWaitingOnboarding && (
          <>

            {athlete && athlete.calendars.length > 0 && activeView === 'training' && (
              
              <CardDayAtheletesPlan
                athlete={athlete}
                OnchangeEvents={setSelectedEvents}
                onChangeDateTable={setChangeDateTable}
                curDate={ChangeDateTable}
                onDrop={(plan, date) => {
                  if (athlete && athlete.calendars.length > 0) {
                    console.log("Drop target date:", date);
                    
                    // Clear any previous dropped state first
                    resetDropState();
                    
                    // Small delay to ensure state is cleared before setting new values
                    setTimeout(() => {
                      // Format the date for display
                      const formattedDate = date.toLocaleString("en-US", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      });
                      
                      // Add a unique timestamp to each dropped plan
                      const uniqueDropId = Date.now();
                      console.log(`Setting new drop (id: ${uniqueDropId}) for date: ${formattedDate}`);
                      
                      // Directly show the AddNewTraining component and handle the drop
                      setChangeDateTable(formattedDate);
                      setDroppedDate(date.toISOString());
                      setDroppedPlan({
                        ...plan, 
                        dropTargetDate: date, 
                        dropTimestamp: uniqueDropId,
                        uniqueDropId: uniqueDropId
                      });
                      setShowAddTraining(true);
                    }, 100);
                  }
                }}
              />
            )}

            <Grid2 container spacing={2}>
              {activeView === 'training' && (
                <Grid2 xs={12}>
                  {athlete && athlete.calendars.length > 0 && (
                    <ClientDetailTraining
                      events={SelectedEvents}
                      height={600}
                      date={ChangeDateTable}
                      currentAthlete={athlete}
                      user={user}
                      reload={() => {
                        fetchAthlete();
                      }}
                      onNewEventClick={() => {
                        setModals(prev => ({ ...prev, event: true }));
                      }}
                      onEventDelete={() => {
                        reloadCalendars();
                      }}
                      droppedPlan={droppedPlan}
                      droppedDate={droppedDate}
                      showAddTraining={showAddTraining}
                      onAddTrainingClose={handleAddTrainingClose}
                    />
                  )}
                </Grid2>
              )}
              
              {activeView === 'feedback' && (
                <Grid2 xs={12} sx={{ borderRadius: "24px" }}>
                  {athlete && athlete.calendars.length > 0 && (
                    <Paper
                      variant="outlined"
                      sx={{
                        overflow: "auto",
                        height: 600,
                        backgroundColor: "#FBFBFB",
                        borderRadius: "24px",
                        border: "none",
                      }}
                    >
                      <Box sx={{ maxHeight: "600px" }}>
                        <List
                          sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                            minHeight: "580px",
                          }}
                          subheader={
                            <ListSubheader
                              sx={{
                                color: "#525A65",
                                fontWeight: "600 important",
                                fontSize: "16px ",
                                pb: 1,
                                pl: 3,
                                pt: 2,
                              }}
                            >
                              Client Training Feeback
                            </ListSubheader>
                          }
                        >
                          <Box sx={{ position: 'relative', width: '100%' }}>
                            <Divider sx={{ 
                              position: 'absolute', 
                              left: '-100vw', 
                              right: '-100vw', 
                              width: '200vw',
                              borderColor: '#CECECE'
                            }}/>
                          </Box>
                          {checkInElements}
                        </List>
                      </Box>
                    </Paper>
                  )}
                </Grid2>
              )}

              {activeView === 'forms' && (
                <Grid2 xs={12}>
                  {athlete && (
                    <Paper
                      variant="outlined"
                      sx={{
                        overflow: "auto",
                        backgroundColor: "#EEEEE",
                        borderRadius: "24px",
                        border: "none",
                        p: 3
                      }}
                    >
                      <Forms athleteId={athlete.id} />
                    </Paper>
                  )}
                </Grid2>
              )}
            </Grid2>
          </>
        )}
      </PageContainer>
      <Modal open={showSuccess} onClose={() => setShowSuccess(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "540px",
            height: "550px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            outline: "none",
          }}
        >
          <Typography sx={{ fontSize: "16px" }} variant="h6" component="h2">
            Success!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              
            }}
          >
            <Typography variant="h6" component="h2">
              <SuccessIcon />
            </Typography>
            <Typography
              sx={{
                mt: 2,
                fontSize: "14px",
                fontFamily: "Bricolage Grotesque!important",
                textAlign: "center",
                maxWidth: "370px",
                textAlign: "center",
                color: "#000!important",
                fontWeight: "400!important",
              }}
            >
              The calendar has been created!
              <br />
              You can quickly begin assigning training sessions and managing
              their schedule.
            </Typography>
          </Box>
          <Button
            onClick={() => setShowSuccess(false)}
            sx={{
              backgroundColor: "#EEEEEE",
              borderRadius: "20px",
              color: "#0F0F0F",
              px: 2,
              fontSize: "15px",
              fontWeight: "400!important",
              border: "1px solid #525A65",
              py: 1,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#EEEEEE",
              },
            }}
          >
            Okay!
          </Button>
        </Box>
      </Modal>

      <Dialog
        open={modals.contact}
        onClose={() => setModals(prev => ({ ...prev, contact: false }))}
        PaperProps={{
          sx: {
            backgroundColor: '#FFFFFF',
            borderRadius: '24px',
            maxWidth: '529px',
            width: '100%',
            padding: '32px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Bricolage Grotesque'
          }
        }}
      >

        
        {athlete && (
          
          <AthleteDetail athlete={athlete} setModals={setModals} />
        )}
      </Dialog>
     
      
        <Dialog
          open={modals.delete}
          onClose={() => setModals(prev => ({ ...prev, delete: false }))}
          PaperProps={{
            sx: {
              maxWidth: {xs:"100%",sm:'490px'},
              width:"100%",
              height: '100%',
              maxHeight:"550px",
              overflow:"hidden",
              background: '#FBFBFB',
              borderRadius: '24px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '40px 20px',
            }
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            width: '100%',
            flex: 1
          }}>
            <Typography sx={{
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '29px',
              color: '#525A65',
              marginTop: '54px'
            }}>
              Delete Client
            </Typography>

            <Box sx={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              background: '#EC0B43',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px'
            }}>
              <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="50" fill="#EC0B43"/>
                <path d="M49.9557 78.9265C47.5282 78.9265 45.3537 77.467 44.4155 75.233C43.4921 72.999 43.9985 70.4225 45.7261 68.6949C47.4388 66.9822 50.0153 66.4609 52.2493 67.3992C54.4833 68.3226 55.9428 70.5119 55.9428 72.9395C55.9428 76.2457 53.262 78.9265 49.9557 78.9265Z" fill="#FBFBFB"/>
                <path d="M53.6938 57.6462C53.5448 59.21 51.9066 60.4312 49.9407 60.4312C47.9748 60.4312 46.3365 59.21 46.1876 57.6462L43.3281 26.4004C43.3281 23.4813 46.2919 21.1133 49.9556 21.1133C51.7874 21.1133 53.4406 21.709 54.632 22.6622C55.8235 23.6153 56.5681 24.9408 56.5681 26.4004L53.6938 57.6462Z" fill="#FBFBFB"/>
              </svg>
            </Box>

            <Typography sx={{
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 400,
              fontSize: {xs:"14px",sm:'16px'},
              lineHeight: '19px',
              textAlign: 'center',
              color: '#000000',
              maxWidth: '359px',
              marginTop: '20px'
            }}>
              Are you sure you want to delete <span style={{ color: '#EC0B43', fontWeight: '700' }}>{athlete?.first_name} {athlete?.last_name}</span>? This action cannot be undone and all associated data will be permanently removed.
            </Typography>

            <Box sx={{
              display: 'flex',
              gap: '30px',
              paddingTop: '13%',
              paddingBottom: '40px',
            }}>
              <Button
                onClick={() => setModals(prev => ({ ...prev, delete: false }))}
                disabled={isDeleting}
                sx={{
                  padding: '15px 22px',
                  background: '#EEEEEE',
                  border: '1px solid #525A65',
                  borderRadius: '20px',
                  fontFamily: 'Bricolage Grotesque',
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#0F0F0F',
                  textTransform: 'none',
                  minWidth: '96px',
                  '&:hover': {
                    background: '#E0E0E0'
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  if (athlete) {
                    try {
                      setIsDeleting(true);
                      await deleteAthlete(supabase, athlete.id);
                      setModals(prev => ({ ...prev, delete: false }));
                      navigate("/");
                    } catch (error) {
                      console.error("Error deleting athlete:", error);
                      // You might want to show an error message to the user here
                    } finally {
                      setIsDeleting(false);
                    }
                  }
                }}
                disabled={isDeleting}
                sx={{
                  padding: '15px 22px',
                  background: '#FFF2F5',
                  border: '1px solid #525A65',
                  borderRadius: '20px',
                  fontFamily: 'Bricolage Grotesque',
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#EC0B43',
                  textTransform: 'none',
                  minWidth: '93px',
                  '&:hover': {
                    background: '#FFE5EB'
                  }
                }}
              >
                {isDeleting ? 'Deleting...' : 'Delete'}
              </Button>
            </Box>
          </Box>
          {isDeleting && (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: '24px',
              zIndex: 1,
            }}>
              <CircularProgress size={24} sx={{ color: "#02BD39" }} />
            </Box>
          )}
        </Dialog>
  
      </Box>
    </div>
  );
};

const buttonStyle = {
  color: "#525A65",
  border: "solid 1px",
  borderRadius: "30px",
  fontSize: "12px",
  backgroundColor: "unset",
  padding: "6px 9px 6px 9px",
  maxWidht: "76px",
  cursor: "pointer",
  ".buttonCal:hover": {
    backgroundColor: "unset",
    color: "#525A65",
  },
  float: "right",
  lineHeight: "1",
  height: "26px",
  textTransform: "none",
};

const buttonStyle2 = {
  color: "#525A65",
  float: "right",
  lineHeight: "1",

  border: "solid 1px",
  borderRadius: "30px",
  fontSize: "12px",
  backgroundColor: "unset",
  padding: "6px 9px 6px 9px",
  maxWidht: "99px",
  cursor: "pointer",
  ".buttonCal:hover": {
    backgroundColor: "unset",
    color: "#525A65",
  },
  margin: "0px  0px 0px 15px !important",
  height: "26px",
  textTransform: "none",
};

const buttonStyle3 = {
  color: "#EC0B43",
  float: "right",
  border: "solid 1px",
  borderRadius: "30px",
  fontSize: "12px",
  backgroundColor: "unset",
  padding: "6px 9px 6px 9px",
  lineHeight: "1",
  maxWidht: "99px",
  cursor: "pointer",
  ".buttonCal:hover": {
    backgroundColor: "unset",
    color: "#EC0B43",
  },
  margin: "0px  15px 0px 0px !important",
  height: "26px",
  textTransform: "none",
};
