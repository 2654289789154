import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Zoom
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";

export const ExerciseModalLibrary = ({ open, handleClose, exercise }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      keepMounted={false}
      TransitionComponent={Zoom}
      TransitionProps={{
        timeout: {
          enter: 300,
          exit: 200
        }
      }}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "24px",
          width: "529px",
          minHeight: "525px",
          backgroundColor: "#FBFBFB",
        },
      }}
    >
      <Box
        sx={{
          padding: "40px 23px 16px 23px",
          minHeight: "525px",
          maxHeight: "670px",
        }}
      >
        <DialogTitle
          sx={{
            color: "#000000",
            fontWeight: "600 !important",
            fontSize: "16px",
            display: "flex",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              Exercise Detail
            </Grid>
            <Grid item xs={4} sx={{ paddingTop: "0px !important" }}>
              <Button
                onClick={handleClose}
                startIcon={<CloseIcon />}
                size="small"
                sx={close}
              ></Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              mb: 2,
              fontSize: "16px",
              color: "#525A65",
              fontWeight: "600 !important",
              mt: 2,
            }}
          >
            {exercise.title}
          </DialogContentText>
          <Typography
            sx={{
              mb: 1,
              fontSize: "12px",
              color: "#999999",
              fontWeight: "400 !important",
              mt: 2,
            }}
          >
            Description
          </Typography>
          <Typography
            sx={{
              mb: 2,
              fontSize: "14px",
              color: "#525A65",
              fontWeight: "400 !important",
            }}
          >
            {exercise.description}
          </Typography>
          <Typography
            sx={{
              mb: 1,
              fontSize: "12px",
              color: "#999999",
              fontWeight: "400 !important",
              mt: 2,
            }}
          >
            Muscle/Equipment Type
          </Typography>
          <Typography
            sx={{
              mb: 2,
              fontSize: "14px",
              color: "#525A65",
              fontWeight: "400 !important",
            }}
          >
            {exercise.Muscle_Equipment_type}
          </Typography>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export const close = {
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  float: "right",
  textTransform: "none",
  paddingRight: "10px",
  padding: "13px",
  borderRadius: "100% !important",
  minWidth: "37px !important",
  fontSize: "12px",
  border: "0px !important",
  backgroundColor: "#EEEEEE",
  ".MuiSvgIcon-root": {
    fontSize: "18px !important",
    paddingLeft: "0px",
    color: "#525A65",
  },
  "&:hover": {
    color: "#525A65",
    backgroundColor: "#dddddd",
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px",
  },
};
