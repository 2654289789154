import moment from "moment-timezone";

export const timezones = moment.tz
  .names()
  .map((name) => {
    const offset = moment.tz(name).format("Z");
    const formattedName = `(UTC${offset}) ${name.replace(/_/g, " ")}`;
    return {
      value: name,
      label: formattedName,
    };
  })
  .sort((a, b) => {
    const offsetA = moment.tz(a.value).utcOffset();
    const offsetB = moment.tz(b.value).utcOffset();
    return offsetB - offsetA;
  }); 