import { Box, Button } from "@mui/material";
import React from "react";
import { signoutStyle } from "./style";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getCoachById, updateCoach } from "../../services/query";

export const Subscription = () => {
  const [isInitializing, setIsInitializing] = useState(true);
  const [subData, setSubData] = useState(null);
  const { register, handleSubmit, reset, control } = useForm({
    resetOptions: {
      keepDirtyValues: true,
    },
  });
  const supabase = useSupabase();
  const user = useAuth();
  const getSubStatus = async () => {
    try {
      const { data } = await supabase.functions.invoke("st-sub-status");
      setSubData(data);
    } catch (error) {
      console.error("Subscription status error:", error.message);
    }
  };
  const getCoach = async () => {
    try {
      const { data } = await getCoachById(supabase, user.id);
      if (data?.[0]) {
        reset(data[0]);
      }
    } catch (error) {
      console.error("Get coach error:", error.message);
    }
  };
  useEffect(() => {
    let isMounted = true;

    const initializeData = async () => {
      if (!user) return;

      try {
        setIsInitializing(true);
        await Promise.all([getCoach(), getSubStatus()]);
      } catch (error) {
        console.error("Initialization error:", error);
      } finally {
        if (isMounted) {
          setIsInitializing(false);
        }
      }
    };

    initializeData();

    return () => {
      isMounted = false;
    };
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        fontSize: "13px",
        color: "#02BD34",
      }}
    >
      <Box>
        Manage up to 3 clients for free. <br /> Upgrade anytime as you grow!
      </Box>
      <Button
        sx={{ 
          ...signoutStyle, 
          marginTop: "10px",
          background: "#02BD36",
          border: "1px solid #E9FFCE",
          color: "#E9FFCE",
        }}
        type="submit"
        disabled={isInitializing}
        onClick={async () => {
          setIsInitializing(true);
          try {
            const { data } = await supabase.functions.invoke("st-checkout", {
              body: {
                priceId: process.env.REACT_APP_STRIPE_PRICE,
                baseUrl: window.location.origin,
              },
            });
            window.location = data.url;
          } catch (error) {
            alert(error.message);
          } finally {
            setIsInitializing(false);
          }
        }}
      >
        Upgrade
      </Button>
    </Box>
  );
};
