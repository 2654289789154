import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

export const getTableColumns = (handleToggleFreeAccount, handleDeleteUser) => ({
  allUsers: [
    { field: "coachname", headerName: "Coach Name", width: 120 },
    { 
      field: "status", 
      headerName: "Status", 
      width: 100,
      renderCell: (params) => {
        if (!params.row.lastDashboardAccess) {
          // Return offline indicator for null/empty lastDashboardAccess
          return (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              color: '#525A65',
            }}>
              <span style={{ 
                width: 8, 
                height: 8, 
                backgroundColor: '#525A65',
                borderRadius: '50%', 
                display: 'inline-block',
                marginRight: 6
              }}></span>
              Offline
            </Box>
          );
        }
        
        // Son erişim zamanını kontrol et
        const lastAccessTime = new Date(params.row.lastDashboardAccess);
        const now = new Date();
        const diffMinutes = Math.floor((now - lastAccessTime) / (1000 * 60));
        const diffHours = Math.floor(diffMinutes / 60);
        
        // 12 saatten fazla ise offline göster
        if (diffHours >= 12) {
          return (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              color: '#525A65',
            }}>
              <span style={{ 
                width: 8, 
                height: 8, 
                backgroundColor: '#525A65',
                borderRadius: '50%', 
                display: 'inline-block',
                marginRight: 6
              }}></span>
              Offline
            </Box>
          );
        }
        
        // Zaman dilimini hesapla
        let timeAgoText = '';
        if (diffMinutes < 5) {
          return (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              color: '#02BD36',
              fontWeight: 600
            }}>
              <span style={{ 
                width: 8, 
                height: 8, 
                backgroundColor: '#02BD36',
                borderRadius: '50%', 
                display: 'inline-block',
                marginRight: 6
              }}></span>
              Online
            </Box>
          );
        } else if (diffMinutes < 60) {
          timeAgoText = `${diffMinutes}m ago`;
        } else {
          timeAgoText = `${diffHours}h ago`;
        }
        
        return (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            color: '#FFA500',
          }}>
            <span style={{ 
              width: 8, 
              height: 8, 
              backgroundColor: '#FFA500',
              borderRadius: '50%', 
              display: 'inline-block',
              marginRight: 6
            }}></span>
            {timeAgoText}
          </Box>
        );
      }
    },
    { field: "email", headerName: "E-mail", width: 120 },
    { 
      field: "lastDashboardAccess", 
      headerName: "Last Dashboard Access", 
      width: 150,
      renderCell: (params) => {
        if (!params.value) return "—";
        const date = new Date(params.value);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
      }
    },
    { field: "referralCode", headerName: "Referral Code", width: 130 },
    { field: "phonenumber", headerName: "Phone Number", width: 120 },
    { 
      field: "client_number", 
      headerName: "Total Clients", 
      width: 90,
      renderCell: (params) => params.value || "0"
    },
    { 
      field: "freeAccount", 
      headerName: "Account Type", 
      width: 200,
      renderCell: (params) => (
        <Button
          variant={params.row.isFreeAccount ? "contained" : "outlined"}
          onClick={() => handleToggleFreeAccount(params.row.userId, params.row.isFreeAccount)}
          sx={{
            backgroundColor: params.row.isFreeAccount ? '#02BD36' : 'transparent',
            color: params.row.isFreeAccount ? '#fff' : '#02BD36',
            borderColor: '#02BD36',
            '&:hover': {
              backgroundColor: params.row.isFreeAccount ? '#01a62f' : 'rgba(2, 189, 54, 0.1)',
              borderColor: '#02BD36'
            },
            textTransform: 'none',
            borderRadius: '20px',
            padding: '5px 15px',
            fontSize: '12px'
          }}
        >
  {params.row?.isFreeAccount ? "Downgrade to Regular" : "Upgrade to Premium"}
  </Button>
      )
    },
  
    {
      field: 'viewProfile',
      headerName: 'Profile',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          onClick={() => window.location.href = `/coach/${params.row.userId}`}
          sx={{
            backgroundColor: '#1976d2',
            '&:hover': {
              backgroundColor: '#1565c0',
            },
            textTransform: 'none',
            borderRadius: '20px',
            padding: '5px 25px',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            minWidth: 'auto'
          }}
        >
          View Profile
        </Button>
      )
    }
  ],
  referrals: [
    { field: "id", headerName: "ID", width: 70 },
    { 
      field: "referralCoach", 
      headerName: "Coach Name", 
      width: 200,
      renderCell: (params) => params.value || "—"
    },
    { field: "coachname", headerName: "Coach via Invitation", width: 200 },
    { 
      field: "invitedCoachStatus", 
      headerName: "Status", 
      width: 130,
      renderCell: (params) => params.value || "—"
    },
    { field: "referralCode", headerName: "Referral Code", width: 130 },
    { 
      field: "referredBy", 
      headerName: "Referred By", 
      width: 130,
      renderCell: (params) => params.value || "—"
    },
    { 
      field: "lastSubscription", 
      headerName: "Last Subscription", 
      width: 150,
      renderCell: (params) => {
        if (!params.value) return "—";
        const date = new Date(params.value);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      }
    },
    { 
      field: "subscriptionCount", 
      headerName: "Subscription Count", 
      width: 150,
      renderCell: (params) => params.row.subscriptionCount ?? "0"
    },
    {
      field: 'viewProfile',
      headerName: 'Profile',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          onClick={() => window.location.href = `/coach/${params.row.userId}`}
          sx={{
            backgroundColor: '#1976d2',
            '&:hover': {
              backgroundColor: '#1565c0',
            },
            textTransform: 'none',
            borderRadius: '20px',
            padding: '5px 25px',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            minWidth: 'auto'
          }}
        >
          View Profile
        </Button>
      )
    }
  ]
}); 