import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Autocomplete,
  CircularProgress,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Menu,
  MenuItem,
  Divider
} from "@mui/material";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { getCoachById, updateCoach } from "../../services/query";
import { MobileDatePicker } from "@mui/x-date-pickers";
import {
  Typography1style,
  Typography2style,
  Typography3style,
  subsStyle,
  submitStyle,
  signoutStyle,
} from "./style";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { sortedCountries } from "../../components/countrycode/countrycode";
import dayjs from "dayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputAdornment from "@mui/material/InputAdornment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Snackbar, Alert } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";
import MenuIcon from "@mui/icons-material/Menu";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import TelegramIcon from "@mui/icons-material/Telegram";
import LinkIcon from "@mui/icons-material/Link";
import { languages } from "./languages";
import Popper from "@mui/material/Popper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleIcon from "@mui/icons-material/People";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MoreHoriz } from "@mui/icons-material";
import { IconClose } from "../../components/ExerciseModal/ExerciseModal";

const referralBoxStyle = {
  width: "100%",
  backgroundColor: "#E3E2E0",
  padding: "20px 17px",
  borderRadius: "20px",
  marginTop: "34px",
  display: "flex",
  flexDirection: "column",
  height: { xs: "auto", sm: "auto", md: "170px" },
};

const copyButtonStyle = {
  backgroundColor:"#EEEEEE",
  border:"1px solid #525A65",
  minWidth: "auto",
  
  padding: { xs: "4px 7px", sm: "5px 6px" },
  mt:"10px",
  color: "#0F0F0F",
  borderRadius:"30px",
  lineHeight:"14px",
  fontSize: { xs: "11px", sm: "12px" },
  fontWeight:"500",
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
};

const formtextfieldStyle = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FBFBFB',
    border: '1px solid #DAD9D8',
    borderRadius: '20px',
    '& input': {
      padding: '16px 19px',
    },
    fontSize: '12px',
    color: '#525A65',
    fontWeight: 400,
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused': {
      border: '1px solid #838F99',
    },
  },
};

const formautodStyle1 = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FBFBFB',
    border: '1px solid #DAD9D8',
    borderRadius: '20px',
    fontSize: '12px',
    color: '#525A65',
    fontWeight: 400,
    '& fieldset': {
      border: 'none',
    },
    '& input': {
      padding: '16px 19px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '16px 19px',
    },
    '&.Mui-focused': {
      border: '1px solid #838F99',
    },
  },
};

const formautodStyle2 = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FBFBFB',
    border: '1px solid #DAD9D8',
    borderRadius: '20px',
    padding: '16px 19px',
    fontSize: '12px',
    color: '#525A65',
    fontWeight: 400,
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused': {
      border: '1px solid #838F99',
    },
  },
};

const formDateFieldStyle = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FBFBFB',
    border: '1px solid #DAD9D8',
    borderRadius: '20px',
    height: 'auto',
    '& fieldset': {
      border: 'none',
    },
    '& input': {
      padding: '16px 19px',
      fontSize: '12px',
      color: '#525A65',
      fontWeight: 400,
    },
    '&.Mui-focused': {
      border: '1px solid #838F99',
    },
  },
  '& .MuiInputBase-root': {
    padding: 0
  }
};

export const AccountSettings = () => {
  const { register, handleSubmit, reset, control, formState: { isDirty } } = useForm({
    resetOptions: {
      keepDirtyValues: true,
    },
  });
  const { user, signOut } = useAuth();
  const supabase = useSupabase();
  const [isInitializing, setIsInitializing] = useState(true);
  const [subData, setSubData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [referralUsers, setReferralUsers] = useState([]);
  const [pendingReferrals, setPendingReferrals] = useState([]);
  const [showReferralsDialog, setShowReferralsDialog] = useState(false);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const moreMenuOpen = Boolean(moreAnchorEl);

  const getCoach = async () => {
    try {
      const { data } = await getCoachById(supabase, user.id);
      if (data?.[0]) {
        reset(data[0]);
      }
    } catch (error) {
      console.error("Get coach error:", error.message);
    }
  };

  const getSubStatus = async () => {
    try {
      const { data } = await supabase.functions.invoke("st-sub-status");
      setSubData(data);

    } catch (error) {
      console.error("Subscription status error:", error.message);
    }
  };

  const getReferralUsers = async () => {
    try {
      if (!user?.referral_code) return;
      
      const { data, error } = await supabase
        .from("users")
        .select(`
          id,
          full_name,
          status,
          created_at,
          subscription_count
        `)
        .eq("referred_by", user.referral_code);

      if (error) throw error;
      
      // Separate active and pending referrals
      const active = data?.filter(user => user.status === "ACTIVE" || user.status === "VERIFIED") || [];
      const pending = data?.filter(user => user.status !== "ACTIVE" && user.status !== "VERIFIED") || [];
      
      setReferralUsers(active);
      setPendingReferrals(pending);
    } catch (error) {
      console.error("Get referral users error:", error.message);
    }
  };

  const onSubmit = handleSubmit((data) => {
    setSubmitLoading(true);
    if (data.country_code === null) {
      setSubmitLoading(false);
      return alert("Please fill the code");
    }

    updateCoach(
      supabase,
      { ...data, full_name: `${data.first_name} ${data.last_name}` },
      user.id
    )
      .then((response) => {
        setSubmitLoading(false);
        setSnackbar({
          open: true,
          message: "Settings updated successfully!",
          severity: "success",
        });
      })
      .catch((error) => {
        setSubmitLoading(false);
        setSnackbar({
          open: true,
          message: "Failed to update settings. Please try again.",
          severity: "error",
        });
      });
  });

  const handleCopyFullLink = async () => {
    try {
      const referralLink = `${window.location.origin}/invite?ref=${user?.referral_code}`;
      await navigator.clipboard.writeText(referralLink);
      setSnackbar({
        open: true,
        message: "Referral link copied!",
        severity: "success",
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Failed to copy referral link",
        severity: "error",
      });
    }
    handleClose();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShare = (platform) => {
    const referralLink = `${window.location.origin}/invite?ref=${user?.referral_code}`;
    const message = "Join CoachPlus using my referral link:";

    switch (platform) {
      case "whatsapp":
        window.open(
          `https://wa.me/?text=${encodeURIComponent(
            message + " " + referralLink
          )}`
        );
        break;
      case "telegram":
        window.open(
          `https://t.me/share/url?url=${encodeURIComponent(
            referralLink
          )}&text=${encodeURIComponent(message)}`
        );
        break;
      case "email":
        window.open(
          `mailto:?subject=Join CoachPlus&body=${encodeURIComponent(
            message + " " + referralLink
          )}`
        );
        break;
      case "linkedin":
        const linkedinUrl = `https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(
          message + " " + referralLink
        )}`;
        window.open(linkedinUrl, "_blank");
        break;
      case "native":
        if (navigator.share) {
          navigator
            .share({
              title: "Join CoachPlus",
              text: message,
              url: referralLink,
            })
            .catch((error) => console.error("Error sharing:", error));
        } else {
          handleCopyFullLink();
        }
        break;
      default:
        handleCopyFullLink();
    }
    handleClose();
  };

  const handleMoreClick = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };

  useEffect(() => {
    let isMounted = true;
    
    const initializeData = async () => {
      if (!user) return;
      
      try {
        setIsInitializing(true);
        await Promise.all([getCoach(), getSubStatus(), getReferralUsers()]);
      } catch (error) {
        console.error("Initialization error:", error);
      } finally {
        if (isMounted) {
          setIsInitializing(false);
        }
      }
    };
    
    initializeData();
    
    return () => {
      isMounted = false;
    };
  }, [user?.id]); // Only depend on user.id instead of the entire user object


  return (
    <Box sx={{display:{xs:"block",sm:"flex"},alignItems:"center",justifyContent:"center",width:"100%",background:"#fff"}}>
      <PageContainer
      padding={{ xs: "10px!important", sm: "0!important" }}
      marginTop="22px!important"
      sx={{
        width: "100%",
        maxWidth: "1600px",
        padding: { xs: "10px", sm: "20px" },
      }}>
        {
            (isInitializing) ?(
                <PageContainer>
                  <Paper
                    variant="outlined"
                    sx={{
                      borderRadius: "24px",
                      height: "100%",
                      border: "none",
                      width: { xs: "100%", sm: "100%", md: "650px" },
                    }}
                  >
                    <Box sx={{ padding: "20px", maxWidth: "1000px", width: "100%" }}>
                      {/* Email Box */}
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "space-between",
                        backgroundColor: "#EEEEEE",
                        borderRadius: "20px",
                        padding: "0px 0px 0px 0px",
                        mb: 3
                      }}>
                        <Skeleton variant="text" width="60%" height={50} sx={{ ml: 2 }} />
                        <Skeleton variant="rectangular" width="100px" height={36} sx={{ mr: 2, borderRadius: "20px" }} />
                      </Box>
          
                      {/* Referral and Subscription Boxes */}
                      <Box sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "stretch",
                        gap: "24px",
                        width: "100%",
                        padding: "0"
                      }}>
                        {/* Referral Box */}
                        <Box sx={{
                          width: { xs: "100%", sm: "45%", md: "45%", lg: "45%" },
                          backgroundColor: "#E3E2E0",
                          padding: "20px 17px",
                          borderRadius: "20px",
                          marginTop: "34px",
                          display: "flex",
                          flexDirection: "column",
                          height: { xs: "auto", sm: "170px" },
                        }}>
                          <Skeleton variant="text" width="60%" height={24} />
                          <Skeleton variant="text" width="80%" height={40} sx={{ mt: 2 }} />
                          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                            <Skeleton variant="rectangular" width={100} height={36} sx={{ borderRadius: "30px", mr: 1 }} />
                            <Skeleton variant="circular" width={36} height={36} />
                          </Box>
                        </Box>
          
                        {/* Subscription Box */}
                        <Box sx={{
                          width: { xs: "100%", sm: "45%", md: "45%", lg: "45%" },
                          backgroundColor: "#EEEEEE",
                          padding: "20px 17px",
                          borderRadius: "20px",
                          marginTop: "34px",
                          display: "flex",
                          flexDirection: "column",
                          height: { xs: "auto", sm: "170px" },
                        }}>
                          <Skeleton variant="text" width="40%" height={24} />
                          <Skeleton variant="text" width="70%" height={20} sx={{ mt: 2 }} />
                          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: "auto" }}>
                            <Skeleton variant="rectangular" width={120} height={40} sx={{ borderRadius: "20px" }} />
                            <Skeleton variant="text" width={80} height={40} />
                          </Box>
                        </Box>
                      </Box>
          
                      {/* Form Fields */}
                      {[...Array(7)].map((_, index) => (
                        <Box key={index} sx={{ mt: 3 }}>
                          <Skeleton variant="text" width="30%" height={24} sx={{ ml: 3, mb: 1 }} />
                          <Skeleton 
                            variant="rectangular" 
                            height={56} 
                            sx={{ 
                              width: "100%",
                              borderRadius: "20px",
                            }} 
                          />
                        </Box>
                      ))}
          
                      {/* Submit Button */}
                      <Box sx={{ mt: 4 }}>
                        <Skeleton 
                          variant="rectangular" 
                          height={48} 
                          sx={{ 
                            width: "100%",
                            borderRadius: "20px",
                          }} 
                        />
                      </Box>
                    </Box>
                  </Paper>
                </PageContainer>
                        )
         :   
        (
        <>
        <Paper
          variant="outlined"
          sx={{
            borderRadius: "24px",
            height: "100%",
            border: "none",
            width: { xs: "100%", sm: "491px", md: "491px" },
          }}
        >
          <Box sx={{ maxWidth: "1000px", width: "100%" }}>
         
            <Box  sx={{display:"flex",alignItems:"center",width:"100%",justifyContent:"space-between",backgroundColor:"#EEEEEE",borderRadius:"20px",padding:"0px 0px 0px 0px"}}>
              <Box sx={{color:"#666666" ,fontSize:"12px",fontWeight:"600",ml:"13px", padding:"7px"}}>
             <span style={{color:"#666666" ,fontSize:"12px",fontWeight:"300"}}>  Logged in with:</span> <br />
              <span style={{color:"#666666" ,fontSize:"12px",fontWeight:"500"}}> {user?.email}</span>

              </Box>
              <Box>
              <Button 
                sx={{
                  ...signoutStyle,
                  backgroundColor:"#CECECE",
                  borderRadius:"20px",
                  color:"#525A65",
                  border:"1px solid #A9A9A9",
                  fontSize: { xs: "14px", sm: "16px" },
                  padding: { xs: "13.5px 19.5px", sm: "13.5px 19.5px!important" },
                  lineHeight:"19px",
                  fontWeight:"500",
                }} 
                type="submit" 
                onClick={signOut}
              >
                Sign out
              </Button>
              </Box>
            </Box>
            <Box sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "row", md: "row" },
              gap: { xs: "18px", sm: "18px", md: "18px" },
              width: "100%",
              padding: "0",
              marginTop: "15px",
              justifyContent: "space-between",
            }}>
              <Box sx={{
                flex: { xs: "0 0 30%", sm: "0 0 150px" },
                backgroundColor: "#E3E2E0",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "column",
                height: { xs: "128px", sm: "auto", md: "150px" },
                width:"30%",
                pl:{xs:"0px",sm:"16px"},
                alignItems:{xs:"center",sm:"start"},
                justifyContent:"end",
                position: "relative",
                pb:{xs:"11px",sm:"16px"},
              }}>
     <IconButton
  onClick={handleMoreClick}
  sx={{
    position: "absolute",
    right:{xs:"13px",sm: "19px"},
    top:{xs:"14px",sm: "21px"},
    color: "#525A65",
    display: "flex",        // Flexbox kullanarak
    justifyContent: "center", // SVG'yi ortala
    alignItems: "center",    // Yükseklik yönünde ortala
    padding: 0,             // İçeride ekstra boşluk bırakmamak için padding sıfırlanır
    width: "auto",          // width değerini otomatik bırak
    height: "auto", 
  }}
>
<svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#525A65"/>
<circle cx="8" cy="2" r="2" transform="rotate(-90 8 2)" fill="#525A65"/>
<circle cx="14" cy="2" r="2" transform="rotate(-90 14 2)" fill="#525A65"/>
</svg>

</IconButton>
                <Menu
                  anchorEl={moreAnchorEl}
                  open={moreMenuOpen}
                  onClose={handleMoreClose}
                  PaperProps={{
                    sx: {
                      backgroundColor: "#E3E2E0",
                      boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "16px",
                      border: "1px solid #EEEEEE",
                      width: "182px",
                      boxSizing: "border-box",
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem 
                    onClick={() => {
                      setShowReferralsDialog(true);
                      handleMoreClose();
                    }}
                    sx={{ 
                      padding: "8px 16px",
                      paddingTop: "6px",
                      color: "#525A65",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Bricolage Grotesque",
                      position: "relative",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: "16px",
                        width: "calc(100% - 48px)",
                        borderBottom: "1px solid #CECECE"
                      }
                    }}
                  >
                    View your referrals
                  </MenuItem>
                  <MenuItem 
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      handleMoreClose();
                    }}
                    sx={{ 
                      padding: "8px 16px",
                      color: "#525A65",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Bricolage Grotesque",
                    }}
                  >
                    Share code to...
                  </MenuItem>
                </Menu>
                <Box sx={{marginLeft:"6px"}}>
                  <Typography
                    variant="subtitle"
                    sx={{
                      color: "#0F0F0F",
                      fontSize: {xs:"10px",sm:"12px"},
                      marginBottom: {xs:"0px",md:"4px"},
                      display:"flex",
                      fontWeight:"400",
                    }}
                  >
                    <Box sx={{display:{xs:"none",sm:"none",md:"block",

color: "#0F0F0F",
fontSize: {xs:"10px",sm:"12px"},
display:"flex",
fontWeight:"400",
                    }}}>Your&nbsp;</Box>Referral Code:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {xs:"20px",md:"24px"},
                      fontWeight: "bold",
                      letterSpacing: "1px",
                      lineHeight:"29px",
                    }}
                  >
                    {user?.referral_code || "N/A"}
                  </Typography>
                </Box>
                <Box sx={{ display:"flex",alignItems:"center",flexDirection:{xs:"column",sm:"row"} }}>
                  <Button
                    sx={{...copyButtonStyle,textTransform:"capitalize"}}
                    onClick={() => {
                      navigator.clipboard.writeText(user?.referral_code);
                      setSnackbar({
                        open: true,
                        message: "Referral code copied!",
                        severity: "success",
                      });
                    }}
                  >
                    Copy Code
                  </Button>
               
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      sx: {
                        width: { xs: "90%", sm: "529px" },
                        maxWidth: "529px",
                        background: "#FBFBFB",
                        borderRadius: { xs: "16px", sm: "24px" },
                        m: 0,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        paddingBottom: { xs: "4%", sm: "2%" }
                      },
                    }}
                    sx={{
                      '& .MuiBackdrop-root': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      },
                    }}
                  >
                    <Box sx={{ 
                      display: "flex", 
                      justifyContent: "space-between", 
                      alignItems: "center",
                      padding: { xs: "16px 24px", sm: "20px 42px" },
                      marginTop: { xs: "4px", sm: "10px" },
                    }}>
                   <Box sx={{ 
                      display: "flex", 
                      justifyContent: "space-between", 
                      alignItems: "center",
                      width:"100%"
                    }}>
                      <Typography
                        sx={{
                          fontFamily: "Bricolage Grotesque",
                          fontWeight: 600,
                          fontSize: { xs: "14px", sm: "16px" },
                          lineHeight: { xs: "17px", sm: "19px" },
                          color: "#000000",
                        }}
                      >
                        Share to...
                      </Typography>
                      <Box 
              onClick={() => handleClose()}
              sx={{ 
                color: "#666",
                padding: { xs: "4px", sm: "8px" },
                width:"auto",
                height:"auto",
                cursor:"pointer",
              }}
            >
              <IconClose/>
            </Box>
                      </Box>

                      <Box
                        sx={{
                          width: "auto",
                          height: "auto",
                        }}
                      >
                        <svg 
                          onClick={handleClose}
                          style={{
                            cursor: "pointer",
                            width: { xs: "36px", sm: "48px" },
                            height: { xs: "36px", sm: "48px" }
                          }}
                          viewBox="0 0 48 48" 
                          fill="none" 
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="24" cy="24" r="24" fill="#EEEEEE"/>
                          <path d="M17.1423 17.1428C17.7735 16.5117 18.7968 16.5117 19.428 17.1428L30.8564 28.5712C31.4876 29.2024 31.4876 30.2257 30.8564 30.8569C30.2252 31.4881 29.2019 31.4881 28.5707 30.8569L17.1423 19.4285C16.5111 18.7973 16.5111 17.774 17.1423 17.1428Z" fill="#525A65"/>
                          <path d="M17.1428 30.8577C16.5117 30.2265 16.5117 29.2032 17.1428 28.572L28.5712 17.1436C29.2024 16.5124 30.2257 16.5124 30.8569 17.1436C31.4881 17.7748 31.4881 18.7981 30.8569 19.4293L19.4285 30.8577C18.7973 31.4889 17.774 31.4889 17.1428 30.8577Z" fill="#525A65"/>
                        </svg>
                      </Box>
                    </Box>
                    
                    <Box sx={{ 
                      padding: { xs: "12px 24px", sm: "16px 42px" },
                    }}>
                      <Box
                        onClick={() => handleShare("whatsapp")} 
                        sx={{ 
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                          paddingBottom: { xs: "12px", sm: "16px" },
                          borderBottom: "1px solid #CECECE",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                          <WhatsAppIcon sx={{ color: "#25D366", fontSize: { xs: "20px", sm: "24px" } }} />
                          <Typography sx={{ 
                            fontFamily: "Bricolage Grotesque",
                            fontWeight: 600,
                            fontSize: { xs: "13px", sm: "14px" },
                            lineHeight: { xs: "16px", sm: "17px" },
                            color: "#525A65",
                          }}>
                            WhatsApp
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ 
                      padding: { xs: "12px 24px", sm: "16px 42px" },
                    }}>
                      <Box
                        onClick={() => handleShare("telegram")} 
                        sx={{ 
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                          paddingBottom: { xs: "12px", sm: "16px" },
                          borderBottom: "1px solid #CECECE",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                          <TelegramIcon sx={{ color: "#0088cc", fontSize: { xs: "20px", sm: "24px" } }} />
                          <Typography sx={{ 
                            fontFamily: "Bricolage Grotesque",
                            fontWeight: 600,
                            fontSize: { xs: "13px", sm: "14px" },
                            lineHeight: { xs: "16px", sm: "17px" },
                            color: "#525A65",
                          }}>
                            Telegram
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ 
                      padding: { xs: "12px 24px", sm: "16px 42px" },
                    }}>
                      <Box
                        onClick={() => handleShare("email")} 
                        sx={{ 
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                          paddingBottom: { xs: "12px", sm: "16px" },
                          borderBottom: "1px solid #CECECE",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                          <EmailIcon sx={{ color: "#EA4335", fontSize: { xs: "20px", sm: "24px" } }} />
                          <Typography sx={{ 
                            fontFamily: "Bricolage Grotesque",
                            fontWeight: 600,
                            fontSize: { xs: "13px", sm: "14px" },
                            lineHeight: { xs: "16px", sm: "17px" },
                            color: "#525A65",
                          }}>
                            Email
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ 
                      padding: { xs: "12px 24px", sm: "16px 42px" },
                    }}>
                      <Box
                        onClick={() => handleShare("linkedin")} 
                        sx={{ 
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                          paddingBottom: { xs: "12px", sm: "16px" },
                          borderBottom: "1px solid #CECECE",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                          <LinkedInIcon sx={{ color: "#0A66C2", fontSize: { xs: "20px", sm: "24px" } }} />
                          <Typography sx={{ 
                            fontFamily: "Bricolage Grotesque",
                            fontWeight: 600,
                            fontSize: { xs: "13px", sm: "14px" },
                            lineHeight: { xs: "16px", sm: "17px" },
                            color: "#525A65",
                          }}>
                            LinkedIn
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ 
                      padding: { xs: "12px 24px", sm: "16px 42px" },
                    }}>
                      <Box
                        onClick={handleCopyFullLink} 
                        sx={{ 
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                          paddingBottom: { xs: "12px", sm: "16px" },
                          borderBottom: "1px solid #CECECE",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                          <LinkIcon sx={{ color: "#666", fontSize: { xs: "20px", sm: "24px" } }} />
                          <Typography sx={{ 
                            fontFamily: "Bricolage Grotesque",
                            fontWeight: 600,
                            fontSize: { xs: "13px", sm: "14px" },
                            lineHeight: { xs: "16px", sm: "17px" },
                            color: "#525A65",
                          }}>
                            Copy link
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {navigator.share && (
                      <Box sx={{ 
                        padding: { xs: "12px 24px", sm: "16px 42px" },
                      }}>
                        <Box
                          onClick={() => handleShare("native")} 
                          sx={{ 
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                            },
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                            <ShareIcon sx={{ fontSize: { xs: "20px", sm: "24px" } }} />
                            <Typography sx={{ 
                              fontFamily: "Bricolage Grotesque",
                              fontWeight: 600,
                              fontSize: { xs: "13px", sm: "14px" },
                              lineHeight: { xs: "16px", sm: "17px" },
                              color: "#525A65",
                            }}>
                              More options...
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Dialog>
                </Box>
              </Box>
              {subData && (
                <Paper 
                  elevation={0}
                  sx={{
                    borderRadius: "16px",
                    width:{xs:"100%",md:"100%"},
                    overflow: "hidden",
                    height: { xs: "128px", sm: "150px", md: "150px" },
                  }}
                >
                  <Box 
                    sx={{
                      backgroundColor: subData.isSubscribed ? "#EEEEEE" : "#013B12",
                      color: subData.isSubscribed ? "inherit" : "white",
                      padding: {xs:"10px 10px",sm:"16px,14px",md:"16px 14px"},
                      borderRadius: "20px",
                      display: "flex",
                      flexDirection: "column",
                      height: {xs:"100%",md:"100%"},

                      justifyContent: {xs:"none",md:"space-between"}
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontSize:{xs:"12px",sm: "16px"}, 
                        fontWeight: "bold", 
                        color: subData.isSubscribed ? "#02BD36!important" : "#C9FE58",
                      }}
                    >
                      {subData.isSubscribed ? "Pro plan" : "Free plan"}
                    </Typography>
                    
                    <Typography 
                      sx={{ 
                        fontSize: { xs: "10px", sm: "14px" },
                        color: subData.isSubscribed ? "#525A65" : "#02BD34",
                        mb:{xs:"4px",sm:"8px"},
                        maxWidth: "350px",
                        display:"flex",
                        fontWeight:"500",
                      }}
                    >
                    {subData.isSubscribed 
              ?
<Box>
  Unsubscribing will limit access to premium&nbsp;
  <Box sx={{ display: { xs: "none", sm: "inline" } }}>
    <br />
  </Box>  
  features, but you can rejoin anytime.
</Box>
              : <>Manage up to 3 clients for free.<br /> Upgrade anytime as you grow!</>}

                    </Typography>
                    
                    <Box sx={{ display: "flex", justifyContent: subData.isSubscribed ? "unset" : "end", alignItems: "center",
                      mr:{xs:"10px",sm:"10px"},
                      mt:{xs:"5px",sm:"0px"},

                     }}>
                      <Button
                        sx={{
                          borderRadius: "20px",
                          textTransform: "none",
                          width:{xs:"65%",sm:"128px"},
                          height:"48px",
                          fontSize: { xs: "16px",sm:"16px", md: "15px" },
                          border:"1px solid #E9FFCE",
                          fontWeight: "500",
                          backgroundColor: subData.isSubscribed ? "#e0e0e0" : "#02BD36",
                          color: subData.isSubscribed ? "#424242" : "#E9FFCE",
                          "&:hover": {
                            backgroundColor:"#02A62F",
                          }
                        }}
                        disabled={isInitializing}
                        onClick={async () => {
                          setIsInitializing(true);
                          try {
                            if (subData.isSubscribed) {
                              await supabase.functions.invoke("st-cancel-sub");
                            } else {
                              const { data } = await supabase.functions.invoke("st-checkout", {
                                body: {
                                  priceId: process.env.REACT_APP_STRIPE_PRICE,
                                  baseUrl: window.location.origin,
                                },
                              });
                              window.location = data.url;
                            }
                            getSubStatus();
                          } catch (error) {
                            setSnackbar({
                              open: true,
                              message: `Failed to ${subData.isSubscribed ? 'cancel' : 'create'} subscription`,
                              severity: "error",
                            });
                          }
                          setIsInitializing(false);
                        }}
                      >
                        {isInitializing 
                          ? <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} /> 
                          : null}
                        {subData.isSubscribed ? "Unsubscribe" : "Upgrade now"}
                      </Button>
                      
                      {!subData.isSubscribed && (
                        <Typography 
                          sx={{ 
                            fontSize: { xs: "12px", sm: "14px" },
                            fontWeight: "600",
                            color: "#E3E2E0",
                            lineHeight: "20px",
                            "& span": {
                              fontSize: { xs: "20px", sm: "20px" }
                            }
                          }}
                        >
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Paper>
              )}
            </Box>
        
            <br />
            <form onSubmit={onSubmit}>
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "20px",
                    color: "#838F99",
                    fontWeight: "400",
                    fontSize: "10px",
                    mb: "7px"
                  }}
                >
                  First name
                </InputLabel>
                <TextField
                  {...register("first_name")}
                  placeholder="First name"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{}}
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "20px",
                    color: "#838F99",
                    fontWeight: "400",
                    fontSize: "10px",
                    mb: "7px"
                  }}
                >
                  Surname
                </InputLabel>
                <TextField
                  {...register("last_name")}
                  placeholder="Surname"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{}}
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <Controller
                  control={control}
                  name="dob"
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DateField"]}
                          sx={{
                            ".css-10o2lyd-MuiStack-root": {
                              paddingTop: "0px !important",
                            },
                            "& .MuiFormControl-root": {
                              margin: 0,
                              padding: 0
                            }
                          }}
                        >
                          <Box sx={{ mt: 3, width: "100%" }}>
                            <InputLabel
                              htmlFor="bootstrap-input"
                              sx={{
                                paddingLeft: "20px",
                                color: "#838F99",
                                fontWeight: "400",
                                fontSize: "10px",
                                mb: "7px"
                              }}
                            >
                              Date of birth
                            </InputLabel>
                            <DateField
                              value={field.value ? dayjs(field.value) : null}
                              format="DD/MM/YYYY"
                              sx={formDateFieldStyle}
                              onChange={(newValue) => {
                                field.onChange(newValue ? newValue.format('YYYY-MM-DD') : null);
                              }}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  placeholder: "DD/MM/YYYY",
                                  InputProps: {
                                    style: {
                                      padding: 0
                                    }
                                  }
                                }
                              }}
                            />
                          </Box>
                        </DemoContainer>
                      </LocalizationProvider>
                    );
                  }}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "20px",
                    color: "#838F99",
                    fontWeight: "400",
                    fontSize: "10px",
                    mb: "7px"
                  }}
                >
                  City
                </InputLabel>
                <TextField
                  {...register("city")}
                  placeholder="City Name"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{}}
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "20px",
                    color: "#838F99",
                    fontWeight: "400",
                    fontSize: "10px",
                    mb: "7px"
                  }}
                >
                  Country
                </InputLabel>
                <TextField
                  {...register("country")}
                  placeholder="Country Name"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{}}
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "20px",
                    color: "#838F99",
                    fontWeight: "400",
                    fontSize: "10px",
                    mb: "7px"
                  }}
                >
                  Preferred Language
                </InputLabel>
                <Controller
                  name="preferred_language"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={languages}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      value={
                        field.value
                          ? languages.find(
                              (lang) => lang.code === field.value
                            ) || null
                          : null
                      }
                      onChange={(_, newValue) => {
                        field.onChange(newValue ? newValue.code : "");
                      }}
                      noOptionsText="Nothing to show here."
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Language"
                          sx={formautodStyle1}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              padding: 0
                            },
                            endAdornment: (
                              <>
                                {params.InputProps.endAdornment}
                                <KeyboardArrowDownIcon
                                  sx={{
                                    position: "absolute",
                                    right: "14px",
                                    pointerEvents: "none",
                                    color: "#666",
                                  }}
                                />
                              </>
                            ),
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <MenuItem {...props}>{option.name}</MenuItem>
                      )}
                      sx={{
                        "& .MuiAutocomplete-endAdornment": {
                          display: "none",
                        },
                      }}
                      disableClearable
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false,
                            },
                          ],
                          placement: "bottom",
                        },
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: "250px",
                        },
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "20px",
                    color: "#838F99",
                    fontWeight: "400",
                    fontSize: "10px",
                    mb: "7px"
                  }}
                >
                  What sport or activity do you coach?
                </InputLabel>
                <TextField
                  placeholder="Sport or activity"
                  {...register("sports_activities")}
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{}}
                  fullWidth
                />
              </Box>
              <br />
              <br />
              <Button
                type="submit"
                sx={{
                  ...submitStyle,
                  width: { xs: "100%", sm: "100%" },
                  opacity: submitLoading || !isDirty ? 0.7 : 1,
                  cursor: submitLoading || !isDirty ? "not-allowed" : "pointer",
                  "&:disabled": {
                    background: "#EEEEEE",
                    border: "1px solid #DAD9D8",
                    borderRadius: "20px",
                  }
                }}
                disabled={submitLoading || !isDirty}
              >
                {submitLoading ? "Saving..." : "Submit"}
              </Button>
              <br />
              <br />
             
              <br />
            </form>
          </Box>
        </Paper>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{
              width: "100%",
              "& .MuiAlert-message": {
                fontSize: "14px",
                fontWeight: 500,
              },
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Dialog
          open={showReferralsDialog}
          onClose={() => setShowReferralsDialog(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: { xs: "12px", sm: "20px" },
              padding: { xs: "12px", sm: "20px" },
              margin: { xs: "16px", sm: "32px" },
              width: "calc(100% - 32px)",
              maxHeight: { xs: "calc(100% - 32px)", sm: "90vh" }
            }
          }}
        >
          <DialogTitle sx={{ 
            fontSize: { xs: "18px", sm: "24px" }, 
            fontWeight: "600",
            color: "#525A65",
            pb: { xs: 0.5, sm: 1 },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: { xs: 1, sm: 2 }
          }}>
            Your Referrals
            <Box 
              onClick={() => setShowReferralsDialog(false)}
              sx={{ 
                color: "#666",
                padding: { xs: "4px", sm: "8px" },
                width:"auto",
                height:"auto",
                cursor:"pointer",
              }}
            >
              <IconClose/>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ px: { xs: 1, sm: 2 } }}>
            {referralUsers.length > 0 || pendingReferrals.length > 0 ? (
              <>
                {/* Active Referrals Section */}
                {referralUsers.length > 0 && (
                  <>
                    <Typography sx={{ 
                      fontSize: { xs: "16px", sm: "18px" }, 
                      fontWeight: "600", 
                      color: "#02BD36",
                      mt: { xs: 1, sm: 2 },
                      mb: { xs: 0.5, sm: 1 }
                    }}>
                      Active Referrals
                    </Typography>
                    <Box sx={{ 
                      backgroundColor: "#FFFFFF",
                      borderRadius: { xs: "12px", sm: "20px" },
                      border: "1px solid #E3E2E0",
                      overflow: "hidden",
                      mb: { xs: 2, sm: 3 }
                    }}>
                      {referralUsers.map((referral, index) => (
                        <Box
                          key={referral.id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: { xs: "12px 16px", sm: "16px 20px" },
                            borderBottom: index < referralUsers.length - 1 ? "1px solid #E3E2E0" : "none",
                            backgroundColor: index % 2 === 0 ? "#FAFAFA" : "#FFFFFF",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: { xs: 1, sm: 0 }
                          }}
                        >
                          <Box>
                            <Typography sx={{ 
                              fontSize: { xs: "14px", sm: "16px" }, 
                              fontWeight: "500", 
                              color: "#333",
                              textAlign: { xs: "center", sm: "left" }
                            }}>
                              {referral.full_name}
                            </Typography>
                            <Typography sx={{ 
                              fontSize: { xs: "12px", sm: "14px" }, 
                              color: "#666",
                              textAlign: { xs: "center", sm: "left" }
                            }}>
                              Joined {new Date(referral.created_at).toLocaleDateString()}
                            </Typography>
                          </Box>
                          <Box sx={{ 
                            display: "flex", 
                            alignItems: "center", 
                            gap: { xs: 1, sm: 2 },
                            flexDirection: { xs: "row", sm: "row" },
                            width: { xs: "100%", sm: "auto" },
                            justifyContent: { xs: "center", sm: "flex-end" }
                          }}>
                            <Box sx={{ 
                              display: "flex", 
                              flexDirection: "column", 
                              alignItems: "center"
                            }}>
                              <Typography sx={{ fontSize: { xs: "12px", sm: "14px" }, color: "#666" }}>
                                Subscriptions
                              </Typography>
                              <Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, fontWeight: "600", color: "#02BD36" }}>
                                {referral.subscription_count || 0}
                              </Typography>
                            </Box>
                            <Box sx={{
                              backgroundColor: "#E3FFE9",
                              color: "#02BD36",
                              padding: { xs: "2px 10px", sm: "4px 12px" },
                              borderRadius: "20px",
                              fontSize: { xs: "12px", sm: "14px" },
                              fontWeight: "500",
                              minWidth: { xs: "60px", sm: "auto" },
                              textAlign: "center"
                            }}>
                              Active
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </>
                )}

                {/* Pending Referrals Section */}
                {pendingReferrals.length > 0 && (
                  <>
                    <Typography sx={{ 
                      fontSize: { xs: "16px", sm: "18px" }, 
                      fontWeight: "600", 
                      color: "#ED6C02",
                      mt: { xs: 1, sm: 2 },
                      mb: { xs: 0.5, sm: 1 }
                    }}>
                      Pending Referrals
                    </Typography>
                    <Box sx={{ 
                      backgroundColor: "#FFFFFF",
                      borderRadius: { xs: "12px", sm: "20px" },
                      border: "1px solid #E3E2E0",
                      overflow: "hidden"
                    }}>
                      {pendingReferrals.map((referral, index) => (
                        <Box
                          key={referral.id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: { xs: "12px 16px", sm: "16px 20px" },
                            borderBottom: index < pendingReferrals.length - 1 ? "1px solid #E3E2E0" : "none",
                            backgroundColor: index % 2 === 0 ? "#FAFAFA" : "#FFFFFF",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: { xs: 1, sm: 0 }
                          }}
                        >
                          <Box>
                            <Typography sx={{ 
                              fontSize: { xs: "14px", sm: "16px" }, 
                              fontWeight: "500", 
                              color: "#333",
                              textAlign: { xs: "center", sm: "left" }
                            }}>
                              {referral.full_name}
                            </Typography>
                            <Typography sx={{ 
                              fontSize: { xs: "12px", sm: "14px" }, 
                              color: "#666",
                              textAlign: { xs: "center", sm: "left" }
                            }}>
                              Joined {new Date(referral.created_at).toLocaleDateString()}
                            </Typography>
                          </Box>
                          <Box sx={{
                            backgroundColor: "#FFF3E0",
                            color: "#ED6C02",
                            padding: { xs: "2px 10px", sm: "4px 12px" },
                            borderRadius: "20px",
                            fontSize: { xs: "12px", sm: "14px" },
                            fontWeight: "500",
                            minWidth: { xs: "60px", sm: "auto" },
                            textAlign: "center"
                          }}>
                            {referral.status || "Pending"}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </>
                )}
              </>
            ) : (
              <Box sx={{
                backgroundColor: "#F5F5F5",
                borderRadius: { xs: "12px", sm: "20px" },
                padding: { xs: "24px 16px", sm: "40px" },
                borderRadius: "20px",
                padding: "40px",
                textAlign: "center",
                mt: 2
              }}>
                <Typography sx={{ color: "#666", fontSize: "16px" }}>
                  You haven't referred any users yet. Share your referral code to get started!
                </Typography>
              </Box>
            )}
          </DialogContent>
        </Dialog>
        </>)}
      </PageContainer>
     
    </Box>
  );
};
