import { Box, TextField, InputAdornment } from "@mui/material";

const SearchBar = ({ search, setSearch }) => {
  return (
    <Box sx={{ padding: "15px 0 0 0" }}>
      <TextField
        id="outlined-search"
        placeholder="Search forms by name..."
        type="search"
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: "32px",

          },
          '& .MuiOutlinedInput-input': {
            padding: "10px",

          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.8846 11.7934C14.8794 8.99427 14.5665 5.11113 12.1173 2.65979C10.7777 1.32001 8.9946 0.582031 7.09741 0.582031C5.20023 0.582031 3.41714 1.32001 2.07752 2.65979C0.737895 3.99956 0 5.78286 0 7.68026C0 9.57766 0.737895 11.361 2.07752 12.6989C3.41714 14.0387 5.20023 14.7766 7.09741 14.7766C8.56768 14.7766 10.0177 14.3129 11.212 13.4663L14.3273 16.582L16 14.9092L12.8846 11.7934ZM7.09741 2.94688C8.35975 2.94688 9.54848 3.44009 10.4446 4.33266C11.3371 5.22523 11.8302 6.4141 11.8302 7.68026C11.8302 8.94642 11.3389 10.1353 10.4446 11.0279C9.55216 11.9204 8.36343 12.4136 7.09741 12.4136C5.8314 12.4136 4.64267 11.9223 3.7502 11.0279C2.85773 10.1353 2.36458 8.94642 2.36458 7.68026C2.36458 6.4141 2.85589 5.22523 3.74836 4.33266C4.64635 3.43825 5.83508 2.94688 7.09741 2.94688Z" fill="#525A65"/>
              </svg>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
