import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React from "react";
import { AppHeader } from "./components/AppHeader/AppHeader";
import { SideBar } from "./components/SideBar/SideBar";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { AthleteRoute } from "./routes/AthleteDetail/Athlete.route";
import { DashboardRoute } from "./routes/Dashboard/Dashboard.route";
import { LoginRoute } from "./routes/Login/Login.route";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { CalendarRoute } from "./routes/Calendar/Calendar.route";
import { CoachOnboardingRoute } from "./routes/CoachOnboarding/CoachOnboarding.route";
import { PageContainer } from "./components/PageContainer/PageContainer";
import { OnboardingFormsRoute } from "./routes/OnboardingForms/OnboardingForms.route";
import { OnboardingFormDisplayRoute } from "./routes/OnboardingFormDisplay/OnboardingFormDisplay.route";
import { TrainingPlanRoute } from "./routes/TrainingPlan/TrainingPlan.route";
import { PATH_COACH_ONBOARDING } from "./utils/constant";
import { AccountSettings } from "./routes/Account settings/AccountSettings";
import { TPDisplay } from "./components/TPDisplay/TPDisplay";
import { OTP } from "./routes/OTP/OTP";
import { ImportExercisesRoute } from "./routes/ImportExercises/ImportExercises.route";
import { Admin_Panel } from "./routes/Admin/admin_panel";
import Toolbar from "@mui/material/Toolbar";
import { Blog } from "./routes/Blog/Blog.route";
import { BlogCreate } from "./routes/Blog/BlogCreate.route";
import { BlogPost } from "./routes/Blog/BlogPost.route";
import { InviteRedirect } from "./routes/InviteRedirect/InviteRedirect";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import { QuestionForm } from "./routes/QuestionFormDisplay/QuestionForm";
import { Viewcoach } from "./routes/Admin/Viewcoach";
import { NotificationsRoute } from "./routes/Notification/Notification";
import { SimpleLogo } from "./components/SimpleLogo/SimpleLogo";
import { WalkthroughStateProvider } from "./components/WelcomeModal/ToolsStateContext";

function App() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const location = useLocation();

  // Routes where sidebar should be hidden
  const noSidebarRoutes = ["/otp", PATH_COACH_ONBOARDING];

  // Check if sidebar should be shown
  const shouldShowSidebar = !noSidebarRoutes.includes(location.pathname);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setDrawerOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setDrawerOpen(!drawerOpen);
    }
  };

  return (
    <WalkthroughStateProvider>
      <main>
        <div
          style={{ display: "flex", minHeight: "100vh", flexDirection: "column"}}
        > 
          {(noSidebarRoutes.includes(location.pathname) && !location.pathname.startsWith("/blog"))&& <SimpleLogo />}
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            {(shouldShowSidebar && !location.pathname.startsWith("/blog") )&& (
              <SideBar
                drawerOpen={drawerOpen}
                handleDrawerClose={handleDrawerClose}
                handleDrawerTransitionEnd={handleDrawerTransitionEnd}
                handleDrawerToggle={handleDrawerToggle}
              />
            )}
            <div
              style={{
                flexGrow: 1,
                minHeight: "100%",
              }}
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Navigate to="/athlete/default" replace />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/athlete/"
                  element={
                    <ProtectedRoute>
                      <Navigate to="/athlete/default" replace />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/athlete/:id"
                  element={
                    <ProtectedRoute>
                      <AthleteRoute />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/calendar"
                  element={
                    <ProtectedRoute>
                      <CalendarRoute />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={PATH_COACH_ONBOARDING}
                  element={
                    <ProtectedRoute>
                      <CoachOnboardingRoute />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/forms"
                  element={
                    <ProtectedRoute>
                      <OnboardingFormsRoute />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/training-plan"
                  element={
                    <ProtectedRoute>
                      <TrainingPlanRoute />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/import-exercises"
                  element={
                    <ProtectedRoute>
                      <ImportExercisesRoute />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/forms/:id"
                  element={<OnboardingFormDisplayRoute />}
                />
                <Route path="/question-form/:id" element={<QuestionForm />} />

                <Route path="/tp/:bid" element={<TPDisplay />} />
                <Route
                  path="/login"
                  element={
                    <ProtectedRoute>
                      <LoginRoute />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <ProtectedRoute>
                      <Admin_Panel />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/coach/:id"
                  element={
                    <ProtectedRoute>
                      <Viewcoach />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      <AccountSettings />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/otp"
                  element={
                    <ProtectedRoute>
                      <OTP />
                    </ProtectedRoute>
                  }
                />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/create" element={
                  <ProtectedRoute>
                    <BlogCreate />
                  </ProtectedRoute>
                } />
                <Route path="/blog/:id" element={<BlogPost />} />
                <Route path="/blog/edit/:id" element={
                  <ProtectedRoute>
                    <BlogPost />
                  </ProtectedRoute>
                } />
                <Route path="/blogs" element={<Blog />} />

                <Route path="/invite" element={<InviteRedirect />} />
                <Route path="/notifications" element={<NotificationsRoute />} />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-conditions" element={<TermsConditions />} />
                <Route
                  path="*"
                  element={
                    <ProtectedRoute>
                      <PageContainer>Not Found</PageContainer>
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </div>
          </div>
        </div>
      </main>
    </WalkthroughStateProvider>
  );
}

export default App;
