import React, { createContext, useContext, useState } from 'react';

// Create a context for the walkthrough state
const WalkthroughStateContext = createContext();

// Create a provider component
export const WalkthroughStateProvider = ({ children }) => {
  const [isToolsWalkthroughOpen, setIsToolsWalkthroughOpen] = useState(false);
  const [isClientsWalkthroughOpen, setIsClientsWalkthroughOpen] = useState(false);

  return (
    <WalkthroughStateContext.Provider 
      value={{ 
        isToolsWalkthroughOpen, 
        setIsToolsWalkthroughOpen,
        isClientsWalkthroughOpen,
        setIsClientsWalkthroughOpen
      }}
    >
      {children}
    </WalkthroughStateContext.Provider>
  );
};

// Create a custom hook to use the context
export const useWalkthroughState = () => {
  const context = useContext(WalkthroughStateContext);
  if (!context) {
    throw new Error('useWalkthroughState must be used within a WalkthroughStateProvider');
  }
  return context;
};

// For backward compatibility
export const useToolsState = () => {
  const { isToolsWalkthroughOpen, setIsToolsWalkthroughOpen } = useWalkthroughState();
  return { isToolsWalkthroughOpen, setIsToolsWalkthroughOpen };
}; 