import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Paper,
  Fade,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { IconClose } from '../ExerciseModal/ExerciseModal';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '90%',
    maxWidth: '568px',
    height: 'auto',
    minHeight: '379px',
    background: '#FBFBFB',
    borderRadius: '24px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      minHeight: 'auto',
      borderRadius: '16px',
    }
  },
}));

const OptionCard = styled(Paper)(({ theme }) => ({
  width: '241.04px',
  height: '241.04px',
  borderRadius: '40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  cursor: 'pointer',
  paddingLeft:"17px",
  paddingRight:"10px",
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'auto',
    minHeight: '180px',
    borderRadius: '24px',
    padding: '20px',
  }
}));

const AssignActivityModal = ({ open, onClose, onWorkoutClick, onExerciseClick }) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      TransitionProps={{
        timeout: 300
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ 
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%'
        }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            width: '100%',
            mb: { xs: 2, sm: 4 }
          }}>
            <Typography
              sx={{
                fontFamily: 'Bricolage Grotesque',
                fontWeight: 600,
                fontSize: { xs: '14px', sm: '16px' },
                lineHeight: { xs: '17px', sm: '19px' },
                color: '#000000',
              }}
            >
              Assign an activity
            </Typography>
            <IconButton 
              onClick={onClose}
              size="small"
              sx={{ 
                color: '#000000',
                p: 0
              }}
            >
              <IconClose fontSize="small" />
            </IconButton>
          </Box>

          <Box sx={{ 
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 2, sm: 4 },
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}>
            <OptionCard
              onClick={onWorkoutClick}
              sx={{
                background: 'linear-gradient(135.3deg, #E3E2E0 0.52%, #E9FFCE 99.98%)',
                boxShadow: "none!important"
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Bricolage Grotesque',
                  fontWeight: 600,
                  fontSize: '32px',
                  lineHeight: '38px',
                  color: '#000000',
                  textAlign: "left",
                  mb: 0.5
                }}
              >
                Workouts
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Bricolage Grotesque',
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#525A65',
                  textAlign: 'left',
                }}
              >
                Pre-built structured plans for your clients. Set and schedule full workout programs.
              </Typography>
            </OptionCard>

            <OptionCard
              onClick={onExerciseClick}
              sx={{
                background: 'linear-gradient(225deg, #C9FE58 0.01%, #E9FFCE 99.99%)',
                boxShadow: "none!important"
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Bricolage Grotesque',
                  fontWeight: 600,
                  fontSize: '32px',
                  lineHeight: '38px',
                  color: '#000000',
                  mb: 0.5,
                  textAlign: "left",
                  maxWidth: '200px'
                }}
              >
                Exercise
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Bricolage Grotesque',
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#525A65',
                  textAlign: 'left',
                }}
              >
                Assign individual exercises, recovery sessions, or rest days as needed.
              </Typography>
            </OptionCard>
          </Box>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default AssignActivityModal; 