import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Paper, Grid, Card, CardContent, CardMedia, CardActionArea, TextField, IconButton, Tabs, Tab, Avatar, useMediaQuery, useTheme, Divider } from '@mui/material';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { useAuth, useSupabase } from '../../providers/AuthContextProvider';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [showOnlyMyPosts, setShowOnlyMyPosts] = useState(false);
    const { user } = useAuth();
    const supabase = useSupabase();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const mainCategories = {
        'Training & Performance': [
            'Strength & Conditioning',
            'Endurance & Stamina Training',
            'Speed & Agility Drills',
            'Recovery & Injury Prevention',
            'Nutrition for Athletes',
            'Warm-up & Cool-down Techniques'
        ],
        'Coaching Strategies': [
            'Building Team Chemistry',
            'Developing Game Strategies',
            'Analyzing Opponents',
            'Effective Communication',
            'Coaching Youth vs. Professional',
            'Leadership & Motivation'
        ],
        'Mental & Psychological': [
            'Sports Psychology',
            'Performance Anxiety',
            'Building Confidence',
            'Focus & Concentration',
            'Handling Pressure',
            'Winning Mindset'
        ],
        'Sport-Specific': [
            'Football Coaching',
            'Basketball Training',
            'Tennis Coaching',
            'Combat Sports',
            'Swimming Training',
            'Running & Marathon'
        ],
        'Injury & Recovery': [
            'Common Sports Injuries',
            'Recovery Practices',
            'Rest & Sleep',
            'Stretching & Mobility',
            'Rehabilitation Training',
            'Return to Play'
        ],
        'Technology & Tools': [
            'Coaching Apps',
            'Wearable Tech',
            'Video Analysis',
            'AI & Data',
            'Online Training'
        ],
        'Business & Career': [
            'Starting a Business',
            'Personal Branding',
            'Marketing Strategies',
            'Online vs In-Person',
            'Client Acquisition',
            'Certifications'
        ]
    };

    const [selectedMainCategory, setSelectedMainCategory] = useState('All Posts');
    const [selectedSubCategory, setSelectedSubCategory] = useState('All');
    const Navigate = useNavigate()
    useEffect(() => {
        fetchBlogPosts();
    }, [user, showOnlyMyPosts, selectedMainCategory, selectedSubCategory]);

    useEffect(() => {
        // Apply search filter whenever posts or searchQuery changes
        const filtered = posts.filter(post =>
            post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            post.content.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredPosts(filtered);
    }, [posts, searchQuery]);

    const fetchBlogPosts = async () => {
        try {
            let query = supabase
                .from('blog_posts')
                .select(`
                    *,
                    users:author_id (
                        first_name,
                        last_name
                    )
                `)
                .order('created_at', { ascending: false });

            if (showOnlyMyPosts && user) {
                query = query.eq('author_id', user.id);
            } else if (!user) {
                query = query.eq('isPublic', true);
            } else {
                query = query.or(`isPublic.eq.true,author_id.eq.${user.id}`);
            }

            if (selectedMainCategory !== 'All Posts') {
                if (selectedSubCategory === 'All') {
                    query = query.contains('categories', [selectedMainCategory]);
                } else {
                    query = query.contains('categories', [selectedSubCategory]);
                }
            }

            const { data, error } = await query;

            if (error) throw error;
            setPosts(data || []);
        } catch (error) {
            console.error('Error fetching blog posts:', error);
        } finally {
            setLoading(false);
        }
    };

    if(loading){
        return(
            <PageContainer>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '60vh',
                    flexDirection: 'column',
                    gap: 2
                }}>
                    <Typography variant="h6" sx={{ 
                        color: '#757575',
                        fontWeight: 500,
                        letterSpacing: 0.5,
                        animation: 'pulse 1.5s infinite ease-in-out',
                        '@keyframes pulse': {
                            '0%': { opacity: 0.6 },
                            '50%': { opacity: 1 },
                            '100%': { opacity: 0.6 }
                        }
                    }}>
                        Loading content...
                    </Typography>
                </Box>
            </PageContainer>
        )
    }

    return (
        <PageContainer>
            {/* Modern Blog Header with Logo */}
           <Logo/>
            <Paper sx={{
                maxWidth: '1200px',
                margin: '0 auto',
                p: { xs: 2, md: 4 },
                backgroundColor: '#fff',
                boxShadow: 'none',
            }}>
               
                {/* Main category navigation with improved contrast and typography */}
                <Box sx={{ 
                    borderBottom: '1px solid rgba(230, 230, 230, 1)',
                    mb: 3
                }}>
                    <Tabs 
                        value={selectedMainCategory === 'All Posts' ? 0 : Object.keys(mainCategories).indexOf(selectedMainCategory) + 1}
                        onChange={(e, newValue) => {
                            const category = newValue === 0 ? 'All Posts' : Object.keys(mainCategories)[newValue - 1];
                            setSelectedMainCategory(category);
                            setSelectedSubCategory('All');
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="blog categories"
                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#000',
                                height: 3
                            },
                            '& .MuiTab-root': {
                                textTransform: 'none',
                                fontSize: { xs: '15px', md: '16px' },
                                fontWeight: 500,
                                color: '#757575',
                                letterSpacing: 0.2,
                                minHeight: '48px',
                                padding: '12px 16px',
                                '&.Mui-selected': {
                                    color: '#000',
                                    fontWeight: 700
                                }
                            }
                        }}
                    >
                        <Tab 
                            label="All Posts"
                            sx={{
                                minWidth: 'auto',
                                px: 2
                            }}
                        />
                        {Object.keys(mainCategories).map((category) => (
                            <Tab 
                                key={category} 
                                label={category}
                                sx={{
                                    minWidth: 'auto',
                                    px: 2
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>

                {/* Sub-category navigation with improved accessibility */}
                {selectedMainCategory !== 'All Posts' && (
                    <Box sx={{ 
                        display: 'flex',
                        gap: 1,
                        overflowX: 'auto',
                        mb: 4,
                        pb: 1,
                        '::-webkit-scrollbar': {
                            height: '4px'
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                            borderRadius: '10px'
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#c1c1c1',
                            borderRadius: '10px'
                        }
                    }}>
                        <Button
                            onClick={() => setSelectedSubCategory('All')}
                            aria-label={`Show all ${selectedMainCategory} posts`}
                            sx={{
                                textTransform: 'none',
                                color: selectedSubCategory === 'All' ? '#000' : '#757575',
                                fontSize: '15px',
                                fontWeight: selectedSubCategory === 'All' ? 700 : 500,
                                minWidth: 'auto',
                                px: 2,
                                py: 1,
                                borderRadius: '20px',
                                backgroundColor: selectedSubCategory === 'All' ? 'rgba(0,0,0,0.05)' : 'transparent',
                                '&:hover': {
                                    backgroundColor: selectedSubCategory === 'All' ? 'rgba(0,0,0,0.08)' : 'rgba(0,0,0,0.04)',
                                    color: '#000'
                                }
                            }}
                        >
                            All
                        </Button>
                        {mainCategories[selectedMainCategory]?.map((subCategory) => (
                            <Button
                                key={subCategory}
                                onClick={() => setSelectedSubCategory(subCategory)}
                                aria-label={`Show ${subCategory} posts`}
                                sx={{
                                    textTransform: 'none',
                                    color: selectedSubCategory === subCategory ? '#000' : '#757575',
                                    fontSize: '15px',
                                    fontWeight: selectedSubCategory === subCategory ? 700 : 500,
                                    minWidth: 'auto',
                                    px: 2,
                                    py: 1,
                                    borderRadius: '20px',
                                    '&:hover': {
                                        backgroundColor: "unset",
                                        color: '#000'
                                    }
                                }}
                            >
                                {subCategory}
                            </Button>
                        ))}
                    </Box>
                )}

                {/* Page title and control buttons with improved spacing */}
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    my: { xs: 4, md: 6 },
                    pb: 2,
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: isMobile ? 2 : 0
                }}>
                    <Typography variant="h4" sx={{
                        fontWeight: 800,
                        color: '#292929',
                        fontSize: { xs: '26px', md: '32px' },
                        letterSpacing: -0.5,
                        marginRight: 'auto'
                    }}>
                        {showOnlyMyPosts ? 'Your Posts' : 'Featured Posts'}
                    </Typography>
                    {user && user.is_blog_author && (
                        <Box sx={{ 
                            display: 'flex', 
                            gap: 2,
                            width: isMobile ? '100%' : 'auto',
                            justifyContent: isMobile ? 'space-between' : 'flex-end'
                        }}>
                            <Button
                                variant={showOnlyMyPosts ? "contained" : "outlined"}
                                onClick={() => setShowOnlyMyPosts(!showOnlyMyPosts)}
                                aria-label={showOnlyMyPosts ? "Show all posts" : "Show only your posts"}
                                sx={{
                                    borderRadius: '20px',
                                    textTransform: 'none',
                                    backgroundColor: showOnlyMyPosts ? '#292929' : 'transparent',
                                    borderColor: '#292929',
                                    color: showOnlyMyPosts ? 'white' : '#292929',
                                    padding: '8px 16px',
                                    fontSize: '15px',
                                    fontWeight: 600,
                                    '&:hover': {
                                        backgroundColor: showOnlyMyPosts ? '#1a1a1a' : 'rgba(41, 41, 41, 0.1)',
                                        borderColor: '#292929'
                                    }
                                }}
                            >
                                {showOnlyMyPosts ? 'All posts' : 'Your posts'}
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={() => navigate('/blog/create')}
                                aria-label="Create new post"
                                sx={{
                                    backgroundColor: '#1a8917',
                                    borderRadius: '20px',
                                    textTransform: 'none',
                                    padding: '8px 16px',
                                    fontSize: '15px',
                                    fontWeight: 600,
                                    boxShadow: '0 2px 8px rgba(26, 137, 23, 0.2)',
                                    '&:hover': {
                                        backgroundColor: '#156912',
                                        boxShadow: '0 4px 12px rgba(26, 137, 23, 0.3)'
                                    }
                                }}
                            >
                                Write
                            </Button>
                        </Box>
                    )}
                </Box>

                {/* Enhanced search box */}
                <Box sx={{ 
                    display: 'flex', 
                    mb: { xs: 4, md: 6 },
                    transition: 'all 0.3s ease'
                }}>
                    <TextField
                        fullWidth
                        variant="standard"
                        placeholder="Search stories..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        aria-label="Search blog posts"
                        InputProps={{
                            startAdornment: <SearchIcon sx={{ color: '#757575', mr: 1 }} />,
                            sx: {
                                fontSize: '18px',
                                '&::before': {
                                    borderBottom: '1px solid rgba(41, 41, 41, 0.1)'
                                },
                                '&:hover:not(.Mui-disabled):before': {
                                    borderBottom: '1px solid rgba(41, 41, 41, 0.3)'
                                },
                                '& input': {
                                    padding: '8px 0',
                                    fontWeight: 400,
                                    color: '#292929'
                                }
                            }
                        }}
                    />
                </Box>

                {/* Improved post list with better readability and spacing */}
                <Grid container spacing={4}>
                    {filteredPosts.map((post) => (
                        <Grid item xs={12} key={post.id}>
                            <Box 
                                onClick={() => navigate(`/blog/${post.id}`)}
                                sx={{
                                    display: 'flex',
                                    flexDirection: isMobile ? 'column-reverse' : 'row',
                                    gap: { xs: 2, md: 4 },
                                    cursor: 'pointer',
                                    mb: 4,
                                    padding: { xs: 2, md: 3 },
                                    borderRadius: '12px',
                                    transition: 'all 0.2s ease',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                        '& h6': {
                                            color: '#1a8917'
                                        },
                                        '& .cover-image': {
                                            transform: 'scale(1.03)',
                                            transition: 'transform 0.4s ease'
                                        }
                                    }
                                }}
                            >
                                <Box sx={{ 
                                    flex: 1, 
                                    minWidth: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between' 
                                }}>
                                    <Box>
                                        {/* Author and metadata with improved contrast */}
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            gap: 1, 
                                            mb: 1.5, 
                                            flexWrap: 'wrap' 
                                        }}>
                                            <Avatar
                                                src={post.users?.avatar_url || `https://ui-avatars.com/api/?name=${post.users?.first_name}+${post.users?.last_name}&background=random`}
                                                alt={`${post.users?.first_name} ${post.users?.last_name}`}
                                                sx={{ 
                                                    width: 32, 
                                                    height: 32,
                                                    mr: 1,
                                                    bgcolor: 'rgba(0, 0, 0, 0.08)',
                                                    border: '2px solid #fff'
                                                }}
                                            />
                                            <Typography variant="body2" sx={{
                                                color: '#292929',
                                                fontSize: '14px',
                                                fontWeight: 600
                                            }}>
                                                {post.users.first_name} {post.users.last_name}
                                            </Typography>
                                            <Typography variant="body2" sx={{
                                                color: '#757575',
                                                fontSize: '14px'
                                            }}>
                                                • {moment(post.created_at).format('MMM D, YYYY')}
                                            </Typography>
                                            <Typography variant="body2" sx={{
                                                color: '#757575',
                                                fontSize: '14px',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                • {post.totalRead || 0} reads
                                            </Typography>
                                            {user?.id === post.author_id && (
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        color: post.isPublic ? '#1a8917' : '#FF9800',
                                                        backgroundColor: post.isPublic ? 'rgba(26, 137, 23, 0.1)' : 'rgba(255, 152, 0, 0.1)',
                                                        px: 1.5,
                                                        py: 0.5,
                                                        borderRadius: '12px',
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                        ml: 'auto'
                                                    }}
                                                >
                                                    {post.isPublic ? 'Public' : 'Private'}
                                                </Typography>
                                            )}
                                        </Box>
                                        
                                        {/* Post title with improved typography */}
                                        <Typography variant="h6" sx={{
                                            fontWeight: 700,
                                            color: '#292929',
                                            mb: 1.5,
                                            transition: 'color 0.2s',
                                            fontSize: { xs: '18px', md: '22px' },
                                            lineHeight: 1.3,
                                            letterSpacing: '-0.01em'
                                        }}>
                                            {post.title}
                                        </Typography>
                                        
                                        {/* Post excerpt with improved readability */}
                                        <Typography 
                                            variant="body1" 
                                            sx={{
                                                color: '#4e4e4e',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 3,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                lineHeight: 1.6,
                                                fontSize: '16px',
                                                mb: 2,
                                                fontWeight: 400,
                                                letterSpacing: '0.01em'
                                            }}
                                            dangerouslySetInnerHTML={{ 
                                                __html: post.content.replace(/<[^>]*>/g, '').substring(0, 240) + '...'
                                            }}
                                        />
                                    </Box>
                                    
                                    {/* Category tags and read more CTA */}
                                    <Box sx={{ 
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mt: 'auto',
                                        pt: 1
                                    }}>
                                        {post.categories && post.categories.length > 0 && (
                                            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                                {post.categories.slice(0, 2).map((category, index) => (
                                                    <Typography 
                                                        key={index}
                                                        variant="caption"
                                                        sx={{
                                                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                                            color: '#555',
                                                            px: 1.5,
                                                            py: 0.5,
                                                            borderRadius: '12px',
                                                            fontSize: '12px',
                                                            fontWeight: 500
                                                        }}
                                                    >
                                                        {category}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        )}
                                        
                                        <Button
                                            sx={{
                                                color: '#1a8917',
                                                fontWeight: 600,
                                                fontSize: '14px',
                                                textTransform: 'none',
                                                padding: 0,
                                                minWidth: 'auto',
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    textDecoration: 'underline'
                                                }
                                            }}
                                        >
                                            Read more
                                        </Button>
                                    </Box>
                                </Box>
                                
                                {/* Improved image container with better responsiveness */}
                                {post.cover_image && (
                                    <Box sx={{
                                        width: { xs: '100%', sm: '180px', md: '260px' },
                                        height: { xs: '200px', sm: '140px', md: '180px' },
                                        flexShrink: 0,
                                        borderRadius: '12px',
                                        overflow: 'hidden',
                                        bgcolor: '#f5f5f5',
                                        position: 'relative',
                                        boxShadow: '0 4px 10px rgba(0,0,0,0.05)'
                                    }}>
                                        <Box
                                            className="cover-image"
                                            component="img"
                                            src={post.cover_image}
                                            alt={post.title}
                                            loading="lazy"
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                transition: 'transform 0.4s ease',
                                                display: 'block'
                                            }}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.style.display = 'none';
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                            {!isMobile && <Divider sx={{ mt: 1, mb: 3, opacity: 0.5 }} />}
                        </Grid>
                    ))}
                </Grid>

                {/* Improved empty state */}
                {filteredPosts.length === 0 && (
                    <Box sx={{
                        textAlign: 'center',
                        py: 10,
                        px: 2
                    }}>
                        <Typography variant="h6" sx={{ 
                            color: '#757575',
                            fontWeight: 500,
                            mb: 2
                        }}>
                            No stories found
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#999',
                            maxWidth: '500px',
                            margin: '0 auto'
                        }}>
                            {searchQuery 
                                ? `No posts match your search for "${searchQuery}". Try different keywords or browse categories.` 
                                : `No posts available in this category yet. Check back later or explore other categories.`
                            }
                        </Typography>
                    </Box>
                )}
            </Paper>
        </PageContainer>
    );
};

export const Logo = () => {
    const Navigate = useNavigate()
    return(
        <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            marginY: { xs: '20px', md: '40px' },
            marginX: 'auto',
            maxWidth: '1200px'
        }}>
            <Box sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                cursor: 'pointer',
                transition: 'transform 0.2s ease',
                '&:hover': {
                    transform: 'translateY(-2px)'
                }
            }}
            onClick={() => Navigate("/")}
            >
                <Box 
                    component="img"
                    src={require("../../assets/OldLogoDasboard.png")}
                    alt="CoachPlus"
                    sx={{
                        height: { xs: 36, md: 42 },
                        width: 'auto',
                        transition: 'all 0.2s ease'
                    }}
                />
                <Typography 
                    sx={{
                        fontSize: { xs: '22px', md: '24px' },
                        fontWeight: 700,
                        color: '#292929',
                        borderLeft: '3px solid #1a8917',
                        paddingLeft: 2,
                        letterSpacing: '-0.02em',
                        lineHeight: 1.2
                    }}
                >
                    Blog
                </Typography>
            </Box>
        </Box>
    )
}