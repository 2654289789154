import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, IconButton, Paper, Chip, Grid, TextField, Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { supabase } from "../../services/supabase";

const AthleteFeedbackAvatar = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill="#525A65"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.2497 36.0055C26.5955 36.0055 35.1969 31.4829 35.1969 23.5799C35.1969 18.0822 30.1716 13.6055 23.9969 13.6055C17.8221 13.6055 12.7969 18.2088 12.7969 23.5718C12.7969 28.9348 18.0228 32.4354 23.9969 32.6455C23.958 32.8282 23.8753 33.1127 23.783 33.4302L23.783 33.4303L23.783 33.4303C23.6032 34.049 23.387 34.793 23.387 35.1543C23.387 35.6257 23.7701 36.0055 24.2497 36.0055ZM19.5184 24.8002C20.137 24.8002 20.6384 24.2987 20.6384 23.6802C20.6384 23.0616 20.137 22.5602 19.5184 22.5602C18.8999 22.5602 18.3984 23.0616 18.3984 23.6802C18.3984 24.2987 18.8999 24.8002 19.5184 24.8002ZM25.1197 23.6802C25.1197 24.2987 24.6182 24.8002 23.9997 24.8002C23.3811 24.8002 22.8797 24.2987 22.8797 23.6802C22.8797 23.0616 23.3811 22.5602 23.9997 22.5602C24.6182 22.5602 25.1197 23.0616 25.1197 23.6802ZM28.4809 24.8002C29.0995 24.8002 29.6009 24.2987 29.6009 23.6802C29.6009 23.0616 29.0995 22.5602 28.4809 22.5602C27.8624 22.5602 27.3609 23.0616 27.3609 23.6802C27.3609 24.2987 27.8624 24.8002 28.4809 24.8002Z" fill="#C9FE58"/>
    </svg>
);

const getRelativeTime = (date) => {
    const now = new Date();
    const feedbackDate = new Date(date);
    const diffTime = Math.abs(now - feedbackDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffDays / 365);

    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    if (diffDays < 7) return `${diffDays} days ago`;
    if (diffDays < 30) return `${Math.floor(diffDays / 7)} weeks ago`;
    if (diffMonths === 1) return '1 month ago';
    if (diffMonths > 1) return `${diffMonths} months ago`;
    if (diffYears === 1) return '1 year ago';
    if (diffYears > 1) return `${diffYears} years ago`;
    return 'Today'; // fallback
};

export const ViewFeedback = ({ feedback, onClose }) => {
    const [athleteName, setAthleteName] = useState('');
    const [clientName, setClientName] = useState('');
    const [eventDate, setEventDate] = useState("");
    const [loading, setLoading] = useState(true);

    const getStatus = (eventStatus, eventDate) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const eventDateObj = new Date(eventDate);
        eventDateObj.setHours(0, 0, 0, 0);
        if (!eventStatus || eventStatus === null) {
            return (eventDateObj >= today)
                ? { label: "Upcoming", color: "info", icon: <HourglassEmptyIcon fontSize="small" /> }
                : { label: "No feedback yet", color: "secondary" };
        }

        return {
            '0': { label: "Completed", color: "success", icon: <DoneIcon fontSize="small" /> },
            '1': { label: "Didn't Finish", color: "warning", icon: <EventBusyIcon fontSize="small" /> },
            '2': { label: "Skipped", color: "error", icon: <CancelIcon fontSize="small" /> }
        }[eventStatus.charAt(0)] || { label: "Unknown", color: "default" };
    };

    const fetchAthleteDetails = async (athlete_id) => {
        const { data, error } = await supabase.from('athletes').select('full_name').eq('id', athlete_id).single();
        if (!error) setAthleteName(data.full_name);
    };

    const fetchEventDetails = async (event_id) => {
        const { data, error } = await supabase.from('events').select('date,plan_client(name)').eq('id', event_id).single();
        if (!error) {
            setClientName(data.plan_client.name);
            setEventDate(data?.date);
        }
    };

    useEffect(() => {
        setLoading(true);
        if (feedback && feedback.length > 0) {
            const startTime = Date.now();
            
            // Create promises for both data fetching operations
            const promises = feedback.map(item => {
                return Promise.all([
                    fetchAthleteDetails(item.athlete_id),
                    fetchEventDetails(item.event_id)
                ]);
            });
            
            // When all promises are resolved
            Promise.all(promises.flat()).then(() => {
                const elapsedTime = Date.now() - startTime;
                const remainingTime = Math.max(0, 100 - elapsedTime);
                
                // Add delay to ensure skeleton shows for at least 200ms
                setTimeout(() => {
                    setLoading(false);
                }, remainingTime);
            });
        } else {
            // Still add the delay for consistency
            setTimeout(() => {
                setLoading(false);
            }, 200);
        }
    }, [feedback]);

    return (
        <Dialog 
            open={Boolean(feedback)} 
            onClose={onClose} 
            fullWidth 
            maxWidth="sm"
            PaperProps={{
                sx: {
                    borderRadius: '12px',
                    minHeight: '30vh',
                    maxHeight: '40vh',
                    paddingX:"10px"
                }
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', padding: '24px 24px',color:"#000000",fontSize:"16px", marginTop:"5px"}}>
            
            <Box>
                <Typography>
                Client Feedback
                </Typography>
                <Box sx={{
                    display:"flex",alignItems:"center",color:"#02BD36",gap:"3px"
                }}>
                {loading ? (
                    <Skeleton width={200} height={24} />
                ) : (
                    <>
                    <Typography>{clientName || ""}</Typography> / 
                    <Typography>
                        {eventDate ? new Date(eventDate).toLocaleDateString('en-US', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                        }) : ""}
                    </Typography>
                    </>
                )}
                </Box>
                
                </Box>
                <Box onClick={onClose} aria-label="close"
                 sx={{cursor:"pointer",
                    width: "auto",  // Ensures the Box only takes as much width as its content
                    height: "auto", // Ensures the Box only takes as much height as its content
                  }}
                >
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32 18.3353L29.6647 16L24 21.6647L18.3353 16L16 18.3353L21.6647 24L16 29.6647L18.3353 32L24 26.3353L29.6647 32L32 29.6647L26.3353 24L32 18.3353Z" fill="#525A65"/>
<circle cx="24" cy="24" r="24" fill="#EEEEEE"/>
<path d="M17.1462 17.1428C17.7774 16.5117 18.8007 16.5117 19.4319 17.1428L30.8603 28.5712C31.4915 29.2024 31.4915 30.2257 30.8603 30.8569C30.2291 31.4881 29.2058 31.4881 28.5746 30.8569L17.1462 19.4285C16.515 18.7973 16.5151 17.774 17.1462 17.1428Z" fill="#525A65"/>
<path d="M17.1428 30.8577C16.5117 30.2265 16.5117 29.2032 17.1428 28.572L28.5712 17.1436C29.2024 16.5124 30.2257 16.5124 30.8569 17.1436C31.4881 17.7748 31.4881 18.7981 30.8569 19.4293L19.4285 30.8577C18.7973 31.4889 17.774 31.4889 17.1428 30.8577Z" fill="#525A65"/>
</svg>
                </Box>
            </DialogTitle>

            <DialogContent sx={{ padding: 3,marginTop:2 }}>
                {loading ? (
                    <>
                        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                            <Skeleton variant="circular" width={48} height={48} />
                            <Box sx={{ width: '100%' }}>
                                <Skeleton width="40%" height={24} />
                                <Skeleton width="20%" height={20} />
                                <Skeleton width="100%" height={60} />
                            </Box>
                        </Box>
                    </>
                ) : feedback && feedback.length > 0 ? feedback.map((item, index) => (
                    <Box key={index} sx={{ 
                        display: 'flex', 
                        gap: 2, 
                        mb: 3,
                        '&:last-child': { mb: 0 }
                    }}>
                        <Box sx={{ flexShrink: 0 }}>
                            <AthleteFeedbackAvatar />
                        </Box>
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            flex: 1 
                        }}>
                            <Typography sx={{ 
                                fontFamily: 'Bricolage Grotesque',
                                fontWeight: 600,
                                fontSize: 14,
                                color: '#0F0F0F'
                            }}>
                                {athleteName || ""}
                            </Typography>
                            <Typography sx={{ 
                                fontFamily: 'Bricolage Grotesque',
                                fontSize: 12,
                                color: '#525A65'
                            }}>
                                {item.updated_at ? getRelativeTime(item.updated_at) : "Not answered yet"}
                            </Typography>
                            <Typography sx={{ 
                                fontFamily: 'Bricolage Grotesque',
                                fontSize: 12,
                                lineHeight: '20px',
                                color: '#0F0F0F',
                                fontWeight:"400",
                                mt:1
                            }}>
                                {item.feedback || "No feedback provided."}
                            </Typography>
                        </Box>
                    </Box>
                )) : (
                    <Typography sx={{ textAlign: 'center', color: '#757575' }}>
                        No feedback available.
                    </Typography>
                )}
            </DialogContent>

       
        </Dialog>
    );
};
