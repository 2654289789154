import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  TextField,
  Divider,
  Skeleton,
  Grow,
  Fade,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { supabase } from "../../services/supabase";
import {
  getPlansByCoachId,
  getExercisesByCoachId,
  insertEvent,
  getPlanByIdPublic,
  deletePlanEvents,
  plan_client_upsert,
  insertExercisePlans,
} from "../../services/query";
import { useAuth } from "../../providers/AuthContextProvider";
import { TrainingPlanModal } from "../TrainingPlanModal/TrainingPlanModal";
import { button3Style, searchStyle, LeftArrow, buttonStyle } from "./style";
import { getAthleteName } from "../../utils/selectors";
import {
  getTimeZone,
  renderGCalDescription,
  getSQLDate,
} from "../../utils/calendar";
import { addDays, format } from "date-fns";
import { useCalendar } from "../../providers/CalendarProvider";
import { TimeSelectionModal } from "./TimeSelectionModal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from '@mui/material/CircularProgress';

const loadingOverlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(251, 251, 251, 0.7)',
  zIndex: 1000,
  borderRadius: '24px',
};

const LoadingSkeleton = () => (
  <Paper
    sx={{
      backgroundColor: "#FBFBFB",
      borderRadius: "24px",
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      border: "none",
      boxShadow: "unset",
      position: 'relative',
    }}
  >
    <Box
      sx={{
        width: "582px",
        height: "100%",
        marginBottom: "20px"
      }}
    >
      {/* Back Button Skeleton */}
      <Box sx={{ ml: 2, mt: 2, mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
        <Skeleton variant="circular" width={20} height={20} sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)' }} />
        <Skeleton variant="text" width={40} height={24} sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)' }} />
      </Box>

      {/* Header Section */}
      <Box p={1.8} pb={0} pt={1} sx={{ height: "100px", width: "100%" }}>
        <Box pb={2} display="flex" alignItems="flex-end" justifyContent="space-between">
          <Skeleton variant="text" width={100} height={30} sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)' }} />
          <Skeleton variant="rounded" width={120} height={36} sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)', borderRadius: '20px' }} />
        </Box>

        {/* Search Bar Skeleton */}
        <Skeleton 
          variant="rounded" 
          width="100%" 
          height={56} 
          sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)', borderRadius: '4px' }} 
        />
        <Skeleton 
          variant="text" 
          width="100%" 
          height={2} 
          sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)', mt: 1.5 }} 
        />
      </Box>

      {/* Workout Cards Grid */}
      <Box pl={2} pr={2} mt={2}>
        <Grid container spacing={1}>
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item}>
              <Paper
                sx={{
                  backgroundColor: "rgba(238, 238, 238, 0.4)",
                  borderRadius: "8px",
                  boxShadow: "none",
                  position: "relative",
                  height: "145px",
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Skeleton 
                  variant="text" 
                  width="80%" 
                  height={24} 
                  sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }} 
                />
                {/* Menu Icon Skeleton */}
                <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                  <Skeleton 
                    variant="circular" 
                    width={24} 
                    height={24} 
                    sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }} 
                  />
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  </Paper>
);

export const AddNewTraining = ({
  back,
  athlete,
  title,
  date,
  reload,
  onEventDelete,
  onSuccess,
  initialPlan,
}) => {
  const { user } = useAuth();
  const [trainingPlanOpen, setTrainingPlanOpen] = useState(false);
  const [openMenuIndex, setOpenMenuIndex] = useState(null); // Track which menu is open
  const [SelectedPlan, setSelectedPlan] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tpSearch, setTpSearch] = useState("");
  const [plans, setPlans] = useState([]);
  const [exercises, setExercises] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeModalOpen, setTimeModalOpen] = useState(false);
  const [selectedPlanForTime, setSelectedPlanForTime] = useState(null);
  const [isTrainingDialogOpen, setIsTrainingDialogOpen] = useState(false);
  const [pendingPlan, setPendingPlan] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [animationsComplete, setAnimationsComplete] = useState(false);
  const initialLoadComplete = useRef(false);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index); // Set the index of the clicked plan
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null); // Close the menu
  };
  const loadPlans = async () => {
    const { data } = await getPlansByCoachId(supabase, user.id);
    // More strict validation of plans
    const validPlans = (data || []).filter((plan) => {
      return (
        plan && 
        typeof plan.name === "string" && 
        plan.name.trim().length > 0 &&
        !plan.is_single_exercise // Filter out single exercises
      );
    });
    // Sort plans by created_at in descending order (newest first)
    const sortedPlans = validPlans.sort((a, b) => {
      const dateA = new Date(a.created_at || 0);
      const dateB = new Date(b.created_at || 0);
      return dateB - dateA;
    });
    setPlans(sortedPlans);
  };
  const loadExercises = async () => {
    const { data } = await getExercisesByCoachId(supabase, user.id);
    setExercises(data || []);
  };

  useEffect(() => {
    // Set title only if title is a function
    if(typeof title === 'function' && !trainingPlanOpen){
      title("Select a Workout");
    }
    
    // Early return if no user
    if (!user) {
      return;
    }
    
    // Only load data if it hasn't been loaded before
    if (!initialLoadComplete.current) {
      const loadData = async () => {
        try {
          setIsInitializing(true);
          setAnimationsComplete(false);
          await Promise.all([
            loadPlans(),
            loadExercises()
          ]);
          
          // Set animation complete after a delay
          const timer = setTimeout(() => {
            setAnimationsComplete(true);
          }, 1000);
          
          initialLoadComplete.current = true;
          return () => clearTimeout(timer);
        } finally {
          setIsInitializing(false);
        }
      };
      loadData();
    }
  }, [user?.id, trainingPlanOpen]); // Use user.id instead of user to avoid unnecessary rerenders

  // Handle initial plan if provided via props
  useEffect(() => {
    if (initialPlan && !isInitializing) {
      // Slight delay to ensure component is fully rendered
      setTimeout(() => {
        handlePlanSelection(initialPlan);
      }, 300);
    }
  }, [initialPlan, isInitializing]);

  const { createEvent } = useCalendar();

  const handleSubmit = async (plan, time = null) => {
    try {
      // Add validation check at the start
      if (!plan || typeof plan !== "object") {
        console.error("Invalid plan object:", plan);
        throw new Error("Invalid plan object");
      }

 
      
      // Special case: if the plan has a dropTargetDate (from drag and drop), use that
      let currentDate;
      if (plan.dropTargetDate) {
        currentDate = new Date(plan.dropTargetDate);
      } else if (typeof date === 'string' && date.includes('T')) {
        currentDate = new Date(date);
      } else {
        currentDate = new Date(date);
      }
      
        
      const gcal_id = athlete.calendars[0].gcal_id;

      const start = format(currentDate, "yyyy-MM-dd");
      
      const end = format(addDays(currentDate, 1), "yyyy-MM-dd");
      const sb_start = getSQLDate(currentDate);
      
      const planID = plan?.plan_id ? plan.plan_id : plan.id;

      if (!planID) {
        console.error("Plan ID is a");
        throw new Error("Plan ID is missing");
      }

      // Add error handling for getPlanByIdPublic
      const planResponse = await getPlanByIdPublic(supabase, planID);

      if (!planResponse || !planResponse.data) {
        console.error("Invalid response from getPlanByIdPublic:", planResponse);
        throw new Error("Failed to get plan details");
      }

      const getplan = planResponse.data;

      if (!getplan || !getplan[0]) {
        console.error("Plan not found:", planID);
        throw new Error("Plan not found");
      }


      const jsonPlan_Client = {
        name: plan.type === "Group" 
          ? (getplan[0].exercise_plans?.[0]?.exercises?.title || "Untitled Class")
          : (plan.name || getplan[0].name || "Untitled Plan"),
        type: getplan[0].type || "Unknown",
        start_time: time?.start_time || null,
        end_time: time?.end_time || null,
        overview: plan.overview || getplan[0].overview || "",
        created_at: new Date(),
        coach_id: user.id,
      };


      const { data: plan_client, error: planClientError } =
        await plan_client_upsert(supabase, jsonPlan_Client);

      if (planClientError) {
        console.error("Plan client error:", planClientError);
        throw new Error(
          `Failed to create plan client: ${planClientError.message}`
        );
      }

      if (!plan_client || !plan_client[0]) {
        console.error("No plan client data returned");
        throw new Error("No plan client data returned from database");
      }

      const timezoneName = time?.start_time?.split(" ")[1] || "Europe/Paris";

      const gcal_event = {
        summary: `C+ ${getAthleteName(athlete)} - ${
          plan.type === "Group" 
          ? (getplan[0].exercise_plans?.[0]?.exercises?.title || "Untitled Class")
          : (plan.name || getplan[0].name || "Untitled Plan")
        }`,
        description: renderGCalDescription(
          plan_client[0],
          athlete.email,
          plan_client[0].id
        ),
        start: time?.start_time
          ? {
              dateTime: `${start}T${time.start_time.split(" ")[0]}:00`,
              timeZone: timezoneName,
            }
          : {
              date: start,
            },
        end: time?.end_time
          ? {
              dateTime: `${start}T${time.end_time.split(" ")[0]}:00`,
              timeZone: timezoneName,
            }
          : {
              date: end,
            },
        reminders: {
          useDefault: true,
        },
      };

      const createdGcalEvent = await createEvent(gcal_id, gcal_event);
      if (!createdGcalEvent) {
        console.error("Failed to create Google Calendar event");
        throw new Error("Failed to create Google Calendar event");
      }

      const sb_event = {
        date: sb_start,
        calendar_id: athlete.calendars[0].id,
        gcal_id,
        payload: createdGcalEvent,
        coach_id: user.id,
        athlete_id: athlete.id,
        plan_client_id: plan_client[0].id,
        plan_id: planID,
        workout: getplan[0].exercise_plans?.[0]?.exercises?.workout || "",
      };

      const { data: eventData, error: eventError } = await insertEvent(
        supabase,
        sb_event
      );

      if (eventError) {
        console.error("Event insert error:", eventError);
        throw new Error(`Failed to insert event: ${eventError.message}`);
      }

      const exercisePlans = (getplan[0].exercise_plans || []).map((e) => ({
        plan_client_id: plan_client[0].id,
        exercise_id: e.exercise_id,
        order: e.order,
        notes: e.notes || "",
        sets: e.sets || 0,
        isSuperset: e.isSuperset || false,
        supersetGroup: e.supersetGroup || null,
      }));

      if (exercisePlans.length > 0) {
        await insertExercisePlans(supabase, exercisePlans);
      }

      if (reload) {
        reload();
      }
      if (onSuccess) {
        onSuccess();
      }
      back();
    } catch (error) {
      console.error("Detailed error:", {
        message: error.message,
        stack: error.stack,
        error,
      });
      alert(`Failed to create training plan: ${error.message}`);
      throw error;
    }
  };

  const throttledHandleSubmit = useCallback(
    async (plan, time = null) => {
      if (isSubmitting) return;
      
      // Immediately set submitting state and close dialogs
      setIsSubmitting(true);
      setIsTrainingDialogOpen(false);
      setTimeModalOpen(false);
      
      try {
        await handleSubmit(plan, time);
      } catch (error) {
        // If there's an error, reset the submission state
        setIsSubmitting(false);
        throw error;
      }
      
      // Keep loading state for consistent user experience
      // The back() function will be called by handleSubmit which will navigate away,
      // so we don't need to reset isSubmitting here
    },
    [isSubmitting]
  );

  const handlePlanSelection = (plan) => {
    if (!plan || !plan.type) {
      console.error("Invalid plan object:", plan);
      return;
    } 

    // Set loading state immediately on plan selection
    setIsSubmitting(true);

    // Preserve the dropTargetDate if it exists (from drag and drop)
    const planWithDropDate = plan.dropTargetDate 
      ? plan 
      : plan;

    if (planWithDropDate.type === "Group") {
      throttledHandleSubmit(planWithDropDate);
    } else {
      // Small delay to ensure loading state is visible before dialog appears
      setTimeout(() => {
        setPendingPlan(planWithDropDate);
        setIsTrainingDialogOpen(true);
        setIsSubmitting(false);
      }, 300);
    }
  };

  const handleTrainingResponse = (isTraining) => {
    if(isTraining!==false && isTraining!==true){
      return;
    }
    setIsTrainingDialogOpen(false);

    if (isTraining) {
      if (pendingPlan.type === "Solo") {
        // Add loading state to prevent "Ready to schedule" flicker
        setIsSubmitting(true);
        setTimeout(() => {
          setTimeModalOpen(true);
          setSelectedPlanForTime(pendingPlan);
          setIsSubmitting(false);
        }, 300);
      } else {
        // Set loading immediately before submitting
        setIsSubmitting(true);
        throttledHandleSubmit(pendingPlan);
      }
    } else if (pendingPlan.type === "Solo") {
      // Set loading immediately before submitting
      setIsSubmitting(true);
      throttledHandleSubmit(pendingPlan);
    }
    setPendingPlan(null);
  };

  if (isInitializing) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Paper
        sx={{
          backgroundColor: "#FBFBFB",
          borderRadius: "24px",
          height: "100%",
          width:"100%",
          display: trainingPlanOpen ? "none" : "flex",
          justifyContent:"center",
          border: "none",
          boxShadow: "unset",
          position: 'relative',
        }}
      >
        <Box
        sx={{
          width:"568px",
          height:"100%",
          marginBottom:"20px"
        }}
        >

        {(isInitializing || isSubmitting) && (
          <Box sx={loadingOverlayStyle}>
            <CircularProgress 
              size={40}
              sx={{
                color: '#2998E9',
              }}
            />
          </Box>
        )}
        <Fade in={!isInitializing} timeout={300}>
          <Button
            startIcon={<LeftArrow />}
            onClick={back}
            sx={{
              fontSize: "12px",
              textTransform: "none",
              ml: 2,
              mt: 2,
              mb: 1,
              ".MuiButton-icon": {
                marginRight: "3px",
              },
            }}
          >
            <span style={{ marginTop: "1px" }}>Back</span>
          </Button>
        </Fade>
        <Fade in={!isInitializing} timeout={400}>
          <Box p={1.8} pb={0} pt={1} sx={{ height: "100px",width:"100%" }}>
            <Box pb={2} display={"flex"} alignItems={"flex-end"}>
              <Typography
                sx={{
                  fontSize: {
                    xl: "16px",
                    lg: "16px",
                    md: "13px",
                    sm: "13px",
                    xs: "13px",
                  },
                  fontWeight: "600 !important",
                  color: "#02BD36",
                }}
              >
My workouts            </Typography>
              <Button
                ml={"auto !important"}
                sx={{...button3Style,

                  border:"1px solid #525A65",
                  background:"#EEEEEE",
                  color:"#0F0F0F",

                }}
                onClick={() => setTrainingPlanOpen(true)}
              >
                Workout builder
              </Button>
            </Box>

            <TextField
              id="outlined-search"
              label="Search workout by name..."
              type="search"
              fullWidth
              value={tpSearch}
              sx={searchStyle}
              onChange={(e) => setTpSearch(e.target.value)}
            />
          <Divider sx={{mt:1.5}}/>

          </Box>
        </Fade>
        <Fade in={!isInitializing} timeout={500}>
          <Box pl={1} pr={1} mt={2}>
            <Grid container spacing={1}>
              {plans
                .filter((plan) => {
                  if (!tpSearch) return true; // Show all plans if search is empty
                  if (!plan?.name) return false; // Filter out plans without names
                  return plan.name
                    .toLowerCase()
                    .includes(tpSearch.toLowerCase());
                })
                .map((plan, i) => (
                  <Grid item xs={6} sm={6} md={4} key={i}>
                    <Grow
                      in={!isInitializing}
                      style={{ transformOrigin: '0 0 0' }}
                      timeout={600 + (i * 100)}
                    >
                      <Paper
                        onClick={(e) => {
                          if (
                            e.target.closest("#basic-button") ||
                            e.target.closest("#basic-menu")
                          ) {
                            return;
                          }
                          handlePlanSelection(plan);
                        }}
                        draggable
                        onDragStart={(e) => {
                          // Set data for the drag operation
                          e.dataTransfer.setData("plan", JSON.stringify(plan));
                          e.dataTransfer.effectAllowed = "copy";
                          
                          // Add a class to the element while dragging
                          e.currentTarget.classList.add("dragging");
                        }}
                        onDragEnd={(e) => {
                          // Remove the class when dragging ends
                          e.currentTarget.classList.remove("dragging");
                        }}
                        sx={{
                          backgroundColor: "#F1F4F1",
                          borderRadius: "8px",
                          boxShadow: "none",
                          position: "relative",
                          height: "100%",
                          minHeight: "145px",
                          cursor: "pointer",
                          opacity: 1,
                          transform: "translateY(0)",
                          transition: "all 0.3s ease-in-out",
                          "&:hover": {
                            transform: "translateY(-2px)",
                            boxShadow: "0 4px 8px rgba(0,0,0,0.05)",
                            backgroundColor: "#E9FFCE",
                            "& .menu-icon": {
                              opacity: 1
                            }
                          },
                          "&.dragging": {
                            opacity: 0.7,
                            transform: "scale(0.95)",
                            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
                          },
                          p: 2
                        }}
                      >
                        <Box sx={{ position: 'relative', height: '100%',
                          display:"flex",
                          alignItems:"center",
                          justifyContent:"center"
                         }}>
                          <Typography
                            className="plan-title"
                            sx={{
                              color: "#0F0F0F",
                              fontSize: "16px",
                              fontWeight: "600",
                              textAlign: 'center',
                              transition: "color 0.3s ease",
                            }}
                          >
                            {plan?.name || ""}
                          </Typography>

                          <Box
                            className="menu-icon"
                            sx={{ 
                              position: 'absolute',
                              top: -8,
                              right: -8,
                              transition: "all 0.3s ease",
                              opacity: 0.7,
                              transform: "scale(1)",
                            }}
                          >
                            <IconButton
                              id="basic-button"
                              aria-controls={openMenuIndex === i ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              sx={{
                                padding: "16px 10px 16px 10px !important",
                              }}
                              aria-expanded={openMenuIndex === i ? "true" : undefined}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleClick(event, i);
                              }}
                            >
                              <svg
                                width="16"
                                height="4"
                                viewBox="0 0 16 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="2"
                                  cy="2"
                                  r="2"
                                  transform="rotate(-90 2 2)"
                                  fill="#525A65"
                                />
                                <circle
                                  cx="8"
                                  cy="2"
                                  r="2"
                                  transform="rotate(-90 8 2)"
                                  fill="#525A65"
                                />
                                <circle
                                  cx="14"
                                  cy="2"
                                  r="2"
                                  transform="rotate(-90 14 2)"
                                  fill="#525A65"
                                />
                              </svg>
                            </IconButton>
                            <Menu
                              sx={{
                                '& .MuiPaper-root': {
                                  backgroundColor: '#F1F4F1',
                                  borderRadius: '16px',
                                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                  minWidth: '190px',
                                  paddingX: "4px",
                                  animation: 'menuSlideIn 0.2s ease-out',
                                  "@keyframes menuSlideIn": {
                                    "0%": {
                                      opacity: 0,
                                      transform: "translateY(-10px)"
                                    },
                                    "100%": {
                                      opacity: 1,
                                      transform: "translateY(0)"
                                    }
                                  }
                                }
                              }}
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={openMenuIndex === i}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              onClick={(e) => e.stopPropagation()}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              <MenuItem
                                sx={{ 
                                  fontSize: "15px", 
                                  color: "#525A65",
                                  fontWeight: "400",
                                  ":hover":{
                                    fontWeight:"500",
                                    background:"unset!important"
                                  }
                                }}
                                onClick={() => {
                                  setTrainingPlanOpen(true);
                                  setSelectedPlan(plan);
                                  handleClose();
                                }}
                              >
                                View/Edit
                              </MenuItem>
                              <Divider sx={{ my: 1, mr: 1, ml: 1 }} />
                              <MenuItem
                                sx={{ 
                                  fontSize: "15px", 
                                  color: "#EC0B43",
                                  ":hover": {
                                    fontWeight:"500",
                                    background:"unset!important"
                                  }
                                }}
                                onClick={async () => {
                                  if (!plan?.name) {
                                    console.error("Plan name is missing");
                                    return;
                                  }
                                  if (
                                    window.confirm(
                                      `Deleting training plan: "${plan.name}". Please confirm.`
                                    )
                                  ) {
                                    await deletePlanEvents(supabase, plan.id);
                                    loadPlans();
                                    reload();
                                  }
                                  handleClose();
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </Box>
                        </Box>
                      </Paper>
                    </Grow>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Fade>
        </Box>

      </Paper>
      <Box 
      sx={{
        width:"100%",
        display:"flex",
        justifyContent:"center",
        background:"#F5F5F5"
      }}
      
      >

      {trainingPlanOpen && (
        <TrainingPlanModal
          exercises={exercises}
          open={trainingPlanOpen}
          plan={SelectedPlan}
          isvisible={true}
          title={title}
          handleClose={() => {
            setTrainingPlanOpen(false);
            setSelectedPlan(null);
          }}
          onSuccess={() => {
            loadPlans();
            reload();
          }}
          onCreateEvent={(planId, time) => {
              
            handleSubmit(planId, time);
          }}
          fromAthleteRoute={true}
          date={date}
          athleteTZ={athlete?.timezone}
        />
      )}
      </Box>
      <TimeSelectionModal
        open={timeModalOpen && !isSubmitting}
        onClose={() => {
          setTimeModalOpen(false);
          setSelectedPlanForTime(null);
        }}
        onSubmit={({ start_time, end_time }) => {
          // Add loading state immediately when time is selected
          setIsSubmitting(true);
          setTimeout(() => {
            throttledHandleSubmit(selectedPlanForTime, { start_time, end_time });
            setTimeModalOpen(false);
            setSelectedPlanForTime(null);
          }, 100);
        }}
        type={selectedPlanForTime?.type}
        planType={"workout"}
        date={date}
        athleteTZ={athlete?.timezone}
      />

      <Dialog
        open={isTrainingDialogOpen && !isSubmitting}
        onClose={() => {
          setIsTrainingDialogOpen(false);
          setPendingPlan(null);
        }}
        TransitionComponent={Fade}
        transitionDuration={300}
        PaperProps={{
          style: {
            borderRadius: "24px",
            padding: "0",
            width: "100%",
            maxWidth: "529px",
            height: "525px",
            background: "#FBFBFB",
          },
        }}
      >
        <Fade in={isTrainingDialogOpen && !isSubmitting} timeout={500}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "40px 20px",
              height: "100%",
              position: "relative",
            }}
          >
            {isSubmitting && (
              <Box sx={loadingOverlayStyle}>
                <CircularProgress 
                  size={40}
                  sx={{
                    color: '#2998E9',
                  }}
                />
              </Box>
            )}
            <Box sx={{ 
              marginBottom: "30px", 
              display: "flex", 
              justifyContent: "center",
              marginTop: "60px",
              animation: "pulse 2s infinite ease-in-out",
              "@keyframes pulse": {
                "0%": { transform: "scale(1)" },
                "50%": { transform: "scale(1.05)" },
                "100%": { transform: "scale(1)" }
              }
            }}>
              <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="50" fill="#02BD36"/>
                <path d="M49.9557 78.9265C47.5282 78.9265 45.3537 77.467 44.4155 75.233C43.4921 72.999 43.9985 70.4225 45.7261 68.6949C47.4388 66.9822 50.0153 66.4609 52.2493 67.3992C54.4833 68.3226 55.9428 70.5119 55.9428 72.9395C55.9428 76.2457 53.262 78.9265 49.9557 78.9265Z" fill="#FBFBFB"/>
                <path d="M53.6938 57.6462C53.5448 59.21 51.9066 60.4312 49.9407 60.4312C47.9748 60.4312 46.3365 59.21 46.1876 57.6462L43.3281 26.4004C43.3281 23.4813 46.2919 21.1133 49.9556 21.1133C51.7874 21.1133 53.4406 21.709 54.632 22.6622C55.8235 23.6153 56.5681 24.9408 56.5681 26.4004L53.6938 57.6462Z" fill="#FBFBFB"/>
              </svg>
            </Box>

            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#525A65",
                fontFamily: "'Bricolage Grotesque', sans-serif",
                textAlign: "center",
                marginBottom: "30px",
              
              }}
            >
              Hold on!
            </Typography>

            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#000000",
                fontFamily: "'Bricolage Grotesque', sans-serif",
                textAlign: "center",
                maxWidth: "359px",
                marginBottom: "50px",
              }}
            >
              Do you want to set a specific time <Box sx={{width:"auto",height:"auto",display:{xs:"none",sm:"unset"}}}> <br /></Box> for this activity?
            </Typography>

            <Box 
              sx={{ 
                display: "flex", 
                gap: "30px", 
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={() => handleTrainingResponse(false)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "15px 22px",
                  background: "#EEEEEE",
                  border: "1px solid #525A65",
                  borderRadius: "20px",
                  width: "66px",
                  height: "49px",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#0F0F0F",
                  fontFamily: "'Bricolage Grotesque', sans-serif",
                  textTransform: "none",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                    background: "#E5E5E5"
                  }
                }}
              >
                No
              </Button>
              <Button
                onClick={() => handleTrainingResponse(true)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "15px 22px",
                  background: "#EEEEEE",
                  border: "1px solid #525A65",
                  borderRadius: "20px",
                  width: "71px",
                  height: "49px",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#0F0F0F",
                  fontFamily: "'Bricolage Grotesque', sans-serif",
                  textTransform: "none",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                    background: "#E5E5E5"
                  }
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Fade>
      </Dialog>
    </>
  );
};
