import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const SimpleLogo = () => {
  const Navigate=useNavigate()
  return (
    <Box
      sx={{
        height: 64,
        backgroundColor: "#FBFBFB",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        borderBottom: "1px solid #e0e0e0",
      }}
      onClick={()=>window.location.pathname.startsWith("/blog")&&Navigate("/")}
    >
      <img
        src={require("../../assets/OldLogoDasboard.png")}
        alt={require("../../assets/OldLogoDasboard.png")}
        width={218}
        style={{ cursor: "pointer" }}
      />
    </Box>
  );
};
