import { useEffect, useState, useCallback } from "react"
import {
    Box,
    Button,
    IconButton,
    Typography,
    Container,
    List, ListItem, ListItemText, ListItemIcon,
} from "@mui/material";
import { supabase } from "../../services/supabase";
import { getPlanByIdClient } from "../../services/query";
import { useAuth } from "../../providers/AuthContextProvider";
import { FirstVideoImage } from "../TPDisplay/TPDisplay";
import { TPModal } from "../TPDisplay/OpenmodelTP";
import { TrainingPlanModal } from "../TrainingPlanModal/TrainingPlanModal"
import { LeftArrow, InfoIcon } from "./style"
import { ExerciseModal } from "./ExerciseModal";


export const ViewExerciseAndPlan = ({ athelete, back, title, reload }) => {
    const [openModal, setOpenModal] = useState(false);
    const [ExerciseModals, setExerciseModals] = useState(false);
    const [trainingPlanOpen, setTrainingPlanOpen] = useState(false);
    const [ModalData, setModalData] = useState([]);
    const [exercises, setExercises] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [Plans, setPlans] = useState(null);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const handleClickExercise = (videoId, event) => {
        if (event) {
            event.stopPropagation();
        }
        setSelectedExercise(videoId);
        setOpenModal(true);
    };

    const loadPlans = useCallback(async () => {
        try {
            if (!Plans?.id) return;
            const { data } = await getPlanByIdClient(supabase, Plans.id);
            if (data && data[0]) {
                setPlans(data[0]);
                if (data[0].exercise_plans) {
                    data[0].exercise_plans.sort((a, b) => a.order - b.order);
                }
            }
        } catch (error) {
            console.error("Error loading plans:", error);
        }
    }, [Plans?.id]);

    useEffect(() => {
        if (athelete?.[0]?.plan?.[0]) {
            setPlans(athelete[0].plan[0]);
        }
    }, [athelete]);

    useEffect(() => {
        loadPlans();
    }, [loadPlans]);

    useEffect(() => {
        if(!Plans){
            return
        }
        if (trainingPlanOpen) {
            title("Edit Workout");
        } else {
            if(Plans?.is_single_exercise){
            title("Exercise");
            }else{
                title("Workout");
            }
        }
    }, [trainingPlanOpen,Plans]);

    const groupExercisesBySuperset = (exercises) => {
        if (!exercises) return [];
        
        const groups = [];
        let currentGroup = [];
        let currentSupersetId = null;

        const pushGroup = () => {
            if (currentGroup.length > 0) {
                groups.push({
                    type: currentSupersetId ? 'superset' : 'normal',
                    exercises: currentGroup.map(exercise => ({
                        exercises: exercise.exercises,
                        sets: currentSupersetId ? currentGroup[0].sets : exercise.sets,
                        notes: exercise.notes,
                        isSuperset: exercise.isSuperset,
                        supersetGroup: exercise.supersetGroup,
                        order: exercise.order
                    }))
                });
                currentGroup = [];
            }
        };

        exercises.forEach((exercise) => {
            if (exercise.isSuperset) {
                if (exercise.supersetGroup !== currentSupersetId) {
                    pushGroup();
                    currentSupersetId = exercise.supersetGroup;
                }
                currentGroup.push({ ...exercise });
            } else {
                pushGroup();
                currentSupersetId = null;
                groups.push({
                    type: 'normal',
                    exercises: [{
                        exercises: exercise.exercises,
                        sets: exercise.sets,
                        notes: exercise.notes,
                        isSuperset: exercise.isSuperset,
                        supersetGroup: exercise.supersetGroup,
                        order: exercise.order
                    }]
                });
            }
        });

        pushGroup();
        return groups;
    };

    return (
        <>
            <Container maxWidth="100" maxHeight={"555px"} sx={{
                width: "100%",
                minHeight:"120vh",
                backgroundColor: '#EEE', padding: 2,
                borderBottomLeftRadius: "24px",
                borderBottomRightRadius: "24px",
                display: trainingPlanOpen ? "none" : "flex",
                justifyContent:"center",
            }}>
                <Box
                sx={{
          width:"568px",

                }}
                
                >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Button startIcon={<LeftArrow />} onClick={back} sx={{
                        fontSize: "12px", textTransform: "none", ".MuiButton-icon": {
                            marginRight: "3px"
                        }
                    }}>
                        <span style={{ marginTop: "1px" }}>
                            Back
                        </span>
                    </Button>
                    {!Plans?.is_single_exercise && (
                        <Button variant="outlined" sx={{
                            backgroundColor: "#525A65",
                            borderRadius: "30px", color: "#EEEEEE", fontSize: "12px", borderColor: "#2998E9", maxHeight: "26px", lineHeight: "1", ":hover": {
                                backgroundColor: "unset",
                                color: "#2998E9",
                                borderColor: "#2998E9"
                            },
                            padding: "6px 9px",
                            textTransform: "none"
                        }}
                            onClick={() => { setTrainingPlanOpen(true); setSelectedPlan(Plans); }}>
                            Edit workout
                        </Button>
                    )}
                </Box>
                <Typography fontWeight="600 !important" fontSize={"16px"} color={"#525A65"} mb={2} ml={1}>
                    {Plans?.name}
                </Typography>
                <Typography mb={2} ml={1} fontWeight="400 !important" fontSize={"10px"} color={"#525A65"}>
                    {Plans?.overview}
                </Typography>
                <Typography fontWeight="600 !important" fontSize={"16px"} color={"#525A65"} mb={1} ml={1}>
                    {Plans?.is_single_exercise ? "Exercise" : "Workout"}
                </Typography>
                <List sx={{ maxHeight: "355px", height: "355px", overflow: "auto" }}>
                    {Plans?.exercise_plans ? 
                        groupExercisesBySuperset(Plans.exercise_plans.sort((a, b) => a.order - b.order))
                            .map((group, groupIndex) => (
                                <Box key={groupIndex} sx={{
                                    marginBottom: 1,
                                    marginRight: 1,
                                    position: 'relative',
                                    ...(group.type === 'superset' && {
                                        position: 'relative',
                                    })
                                }}>
                                    {group.type === 'superset' ? (
                                        <Box sx={{
                                            borderRadius: '24px',
                                            padding: '8px',
                                            marginBottom: '8px',
                                            backgroundColor: '#FBFBFB',
                                            position: 'relative'
                                        }}>
                                            <Box sx={{
                                                position: 'absolute',
                                                left: '8px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                zIndex: 1
                                            }}>
                                                <Box sx={{
                                                    border: "1px solid #DAD9D8",
                                                    borderRadius: '50%',
                                                    width: 30,
                                                    height: 30,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: '#FBFBFB'
                                                }}>
                                                    <span style={{
                                                        fontSize: "12px",
                                                        fontWeight: '600',
                                                        color: "#525A65",
                                                        lineHeight: 1
                                                    }}>
                                                        {"x" + (group.exercises[0].sets || 1)}
                                                    </span>
                                                </Box>
                                            </Box>
                                            {group.exercises.map(({ exercises: e, sets, notes }, i) => (
                                                <ListItem key={i} sx={{
                                                    padding: '12px',
                                                    ...(i < group.exercises.length - 1 && {
                                                        borderBottom: '1px solid #E5E8EB',
                                                        opacity: 1
                                                    }),
                                                    backgroundColor: '#FBFBFB',
                                                    borderRadius: "24px",
                                                    paddingLeft: '50px'
                                                }}>
                                                    <ListItemText
                                                        primary={
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                {e.videos.length > 0 ?
                                                                    <>
                                                                        <FirstVideoImage                               videoId={e.videos[0].id} height="56px" width="100px" borderRadius="10px" handleClickExercise={(event) => handleClickExercise(e.id, event)} />
                                                                        <Box display={"flex"} flexDirection={"column"}>
                                                                            <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                                {e.title}
                                                                            </Typography>
                                                                            <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                                {notes}
                                                                            </Typography>
                                                                        </Box>
                                                                    </>
                                                                    : e.images.length > 0 ?
                                                                        <>
                                                                            <div style={{ width: '100%', maxWidth: '150px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                <a style={{ cursor: "pointer" }} onClick={(event) => handleClickExercise(e.id, event)}>
                                                                                    <img
                                                                                        src={
                                                                                            e.images[0]?.id?.startsWith("custom_image_")
                                                                                              ? `https://zoqbnckhrsteugiuynck.supabase.co/storage/v1/object/public/custom_videos//custom_image_${e.images[0].id}`
                                                                                              : e.images[0] // Eğer normal bir image URL'si ise olduğu gibi kullan
                                                                                          }
                                                                                        alt={e.title}
                                                                                        style={{
                                                                                            borderRadius: "10px",
                                                                                            maxWidth: 100,
                                                                                            maxHeight: 56,
                                                                                            width: "100%",
                                                                                            height: "100%",
                                                                                        }}
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                            <Box display={"flex"} flexDirection={"column"}>
                                                                                <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                                    {e.title}
                                                                                </Typography>
                                                                                <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                                    {notes}
                                                                                </Typography>
                                                                                <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#838F99"} ml={1} mt={0.5}>
                                                                                    {e.type}
                                                                                </Typography>
                                                                            </Box>
                                                                        </>
                                                                        : <></>}
                                                                {e.images.length === 0 && e.videos.length === 0 ?
                                                                    <>
                                                                        <img
                                                                            src={require("../../assets/Rectangle-ccc.png")}
                                                                            alt={"empty"}
                                                                            style={{
                                                                                maxWidth: 100,
                                                                                maxHeight: 56,
                                                                                width: "100%",
                                                                                height: "100%",
                                                                            }}
                                                                        />
                                                                        <Box display={"flex"} flexDirection={"column"}>
                                                                            <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                                {e.title}
                                                                            </Typography>
                                                                            <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                                {notes}
                                                                            </Typography>
                                                                        </Box>
                                                                    </>
                                                                    : <></>}
                                                            </Box>
                                                        }
                                                    />
                                                    <IconButton edge="end" aria-label="info" sx={{ marginRight: "0px !important" }}
                                                        onClick={() => { setExerciseModals(true); setModalData(group.exercises[i]); }}>
                                                        <InfoIcon />
                                                    </IconButton>
                                                </ListItem>
                                            ))}
                                        </Box>
                                    ) : (
                                        group.exercises.map(({ exercises: e, sets, notes }, i) => (
                                            <ListItem key={i} sx={{
                                                padding: '12px',
                                                backgroundColor: '#FBFBFB',
                                                borderRadius: "24px",
                                                marginBottom: '8px'
                                            }}>
                                                {sets>0&&
                                        
                                                <ListItemIcon>
                                                    <Box sx={{
                                                        border: "1px solid #DAD9D8",
                                                        borderRadius: '50%',
                                                        width: 30,
                                                        height: 30,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        marginRight: '12px'
                                                    }}>
                                                        <span style={{
                                                            fontSize: "12px",
                                                            fontWeight: '600',
                                                            color: "#525A65",
                                                            lineHeight: 1
                                                        }}>
                                                            {"x" + (sets || 1)}
                                                        </span>
                                                    </Box>
                                                </ListItemIcon>}
                                                <ListItemText
                                                    primary={
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            {e.videos.length > 0 ?
                                                                <>
                                                                    <FirstVideoImage v                              videoId={e.videos[0].id}
 height="56px" width="100px" borderRadius="10px" handleClickExercise={(event) => handleClickExercise(e.id, event)} />
                                                                    <Box display={"flex"} flexDirection={"column"}>
                                                                        <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                            {e.title}
                                                                        </Typography>
                                                                        <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                            {notes}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                                : e.images.length > 0 ?
                                                                    <>
                                                                        <div style={{ width: '100%', maxWidth: '150px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <a style={{ cursor: "pointer" }} onClick={(event) => handleClickExercise(e.id, event)}>
                                                                                <img
                                                                                    src={
                                                                                        e.images[0]?.id?.startsWith("custom_image_")
                                                                                          ? `https://zoqbnckhrsteugiuynck.supabase.co/storage/v1/object/public/custom_videos//custom_image_${e.images[0].id}`
                                                                                          : e.images[0] // Eğer normal bir image URL'si ise olduğu gibi kullan
                                                                                      }
                                                                                    alt={e.title}
                                                                                    style={{
                                                                                        borderRadius: "10px",
                                                                                        maxWidth: 100,
                                                                                        maxHeight: 56,
                                                                                        width: "100%",
                                                                                        height: "100%",
                                                                                    }}
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                        <Box display={"flex"} flexDirection={"column"}>
                                                                            <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                                {e.title}
                                                                            </Typography>
                                                                            <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                                {notes}
                                                                            </Typography>
                                                                        </Box>
                                                                    </>
                                                                    : <></>}
                                                            {e.images.length === 0 && e.videos.length === 0 ?
                                                                <>
                                                                    <img
                                                                        src={require("../../assets/Rectangle-ccc.png")}
                                                                        alt={"empty"}
                                                                        style={{
                                                                            maxWidth: 100,
                                                                            maxHeight: 56,
                                                                            width: "100%",
                                                                            height: "100%",
                                                                        }}
                                                                    />
                                                                    <Box display={"flex"} flexDirection={"column"}>
                                                                        <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                            {e.title}
                                                                        </Typography>
                                                                        <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                            {notes}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                                : <></>}
                                                        </Box>
                                                    }
                                                />
                                                <IconButton edge="end" aria-label="info" sx={{ marginRight: "0px !important" }}
                                                    onClick={() => { setExerciseModals(true); setModalData(group.exercises[i]); }}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </ListItem>
                                        ))
                                    )}
                                </Box>
                            ))
                        : null
                    }
                </List>
                {ExerciseModals && <ExerciseModal open={ExerciseModals} handleClose={() => setExerciseModals(false)} exercise={ModalData} />}

                {openModal && Plans?.exercise_plans && (
                    <TPModal 
                        open={openModal} 
                        onClose={() => setOpenModal(false)} 
                        selected={selectedExercise} 
                        exerciseplan={Plans.exercise_plans.sort((a, b) => a - b).map(({ exercises: e }) => (e))} 
                    />
                )}
                
                </Box>

            </Container >
        
            {trainingPlanOpen && (
                    <Box sx={{
                        overflow:"hidden",
                        display:"flex",
                        justifyContent:"center",
                        height:"100%",
                        minHeight:"100vh",
                        width:"100%",
                        paddingTop:"0px",
                        marginTop:"0px",
                        marginBottom:"0px",
                        backgroundColor:"#EEEEEE",
                    }}>
                      
                <TrainingPlanModal
                    exercises={exercises}
                    open={trainingPlanOpen}
                    plan={selectedPlan}
                    isvisible={true}
                    handleClose={() => {
                        setTrainingPlanOpen(false);
                        setSelectedPlan(null);
                    }}
                    onSuccess={() => { 
                        loadPlans();
                        reload();
                    }}
                    fromAthleteRoute={true}
                />
            </Box>
            )}
        </>
    )
}