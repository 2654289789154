import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Autocomplete,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import {
  deleteExercisePlans,
  insertExercisePlans,
  upsertPlan,
  get_fill_exercise,
  upsertExercise,
  getPlanByIdClient,
  plan_client_upsert,
  deleteExercisePlanClient,
  getExercisesByCoachId,
} from "../../services/query";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LeftArrow } from "../ClientDetailTraining/style";
import {
  formtextfieldStyle,
  formtextfiel2dStyle,
  formtextfiel3dStyle,
  formtextselectStyle,
  formDescriptionStyle,
  close,
  button2Style,
  CreateUpdateStyle,
  DeleteIcon,
  DragDropIcon,
  button1Style,
  SuperSetIcon,
} from "./style";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TimeSelectionModal } from "../ClientDetailTraining/TimeSelectionModal";
import { getYouTubeVideoId } from "../../utils/validate";
import { IconClose } from "../ExerciseModal/ExerciseModal";
import { SuccessIcon } from "../../routes/Dashboard/Dashboard.component";
import { Grow, Zoom, Fade, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const TrainingPlanModal = ({
  open,
  handleClose,
  exercises = [], // Add default value
  plan,
  isvisible,
  onSuccess,
  onCreateEvent,
  title,
  fromAthleteRoute,
  date,
  athleteTZ
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [exerciseGroupedBy, setExerciseGroupedBy] = useState([]);
  const [fillexercise, setFillexercise] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [draggingItem, setDraggingItem] = useState(null);
  const [triggered, setTriggered] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [firstType, setFirstType] = useState("Solo");
  const [firstSelected,setFirstSelected]=useState(true)
  const [customInputValues, setCustomInputValues] = useState({});
  const [time, setTime] = useState({
    start_time: null,
    end_time: null
  });
  const [type, setType] = useState("Solo");
  const isUpdate = Boolean(plan?.id);
  const [isTrainingDialogOpen, setIsTrainingDialogOpen] = useState(false);
  const [timeModalOpen, setTimeModalOpen] = useState(false);
  const [pendingSubmitData, setPendingSubmitData] = useState(null);
  const [pendingInsertPlan, setPendingInsertPlan] = useState(null);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [pendingClose, setPendingClose] = useState(false);
  const [initialPlanState, setInitialPlanState] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigate = useNavigate();

  const { register, handleSubmit, watch } = useForm({
    defaultValues: plan
      ? { name: plan.name, overview: plan.overview, type: plan.type }
      : { name: "", overview: "", type: "Solo" },
  });

  // Watch form values for changes
  const watchedValues = watch();

  useEffect(() => {
    if(title){

      title("Build a workout")
    }
    const fetchExercises = async () => {
      try {
        setFetchLoading(true); // Ensure loading state is set at the beginning
        const { data: _fillexercise } = await get_fill_exercise(supabase);
        const { data: exercisesByCoach } = await getExercisesByCoachId(supabase,user.id);
        const customExercise = exercisesByCoach.filter(e=>e.workout==="Custom");
        if (_fillexercise && exercisesByCoach&&customExercise&&customExercise.length>0) {
            _fillexercise.unshift(...customExercise);
        }
        
        const filteredExercises = _fillexercise
        const finalSet = [...filteredExercises];
        setFillexercise(finalSet);
        
        // Filter exercises for Solo type immediately
        const soloExercises = finalSet.filter(exercise => exercise.type === "Solo");
        setExerciseGroupedBy(soloExercises);
        
        setFetchLoading(false); // Make sure to set loading to false when done
      } catch (error) {
        console.error("Error fetching exercises:", error);
        setFetchLoading(false); // Also set loading to false in case of error
      }
    };

    fetchExercises();
  }, []);
  const onDragStart = (result) => {
    setDragging(true);
    const reorderedExercises = Array.from(selectedExercises);
    const [movedExercise] = reorderedExercises.splice(result.source.index, 1);
    setDraggingItem(movedExercise.boxid);
  };
  const handleDelete = useCallback(
    (index) => {
      setSelectedExercises((prev) => {
        const updatedExercises = prev.filter((_, i) => i !== index);
        const supersetGroups = new Map();
        updatedExercises.forEach((exercise) => {
          if (exercise.supersetGroup) {
            if (!supersetGroups.has(exercise.supersetGroup)) {
              supersetGroups.set(exercise.supersetGroup, []);
            }
            supersetGroups.get(exercise.supersetGroup).push(exercise);
          }
        });

        supersetGroups.forEach((exercises, groupId) => {
          if (exercises.length === 1) {
            exercises[0].isSuperset = false;
            exercises[0].supersetGroup = null;
          }
        });

        return updatedExercises;
      });
    },
    [selectedExercises]
  );

  const onDragEnd = (result) => {
    if (!result.destination) {
      setDragging(false);
      setDraggingItem(null);
      return;
    }

    const reorderedExercises = Array.from(selectedExercises);
    const [movedExercise] = reorderedExercises.splice(result.source.index, 1);

    const destinationIndex = result.destination.index;
    const destinationExercise = reorderedExercises[destinationIndex];

    if (destinationExercise?.isSuperset) {
      movedExercise.isSuperset = true;
      movedExercise.supersetGroup = destinationExercise.supersetGroup;
    } else {
      movedExercise.isSuperset = false;
      movedExercise.supersetGroup = null;
    }

    reorderedExercises.splice(destinationIndex, 0, movedExercise);

    const updatedExercises = reorderedExercises.map((exercise, index) => ({
      ...exercise,
      boxid: index + 1,
      exercise: {
        ...exercise.exercise,
        order: index + 1,
      },
    }));

    setSelectedExercises(updatedExercises);
    setDragging(false);
    setDraggingItem(null);
    handleDelete();
  };

  const handleAddExercise = useCallback(() => {
    setTriggered(true);
    
    // Don't block adding exercises if fetchLoading is true
    // This was preventing adding exercises
    
    // Since we're always using "Solo" type now, we don't need this check
    // if (!["Solo", "Group"].includes(type)) {
    //   return;
    // }
    
    const newId = selectedExercises.length + 1;
    setSelectedExercises((prev) => [
      ...prev,
      {
        boxid: newId,
        exercise: [],
        notes: "",
        sets: 1,
        supersetGroup: null,
        isSuperset: false,
      },
    ]);
  }, [selectedExercises.length]);

  function generateUUID() {
    const timestamp = Date.now();
    const randomValues =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    return `superset-${timestamp}-${randomValues}`;
  }

  const addSuperSet = (index) => {
    if (index < 0 || index >= selectedExercises.length - 1) return;

    setSelectedExercises((prevExercises) => {
      const newExercises = [...prevExercises];
      const current = newExercises[index];
      const next = newExercises[index + 1];

      if (!current.supersetGroup && next.supersetGroup) {
        return prevExercises;
      }

      const createNewSuperset = () => {
        const supersetId = generateUUID();
        [current, next].forEach((exercise) => {
          exercise.supersetGroup = supersetId;
          exercise.isSuperset = true;
        });
      };

      const extendSuperset = () => {
        next.supersetGroup = current.supersetGroup;
        next.isSuperset = true;
      };

      const mergeSupersets = () => {
        const targetId = current.supersetGroup;
        newExercises.forEach((exercise) => {
          if (exercise.supersetGroup === next.supersetGroup) {
            exercise.supersetGroup = targetId;
            exercise.isSuperset = true;
          }
        });
      };

      if (!current.supersetGroup && !next.supersetGroup) createNewSuperset();
      else if (current.supersetGroup && !next.supersetGroup) extendSuperset();
      else if (current.supersetGroup !== next.supersetGroup) mergeSupersets();

      return newExercises;
    });
  };

  const shouldShowSupersetIcon = useCallback(
    (index) => {
      if (index + 1 >= selectedExercises.length) return false;

      const currentExercise = selectedExercises[index];
      const nextExercise = selectedExercises[index + 1];

      if (dragging && draggingItem === currentExercise.boxid) return false;

      return (
        !currentExercise.supersetGroup ||
        (currentExercise.supersetGroup && !nextExercise.supersetGroup) ||
        (currentExercise.supersetGroup &&
          nextExercise.supersetGroup &&
          currentExercise.supersetGroup !== nextExercise.supersetGroup)
      );
    },
    [selectedExercises, dragging, draggingItem]
  );

  const groupedExercises = useMemo(() => {
    const groupExercisesBySuperset = (exercises) => {
      const groups = [];
      let currentGroup = [];
      let currentSupersetId = null;

      const pushGroup = (type) => {
        if (currentGroup.length > 0) {
          const groupSets =
            type === "superset" ? currentGroup[0].exercise.sets : undefined;
          groups.push({ type, exercises: currentGroup, sets: groupSets });
          currentGroup = [];
        }
      };

      exercises.forEach((exercise, index) => {
        const isPartOfSuperset = !!exercise.supersetGroup;

        if (isPartOfSuperset) {
          if (exercise.supersetGroup !== currentSupersetId) {
            pushGroup(currentSupersetId ? "superset" : "normal");
            currentSupersetId = exercise.supersetGroup;
          }
          currentGroup.push({ exercise, index });
        } else {
          pushGroup(currentSupersetId ? "superset" : "normal");
          currentSupersetId = null;
          currentGroup.push({ exercise, index });
        }
      });

      pushGroup(currentSupersetId ? "superset" : "normal");

      return groups;
    };

    return groupExercisesBySuperset(selectedExercises);
  }, [selectedExercises]);

  const { user } = useAuth();
  const supabase = useSupabase();

  useEffect(() => {
    if (!plan) {
      setFetchLoading(false);
      setInitialPlanState({ exercises: [], name: "", overview: "", type: "Solo" });
      return;
    }

    // If plan exists but type is not "Solo", override it
    const planType = plan.type === "Group" ? plan.type : "Solo";
    setType(planType);
    setFirstType(planType);
    
    // Plan'dan gelen time bilgilerini ayarla
    if (plan.start_time && plan.end_time) {
      setPendingSubmitData({
        ...plan,
        start_time: plan.start_time,
        end_time: plan.end_time
      });
    }

    const selected = plan.exercise_plans.map((e, i) => ({
      boxid: i + 1,
      exercise: { ...e.exercises, order: e.order, type: e.type },
      notes: e.notes,
      sets: e.sets,
      isSuperset: e.isSuperset,
      supersetGroup: e.supersetGroup,
    }));

    setFetchLoading(false);
    setSelectedExercises(
      selected.sort((a, b) => a.exercise.order - b.exercise.order)
    );
    setInitialPlanState({
      exercises: selected.sort((a, b) => a.exercise.order - b.exercise.order),
      name: plan.name,
      overview: plan.overview,
      type: plan.type
    });
  }, [plan]);

  useEffect(() => {
    // Since type is always "Solo", we can simplify this
    if (!fillexercise.length) {
      return;
    }
    
    // Only reset selected exercises if we're not editing an existing plan
    if (!plan) {
      // Don't reset selected exercises here as it might interfere with adding exercises
      // setSelectedExercises([]);
    }
    
    // Filter exercises for Solo type
    const filtered = fillexercise.filter((exercise) => exercise.type === "Solo");
    setExerciseGroupedBy(filtered);
    setFirstSelected(false);
    
    // Log the number of filtered exercises to help with debugging
  }, [plan, fillexercise]);



  useEffect(() => {
    if (pendingInsertPlan && time.start_time && time.end_time) {
      if (onCreateEvent) {
        onCreateEvent(pendingInsertPlan, time);
        setPendingInsertPlan(null);
      }
    }
  }, [time, pendingInsertPlan]);

  const handleError = (error) => {
    console.error("Error saving training plan:", error);
    const errorMessage =
      error.message ||
      "An unexpected error occurred while saving the training plan";
    alert(errorMessage);
  };

  const validateExercises = (exercises) => {
    const errors = [];
    exercises.forEach((exercise, index) => {
      if (!exercise.exercise.title) {
        errors.push(`Exercise at position ${index + 1} is empty`);
      }
    });
    return errors;
  };

  const handleFormSubmit = (data) => {
    // Always set type to "Solo"
    data.type = "Solo";

    // Create button from non-visible mode - direct submit
    if (!isvisible) {
      return onSubmit(data, { start_time: null, end_time: null });
    }
    
    // For visible mode (both new and edit), show training dialog for Solo type
    if (type === "Solo" && isvisible) {
      setPendingSubmitData(data);
      setIsTrainingDialogOpen(true);
    } else {
      // Direct submit for non-Solo types
      return onSubmit(data, { start_time: null, end_time: null });
    }
  };

  const handleTrainingResponse = async (isTraining) => {
    setIsTrainingDialogOpen(false);

    if (isTraining) {
      setTimeModalOpen(true);
    } else {
      // Execute the regular submit without time
      if (isvisible && !onCreateEvent && isUpdate) {
        await onSubmitClient(pendingSubmitData);
      } else {
        await onSubmit(pendingSubmitData);
      }
    }
  };

  const handleTimeSubmit = async ({ start_time, end_time }) => {
    const submitData = {
      ...pendingSubmitData,
      start_time,
      end_time
    };

    if (isvisible && !onCreateEvent && isUpdate) {
      await onSubmitClient(submitData, { start_time, end_time });
    } else {
      await onSubmit(submitData, { start_time, end_time });
    }
    setTimeModalOpen(false);
  };

  const onSubmit = handleSubmit(async (data, timeData = null) => {
    const errors = validateExercises(selectedExercises);
    if (errors.length > 0) {
      alert(errors.join("\n"));
      return;
    }

    try {
      setLoading(true);
      const planPayload = {
        ...(plan?.id ? { id: plan.id } : {}),
        ...data,
        name: data.name,
        type: "Solo",
        coach_id: user.id,
      };

      const { data: newPlan, error: newPlanError } = await upsertPlan(
        supabase,
        planPayload
      );

      if (newPlanError) throw newPlanError;

      if (plan?.id) {
        await deleteExercisePlans(supabase, plan.id);
      }

      const supersetSets = new Map();
      selectedExercises.forEach((exercise) => {
        if (exercise.supersetGroup) {
          supersetSets.set(exercise.supersetGroup, exercise.sets);
        }
      });

      const processedExercises = await Promise.all(
        selectedExercises.map(async (e) => {
          if (!e.exercise.type) return e;

          const payload = {
            id: e.exercise.id,
            description: e.exercise.description,
            title: e.exercise.title,
            tags: e.exercise.tags,
            workout: e.exercise.title,
            requires_equipment: e.exercise.requires_equipment,
            videos: e?.exercise?.videos?.length>0?e.exercise.videos: e.exercise.video_link ? [
              {
                id: getYouTubeVideoId(e.exercise.video_link),
                url: e.exercise.video_link,
              },
            ] : [],
            images: e.exercise.images || [],
            coach_id: user.id,
          };

          const { data } = await upsertExercise(supabase, payload);
          return data[0]?.id
            ? {
                ...e,
                exercise: { ...e.exercise, id: data[0].id },
              }
            : e;
        })
      );

      const exercisePlans = processedExercises.map((e) => ({
        exercise_id: e.exercise.id,
        plan_id: newPlan[0].id,
        plan_client_id: null,
        order: e.exercise.order,
        notes: e.notes,
        sets: e.supersetGroup ? supersetSets.get(e.supersetGroup) : e.sets,
        isSuperset: e.isSuperset,
        supersetGroup: e.supersetGroup,
      }));

      const { data: insertPlan, error: insertError } = await insertExercisePlans(
        supabase,
        exercisePlans
      );

      if (insertError || !insertPlan || !insertPlan[0]) {
        throw new Error('Failed to insert exercise plans');
      }

      // If we're assigning the plan (timeData exists), create a plan_client entry
      if (timeData && onCreateEvent) {
        const planClientPayload = {
          name: data.name,
          overview: data.overview,
          type: "Solo",
          coach_id: user.id,
          created_at: new Date(),
          start_time: timeData.start_time,
          end_time: timeData.end_time
        };

        const { data: planClient, error: planClientError } = await plan_client_upsert(
          supabase,
          planClientPayload
        );

        if (planClientError) throw planClientError;

        // Update exercise plans with plan_client_id
        const clientExercisePlans = exercisePlans.map(ep => ({
          exercise_id: ep.exercise_id,
          plan_id: newPlan[0].id,
          plan_client_id: planClient[0].id,
          order: ep.order,
          notes: ep.notes,
          sets: ep.sets,
          isSuperset: ep.isSuperset,
          supersetGroup: ep.supersetGroup
        }));

        const { error: clientExerciseError } = await insertExercisePlans(
          supabase,
          clientExercisePlans
        );

        if (clientExerciseError) throw clientExerciseError;

        // Call onCreateEvent with the plan structure that matches the original plan format
        onCreateEvent({
          ...newPlan[0],  // This contains the original plan data
          id: newPlan[0].id,  // This is the plan ID that needs to be found
          exercise_plans: clientExercisePlans.map(ep => ({
            ...ep,
            exercises: processedExercises.find(e => e.exercise.id === ep.exercise_id)?.exercise
          }))
        }, timeData);
      }

      setPendingInsertPlan(insertPlan[0]);
      if(!fromAthleteRoute){
        onSuccess();
      }
      setIsSuccessModalOpen(true);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  });

  const onSubmitClient = handleSubmit(async (data, timeData) => {
    const errors = validateExercises(selectedExercises);
    if (errors.length > 0) {
      alert(errors.join("\n"));
      return;
    }

    try {
      setLoading(true);
      const planPayload = {
        ...(plan?.id ? { id: plan.id } : {}),
        ...data,
        // Use the provided name since we're always using Solo type
        name: data.name,
        type: "Solo", // Always set to "Solo"
        coach_id: user.id,
        created_at: plan?.created_at || new Date(),
        start_time: timeData?.start_time || data.start_time,
        end_time: timeData?.end_time || data.end_time,
      };

      const { data: newPlan, error: newPlanError } = await plan_client_upsert(
        supabase,
        planPayload
      );

      if (newPlanError) throw newPlanError;
      if (!newPlan || !newPlan[0]) throw new Error('Failed to create/update plan');

      const { data: planId, error: planIdError } = await getPlanByIdClient(supabase, newPlan[0].id);
      
      if (planIdError) throw planIdError;
      if (!planId || !planId[0] || !planId[0].exercise_plans || !planId[0].exercise_plans[0]) {
        throw new Error('Invalid plan data structure received');
      }

      const planClientId = newPlan[0].id;
      const originalPlanId = planId[0].exercise_plans[0].plan_id;

      if (plan?.id) {
        await deleteExercisePlanClient(supabase, plan.id);
      }

      const supersetSets = new Map();
      selectedExercises.forEach((exercise) => {
        if (exercise.supersetGroup) {
          supersetSets.set(exercise.supersetGroup, exercise.sets);
        }
      });

      const processedExercises = await Promise.all(
        selectedExercises.map(async (e) => {
          if (!e.exercise.type) return e;

          const payload = {
            id: e.exercise.id,
            description: e.exercise.description,
            title: `${e.exercise.title}`,
            tags: e.exercise.tags,
            workout: e.exercise.title,
            requires_equipment: e.exercise.requires_equipment,
            videos:e?.exercise?.videos.length>0?e.exercise.videos: e.exercise.video_link ? [
              {
                id: getYouTubeVideoId(e.exercise.video_link),
                url: e.exercise.video_link,
              },
            ] : [],
            images: e.exercise.images || [],
            coach_id: user.id,
          };

          const { data } = await upsertExercise(supabase, payload);
          return data[0]?.id
            ? {
                ...e,
                exercise: { ...e.exercise, id: data[0].id },
              }
            : e;
        })
      );

      const exercisePlans = processedExercises.map((e) => ({
        exercise_id: e.exercise.id,
        plan_id: originalPlanId,
        plan_client_id: planClientId,
        order: e.exercise.order,
        notes: e.notes,
        sets: e.supersetGroup ? supersetSets.get(e.supersetGroup) : e.sets,
        isSuperset: e.isSuperset,
        supersetGroup: e.supersetGroup,
      }));

      const { data: insertPlan, error: insertError } = await insertExercisePlans(
        supabase,
        exercisePlans
      );

      if (insertError || !insertPlan || !insertPlan[0]) {
        throw new Error('Failed to insert exercise plans');
      }

      setPendingInsertPlan(insertPlan[0]);
      if(!fromAthleteRoute){
        onSuccess();
      }
      if (onCreateEvent && insertPlan[0]) {
        const eventTimeData = {
          start_time: timeData?.start_time || data.start_time,
          end_time: timeData?.end_time || data.end_time
        };
        onCreateEvent(insertPlan[0], eventTimeData);
      }
      setIsSuccessModalOpen(true);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  });
  useEffect(()=>{

    if(firstSelected){
      return
    }
  },[selectedExercises])
  
  const hasChanges = () => {
    if (!initialPlanState) return selectedExercises.length > 0;
    
    // Get current form values from React Hook Form
    const currentName = watchedValues.name || "";
    const currentOverview = watchedValues.overview || "";
    // Type is always "Solo" now
    
    // Check if form fields have changed
    if (currentName !== initialPlanState.name || 
        currentOverview !== initialPlanState.overview) {
      return true;
    }
    
    // Check if exercises have changed
    if (selectedExercises.length !== initialPlanState.exercises.length) return true;
    
    // Check if any exercise details have changed
    for (let i = 0; i < selectedExercises.length; i++) {
      const current = selectedExercises[i];
      const initial = initialPlanState.exercises[i];
      
      if (!initial) return true;
      
      // Compare relevant properties
      if (current.exercise.title !== initial.exercise.title ||
          current.notes !== initial.notes ||
          current.sets !== initial.sets ||
          current.isSuperset !== initial.isSuperset ||
          current.supersetGroup !== initial.supersetGroup) {
        return true;
      }
    }
    
    return false;
  };
  
  const handleCloseAttempt = () => {
    if (hasChanges()) {
      setIsWarningModalOpen(true);
      setPendingClose(true);
    } else {
      handleClose();
    }
  };

  const handleWarningModalClose = () => {
    setIsWarningModalOpen(false);
    setPendingClose(false);
  };

  const handleWarningModalConfirm = () => {
    setIsWarningModalOpen(false);
    setPendingClose(false);
    handleClose();
  };

  return (
    <>
      <Paper
        sx={{
          backgroundColor: "unset",
          borderRadius: isvisible ? "0px 0px 24px 24px" : "24px",
          border: "none",
          boxShadow: "unset",
          width: ["90vw", "90vw", "568px", "568px"], 

          padding:"0",
          minHeight: isvisible ? "555px" : "684px",
          transform: "scale(1)",
          animation: "modalScale 0.3s ease-out",
          "@keyframes modalScale": {
            "0%": {
              transform: "scale(0.95)",
              opacity: 0
            },
            "100%": {
              transform: "scale(1)",
              opacity: 1
            }
          }
        }}
      >
        <WarningModal 
          open={isWarningModalOpen} 
          onClose={handleWarningModalClose}
          onConfirm={handleWarningModalConfirm}
        />
        <Box  sx={{ paddingBottom: "0px",paddingX:{xs:"18px",sm:"0"}}}>
          <form onSubmit={handleSubmit(handleFormSubmit)} 
          style={{position:"relative"}}>
            <Box  display={"flex"} sx={{
              justifyContent:"space-between",
              marginTop:"16px",
              alignItems:"center"
            }} >
              {isvisible ? (
                <Button
                  startIcon={<LeftArrow />}
                  onClick={handleCloseAttempt}
                  sx={{
                    fontSize: "12px",
                    textTransform: "none",
                    ".MuiButton-icon": {
                      marginRight: "3px",
                    },
                  }}
                >
                  <span style={{ marginTop: "1px" }}>Back</span>
                </Button>
              ) : (
                <Typography
                  sx={{
                    fontSize: {
                      xl: "16px",
                      lg: "16px",
                      md: "13px",
                      sm: "13px",
                      xs: "13px",
                    },
                    fontWeight: "600 !important",
                    color: "#02BD36",
                    mt:1,
                    ml:-2,
                  }}
                >
                  {isUpdate
                    ? "Update the workout"
                    : "Build a workout"}
                </Typography>
              )}

              {isvisible && type === "Group" && <></>}
              {isvisible ? (
                <div style={{ display: 'flex', position:"absolute", right: "0", gap: '10px' }}>
                  <Button
                    sx={{
                      ...button2Style,
                      background:!(loading || selectedExercises.length === 0)&&"#02BD36",
                      color:!(loading || selectedExercises.length === 0)&&"#FBFBFB",
                      ":hover":{
                        color:!(loading || selectedExercises.length === 0)&&"#000",
  
                        }

                      
                    }}
                    disabled={loading || selectedExercises.length === 0}
                    onClick={() => {
                      handleSubmit(async (data) => {
                        const originalIsVisible = isvisible;
                        const originalOnCreateEvent = onCreateEvent;
                        isvisible = false;
                        onCreateEvent = null;
                        
                        const timeData = { start_time: null, end_time: null };
                        await onSubmit(data, timeData);
                        
                        isvisible = originalIsVisible;
                        onCreateEvent = originalOnCreateEvent;
                      })();
                    }}
                  >
                    Save workout
                  </Button>
                  <Button
                    sx={{...button2Style,
                      background:!(loading || selectedExercises.length === 0)&&"#02BD36",
                      color:!(loading || selectedExercises.length === 0)&&"#FBFBFB",
                      ":hover":{
                      color:!(loading || selectedExercises.length === 0)&&"#000",

                      }
                    }}
                    disabled={loading || selectedExercises.length === 0}
                    type="submit"
                  >
                   Save & assign workout
                  </Button>
                </div>
              ) : (
                <Box
                  onClick={handleCloseAttempt}
                  size="small"
                  sx={{...close,bgcolor:"unset",padding:"0"}}
                >
                <IconClose/>

                </Box>
              )}
            </Box>
            {(type==="Solo")&& (
            <Box sx={{ mt: 3 }}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important",
                }}
              >
                Workout name
              </InputLabel>

              <TextField
                {...register("name")}
                placeholder="Workout name"
                required
                sx={formtextfieldStyle}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Box>
            )}
            {/* GroupSelect component removed as type is now fixed to "Solo" */}

            {(type === "Solo" &&
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  Description
                </InputLabel>
                <TextField
                  {...register("overview")}
                  variant="outlined"
                  type="textarea"
                  placeholder="Description"
                  minRows={4}
                  multiline
                  sx={formDescriptionStyle}
                  fullWidth
                  inputProps={{ maxLength: 1000 }}
                  value={
                    type === "Group"
                      ? selectedExercises
                          .filter((ex) => ex.exercise?.description)
                          .map((ex) => ex.exercise.description)
                          .join("\n\n")
                      : undefined
                  }
                  disabled={type === "Group"}
                />
              </Box>
            )}
               
            <Box mt={1} >
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{
                  paddingLeft: "24px",
                  color: "#838F99",

                  fontWeight: "400 !important",
                  paddingLeft: type == "Group" && "8px",
                }}
              >
                {type==="Solo" && <>Exercises</>}
                {(
                  (type === "Group" && selectedExercises.length == 1)) && (
                  <>Class Name</>
                )}
              </InputLabel>
              <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd} >
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                      {groupedExercises.map((group, groupIndex) => (
                        <Box
                          key={groupIndex}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ...(group.type === "superset" && {
                              borderRadius: "8px",
                              position: "relative",
                            }),
                          }}
                        >
                          {(group.type === "superset" ||
                            group.exercises.length > 1) && (
                            <>
                              <Typography
                                sx={{
                                  position: "absolute",
                                  top: "45%",
                                  left: "10px",
                                  transform: "translateY(-50%)",
                                  fontSize: "12px",
                                  color: "#666",
                                  fontWeight: 600,
                                  zIndex: 1000,
                                  cursor: "pointer",
                                }}
                              >
                                {type === "Solo" && <DragDropIcon />}
                              </Typography>
                              {group.type === "superset" && (
                                <TextField
                                  value={`x${group.sets || ""}`}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      /^x/,
                                      ""
                                    );
                                    setSelectedExercises((prevExercises) => {
                                      return prevExercises.map((ex) => {
                                        if (
                                          ex.supersetGroup ===
                                          group.exercises[0].exercise
                                            .supersetGroup
                                        ) {
                                          return { ...ex, sets: newValue };
                                        }
                                        return ex;
                                      });
                                    });
                                  }}
                                  type="text"
                                  sx={{
                                    ...formtextfiel2dStyle,
                                    position: "absolute",
                                    left: "20px",
                                    top: "0",
                                    height: "90%",
                                    borderRadius: "40px",
                                    width: "50px",
                                    zIndex: 1000,
                                    "& .MuiInputBase-root": {
                                      height: "100%",
                                      minWidth: "50px",
                                      minHeight: "40px",
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "0 8px",
                                      textAlign: "center",
                                      
                                      minWidth: "34px",
                                      minHeight: "40px",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {},
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </>
                          )}

                          {group.exercises.map(({ exercise, index }) => (
                            <Draggable
                              key={`${groupIndex}-${index}`}
                              draggableId={`${groupIndex}-${index}`}
                              index={index}
                              type={
                                group.type === "superset" ? "group" : "normal"
                              }
                            >
                              {(provided) => (
                                <>
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                      marginBottom: "8px",
                                      marginLeft:
                                        group.type === "superset"
                                          ? "90px"
                                          : "0",
                                      "& .MuiAutocomplete-root": {
                                        paddingLeft:"5px",
                                        background:"transparent",
                                        width: {
                                          xs: "calc(100%)",
                                          sm: "calc(100% - 100px)",
                                          md: "calc(100% - 120px)",
                                          lg: "calc(100% - 120px)",
                                          xl: "calc(100% - 120px)"
                                        },
                                      },
                                      ...(provided.draggableProps.style && {
                                        ...provided.draggableProps.style,
                                        left: "auto !important",
                                        top: "auto !important",
                                        position: dragging ? "relative" : provided.draggableProps.style.position,
                                        transform: dragging ? 
                                          `translate(0px, 0px)${provided.draggableProps.style.transform?.replace(/translate\([^)]+\)/, '')}` : 
                                          provided.draggableProps.style.transform,
                                        ...(group.type === "superset" && {
                                          transform: dragging ? 
                                            `translate(0px, 0px)${provided.draggableProps.style.transform?.replace(/translate\([^)]+\)/, '')}` : 
                                            provided.draggableProps.style.transform,
                                          transformOrigin: "left top",
                                        })
                                      })
                                    }}
                                  >
                                    <>
                                      <Typography
                                        sx={{
                                        }}
                                      >
                                        {type === "Solo" && <DragDropIcon />}
                                      </Typography>

                                      {type === "Solo" &&
                                        !exercise.isSuperset && (
                                          <TextField
                                            value={`x${exercise.sets || ""}`}
                                            onChange={(e) => {
                                              const newValue =
                                                e.target.value.replace(
                                                  /^x/,
                                                  ""
                                                );
                                              setSelectedExercises(
                                                (prevSets) => {
                                                  const updatedSets = [
                                                    ...prevSets,
                                                  ];
                                                  updatedSets[index].sets =
                                                    newValue;
                                                  return updatedSets;
                                                }
                                              );
                                            }}
                                            type="text"
                                            sx={{
                                              ...formtextfiel2dStyle,
                                              "& .MuiInputBase-root": {
                                                minWidth: "50px",
                                                minHeight: "49px",
                                                
                                              },
                                              "& .MuiOutlinedInput-input": {
                                                padding: "0 8px",
                                                textAlign: "center",
                                                minWidth: "34px",
                                                minHeight: "49px",

                                              },
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        )}
                                    </>

                                    <Autocomplete
                                      options={[
                                        ...exerciseGroupedBy
                                      ]}
                                      disableClearable
                                      sx={{
                                        ...formtextselectStyle,
                                        marginLeft: !exercise.isSuperset
                                          ? {xs:"35px",sm:"30px"}
                                          : "0",
                                        ...(type === "Group" && {
                                          width: "90%!important",
                                          padding: "0",
                                          margin: "0",
                                        }),
                                        "& .MuiOutlinedInput-root": {
                                          paddingRight:{xs:"20px!important",md:"25px!important"},
                                          background:"#FBFBFB",
                                          overflow: "visible"
                                        },
                                        "& .MuiAutocomplete-input": {
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          width: "100%"
                                        },
                                        "& .MuiAutocomplete-endAdornment": {
                                          position: "absolute",
                                          right: "8px"
                                        }
                                      }}
                                      value={exercise.exercise || null}
                                      inputValue={customInputValues[index] || ''}
                                      onInputChange={(event, newInputValue) => {
                                        setCustomInputValues(prev => ({
                                          ...prev,
                                          [index]: newInputValue
                                        }));
                                      }}
                                      onChange={(e, selectedOption) => {
                                        if (selectedOption?.isAddNew) {
                                          window.location.href='/training-plan?openExerciseModal=true';
                                          return;
                                        }
                                        
                                        if (selectedOption) {
                                          setSelectedExercises(
                                            (prevExercises) => {
                                              const updatedExercises = [
                                                ...prevExercises,
                                              ];
                                              updatedExercises[index].exercise =
                                                {
                                                  ...selectedOption,
                                                  order: index + 1,
                                                };
                                              return updatedExercises;
                                            }
                                          );
                                          
                                          // Clear input value after selection
                                          setCustomInputValues(prev => ({
                                            ...prev,
                                            [index]: ''
                                          }));
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        placeholder={type === "Solo" ? "Search Exercise" : "Search Class"}
                                        sx={{ 
                                          width: "100%",
                                          "& .MuiInputBase-input": {
                                            textOverflow: "ellipsis"
                                          }
                                        }}
                                      />
                                      )}
                                      getOptionLabel={(option) =>
                                        option.title || ""
                                      }
                                      getOptionKey={(option) => option.id}
                                      noOptionsText={
                                        <Box 
                                          sx={{ 
                                            cursor: "pointer", 
                                            color: "#02BD36", 
                                            fontWeight: "bold",
                                            fontSize:"15px",
                                            p: 1
                                          }}
                                          onClick={async () => {
                                            const inputValue = customInputValues[index];
                                            
                                            if (inputValue) {
                                              // Create a new custom exercise with just the title
                                              const newExercisePayload = {
                                                
                                                title: inputValue,
                                                workout: "Custom",
                                                type: "Solo",
                                                tags: [],
                                                description: "",
                                                requires_equipment: false,
                                                videos: [],
                                                images: [],
                                                coach_id: user.id,
                                                is_my_exercise: true
                                              };
                                              
                                              try {
                                                // Save the exercise to the database immediately
                                                const { data, error } = await upsertExercise(supabase, newExercisePayload);
                                                
                                                if (error) throw error;
                                                
                                                if (data && data[0]) {
                                                  const savedExercise = data[0];
                                                  
                                                  // Add to the selected exercises with the database ID
                                                  setSelectedExercises((prevExercises) => {
                                                    const updatedExercises = [...prevExercises];
                                                    updatedExercises[index].exercise = {
                                                      ...savedExercise,
                                                      order: index + 1,
                                                    };
                                                    return updatedExercises;
                                                  });
                                                  
                                                  // Add to the available exercises list
                                                  setFillexercise(prev => [savedExercise, ...prev]);
                                                  setExerciseGroupedBy(prev => [savedExercise, ...prev]);
                                                  
                                                  // Clear the input value after adding
                                                  setCustomInputValues(prev => ({
                                                    ...prev,
                                                    [index]: ''
                                                  }));
                                                }
                                              } catch (err) {
                                                console.error("Error saving custom exercise:", err);
                                                
                                                // Fall back to using local state if database save fails
                                                const tempExercise = {
                                                  id: `custom-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`,
                                                  ...newExercisePayload
                                                };
                                                
                                                setSelectedExercises((prevExercises) => {
                                                  const updatedExercises = [...prevExercises];
                                                  updatedExercises[index].exercise = {
                                                    ...tempExercise,
                                                    order: index + 1,
                                                  };
                                                  return updatedExercises;
                                                });
                                                
                                                setFillexercise(prev => [tempExercise, ...prev]);
                                                setExerciseGroupedBy(prev => [tempExercise, ...prev]);
                                                
                                                // Clear the input value after adding
                                                setCustomInputValues(prev => ({
                                                  ...prev,
                                                  [index]: ''
                                                }));
                                              }
                                            }
                                          }}
                                        >
                                          + Add as a new exercise
                                        </Box>
                                      }
                                      PaperComponent={(props) => (
                                        <Paper
                                          {...props}
                                          sx={{
                                            borderRadius: "16px",
                                            backgroundColor: "#FBFBFB",
                                            width: {xs:"140%",md:"100%"},
                                          }}
                                        >
                                    
                                          {props.children}
                                        </Paper>
                                      )}
                                      ListboxProps={{
                                        sx: {
                                          "& .MuiAutocomplete-option": {
                                            color: "#525A65",
                                            fontSize: "14px",
                                            borderBottom: "1px solid #CECECE",
                                            padding: "10px 10px",
                                            margin: "0px 10px",
                                          
                                            "&:last-child": {
                                              borderBottom: "none",
                                            },
                                          },
                                        },
                                      }}
                                    />
                                 
                                    {type === "Solo" && (
                                      <TextField
                                        sx={formtextfiel3dStyle}
                                        value={
                                          exercise.notes?.substring(0, 40) || ""
                                        }
                                        multiline
                                        minRows={1}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setSelectedExercises((prevNotes) => {
                                            const updatedNotes = [...prevNotes];
                                            updatedNotes[index].notes =
                                              newValue;
                                            return updatedNotes;
                                          });
                                        }}
                                        placeholder="Add notes"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => handleDelete(index)}
                                      sx={{ marginLeft: "auto" }}
                                    >
                                      <DeleteIcon
                                        width={"20px"}
                                        height={"20px"}
                                      />
                                    </IconButton>
                                  </Box>
                                  {shouldShowSupersetIcon(index) && (
                                    <Button
                                      onClick={() => addSuperSet(index)}
                                      sx={{ mb: 1,width:"100%"}}
                                    >
                                      <SuperSetIcon />
                                    </Button>
                                  )}
                                </>
                              )}
                            </Draggable>
                          ))}
                        </Box>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
            { ((type === "Group" &&
                selectedExercises.length > 0 &&
                selectedExercises[0].exercise?.title)) && (
                  <Box sx={{ mt: 3 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  Overview
                </InputLabel>
                
                <TextField
                  {...register("overview")}
                  variant="outlined"
                  type="textarea"
                  placeholder="Overview"
                  minRows={4}
                  multiline
                  sx={formDescriptionStyle}
                  fullWidth
                  inputProps={{ maxLength: 1000 }}
                  value={
                    type === "Group"
                      ? selectedExercises
                          .filter((ex) => ex.exercise?.description)
                          .map((ex) => ex.exercise.description)
                          .join("\n\n")
                      : undefined
                  }
                  disabled={type === "Group"}
                />
              </Box>
            )}
            {/* Always show the Add Exercise button for Solo type */}
            {type === "Solo" && (
              <Box mt={1} width={"100%"} height={"58px"}>
                <Button
                  startIcon={<AddIcon />}
                  sx={{
                    ...button1Style,
                  }}
                  onClick={handleAddExercise}
                >
                  <>Add exercise</>
                </Button>
              </Box>
            )}
            {!isvisible && (
              <Box width={"100%"} height={"80px"} sx={{ marginTop: "23%" }}>
                <Button
                  type="submit"
                  disabled={loading || selectedExercises.length === 0}
                  sx={CreateUpdateStyle}
                >
                  {isUpdate ? "Update" : "Create"}
                </Button>
              </Box>
            )}
          </form>
        </Box>
      </Paper>

      {/* Add the training session dialog */}
      <Dialog
        open={isTrainingDialogOpen}
        onClose={() => {
          setIsTrainingDialogOpen(false);
          setPendingSubmitData(null);
        }}
        TransitionComponent={Fade}
        transitionDuration={300}
        PaperProps={{
          style: {
            borderRadius: "24px",
            padding: "0",
            width: "100%",
            maxWidth: "529px",
            height: "525px",
            background: "#FBFBFB",
          },
        }}
      >
        <Fade in={isTrainingDialogOpen} timeout={500}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "40px 20px",
              height: "100%",
              position: "relative",
            }}
          >
            {loading && (
              <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(251, 251, 251, 0.7)',
                zIndex: 1000,
                borderRadius: '24px',
              }}>
                <CircularProgress 
                  size={40}
                  sx={{
                    color: '#2998E9',
                  }}
                />
              </Box>
            )}
            <Box sx={{ 
              marginBottom: "30px", 
              display: "flex", 
              justifyContent: "center",
              marginTop: "60px",
              animation: "pulse 2s infinite ease-in-out",
              "@keyframes pulse": {
                "0%": { transform: "scale(1)" },
                "50%": { transform: "scale(1.05)" },
                "100%": { transform: "scale(1)" }
              }
            }}>
              <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="50" fill="#02BD36"/>
                <path d="M49.9557 78.9265C47.5282 78.9265 45.3537 77.467 44.4155 75.233C43.4921 72.999 43.9985 70.4225 45.7261 68.6949C47.4388 66.9822 50.0153 66.4609 52.2493 67.3992C54.4833 68.3226 55.9428 70.5119 55.9428 72.9395C55.9428 76.2457 53.262 78.9265 49.9557 78.9265Z" fill="#FBFBFB"/>
                <path d="M53.6938 57.6462C53.5448 59.21 51.9066 60.4312 49.9407 60.4312C47.9748 60.4312 46.3365 59.21 46.1876 57.6462L43.3281 26.4004C43.3281 23.4813 46.2919 21.1133 49.9556 21.1133C51.7874 21.1133 53.4406 21.709 54.632 22.6622C55.8235 23.6153 56.5681 24.9408 56.5681 26.4004L53.6938 57.6462Z" fill="#FBFBFB"/>
              </svg>
            </Box>

            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#525A65",
                fontFamily: "'Bricolage Grotesque', sans-serif",
                textAlign: "center",
                marginBottom: "30px",
              }}
            >
              Hold on!
            </Typography>

            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#000000",
                fontFamily: "'Bricolage Grotesque', sans-serif",
                textAlign: "center",
                maxWidth: "359px",
                marginBottom: "50px",
              }}
            >
              Do you want to set a specific time <Box sx={{width:"auto",height:"auto",display:{xs:"none",sm:"unset"}}}> <br /></Box> for this activity?
            </Typography>

            <Box 
              sx={{ 
                display: "flex", 
                gap: "30px", 
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={() => handleTrainingResponse(false)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "15px 22px",
                  background: "#EEEEEE",
                  border: "1px solid #525A65",
                  borderRadius: "20px",
                  width: "66px",
                  height: "49px",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#0F0F0F",
                  fontFamily: "'Bricolage Grotesque', sans-serif",
                  textTransform: "none",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                    background: "#E5E5E5"
                  }
                }}
              >
                No
              </Button>
              <Button
                onClick={() => handleTrainingResponse(true)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "15px 22px",
                  background: "#EEEEEE",
                  border: "1px solid #525A65",
                  borderRadius: "20px",
                  width: "71px",
                  height: "49px",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#0F0F0F",
                  fontFamily: "'Bricolage Grotesque', sans-serif",
                  textTransform: "none",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                    background: "#E5E5E5"
                  }
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Fade>
      </Dialog>

      {/* Add the time selection modal */}
      <TimeSelectionModal
        open={timeModalOpen}
        onClose={() => setTimeModalOpen(false)}
        onSubmit={handleTimeSubmit}
        type={type}
        planType={"workout"}
        date={date}
        athleteTZ={athleteTZ}
      />

      <SuccessModal 
        open={isSuccessModalOpen}
        onClose={() => {
          setIsSuccessModalOpen(false);
          handleClose();
        }}
        onSuccess={()=>{
          onSuccess();
        }}
        fromAthleteRoute={fromAthleteRoute}
        isUpdate={isUpdate}
      />
    </>
  );
};

TrainingPlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  exercises: PropTypes.array,
  plan: PropTypes.object,
  isvisible: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
};
const WarningModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      TransitionComponent={Zoom}
      TransitionProps={{
        timeout: {
          enter: 300,
          exit: 200
        }
      }}
      PaperProps={{
        style: {
          borderRadius: '24px',
          padding: '32px',
          maxWidth: '450px',
          width: '90%',
        }
      }}
    >
      <Box sx={{ textAlign: 'center',
                display:"flex",
                flexDirection:"column",
                gap:"30px",
                mt:4
       }}>
        <Typography variant="h5" sx={{ 
          color: '#525A65',
          fontSize: '24px',
          fontWeight: 600,
          mb: 3
        }}>
          Heads Up!
        </Typography>
        
        <Box sx={{ 
          mb: 4,
          '@keyframes warningPulse': {
            '0%': {
              transform: 'scale(1)',
              opacity: 1
            },
            '50%': {
              transform: 'scale(1.1)',
              opacity: 0.8
            },
            '100%': {
              transform: 'scale(1)',
              opacity: 1
            }
          },
          '& svg': {
            animation: 'warningPulse 2s ease-in-out infinite'
          }
        }}>
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="50" cy="50" r="50" fill="#EC0B43"/>
<path d="M49.9557 78.9265C47.5282 78.9265 45.3537 77.467 44.4155 75.233C43.4921 72.999 43.9985 70.4225 45.7261 68.6949C47.4388 66.9822 50.0153 66.4609 52.2493 67.3992C54.4833 68.3226 55.9428 70.5119 55.9428 72.9395C55.9428 76.2457 53.262 78.9265 49.9557 78.9265Z" fill="#FBFBFB"/>
<path d="M53.6938 57.6462C53.5448 59.21 51.9066 60.4312 49.9407 60.4312C47.9748 60.4312 46.3365 59.21 46.1876 57.6462L43.3281 26.4004C43.3281 23.4813 46.2919 21.1133 49.9556 21.1133C51.7874 21.1133 53.4406 21.709 54.632 22.6622C55.8235 23.6153 56.5681 24.9408 56.5681 26.4004L53.6938 57.6462Z" fill="#FBFBFB"/>
</svg>
        </Box>

        <Typography sx={{ 
          mb: 4,
          color: '#000000',
          fontSize: {
            xs: '13px',
            sm: '14px',
            md: '16px',
            lg: '16px',
          }
        }}>
          If you go back your progress won't be saved.
          <br />
          Do you want to continue?
        </Typography>

        <Box sx={{ 
          display: 'flex', 
          gap: 2,
          justifyContent: 'center'
        }}>
          <Button
            onClick={onClose}
            sx={{
              color: '#0F0F0F',
              backgroundColor: '#EEEEEE',
              border:"1px solid #525A65",
              textTransform:"none",
              borderRadius: '20px',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: '#E0E0E0'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            sx={{
              border:"1px solid #525A65",
              color: '#EC0B43',
              fontWeight:"500!important",
              textTransform:"none",
              backgroundColor: '#FFF2F5',
              borderRadius: '20px',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: '#FFD6DE'
              }
            }}
          >
            Leave anyway
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
const SuccessModal = ({ open, onClose, isUpdate, onSuccess, fromAthleteRoute }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      TransitionComponent={Zoom}
      TransitionProps={{
        timeout: {
          enter: 300,
          exit: 200
        }
      }}
      PaperProps={{
        style: {
          borderRadius: '24px',
          padding: '32px',
          maxWidth: '450px',
          width: '90%',
        }
      }}
    >
      <Box sx={{ 
        textAlign: 'center',
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        mt: 4
      }}>
        <Typography variant="h5" sx={{ 
          color: '#525A65',
          fontSize: '24px',
          fontWeight: 600,
          mb: 3
        }}>
          Keep It Up!
        </Typography>
        
        <Box sx={{ 
          mb: 4,
          animation: 'successIconAnimation 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
          '@keyframes successIconAnimation': {
            '0%': {
              transform: 'scale(0)',
              opacity: 0
            },
            '100%': {
              transform: 'scale(1)',
              opacity: 1
            }
          }
        }}>
          <SuccessIcon />
        </Box>

        <Typography sx={{ 
          mb: 4,
          color: '#0F0F0F',
          fontSize: '16px'
        }}>
A new workout has been {isUpdate ? "updated" : "created"} and         <br />
         
         
          
         saved to your library.        </Typography>

        <Box
        sx={{
          display:"flex",
          justifyContent:"center",
          width:"100%",
        }}>

       
        <Button
          onClick={()=>{
            if(fromAthleteRoute){  
              onSuccess();
            }
            onClose();
          }}

          sx={{
            color: '#0F0F0F',
            backgroundColor: '#EEEEEE',
            border: "1px solid #525A65",
            textTransform: "none",
            fontSize:"16px",
            borderRadius: '20px',
            mt:2,
            padding: '9px 17px',
            '&:hover': {
              backgroundColor: '#E0E0E0'
            }
          }}
        >
          Okay!
        </Button>
        </Box>
      </Box>
    </Dialog>
  );
};