export const Typography1style = {
    fontSize: "24px", fontWeight: "600 !important", color: "#525A65"
}

export const Typography2style = {
    fontSize: "16px", color: "#525A65"
}

export const Typography3style = {
    fontSize: "16px", color: "#525A65", fontWeight: "600 !important"
}

export const subsStyle = {
    marginLeft: "auto",
    color: "#02BD36",
    border: "solid 1px",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "13px",
    padding: "11px 8px !important",
    width: "103px !important",
    "&:hover": {
        color: "#02BD36",
        backgroundColor: "unset"
    },
}

export const signoutStyle = {
    marginLeft: "auto",
    width: "100% !important",
    color: "#FFFFFF",
    border: "solid 1px",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "13px",
    padding: "11px 8px !important",
    minWidth: "96px !important",
    backgroundColor: "#525A65",
}

// Shared input field styles for consistency across all form fields
const sharedInputStyles = {
    borderRadius: "20px",
    border: "1px solid #E0E0E0",
    transition: "all 0.2s ease-in-out",
    backgroundColor: "#FAFAFA",
    "&:hover": {
        backgroundColor: "#FFFFFF",
        border: "1px solid #BDBDBD",
    },
    "&.Mui-focused": {
        backgroundColor: "#FFFFFF",
        border: "1px solid #02BD36",
        boxShadow: "0 0 0 4px rgba(2, 189, 54, 0.1)",
    },
    "&.Mui-error": {
        border: "1px solid #d32f2f",
        "&.Mui-focused": {
            boxShadow: "0 0 0 4px rgba(211, 47, 47, 0.1)",
        }
    }
}

export const formtextfieldStyle = {
    ".MuiInputBase-root": {
        ...sharedInputStyles,
        width: "100%",
    },
    ".MuiInputBase-input": {
        fontSize: "16px",
        padding: "16px 24px",
        color: "#2C3E50",
        fontWeight: "400 !important",
        "&::placeholder": {
            color: "#95A5A6",
            opacity: 0.8,
        },
        transform: { xs: "scale(0.875)", sm: "scale(1)" },
        transformOrigin: "left center",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none"
    }
}

export const formautodStyle1 = {
    width: "100%",
    ".css-feqhe6": {
        paddingRight: "8px !important"
    },
    ".MuiInputBase-root": {
        ...sharedInputStyles
    },
    ".MuiInputBase-input": {
        fontSize: "16px",
        padding: "16px 24px !important",
        color: "#2C3E50",
        fontWeight: "400 !important",
        "&::placeholder": {
            color: "#95A5A6",
            opacity: 0.8,
        },
        transform: { xs: "scale(0.875)", sm: "scale(1)" },
        transformOrigin: "left center",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none"
    }
}

export const formautodStyle2 = {
    mr: 1,
    "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
    },
    ".MuiInputBase-root": {
        ...sharedInputStyles
    },
    ".MuiInputBase-input": {
        fontSize: "16px",
        padding: "16px 24px",
        color: "#2C3E50",
        fontWeight: "400 !important",
        "&::placeholder": {
            color: "#95A5A6",
            opacity: 0.8,
        },
        transform: { xs: "scale(0.875)", sm: "scale(1)" },
        transformOrigin: "left center",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none"
    }
}

export const formDateFieldStyle = {
    ".css-10o2lyd-MuiStack-root": {
        paddingTop: "0px !important",
    },
    ".MuiInputBase-root": {
        ...sharedInputStyles
    },
    ".MuiInputBase-input": {
        fontSize: "16px",
        padding: "16px 24px",
        color: "#2C3E50",
        fontWeight: "400 !important",
        "&::placeholder": {
            color: "#95A5A6",
            opacity: 0.8,
        },
        transform: { xs: "scale(0.875)", sm: "scale(1)" },
        transformOrigin: "left center",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none"
    }
}

export const submitStyle = {
    marginLeft: "auto",
    width: "100% !important",
    color: "#0F0F0F",
    border: "solid 1px #525A65",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "13px",
    padding: "11px 8px !important",
    minWidth: "96px !important",
    backgroundColor: "#EEEEEE",
}