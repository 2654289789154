import { useState, useEffect } from 'react';
import { PageContainer } from "../../components/PageContainer/PageContainer";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  IconButton,
  Divider,
  Skeleton,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { supabase } from "../../services/supabase";
import { useAuth } from "../../providers/AuthContextProvider";
import moment from 'moment';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const NotificationSkeleton = () => (
  <ListItem sx={{ p: 2.5 }}>
    <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
      <Box sx={{ mr: 2 }}>
        <Skeleton variant="circular" width={32} height={32} />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Skeleton variant="text" width="40%" height={24} sx={{ mb: 1 }} />
        <Skeleton variant="text" width="70%" height={20} sx={{ mb: 1 }} />
        <Skeleton variant="text" width="20%" height={16} />
      </Box>
    </Box>
  </ListItem>
);

export const NotificationsRoute = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const fetchNotifications = async () => {
    try {
      const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .eq('user_id', user.id)
        .is('isHidden', false)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setNotifications(data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      await supabase
        .from('notifications')
        .update({ isRead: true })
        .eq('id', notificationId);

      setNotifications(notifications.map(notification => 
        notification.id === notificationId 
          ? { ...notification, isRead: true }
          : notification
      ));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleMenuClick = (event, notification) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedNotification(notification);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedNotification(null);
  };

  const hideNotification = async (notificationId) => {
    try {
      await supabase
        .from('notifications')
        .update({ isHidden: true })
        .eq('id', notificationId);

      setNotifications(notifications.filter(n => n.id !== notificationId));
    } catch (error) {
      console.error('Error hiding notification:', error);
    }
    handleMenuClose();
  };

  const markAllAsRead = async () => {
    try {
      await supabase
        .from('notifications')
        .update({ isRead: true })
        .eq('user_id', user.id)
        .is('isHidden', false);

      setNotifications(notifications.map(notification => ({
        ...notification,
        isRead: true
      })));
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  const toggleRead = async (notificationId, currentReadState) => {
    try {
      await supabase
        .from('notifications')
        .update({ isRead: !currentReadState })
        .eq('id', notificationId);

      setNotifications(notifications.map(notification => 
        notification.id === notificationId 
          ? { ...notification, isRead: !notification.isRead }
          : notification
      ));
    } catch (error) {
      console.error('Error toggling notification read state:', error);
    }
    handleMenuClose();
  };

  useEffect(() => {
    if(user===null){
        return
    }
    fetchNotifications();
  }, [user?.id]);

  const getNotificationIcon = (action) => {
    switch (action) {
      case 'form_submit':
        return <AssignmentIcon sx={{ color: '#02BD36' }} />;
      case 'new_athlete':
        return <PersonAddIcon sx={{ color: '#1976d2' }} />;
      case 'new_calendar':
        return <CalendarMonthIcon sx={{ color: '#02BD36' }} />;
      default:
        return <NotificationsIcon sx={{ color: '#757575' }} />;
    }
  };

  return (
    <PageContainer>
      <Paper
        sx={{
          maxWidth: 800,
          margin: 'auto',
          borderRadius: '24px',
          overflow: 'hidden',
          boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
          backgroundColor: '#FBFBFB',
        }}
      >
        <Box 
          sx={{ 
            p: 3, 
            bgcolor: 'transparent',
            borderBottom: "1px solid #ececec", 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center' 
          }}
        >
          <Typography 
            variant="h5" 
            sx={{ 
              fontWeight: 500, 
              color: '#525A65',
              fontSize: '20px',
              fontFamily: 'Bricolage Grotesque'
            }}
          >
            Notifications
          </Typography>
          {notifications.length > 0 && !loading && (
            <Button
              onClick={markAllAsRead}
              sx={{
                color: '#02BD36',
                fontSize: '13px',
                textTransform: 'none',
                fontFamily: 'Bricolage Grotesque',
                padding: '6px 12px',
                borderRadius: '20px',
                border: '1px solid #02BD36',
                '&:hover': {
                  backgroundColor: 'rgba(2, 189, 54, 0.04)',
                }
              }}
            >
              Mark all as read
            </Button>
          )}
        </Box>
        
        <List sx={{ p: 0, backgroundColor: '#FBFBFB' }}>
          {loading ? (
            <>
              {[...Array(4)].map((_, index) => (
                <Box key={index}>
                  <NotificationSkeleton />
                  {index < 3 && <Divider sx={{ opacity: 0.6 }} />}
                </Box>
              ))}
            </>
          ) : notifications.length === 0 ? (
            <Box sx={{ 
              p: 6, 
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2
            }}>
              <NotificationsIcon sx={{ fontSize: 48, color: '#CCCCCC' }} />
              <Typography 
                sx={{ 
                  color: '#525A65',
                  fontSize: '15px',
                  fontFamily: 'Bricolage Grotesque'
                }}
              >
                You're all caught up.
              </Typography>
            </Box>
          ) : (
            notifications.map((notification, index) => (
              <Box key={notification.id}>
                <ListItem
                  sx={{
                    p: 2.5,
                    opacity: notification.isRead ? 0.85 : 1,
                    bgcolor: notification.isRead ? '#F8F8F8' : 'white',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      bgcolor: notification.isRead ? '#F5F5F5' : '#FAFAFA'
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                    <Box 
                      sx={{ 
                        mr: 2,
                        backgroundColor: notification.isRead ? '#F0F0F0' : '#F8FFF9',
                        p: 1,
                        borderRadius: '12px'
                      }}
                    >
                      {getNotificationIcon(notification.action)}
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: notification.isRead ? 400 : 500,
                          color: '#525A65',
                          mb: 0.5,
                          fontSize: '15px',
                          fontFamily: 'Bricolage Grotesque'
                        }}
                      >
                        {notification.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ 
                          color: '#525A65', 
                          mb: 1,
                          fontSize: '13px',
                          opacity: 0.8,
                          fontFamily: 'Bricolage Grotesque',
                          fontWeight:"700",
                        }}
                      >
                        {notification.message}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ 
                          color: '#525A65',
                          opacity: 0.6,
                          fontSize: '12px',
                          fontFamily: 'Bricolage Grotesque'
                        }}
                      >
                        {moment(notification.created_at).fromNow()}
                      </Typography>
                    </Box>
                    <Box sx={{ ml: 2, display: 'flex', gap: 1 }}>
                      {!notification.isRead && (
                        <IconButton
                          onClick={() => markAsRead(notification.id)}
                          sx={{ 
                            ml: 2,
                            '&:hover': {
                              backgroundColor: '#F8FFF9'
                            }
                          }}
                          title="Mark as read"
                        >
                          <DoneAllIcon sx={{ color: '#02BD36', fontSize: '20px' }} />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={(e) => handleMenuClick(e, notification)}
                        size="small"
                        sx={{
                          '&:hover': {
                            backgroundColor: '#F5F5F5'
                          }
                        }}
                      >
                        <MoreVertIcon sx={{ fontSize: '20px', color: '#525A65' }} />
                      </IconButton>
                    </Box>
                  </Box>
                </ListItem>
                {index < notifications.length - 1 && (
                  <Divider sx={{ opacity: 0.6 }} />
                )}
              </Box>
            ))
          )}
        </List>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
              borderRadius: '16px',
              mt: 1,
              minWidth: '160px',
              backgroundColor: '#FBFBFB'
            }
          }}
        >
          <MenuItem 
            onClick={() => toggleRead(selectedNotification?.id, selectedNotification?.isRead)}
            sx={{ 
              fontSize: '13px',
              py: 1.5,
              px: 2,
              fontFamily: 'Bricolage Grotesque',
              color: '#525A65',
              '&:hover': {
                backgroundColor: '#F5F5F5'
              }
            }}
          >
            {selectedNotification?.isRead ? 'Mark as unread' : 'Mark as read'}
          </MenuItem>
          <MenuItem 
            onClick={() => hideNotification(selectedNotification?.id)}
            sx={{ 
              fontSize: '13px',
              py: 1.5,
              px: 2,
              fontFamily: 'Bricolage Grotesque',
              color: '#525A65',
              '&:hover': {
                backgroundColor: '#F5F5F5'
              }
            }}
          >
            Hide this notification
          </MenuItem>
        </Menu>
      </Paper>
    </PageContainer>
  );
};