import { MobileDatePicker } from "@mui/x-date-pickers";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormHelperText,
  Paper,
  TextField,
  Typography,
  Modal,
  Link,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  IconButton,
  MenuItem,
  CircularProgress,
  Grow,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { supabase } from "../../services/supabase";
import { useAuth } from "../../providers/AuthContextProvider";
import {
  getOnboardingFormResponseById,
  upsertAthlete,
  upsertOnboardingFormResponse,
  loops_send_form,
  get_user_onboarding_form_response,
} from "../../services/query";
import { ToolTipComponent } from "./ToolTip";
const simplifiedTimezones = [
  { value: "+0", label: "(GMT +0) London, Dublin, Lisbon" },
  { value: "+1", label: "(GMT +1) Berlin, Paris, Rome" },
  { value: "+2", label: "(GMT +2) Athens, Helsinki, Cairo" },
  { value: "+3", label: "(GMT +3) Istanbul, Moscow, Baghdad, Riyadh" },
  { value: "+4", label: "(GMT +4) Dubai, Baku, Yerevan" },
  { value: "+5", label: "(GMT +5) Karachi, Tashkent" },
  { value: "+6", label: "(GMT +6) Dhaka, Almaty" },
  { value: "+7", label: "(GMT +7) Bangkok, Jakarta, Ho Chi Minh" },
  { value: "+8", label: "(GMT +8) Singapore, Beijing, Manila" },
  { value: "+9", label: "(GMT +9) Tokyo, Seoul" },
  { value: "+10", label: "(GMT +10) Sydney, Melbourne" },
  { value: "+11", label: "(GMT +11) Sydney, Melbourne, Solomon Islands" },
  { value: "+12", label: "(GMT +12) Auckland, Fiji" },
  { value: "+13", label: "(GMT +13) Auckland (DST), Tonga, Samoa" },
  { value: "-12", label: "(GMT -12) Baker Island" },
  { value: "-11", label: "(GMT -11) American Samoa" },
  { value: "-10", label: "(GMT -10) Hawaii" },
  { value: "-9", label: "(GMT -9) Alaska" },
  { value: "-8", label: "(GMT -8) Los Angeles, Vancouver" },
  { value: "-7", label: "(GMT -7) Denver, Phoenix, Vancouver (DST)" },
  { value: "-6", label: "(GMT -6) Chicago, Mexico City" },
  { value: "-5", label: "(GMT -5) New York, Toronto" },
  { value: "-4", label: "(GMT -4) Santiago, Halifax" },
  { value: "-3", label: "(GMT -3) Buenos Aires, Montevideo" },
  { value: "-3", label: "(GMT -3) São Paulo" },
  { value: "-2", label: "(GMT -2) South Georgia" },
  { value: "-1", label: "(GMT -1) Cape Verde, Azores" },
];

const getTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset();
  const sign = offset <= 0 ? "+" : "-";
  const absoluteOffset = Math.abs(offset);
  const hours = Math.floor(absoluteOffset / 60);
  return `${sign}${hours}`;
};

const SuccessModal = ({ open, onClose }) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
  <Modal 
    open={open} 
    onClose={handleClose}
    TransitionComponent={Grow}
    TransitionProps={{
      timeout: 400
    }}
  >
    <Box
  sx={{
    background: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "100%",  // 90% of the screen width on small screens
      sm: "500px", // Small devices
      md: "500px", // Medium devices
      lg: "540px", // Large screens (default)
    },
    height: {
      xs: "auto",  // Auto height for better responsiveness
      sm: "auto",
      md: "auto",
      lg: "auto",
    },
    boxShadow: 24,
    p: 4,
    borderRadius: "24px",
    display: "flex",
    flexDirection: "column",
    outline: "none",
    gap: "10px",
    position: "relative", // Ensures X button stays inside the box
  }}
>
      {/* Add X button */}
  

      {/* Rest of the modal content */}
      <Typography sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <Box>
        <SuccessIconPopup/>

        </Box>
        <Box
        onClick={handleClose}
        sx={{
          cursor: "pointer",
          zIndex: 1,
        }}
      >
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="24" cy="24" r="24" fill="#EEEEEE"/>
          {/* Add X icon inside circle */}
          <path
            d="M31 17L17 31M17 17L31 31"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </Box>
      </Typography>
      <Typography  sx={{  fontSize: {
      xs: "24px", // Extra small screens (phones)
      sm: "32px", // Small screens (tablets)
      md: "40px", // Medium screens (small laptops)
      lg: "48px", // Large screens (desktops)
      xl: "56px", // Extra-large screens (big monitors)
    }, my: 3, fontWeight: "bold", color: "#000000" }}>
        Thank you!
      </Typography>
      <Typography>
      <Typography
  sx={{
    color: "#0F0F0F",
    fontSize: {
      xs: "14px",
      sm: "16px",
      md: "16px",
      lg: "16px",
      xl: "16px",
    },
    fontWeight: "500",
    whiteSpace: "normal", // Ensures text wraps properly
    wordBreak: "break-word", // Prevents text overflow
  }}
>
  Your details have been submitted successfully.
</Typography>

<Typography
  sx={{
    color: "#02BD36",
    mb: 3,
    fontWeight: "600",
    fontSize: {
      xs: "14px",
      sm: "16px",
      md: "16px",
      lg: "16px",
      xl: "16px",
    },
    whiteSpace: "normal",
    wordBreak: "break-word",
  }}
>
  Here's what happens next:
</Typography>

      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mb: 3 }}>
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    {/* Outer Circle */}
    <Box sx={{ position: "relative", width: 64, height: 64 }}>
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="32" cy="32" r="32" fill="#C9FE58"/>
      </svg>
      {/* Centered Inner SVG */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: 22, // Match inner SVG width
          height: 21, // Match inner SVG height
          transform: "translate(-50%, -50%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.8931 13.083C13.0439 13.7111 12.0263 14.0252 11.0052 14.0252C9.98406 14.0252 8.96984 13.7111 8.11721 13.083L0.388498 7.37811C0.250977 7.27876 0.123769 7.16979 0 7.05762V17.1148C0 18.7974 1.46804 20.166 3.27301 20.166H18.727C20.532 20.166 22 18.7974 22 17.1148V7.05762C21.8762 7.16979 21.749 7.27876 21.6115 7.37811L13.8828 13.083H13.8931Z" fill="black"/>
          <path d="M18.7251 0H3.27491C1.47079 0 0 1.33063 0 2.96282V3.60326C0 4.51418 0.457045 5.35671 1.24399 5.92253L8.96907 11.4564C10.1649 12.3145 11.8351 12.3145 13.0344 11.4564L20.7595 5.92253C21.5464 5.35671 22 4.51107 22 3.60015V2.95971C22 1.32752 20.5326 0 18.7285 0L18.7251 0Z" fill="black"/>
        </svg>
      </Box>
    </Box>
    {/* Text */}
    <Typography
      sx={{
        color: "#000000",
        marginLeft: {
          xs: "5px",  // Smaller margin on extra small screens
          sm: "8px",  // Slightly larger margin on small screens
          md: "10px", // Default margin for medium screens
          lg: "12px", // More space on larger screens
          xl: "15px", // Maximum margin for extra-large screens
        },        fontSize: {
          xs: "11px",
          sm: "16px",
          md: "15px",
          lg: "15px",
          xl: "16px",
        },
        fontWeight: "600",
      }}
    >
      Check your email – Accept the Google <br /> Calendar invite to sync your schedule.
    </Typography>
</Box>


<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
  <Box sx={{ position: "relative", width: 64, height: 64 }}>
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="32" fill="#C9FE58" />
    </svg>
    {/* Center the inner SVG */}
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: 24, // Explicit width
        height: 24, // Explicit height
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="5.39957" cy="5.39984" rx="5.39957" ry="5.39984" fill="#0F0F0F" />
        <rect y="13.1979" width="10.7991" height="10.7997" rx="2" fill="#0F0F0F" />
        <path d="M22.9105 10.7997H14.2779C13.9015 10.7997 13.5562 10.6052 13.3594 10.289C13.1625 9.97294 13.1453 9.57347 13.3179 9.23652L17.6342 0.552315C18.0003 -0.184105 19.1985 -0.184105 19.5645 0.552315L23.8808 9.23652C24.0535 9.57347 24.0328 9.97294 23.8394 10.289C23.646 10.6052 23.3007 10.7997 22.9209 10.7997H22.9105Z" fill="#0F0F0F"/>
        <rect width="3.05447" height="12.2179" rx="1.52723" transform="matrix(0.707089 0.707124 -0.707089 0.707124 21.832 13.1999)" fill="#0F0F0F"/>
        <rect width="3.05447" height="12.2179" rx="1.52723" transform="matrix(-0.707089 0.707124 -0.707089 -0.707125 23.9922 21.8399)" fill="#0F0F0F"/>
      </svg>
    </Box>
  </Box>
  <Typography
    sx={{
      color: "#000000",
      marginLeft: {
        xs: "5px",  // Smaller margin on extra small screens
        sm: "8px",  // Slightly larger margin on small screens
        md: "10px", // Default margin for medium screens
        lg: "12px", // More space on larger screens
        xl: "15px", // Maximum margin for extra-large screens
      },      fontSize: {
        xs: "11px",
        sm: "16px",
          md: "15px",
          lg: "15px",
          xl: "16px",
      },
      fontWeight: "600",
    }}
  >
    Your coach is setting up your training
    <br />
    plan – You'll get access once it's ready.
  </Typography>
</Box>


<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Box sx={{ position: "relative", width: 64, height: 64 }}>
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill="#C9FE58" />
        </svg>
        {/* Center the inner SVG */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: 27,
            height: 27,
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.8047 27C16.6323 27 27 21.5487 27 12.0227C27 5.3961 20.9428 0 13.5 0C6.05725 0 0 5.5487 0 12.013C0 18.4773 6.29916 22.6968 13.5 22.95C13.4531 23.1703 13.3534 23.5132 13.2422 23.896L13.2422 23.8961C13.0255 24.6417 12.7648 25.5385 12.7648 25.974C12.7648 26.5422 13.2267 27 13.8047 27ZM8.1 13.4979C8.84558 13.4979 9.45 12.8934 9.45 12.1479C9.45 11.4023 8.84558 10.7979 8.1 10.7979C7.35442 10.7979 6.75 11.4023 6.75 12.1479C6.75 12.8934 7.35442 13.4979 8.1 13.4979ZM14.8509 12.1479C14.8509 12.8934 14.2465 13.4979 13.5009 13.4979C12.7553 13.4979 12.1509 12.8934 12.1509 12.1479C12.1509 11.4023 12.7553 10.7979 13.5009 10.7979C14.2465 10.7979 14.8509 11.4023 14.8509 12.1479ZM18.9018 13.4979C19.6473 13.4979 20.2518 12.8934 20.2518 12.1479C20.2518 11.4023 19.6473 10.7979 18.9018 10.7979C18.1562 10.7979 17.5518 11.4023 17.5518 12.1479C17.5518 12.8934 18.1562 13.4979 18.9018 13.4979Z"
              fill="#0F0F0F"
            />
          </svg>
        </Box>
      </Box>
      <Typography
        sx={{
          color: "#000000",
          fontWeight: "600",
          marginLeft: {
            xs: "5px",  // Smaller margin on extra small screens
            sm: "8px",  // Slightly larger margin on small screens
            md: "10px", // Default margin for medium screens
            lg: "12px", // More space on larger screens
            xl: "15px", // Maximum margin for extra-large screens
          },          fontSize: {
            xs: "11px",
            sm: "16px",
            md: "15px",
            lg: "15px",
            xl: "16px",
          },
        }}
      >
        Stay updated – Receive notifications via <br /> email and WhatsApp.
      </Typography>
    </Box>
      </Box>
            <Typography
            sx={{
              display:"flex",
              justifyContent:"end"
            }}
            >
      <Button
        onClick={handleClose}
        sx={{
          backgroundColor: "#EEEEEE",
          border: "1px solid #525A65",
          borderRadius: "20px",
          px: 3,
          py: 1,
          color: "#0F0F0F",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#FFFFFF",
            border: "1px solidrgb(17, 17, 17)",
          },
        }}
      >
        Done
      </Button>
      </Typography>
    </Box>
  </Modal>
  );
};

const InvitationModal = ({ open, onClose, coachname="Your coach" }) => (
  <Modal 
    open={open} 
    onClose={onClose}
    TransitionComponent={Grow}
    TransitionProps={{
      timeout: 400
    }}
  >
    <Box
      sx={{
        background: "#013B12",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "540px",
        height: "540px",
        boxShadow: 24,
        p: 4,
        borderRadius: "24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        outline: "none",
      }}
    >
      <Typography variant="h4" sx={{transform: {xs: "scale(0.70)", sm: "scale(0.83)", md: "scale(0.85)", lg: "scale(1)"},width:"100%", color: "#FFFFFF", fontWeight: "bold" }}>
       <SuccessIcon/>
      </Typography>
      <Typography sx={{ color: "#E3E2E0", textAlign: "center", mt: 4,fontSize:{xs:"16px", sm: "17px", md: "18px)", lg: "scale(1)"}}}>
       <span style={{color:"#02BD36"}}>{coachname}</span>  has invited you to join <br /> CoachPlus to manage your workouts, <br /> track progress, and communicate easily.
      </Typography>
      <Typography sx={{ color: "#02BD36", textAlign: "center", mt: 3,fontSize:"12px"}}>
      It's completely free & you don't need to download any app - <br /> it all works seamlessly.
      </Typography>
      <Button variant="contained" onClick={onClose} sx={{padding:"15px 22px", border:"1px solid #02BD36",color: "#02BD36",backgroundColor:"transparent",borderRadius:"20px"}}>
        Try it now
      </Button>
    </Box>
  </Modal>
);

export const OnboardingFormDisplayRoute = () => {
  const [obf, setObf] = useState(null);
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const params = useParams();
  const [phone, setPhone] = useState("");
  const [mail, setmail] = useState("");
  const location = useLocation();
  const path = location.pathname;
  const parts = path.split("/");
  const navigate = useNavigate();
  const uuid = parts[parts.length - 1];
  const [showSuccess, setShowSuccess] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [coach_fullname, setCoachName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInvitation, setShowInvitation] = useState(true);

  useEffect(() => {
    // Get the timezone offset in hours (e.g., "+2" or "-5")
    const timezoneOffset = getTimezoneOffset();

    // Find the matching timezone from our simplified list
    const matchingTimezone = simplifiedTimezones.find(
      (tz) => tz.value === timezoneOffset
    );

    // Set the timezone state to the matching timezone value, or the first timezone as fallback
    setTimezone(
      matchingTimezone ? matchingTimezone.value : simplifiedTimezones[0].value
    );
  }, []);
  const onSubmit = handleSubmit(async ({ custom }) => {
    setIsSubmitting(true);
    try {
      const athleteData = {
        id: obf.athlete_id,
        first_name: obf?.athletes?.first_name || "",
        last_name: obf?.athletes?.last_name || "",
        full_name: `${obf?.athletes?.first_name} ${obf?.athletes?.last_name}`,
        timezone: timezone,
        status: "VERIFIED",
      };

      // Ensure custom is an object
      const formResponseData = {
        id: obf.id,
        custom_responses: { ...custom }, // Ensure custom is structured as an object
        status: "completed",
        timezone: timezone,
        updated_at: new Date().toISOString(),
      };

      const { data: get_user_name } = await get_user_onboarding_form_response(
        supabase,
        uuid
      );

      // Create notification
      await supabase.from("notifications").insert({
        title: "New Form Submission",
        message : `<strong>${athleteData.full_name}</strong> has completed the Client Onboarding Form.`,
        action: "form_submit",
        user_id: obf?.coach_id, // Assuming coach_id is stored in onboarding_forms
        athlete_id: obf.athlete_id,
        isRead: false,
        related_link:`https://app.coachplus.club/athlete/${obf.athlete_id}`
      });
   
     
      await upsertAthlete(supabase, athleteData);
      await upsertOnboardingFormResponse(supabase, formResponseData);
      setShowSuccess(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
      setIsSubmitting(false);
    }
  });

  const handleSuccessClose = () => {
    setShowSuccess(false);
    window.location.reload();
  };

  useEffect(() => {
    getOnboardingFormResponseById(supabase, params.id).then(
      async ({ data, error }) => {

        if (error) {
          console.error("error");
          return;
        }
        setObf(data);

        const athletes = await supabase
          .from("athletes")
          .select("*")
          .eq("id", data.athlete_id);
        const coach = await supabase
          .from("users")
          .select("full_name")
          .eq("id", data.coach_id);
        setCoachName(coach.data[0].full_name);
        setPhone(athletes.data[0].phone_number);
        setmail(athletes.data[0].email);
      }
    );
  }, []);
  if (!obf||!(coach_fullname&&phone&&mail)) {
    return <PageContainer>Loading...</PageContainer>;
  }


 // Direct return approach for completed form status
// Direct return approach for completed form status
if (obf?.status === "completed") {
  return (
    <div style={{ 
      display: "flex", 
      flexDirection: "column", 
      alignItems: "center", 
      minHeight: "100vh", 
      backgroundColor: "#f9fafb", 
      padding: "16px" 
    }}>
      <div style={{ 
        width: "100%", 
        maxWidth: "28rem", 
        backgroundColor: "white", 
        borderRadius: "12px", 
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", 
        overflow: "hidden", 
        padding: "24px", 
        marginBottom: "24px" 
      }}>
        <div style={{ 
          textAlign: "center", 
          marginBottom: "16px" 
        }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ 
              height: "64px", 
              width: "64px", 
              backgroundColor: "#d1fae5", 
              borderRadius: "50%", 
              display: "flex", 
              alignItems: "center", 
              justifyContent: "center" 
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "32px", width: "32px", color: "#059669" }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
          </div>
          <h2 style={{ 
            fontSize: "24px", 
            fontWeight: "bold", 
            color: "#1f2937", 
            marginTop: "16px" 
          }}>Form Complete</h2>
          <p style={{ 
            color: "#4b5563", 
            marginTop: "8px" 
          }}>Thank you! Your form has been successfully submitted.</p>
        </div>
      </div>
    </div>
  );
}
  return showInvitation? ( 
    <Box>
      
      <InvitationModal open={showInvitation} onClose={() => setShowInvitation(false)} coachname={coach_fullname}/>
      </Box>
      ):(
    <PageContainer>
      <Paper
        variant="outlined"
        sx={{
          borderRadius: "30px",
          padding: "15px 10px",
          maxWidth: 600,
          margin: "auto",
          background: "#FBFBFB",
        }}
      >
        <form onSubmit={onSubmit}>
          <CardContent>
            <Box sx={{ position: "relative" }}>
              <Typography
                sx={{ mb: 3, fontSize: "14px", fontWeight: "bold!important" }}
              >
                <div
                  style={{ fontWeight: "800",color:"#525A65",fontSize:"24px" }}
                  role="img"
                  aria-label="waving hand"
                >
             Welcome to Coach Plus
                </div>
                <div style={{color:"#02BD39"}}>
                Let's get you set up!
                </div>
                <ToolTipComponent />
              </Typography>{" "}
              <Box sx={{backgroundColor:"#EEEEEE",display:"flex",flexDirection:"column",padding:"12px 20px",borderRadius:"20px"}}>
              <Typography sx={{color:"#999999"}}>
              {mail&&mail}

              </Typography>
              <Typography sx={{color:"#999999"}}>
              +{phone&&phone}
                
              </Typography>

              </Box>
              <Box sx={{color:"#525A65", fontSize:"16px", my:4}}>
              Please fill your custom onboarding form to continue.
              </Box>
            </Box>
       
            <Typography gutterBottom sx={{ mb: 4 }}>
            </Typography>

            {/* First Name - Editable */}
            <Box sx={{ mb: 4, width: "100%", height: "48px", width: "100%" }}>
              <TextField
                label="First Name"
                value={obf?.athletes?.first_name || ""}
                onChange={(e) => setObf({ ...obf, athletes: { ...obf.athletes, first_name: e.target.value } })}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "20px",
                    "& fieldset": {
                      border: "1px solid #DAD9D8",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#525A65",
                  },
                }}
              />
            </Box>

            {/* Last Name - Editable */}
            <Box sx={{ mb: 4, width: "100%", height: "48px", width: "100%" }}>
              <TextField
                label="Last Name"
                value={obf?.athletes?.last_name || ""}
                onChange={(e) => setObf({ ...obf, athletes: { ...obf.athletes, last_name: e.target.value } })}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "20px",
                    "& fieldset": {
                      border: "1px solid #DAD9D8",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#525A65",
                  },
                }}
              />
            </Box>

            <Box sx={{ justifyContent: "space-between" }}>
           {   /*
              <Box sx={{ mb: 4, width: "100%", height: "48px", width: "100%" }}>
                <TextField
                  label="E-mail"
                  fullWidth
                  value={mail}
                  disabled
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      "& fieldset": {
                        border: "1px solid #DAD9D8",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#525A65",
                    },
                  }}
                />
              </Box>

              <Box sx={{ mb: 4, width: "100%", height: "48px", width: "100%" }}>
                <TextField
                  label="Phone Number"
                  fullWidth
                  value={phone}
                  disabled
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      "& fieldset": {
                        border: "1px solid #DAD9D8",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#525A65",
                    },
                  }}
                />
              </Box>*/}
            </Box>

            <Box sx={{ mb: 2 }}>
            <FormControl fullWidth variant="outlined">
  <InputLabel sx={{ color: "#838F99" }}>Your Timezone</InputLabel>
  <Select
    value={timezone}
    onChange={(e) => setTimezone(e.target.value)}
    label="Your Timezone" // Ensures the label stays inside the border
    sx={{
      borderRadius: "20px",
      backgroundColor: "#FBFBFB",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E0E0E0",
      },
    }}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 290,
        },
      },
    }}
  >
    {simplifiedTimezones.map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))}
  </Select>
</FormControl>

              <SuccessModal open={showSuccess} onClose={handleSuccessClose} />

            </Box>
            {Array.isArray(obf?.onboarding_forms?.custom_questions) &&
  obf.onboarding_forms.custom_questions.map((field, idx) => {
    // Sanitize field name: Replace spaces & special characters, provide default name
    const sanitizedFieldName = field?.name?.trim()
      ? field.name.replace(/[^a-zA-Z0-9_]/g, "_") // Keep only alphanumeric & underscores
      : `custom_field_${idx + 1}`;


    return (
      <Box sx={{ mb: 3}} key={sanitizedFieldName}>
        <Typography sx={{marginLeft:"3%",color:"#838F99",fontSize:"10px",display:"flex",flexDirection:"column"}}>
          <Typography
          sx={{color:"#838F99",fontSize:"10px"}}
          >



          Question ({idx+1})

          </Typography>
          <Typography sx={{
            color:"#000000",
            fontSize:"12px"
          }}>
          {field.name || `Custom Field ${idx + 1}`}
          </Typography>
        </Typography>
        <TextField
          sx={{
            marginTop:"5px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": { border: "1px solid #DAD9D8" },
            },
            "& .MuiInputLabel-root": { color: "#525A65" },
          }}
          placeholder={field.name || `Custom Field ${idx + 1}`}
          {...register(`custom.${sanitizedFieldName}`, { required: true })}
          fullWidth
          error={Boolean(errors.custom?.[sanitizedFieldName])}
          helperText={errors.custom?.[sanitizedFieldName] ? "This field is required" : ""}
        />
      </Box>
    );
  })}


          </CardContent>
          <CardActions>
            <Button
              type="Submit"
              disabled={isSubmitting}
              sx={{
                ml: "auto",
                fontSize: "14px",
                padding: "13px 19px",
                fontWeight: "500!important",
                fontFamily: "Bricolage Grotesque",
                lineHeight: "19px",
                type: "submit",
                border: "1px solid #02BD36",
                color: "#02BD36",
                borderRadius: "23px",
                "&:disabled": {
                  border: "1px solid #cccccc",
                  color: "#666666",
                  cursor: "not-allowed",
                },
              }}
            >
              {isSubmitting ? (
                      <CircularProgress size={24} sx={{ color: "#02BD36" }} />
                    ) : (
                      "Submit"
                    )}
            </Button>
          </CardActions>
        </form>
      </Paper>

    
    </PageContainer>
  );
};

const SuccessIcon = () => {
  return (
    <svg
      width="491"
      height="68"
      viewBox="0 0 491 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.4426 7.88828H61.6706C61.3251 7.88828 61.0423 7.60555 61.0423 7.25999V1.86919C61.0423 1.49535 60.7376 1.19063 60.3638 1.19063H50.2482C49.9529 1.19063 49.6921 1.38226 49.601 1.66185L48.9319 7.11548C48.8785 7.55529 48.3978 7.80661 48.0114 7.59298C47.5779 7.35423 47.1287 7.01181 46.8805 6.78562C46.1956 6.16675 42.806 3.21689 40.1483 1.97286C37.1513 0.56861 33.5134 0 29.351 0C25.1885 0 21.6323 0.703694 18.1013 2.11422C14.5702 3.52475 11.4445 5.6107 8.72078 8.37522C5.99711 11.1397 3.86403 14.5325 2.31528 18.5568C0.772807 22.5779 0 27.2053 0 32.4328C0 39.7116 1.4765 45.9946 4.43264 51.2754C7.38879 56.5563 11.5827 60.6214 17.0143 63.4675C22.446 66.3137 28.8703 67.7368 36.2874 67.7368C40.3368 67.7368 44.5181 67.1965 48.8282 66.1158C53.0127 65.0666 56.6599 63.433 59.7732 61.2088C59.9522 61.08 60.0559 60.8727 60.0559 60.6559V44.1222C60.0559 43.8552 59.8988 43.6102 59.6538 43.5034L59.3302 43.3589C59.0946 43.2552 58.8213 43.2929 58.6234 43.4594C56.0316 45.5956 53.2389 47.1883 50.2419 48.2376C47.1224 49.334 43.9338 49.8806 40.676 49.8806C35.147 49.8806 30.8589 48.3287 27.8053 45.2218C24.755 42.1148 23.2282 37.6351 23.2282 31.7762C23.2282 27.8085 23.8094 24.5508 24.9717 22.0061C26.1341 19.4615 27.6671 17.5735 29.5709 16.342C31.4715 15.1106 33.5197 14.4948 35.7093 14.4948C37.2769 14.4948 38.6843 14.7744 39.9378 15.3242V22.0972C39.9378 22.5088 40.2991 22.8261 40.7075 22.7695L46.1108 22.0344C46.5224 21.9779 46.8742 22.3297 46.8176 22.7413L46.1517 27.6514C46.1359 28.1226 46.4878 28.3739 46.8334 28.3739H60.3606C60.7345 28.3708 61.0392 28.0661 61.0392 27.6922V20.5548C61.0392 20.2406 61.2717 19.9736 61.5827 19.9328L66.5305 19.2605C66.8667 19.2134 67.118 18.9275 67.118 18.5882V8.56999C67.118 8.19615 66.8132 7.89143 66.4394 7.89143L66.4426 7.88828Z"
        fill="#E3E2E0"
      />
      <path
        d="M88.8061 67.2818C83.6603 67.2818 79.2214 66.3644 75.483 64.5298C71.7478 62.6952 68.8733 60.0815 66.8628 56.6887C64.8522 53.2958 63.8469 49.259 63.8469 44.5782C63.8469 39.8974 64.8522 35.8197 66.8628 32.4269C68.8733 29.0341 71.7478 26.4141 75.483 24.5638C79.2182 22.7166 83.6603 21.793 88.8061 21.793C93.9518 21.793 98.3908 22.7166 102.126 24.5638C105.861 26.411 108.736 29.031 110.746 32.4269C112.757 35.8197 113.765 39.8723 113.765 44.5782C113.765 49.2842 112.76 53.2958 110.746 56.6887C108.736 60.0815 105.861 62.6952 102.126 64.5298C98.3908 66.3644 93.9487 67.2818 88.8061 67.2818ZM88.8061 55.3347C92.5005 55.3347 94.3477 51.7345 94.3477 44.5374C94.3477 37.3402 92.5005 33.7401 88.8061 33.7401C85.1117 33.7401 83.2645 37.3402 83.2645 44.5374C83.2645 51.7345 85.1117 55.3347 88.8061 55.3347Z"
        fill="#E3E2E0"
      />
      <path
        d="M130.702 67.2848C127.884 67.2848 125.371 66.7162 123.169 65.5821C120.967 64.448 119.242 62.8804 117.995 60.8825C116.751 58.8845 116.125 56.5849 116.125 53.9869C116.125 49.9375 117.687 46.708 120.806 44.2985C123.926 41.889 128.085 40.6858 133.287 40.6858C135.094 40.6858 136.818 40.7958 138.461 41.0157C139.828 41.1979 141.21 41.4837 142.615 41.8733C143.045 41.9927 143.469 41.666 143.469 41.2199C143.469 38.7287 142.709 36.9223 141.191 35.8008C139.674 34.6793 137.243 34.117 133.903 34.117C131.494 34.117 129.059 34.4311 126.596 35.0625C124.249 35.6626 122.104 36.4856 120.153 37.5318C119.968 37.6291 119.751 37.6417 119.559 37.5569L119.157 37.3778C118.912 37.2679 118.755 37.026 118.755 36.759V25.6821C118.755 25.3993 118.928 25.1449 119.192 25.0475C121.959 24.0076 125.076 23.216 128.547 22.6694C132.201 22.0945 135.876 21.8086 139.57 21.8086C147.754 21.8086 153.732 23.1783 157.511 25.9145C161.288 28.6508 163.176 33.0457 163.176 39.0931V53.5628C163.176 53.9366 163.48 54.2413 163.854 54.2413H167.834C168.208 54.2413 168.513 54.5461 168.513 54.9199V65.8806C168.513 66.2544 168.208 66.5591 167.834 66.5591H146.598C146.303 66.5591 146.039 66.3675 145.951 66.0816L144.518 61.495C144.352 60.961 143.648 60.851 143.321 61.3034C142.109 62.9873 140.538 64.3507 138.606 65.3874C136.237 66.6597 133.605 67.2974 130.702 67.2974V67.2848ZM139.281 56.7765C140.02 56.7765 140.792 56.6477 141.6 56.387C142.26 56.1765 142.794 55.9032 143.208 55.5733C143.372 55.4414 143.469 55.2466 143.469 55.0361V48.9416C143.469 48.6558 143.287 48.4013 143.017 48.3008C142.479 48.0997 141.939 47.9552 141.396 47.8704C140.698 47.7605 140.048 47.707 139.445 47.707C138.05 47.707 136.878 48.1186 135.936 48.9385C134.993 49.7584 134.519 50.8548 134.519 52.2214C134.519 53.5879 134.956 54.7251 135.832 55.5451C136.709 56.365 137.858 56.7765 139.281 56.7765Z"
        fill="#E3E2E0"
      />
      <path
        d="M195.591 67.2818C187.819 67.2818 181.696 65.2366 177.219 61.1433C172.746 57.0531 170.506 51.3795 170.506 44.1258C170.506 39.5832 171.458 35.6407 173.358 32.3013C175.259 28.9619 177.807 26.3764 180.995 24.5418C184.184 22.7103 187.693 21.793 191.526 21.793C194.318 21.793 196.766 22.2799 198.874 23.2506C200.633 24.0611 202.172 25.1261 203.492 26.4424C203.85 26.7974 204.453 26.6717 204.619 26.1942L205.735 22.9899C205.829 22.7166 206.086 22.5344 206.375 22.5344H214.289C214.663 22.5344 214.967 22.8391 214.967 23.2129V40.0827C214.967 40.4566 214.663 40.7613 214.289 40.7613H203.749C203.41 40.7613 203.121 40.51 203.08 40.1738C202.813 38.113 202.191 36.5486 201.217 35.4836C200.149 34.3212 198.808 33.7401 197.193 33.7401C195.358 33.7401 193.725 34.5254 192.286 36.0993C190.85 37.6732 190.131 40.2932 190.131 43.9593C190.131 47.6255 191.13 50.4811 193.128 52.1084C195.126 53.7357 197.658 54.5524 200.721 54.5524C203.02 54.5524 205.245 54.1001 207.393 53.1985C209.394 52.3565 211.144 51.3356 212.636 50.1386C212.834 49.9784 213.105 49.9407 213.337 50.0444L213.664 50.1889C213.909 50.2989 214.066 50.5408 214.066 50.8078V62.1988C214.066 62.425 213.956 62.6355 213.771 62.7612C212.53 63.5842 210.971 64.3225 209.099 64.9822C207.073 65.6953 204.883 66.2545 202.53 66.666C200.177 67.0776 197.865 67.2818 195.594 67.2818H195.591Z"
        fill="#E3E2E0"
      />
      <path
        d="M223.3 66.5518C222.926 66.5518 222.621 66.2471 222.621 65.8732V54.2309V16.8126C222.621 16.4387 222.317 16.134 221.943 16.134H217.347C216.973 16.134 216.668 15.8293 216.668 15.4555V5.54405C216.668 5.19534 216.935 4.90318 217.284 4.86863L241.587 2.57534C241.986 2.53764 242.328 2.85179 242.328 3.25076V25.6025C242.328 26.1774 242.997 26.4915 243.44 26.1208C246.871 23.2401 251.222 21.7981 256.493 21.7981C261.447 21.7981 265.079 23.2024 267.394 26.0046C269.706 28.8099 270.862 33.0321 270.862 38.6679V53.5492C270.862 53.923 271.167 54.2277 271.541 54.2277H275.932C276.306 54.2277 276.611 54.5325 276.611 54.9063V65.867C276.611 66.2408 276.306 66.5455 275.932 66.5455H251.837C251.463 66.5455 251.159 66.2408 251.159 65.867V38.6711C251.159 37.0846 250.816 35.9286 250.131 35.2029C249.447 34.4772 248.545 34.1159 247.423 34.1159C246.494 34.1159 245.57 34.3892 244.653 34.9358C243.792 35.451 243.06 36.1422 242.454 37.0155C242.375 37.1286 242.334 37.2668 242.334 37.4051V53.5523C242.334 53.9262 242.639 54.2309 243.013 54.2309H245.35C245.724 54.2309 246.029 54.5356 246.029 54.9094V65.8701C246.029 66.2439 245.724 66.5487 245.35 66.5487L223.306 66.555L223.3 66.5518Z"
        fill="#E3E2E0"
      />
      <path
        d="M301.906 66.5513C301.532 66.5513 301.227 66.2466 301.227 65.8728V52.3832V16.0299C301.227 15.656 300.923 15.3513 300.549 15.3513H295.337C294.963 15.3513 294.659 15.0466 294.659 14.6727V1.86802C294.659 1.49418 294.963 1.18945 295.337 1.18945H329.922C338.844 1.18945 345.576 2.92041 350.118 6.38234C354.661 9.84426 356.932 15.0246 356.932 21.9202C356.932 28.8157 354.674 33.6536 350.159 37.0904C345.642 40.5241 339.035 42.2425 330.33 42.2425H322.756C322.382 42.2425 322.077 42.5472 322.077 42.921V53.5895C322.077 53.9634 322.382 54.2681 322.756 54.2681H333.305C333.679 54.2681 333.984 54.5728 333.984 54.9467V65.8665C333.984 66.2403 333.679 66.545 333.305 66.545L301.903 66.5513H301.906ZM327.541 14.9429H322.759C322.385 14.9429 322.081 15.2476 322.081 15.6215V29.0451C322.081 29.4189 322.385 29.7236 322.759 29.7236H327.129C329.922 29.7236 332.014 29.1362 333.409 27.9581C334.804 26.78 335.501 24.9077 335.501 22.3348C335.501 19.7619 334.823 17.8551 333.469 16.6896C332.115 15.5272 330.135 14.946 327.537 14.946L327.541 14.9429Z"
        fill="#E3E2E0"
      />
      <path
        d="M359.546 66.5531C359.173 66.5531 358.868 66.2483 358.868 65.8745V54.2321V16.3803V5.13378C358.868 4.78507 359.132 4.49606 359.477 4.45836L377.827 2.57975C378.226 2.53891 378.574 2.85306 378.574 3.25517V53.5504C378.574 53.9243 378.879 54.229 379.253 54.229H384.261C384.634 54.229 384.939 54.5337 384.939 54.9076V65.8682C384.939 66.2421 384.634 66.5468 384.261 66.5468L359.55 66.5531H359.546Z"
        fill="#E3E2E0"
      />
      <path
        d="M405.443 67.3235C395.315 67.3235 390.254 61.6312 390.254 50.2464V36.0626C390.254 35.6887 389.95 35.384 389.576 35.384H385.184C384.81 35.384 384.505 35.0793 384.505 34.7054V24.794C384.505 24.4453 384.772 24.1532 385.118 24.1186L409.213 21.8253C409.612 21.7876 409.955 22.1018 409.955 22.5008V50.2872C409.955 51.9554 410.325 53.1617 411.064 53.9C411.802 54.6382 412.747 55.0089 413.897 55.0089C415.898 55.0089 417.456 54.0068 418.575 52.0056C418.631 51.9051 418.66 51.7889 418.66 51.6726V36.0657C418.66 35.6919 418.355 35.3872 417.981 35.3872H415.644C415.27 35.3872 414.965 35.0824 414.965 34.7086V24.7909C414.965 24.4422 415.229 24.1532 415.575 24.1155L437.619 21.8348C438.018 21.7939 438.366 22.1081 438.366 22.5102V53.5513C438.366 53.9251 438.671 54.2298 439.045 54.2298H443.437C443.81 54.2298 444.115 54.5345 444.115 54.9084V65.869C444.115 66.2429 443.81 66.5476 443.437 66.5476H419.75C419.376 66.5476 419.071 66.2429 419.071 65.869V63.3496C419.071 62.7621 418.38 62.4574 417.94 62.8469C416.555 64.0815 414.981 65.0805 413.222 65.8502C410.963 66.8366 408.371 67.3267 405.443 67.3267V67.3235Z"
        fill="#E3E2E0"
      />
      <path
        d="M472.943 67.2847C470.15 67.2847 467.442 66.9549 464.816 66.2983C462.466 65.7108 460.358 64.8941 458.489 63.8479C458.137 63.65 457.694 63.8134 457.54 64.1841L456.736 66.1255C456.632 66.38 456.384 66.5433 456.111 66.5433H448.21C447.836 66.5433 447.531 66.2386 447.531 65.8648V51.7846C447.531 51.4108 447.836 51.106 448.21 51.106H457.543C457.779 51.2725 457.948 51.3919 458.184 51.5553C458.831 53.4779 459.877 55.0109 461.325 56.1544C462.887 57.3859 464.583 58.0016 466.415 58.0016C468.824 58.0016 470.027 57.0969 470.027 55.2937C470.027 53.9805 469.487 52.969 468.406 52.2558C467.326 51.5459 465.928 50.9427 464.219 50.4495C462.507 49.9563 460.694 49.4222 458.778 48.8473C456.861 48.2724 455.049 47.487 453.34 46.4881C451.628 45.4891 450.233 44.1225 449.152 42.3821C448.072 40.6449 447.531 38.3673 447.531 35.5462C447.531 33.0016 448.21 30.6895 449.564 28.6098C450.918 26.5302 452.972 24.8683 455.721 23.6211C458.473 22.3771 461.926 21.752 466.088 21.752C468.771 21.752 471.253 22.0315 473.54 22.5939C475.563 23.0902 477.476 23.8505 479.273 24.8746C479.637 25.0819 480.099 24.906 480.24 24.5102L480.787 22.9803C480.884 22.7101 481.139 22.531 481.425 22.531H489.3C489.674 22.531 489.979 22.8358 489.979 23.2096V35.9735C489.979 36.3473 489.674 36.652 489.3 36.652H479.955C479.672 36.652 479.411 36.4793 479.311 36.2154C478.61 34.3588 477.642 32.9765 476.408 32.0749C475.079 31.1042 473.65 30.6172 472.117 30.6172C469.434 30.6172 468.092 31.4372 468.092 33.0802C468.092 34.23 468.645 35.141 469.754 35.8101C470.863 36.4824 472.286 37.0636 474.023 37.5537C475.761 38.0469 477.602 38.6092 479.546 39.2375C481.488 39.8658 483.328 40.714 485.069 41.7821C486.806 42.8502 488.229 44.2733 489.338 46.0514C490.447 47.8295 491 50.1416 491 52.9878C491 57.3388 489.432 60.807 486.3 63.3956C483.165 65.981 478.71 67.2753 472.936 67.2753L472.943 67.2847Z"
        fill="#E3E2E0"
      />
    </svg>
  );
};
const SuccessIconPopup=()=>{
  return (
    <svg width="142" height="20" viewBox="0 0 142 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.2158 2.28136H17.8357C17.7357 2.28136 17.654 2.19959 17.654 2.09965V0.540585C17.654 0.432468 17.5658 0.344339 17.4577 0.344339H14.5322C14.4468 0.344339 14.3714 0.399761 14.345 0.480621L14.1515 2.05786C14.1361 2.18505 13.9971 2.25774 13.8853 2.19596C13.7599 2.12691 13.63 2.02788 13.5582 1.96246C13.3602 1.78348 12.3799 0.930352 11.6112 0.570567C10.7445 0.164447 9.69238 0 8.48855 0C7.28473 0 6.25625 0.203514 5.23505 0.611452C4.21384 1.01939 3.30984 1.62266 2.52213 2.42219C1.73442 3.22171 1.11751 4.20294 0.669599 5.36679C0.223503 6.52973 0 7.86801 0 9.37984C0 11.4849 0.427017 13.302 1.28196 14.8293C2.1369 16.3566 3.34981 17.5322 4.92069 18.3554C6.49157 19.1785 8.34955 19.5901 10.4946 19.5901C11.6657 19.5901 12.875 19.4338 14.1215 19.1213C15.3317 18.8178 16.3866 18.3454 17.2869 17.7021C17.3387 17.6649 17.3687 17.6049 17.3687 17.5422V12.7605C17.3687 12.6833 17.3233 12.6124 17.2524 12.5816L17.1588 12.5398C17.0907 12.5098 17.0116 12.5207 16.9544 12.5688C16.2048 13.1866 15.3971 13.6473 14.5304 13.9507C13.6282 14.2678 12.706 14.4259 11.7639 14.4259C10.1648 14.4259 8.92466 13.9771 8.04155 13.0785C7.15935 12.18 6.7178 10.8844 6.7178 9.18995C6.7178 8.04246 6.88588 7.10029 7.22204 6.36437C7.5582 5.62845 8.00157 5.08241 8.55215 4.72626C9.10182 4.37011 9.69419 4.19203 10.3275 4.19203C10.7808 4.19203 11.1878 4.2729 11.5504 4.43189V6.39072C11.5504 6.50974 11.6548 6.6015 11.773 6.58515L13.3357 6.37255C13.4547 6.35619 13.5564 6.45795 13.5401 6.57697L13.3475 7.99703C13.3429 8.13331 13.4447 8.20599 13.5446 8.20599H17.4568C17.5649 8.20509 17.6531 8.11696 17.6531 8.00884V5.94462C17.6531 5.85377 17.7203 5.77654 17.8102 5.76473L19.2412 5.5703C19.3384 5.55667 19.4111 5.47399 19.4111 5.37587V2.47852C19.4111 2.3704 19.323 2.28227 19.2149 2.28227L19.2158 2.28136Z" fill="#0F0F0F"/>
<path d="M25.6832 19.4578C24.195 19.4578 22.9113 19.1925 21.8301 18.6619C20.7498 18.1313 19.9185 17.3754 19.337 16.3941C18.7556 15.4129 18.4648 14.2454 18.4648 12.8917C18.4648 11.538 18.7556 10.3587 19.337 9.37743C19.9185 8.3962 20.7498 7.63847 21.8301 7.10334C22.9104 6.56911 24.195 6.302 25.6832 6.302C27.1714 6.302 28.4552 6.56911 29.5355 7.10334C30.6157 7.63757 31.4471 8.39529 32.0285 9.37743C32.61 10.3587 32.9017 11.5307 32.9017 12.8917C32.9017 14.2527 32.6109 15.4129 32.0285 16.3941C31.4471 17.3754 30.6157 18.1313 29.5355 18.6619C28.4552 19.1925 27.1705 19.4578 25.6832 19.4578ZM25.6832 16.0026C26.7517 16.0026 27.2859 14.9614 27.2859 12.8799C27.2859 10.7984 26.7517 9.75721 25.6832 9.75721C24.6148 9.75721 24.0806 10.7984 24.0806 12.8799C24.0806 14.9614 24.6148 16.0026 25.6832 16.0026Z" fill="#0F0F0F"/>
<path d="M37.8016 19.459C36.9866 19.459 36.2598 19.2946 35.6229 18.9666C34.986 18.6386 34.4872 18.1852 34.1265 17.6074C33.7667 17.0296 33.5859 16.3645 33.5859 15.6131C33.5859 14.442 34.0375 13.508 34.9397 12.8112C35.8419 12.1143 37.0448 11.7663 38.5493 11.7663C39.0717 11.7663 39.5705 11.7981 40.0457 11.8617C40.4409 11.9144 40.8407 11.9971 41.2468 12.1098C41.3713 12.1443 41.4939 12.0498 41.4939 11.9208C41.4939 11.2003 41.2741 10.6779 40.8352 10.3536C40.3964 10.0292 39.6932 9.86657 38.7274 9.86657C38.0305 9.86657 37.3264 9.95743 36.6141 10.14C35.9354 10.3136 35.3149 10.5516 34.7507 10.8542C34.6971 10.8823 34.6344 10.886 34.579 10.8614L34.4627 10.8096C34.3918 10.7778 34.3464 10.7079 34.3464 10.6307V7.42712C34.3464 7.34535 34.3964 7.27176 34.4727 7.2436C35.2731 6.94287 36.1744 6.71391 37.1783 6.55583C38.235 6.38956 39.298 6.30688 40.3664 6.30688C42.7332 6.30688 44.4622 6.70301 45.5551 7.49436C46.6472 8.2857 47.1932 9.55676 47.1932 11.3057V15.4905C47.1932 15.5986 47.2814 15.6867 47.3895 15.6867H48.5406C48.6487 15.6867 48.7369 15.7749 48.7369 15.883V19.0529C48.7369 19.161 48.6487 19.2491 48.5406 19.2491H42.3988C42.3134 19.2491 42.2371 19.1937 42.2117 19.111L41.7974 17.7846C41.7492 17.6301 41.5457 17.5983 41.4512 17.7291C41.1005 18.2161 40.6463 18.6104 40.0875 18.9102C39.4025 19.2782 38.6411 19.4626 37.8016 19.4626V19.459ZM40.2828 16.4199C40.4963 16.4199 40.7198 16.3827 40.9533 16.3073C41.1441 16.2464 41.2986 16.1673 41.4185 16.0719C41.4658 16.0338 41.4939 15.9775 41.4939 15.9166V14.154C41.4939 14.0713 41.4412 13.9977 41.3631 13.9687C41.2077 13.9105 41.0515 13.8687 40.8943 13.8442C40.6926 13.8124 40.5045 13.7969 40.3301 13.7969C39.9267 13.7969 39.5878 13.916 39.3152 14.1531C39.0427 14.3902 38.9055 14.7073 38.9055 15.1025C38.9055 15.4977 39.0318 15.8266 39.2853 16.0638C39.5387 16.3009 39.8713 16.4199 40.2828 16.4199Z" fill="#0F0F0F"/>
<path d="M56.5672 19.4578C54.3195 19.4578 52.5487 18.8663 51.2541 17.6825C49.9603 16.4995 49.3125 14.8587 49.3125 12.7609C49.3125 11.4471 49.5878 10.3069 50.1375 9.34109C50.6871 8.37531 51.424 7.62757 52.3461 7.09698C53.2683 6.5673 54.2832 6.302 55.3916 6.302C56.1993 6.302 56.907 6.44283 57.5167 6.72357C58.0255 6.95797 58.4707 7.26597 58.8522 7.64665C58.9558 7.74932 59.1303 7.71298 59.1784 7.57488L59.5009 6.64816C59.5282 6.56912 59.6027 6.51642 59.6863 6.51642H61.9749C62.083 6.51642 62.1712 6.60455 62.1712 6.71267V11.5916C62.1712 11.6997 62.083 11.7878 61.9749 11.7878H58.9267C58.8286 11.7878 58.745 11.7151 58.7332 11.6179C58.656 11.0219 58.4761 10.5694 58.1945 10.2614C57.8855 9.92529 57.4976 9.75721 57.0306 9.75721C56.5 9.75721 56.0276 9.98434 55.6115 10.4395C55.1963 10.8947 54.9882 11.6524 54.9882 12.7127C54.9882 13.773 55.2771 14.5989 55.8549 15.0695C56.4328 15.5401 57.1651 15.7763 58.0509 15.7763C58.716 15.7763 59.3592 15.6455 59.9807 15.3847C60.5594 15.1413 61.0655 14.846 61.497 14.4998C61.5543 14.4535 61.6324 14.4426 61.6996 14.4726L61.7941 14.5144C61.865 14.5462 61.9104 14.6161 61.9104 14.6933V17.9877C61.9104 18.0531 61.8786 18.114 61.825 18.1504C61.4661 18.3884 61.0155 18.6019 60.474 18.7927C59.888 18.9989 59.2547 19.1607 58.5742 19.2797C57.8937 19.3987 57.225 19.4578 56.5682 19.4578H56.5672Z" fill="#0F0F0F"/>
<path d="M64.582 19.2487C64.4739 19.2487 64.3858 19.1606 64.3858 19.0525V15.6854V4.86372C64.3858 4.75561 64.2976 4.66748 64.1895 4.66748H62.8603C62.7522 4.66748 62.6641 4.57935 62.6641 4.47123V1.60477C62.6641 1.50392 62.7413 1.41942 62.8421 1.40943L69.8707 0.746189C69.986 0.735286 70.0851 0.826141 70.0851 0.941526V7.40584C70.0851 7.5721 70.2786 7.66295 70.4067 7.55575C71.3988 6.72261 72.6572 6.30559 74.1817 6.30559C75.6145 6.30559 76.6648 6.71171 77.3344 7.52213C78.0031 8.33346 78.3374 9.55455 78.3374 11.1845V15.4883C78.3374 15.5964 78.4255 15.6845 78.5336 15.6845H79.8038C79.9119 15.6845 80 15.7726 80 15.8808V19.0507C80 19.1588 79.9119 19.2469 79.8038 19.2469H72.8352C72.7271 19.2469 72.639 19.1588 72.639 19.0507V11.1854C72.639 10.7266 72.54 10.3922 72.3419 10.1824C72.1438 9.97248 71.8831 9.868 71.5587 9.868C71.2898 9.868 71.0227 9.94704 70.7574 10.1051C70.5085 10.2541 70.2968 10.454 70.1214 10.7066C70.0987 10.7393 70.0869 10.7793 70.0869 10.8192V15.4892C70.0869 15.5973 70.175 15.6854 70.2831 15.6854H70.9591C71.0672 15.6854 71.1553 15.7736 71.1553 15.8817V19.0516C71.1553 19.1597 71.0672 19.2478 70.9591 19.2478L64.5838 19.2497L64.582 19.2487Z" fill="#0F0F0F"/>
<path d="M87.3148 19.2478C87.2067 19.2478 87.1185 19.1596 87.1185 19.0515V15.1502V4.63652C87.1185 4.5284 87.0304 4.44027 86.9223 4.44027H85.415C85.3069 4.44027 85.2188 4.35214 85.2188 4.24403V0.54079C85.2188 0.432672 85.3069 0.344543 85.415 0.344543H95.4172C97.9975 0.344543 99.9445 0.845153 101.258 1.84637C102.572 2.84759 103.229 4.34578 103.229 6.34004C103.229 8.3343 102.576 9.73347 101.27 10.7274C99.9636 11.7205 98.0529 12.2174 95.5353 12.2174H93.3448C93.2367 12.2174 93.1485 12.3056 93.1485 12.4137V15.4991C93.1485 15.6072 93.2367 15.6953 93.3448 15.6953H96.3957C96.5038 15.6953 96.5919 15.7835 96.5919 15.8916V19.0497C96.5919 19.1578 96.5038 19.246 96.3957 19.246L87.3139 19.2478H87.3148ZM94.7285 4.32216H93.3457C93.2376 4.32216 93.1495 4.41029 93.1495 4.51841V8.40063C93.1495 8.50874 93.2376 8.59687 93.3457 8.59687H94.6095C95.4172 8.59687 96.0223 8.42698 96.4257 8.08627C96.8291 7.74556 97.0308 7.20407 97.0308 6.45997C97.0308 5.71587 96.8345 5.16438 96.4429 4.82731C96.0513 4.49115 95.479 4.32307 94.7276 4.32307L94.7285 4.32216Z" fill="#0F0F0F"/>
<path d="M103.985 19.248C103.877 19.248 103.789 19.1598 103.789 19.0517V15.6847V4.73758V1.48498C103.789 1.38413 103.865 1.30055 103.965 1.28965L109.272 0.746335C109.388 0.734523 109.488 0.825378 109.488 0.941672V15.4875C109.488 15.5956 109.577 15.6837 109.685 15.6837H111.133C111.241 15.6837 111.329 15.7719 111.329 15.88V19.0499C111.329 19.158 111.241 19.2462 111.133 19.2462L103.986 19.248H103.985Z" fill="#0F0F0F"/>
<path d="M117.259 19.4711C114.329 19.4711 112.866 17.8248 112.866 14.5323V10.4302C112.866 10.3221 112.778 10.2339 112.67 10.2339H111.399C111.291 10.2339 111.203 10.1458 111.203 10.0377V7.17123C111.203 7.07038 111.28 6.98589 111.38 6.97589L118.349 6.31266C118.464 6.30175 118.563 6.39261 118.563 6.50799V14.5441C118.563 15.0265 118.67 15.3754 118.884 15.5889C119.097 15.8024 119.371 15.9096 119.703 15.9096C120.282 15.9096 120.733 15.6198 121.056 15.0411C121.073 15.012 121.081 14.9784 121.081 14.9448V10.4311C121.081 10.323 120.993 10.2349 120.885 10.2349H120.209C120.101 10.2349 120.012 10.1467 120.012 10.0386V7.17032C120.012 7.06947 120.089 6.98589 120.189 6.97499L126.564 6.31538C126.679 6.30357 126.78 6.39443 126.78 6.51072V15.4881C126.78 15.5962 126.868 15.6843 126.976 15.6843H128.247C128.355 15.6843 128.443 15.7724 128.443 15.8806V19.0505C128.443 19.1586 128.355 19.2467 128.247 19.2467H121.396C121.288 19.2467 121.2 19.1586 121.2 19.0505V18.3218C121.2 18.1519 121 18.0638 120.873 18.1765C120.472 18.5335 120.017 18.8224 119.508 19.045C118.855 19.3303 118.105 19.472 117.259 19.472V19.4711Z" fill="#0F0F0F"/>
<path d="M136.775 19.4607C135.967 19.4607 135.184 19.3653 134.425 19.1754C133.745 19.0055 133.135 18.7693 132.595 18.4668C132.493 18.4095 132.365 18.4568 132.32 18.564L132.088 19.1255C132.058 19.199 131.986 19.2463 131.907 19.2463H129.622C129.514 19.2463 129.426 19.1582 129.426 19.05V14.9779C129.426 14.8698 129.514 14.7817 129.622 14.7817H132.321C132.389 14.8298 132.439 14.8644 132.507 14.9116C132.694 15.4676 132.996 15.911 133.415 16.2417C133.867 16.5979 134.357 16.776 134.887 16.776C135.584 16.776 135.932 16.5143 135.932 15.9928C135.932 15.613 135.776 15.3205 135.463 15.1142C135.151 14.9089 134.746 14.7345 134.252 14.5918C133.757 14.4492 133.233 14.2947 132.678 14.1285C132.124 13.9622 131.6 13.7351 131.106 13.4461C130.611 13.1572 130.207 12.762 129.895 12.2587C129.582 11.7562 129.426 11.0975 129.426 10.2817C129.426 9.54574 129.622 8.87705 130.014 8.27559C130.405 7.67414 130.999 7.19352 131.794 6.83282C132.59 6.47304 133.589 6.29224 134.793 6.29224C135.568 6.29224 136.286 6.3731 136.948 6.53573C137.533 6.67928 138.086 6.89915 138.606 7.19533C138.711 7.2553 138.845 7.20442 138.886 7.08994L139.044 6.64748C139.072 6.56934 139.145 6.51756 139.228 6.51756H141.506C141.614 6.51756 141.702 6.60568 141.702 6.7138V10.4052C141.702 10.5133 141.614 10.6015 141.506 10.6015H138.803C138.721 10.6015 138.646 10.5515 138.617 10.4752C138.414 9.93823 138.134 9.53847 137.777 9.27772C137.393 8.99698 136.979 8.85616 136.536 8.85616C135.76 8.85616 135.372 9.09329 135.372 9.56846C135.372 9.90098 135.532 10.1645 135.853 10.358C136.174 10.5524 136.585 10.7205 137.088 10.8622C137.59 11.0049 138.122 11.1675 138.685 11.3492C139.246 11.5309 139.779 11.7762 140.282 12.0851C140.784 12.394 141.196 12.8056 141.517 13.3198C141.837 13.8341 141.997 14.5028 141.997 15.3259C141.997 16.5843 141.544 17.5873 140.638 18.3359C139.731 19.0837 138.443 19.458 136.773 19.458L136.775 19.4607Z" fill="#0F0F0F"/>
</svg>

  )
}
