import React, { memo, useEffect, useState, useRef } from "react";
import { TrainingPlanModal } from "../../components/TrainingPlanModal/TrainingPlanModal";
import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
  Grid,
  Card,
  CardContent,
  Menu,
  MenuItem,
  Divider,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  Fade,
  Skeleton,
} from "@mui/material";
import { useSupabase } from "../../providers/AuthContextProvider";
import { deletePlan, getPlansByCoachId } from "../../services/query";
import { MenuIcon, searchStyle, button3Style } from "./style";

const PlanListSkeleton = () => {
  return (
    <Grid container spacing={2}>
      {Array(6).fill(0).map((_, index) => (
        <Fade in={true} timeout={300} style={{ transitionDelay: `${index * 50}ms` }} key={index}>
          <Grid item xs={6} sm={6} md={4}>
            <Card
              sx={{
                backgroundColor: "#F1F4F1",
                borderRadius: "8px",
                boxShadow: "none",
                position: "relative",
                height: "100%",
                minHeight: "145px",
                animation: "shimmer 1.5s infinite",
                "@keyframes shimmer": {
                  "0%": {
                    opacity: 1,
                  },
                  "50%": {
                    opacity: 0.7,
                  },
                  "100%": {
                    opacity: 1,
                  }
                }
              }}
            >
              <CardContent 
                sx={{ 
                  p: 2,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1
                }}
              >
                <Skeleton 
                  variant="text" 
                  width="80%" 
                  height={28}
                  sx={{ backgroundColor: "#E5E5E5" }}
                />
                <Skeleton 
                  variant="text" 
                  width="60%" 
                  height={24}
                  sx={{ backgroundColor: "#E5E5E5" }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Fade>
      ))}
    </Grid>
  );
};

export const PlanList = memo(({ user, onModalStateChange,trainingPlanOpen, setTrainingPlanOpen }) => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [tpSearch, setTpSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const supabase = useSupabase();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [preventCardClick, setPreventCardClick] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [planToDelete, setPlanToDelete] = useState(null);
  const initialLoadComplete = useRef(false);

  const handleClick = (event, index) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
    setPreventCardClick(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null);
    // Prevent card clicks for a short duration after menu closes
    setPreventCardClick(true);
    setTimeout(() => {
      setPreventCardClick(false);
    }, 300);
  };

  const loadPlans = () => {
    setIsLoading(true);
    getPlansByCoachId(supabase, user.id).then(({ data }) => {
      // Filter out plans with is_single_exercise set to true
      const filteredData = (data || []).filter(plan => {
        return (
          plan && 
          typeof plan.name === "string" && 
          plan.name.trim().length > 0 &&
          !plan.is_single_exercise
        );
      });
      
      // Sort plans by created_at in descending order (newest first)
      const sortedPlans = filteredData.sort((a, b) => {
        const dateA = new Date(a.created_at || 0);
        const dateB = new Date(b.created_at || 0);
        return dateB - dateA;
      });
      
      setPlans(sortedPlans);
      setIsLoading(false);
      initialLoadComplete.current = true;
    });
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    
    // Only load plans if it hasn't been loaded before
    if (!initialLoadComplete.current) {
      loadPlans();
    }
  }, [user?.id, trainingPlanOpen]); // Use user.id instead of user to avoid unnecessary rerenders

  useEffect(() => {
    onModalStateChange?.(trainingPlanOpen);
  }, [trainingPlanOpen, onModalStateChange]);

  const filteredPlans = plans.filter((plan) =>
    plan.name.toLowerCase().includes(tpSearch.toLowerCase())
  );

  const handleDeleteConfirm = async () => {
    if (planToDelete) {
      try {
        await deletePlan(supabase, planToDelete.id);
        // Update state locally instead of reloading everything
        setPlans(currentPlans => currentPlans.filter(plan => plan.id !== planToDelete.id));
        setDeleteDialogOpen(false);
        setPlanToDelete(null);
        handleClose();
      } catch (error) {
        console.error("Error deleting plan:", error);
      }
    }
  };

  return (
    <>
      <Paper
        sx={{
          borderRadius: "24px",
          height: "100%",
          backgroundColor:"transparent",
          width: ["90vw", "90vw", "568px", "568px"],
          border: "none",
          display: trainingPlanOpen ? "none" : "block",
          boxShadow: "unset",
          transform: "scale(1)",
         
        }}
      >
        <Box p={1} pt={4} pb={0} sx={{ height: "144px" }}>
          <Box pb={2} display={"flex"} alignItems={"flex-end"}>
            <Typography
              sx={{
                fontSize: {
                  xl: "16px",
                  lg: "16px",
                  md: "13px",
                  sm: "13px",
                  xs: "13px",
                },
                fontWeight: "600 !important",
                color: "#02BD36",
                animation: 'slideDown 0.5s ease-out',
                
              }}
            >
              My workouts
            </Typography>
            <Button 
              sx={{
                ...button3Style,
                color:"#0F0F0F",
                background:"#EEEEEE",
                
                ":hover": {
                  backgroundColor: "#E9FFCE",
              },
              }} 
              onClick={() => setTrainingPlanOpen(true)}
            >
              Workout builder
            </Button>
          </Box>

          <TextField
            id="outlined-search"
            placeholder="Search workout by name..."
            type="search"
            fullWidth
            value={tpSearch}
            sx={searchStyle}
            onChange={(e) => setTpSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment 
                  position="start"
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:"center",
                    padding:"3px",
                    paddingTop:"5px",
                    '& svg': {
                      display: 'block'
                    }
                  }}
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.8846 11.2114C14.8794 8.41224 14.5665 4.5291 12.1173 2.07775C10.7777 0.73798 8.9946 0 7.09741 0C5.20023 0 3.41714 0.73798 2.07752 2.07775C0.737895 3.41753 0 5.20083 0 7.09823C0 8.99563 0.737895 10.7789 2.07752 12.1169C3.41714 13.4566 5.20023 14.1946 7.09741 14.1946C8.56768 14.1946 10.0177 13.7308 11.212 12.8843L14.3273 16L16 14.3271L12.8846 11.2114ZM7.09741 2.36485C8.35975 2.36485 9.54848 2.85806 10.4446 3.75063C11.3371 4.6432 11.8302 5.83207 11.8302 7.09823C11.8302 8.36439 11.3389 9.55325 10.4446 10.4458C9.55216 11.3384 8.36343 11.8316 7.09741 11.8316C5.8314 11.8316 4.64267 11.3402 3.7502 10.4458C2.85773 9.55325 2.36458 8.36439 2.36458 7.09823C2.36458 5.83207 2.85589 4.6432 3.74836 3.75063C4.64635 2.85622 5.83508 2.36485 7.09741 2.36485Z" fill="#525A65"/>
                  </svg>
                </InputAdornment>
              ),
            }}
          />
        <Divider  sx={{marginTop:"15px" }} />

        </Box>
        <Box sx={{paddingTop:"0!important", p: 1, overflow: "auto" }}>
          {isLoading ? (
            <PlanListSkeleton />
          ) : !isLoading && plans.length === 0 ? (
            <Fade in={true} timeout={400}>
              <Box 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="center" 
                height="300px"
                sx={{
                  animation: 'fadeIn 0.6s ease-out',
                  '@keyframes fadeIn': {
                    '0%': {
                      opacity: 0,
                      transform: 'translateY(20px)'
                    },
                    '100%': {
                      opacity: 1,
                      transform: 'translateY(0)'
                    }
                  }
                }}
              >
                <Typography
                  sx={{
                    color: "#525A65",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    mb: 1,
                  }}
                >
                  You don't have any workouts yet
                </Typography>
                <Typography
                  sx={{
                    color: "#525A65",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  Click the "Workout builder" button to get started
                </Typography>
              </Box>
            </Fade>
          ) : filteredPlans.length === 0 ? (
            <Fade in={true} timeout={400}>
              <Box 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="center" 
                height="300px"
                sx={{
                  animation: 'fadeIn 0.6s ease-out',
                  '@keyframes fadeIn': {
                    '0%': {
                      opacity: 0,
                      transform: 'translateY(20px)'
                    },
                    '100%': {
                      opacity: 1,
                      transform: 'translateY(0)'
                    }
                  }
                }}
              >
                <Typography
                  sx={{
                    color: "#525A65",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  {tpSearch!==""&&
                  <>
                    No plans found matching "{tpSearch}"
                  </>
                  }
                </Typography>
              </Box>
            </Fade>
          ) : (
            <Grid container spacing={1}>
              {filteredPlans.map((plan, i) => (
                <Fade in={true} timeout={300} style={{ transitionDelay: `${i * 50}ms` }} key={i}>
                  <Grid item xs={6} sm={6} md={4}>
                    <Card
                      sx={{
                        backgroundColor: "#F1F4F1",
                        borderRadius: "8px",
                        boxShadow: "none",
                        position: "relative",
                        height: "100%",
                        minHeight: "145px",
                        cursor: "pointer",
                        opacity: 1,
                        transform: "translateY(0)",
                        transition: "all 0.3s ease-in-out",
                        "&:hover": {
                          transform: "translateY(-2px)",
                          boxShadow: "0 4px 8px rgba(0,0,0,0.05)",
                            backgroundColor: "#E9FFCE",
                        
                          "& .menu-icon": {
                            opacity: 1
                          }
                        }
                      }}
                      onClick={() => {
                        if (!preventCardClick) {
                          setTrainingPlanOpen(true);
                          setSelectedPlan(plan);
                        }
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={(event) => handleClick(event, i)}
                        className="menu-icon"
                        sx={{ 
                          p: 0.5,
                          position: 'absolute',
                          top: 10,
                          right: 8,
                          transition: "all 0.3s ease",
                          opacity: 0.7,
                          transform: "scale(1)",
                        
                        }}
                      >
                        <MenuIcon />
                      </IconButton>
                      <CardContent 
                        sx={{ 
                          p: 2,
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          className="plan-title"
                          sx={{
                            color: "#0F0F0F",
                            fontSize: "16px",
                            fontWeight: "600",
                            textAlign: 'center',
                            transition: "color 0.3s ease",
                          }}
                        >
                          {plan.name}
                        </Typography>
                      </CardContent>
                      <Menu
                        sx={{
                          '& .MuiPaper-root': {
                            backgroundColor: '#F1F4F1',
                            borderRadius: '16px',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            minWidth: '190px',
                            paddingX: "4px",
                            animation: 'menuSlideIn 0.2s ease-out',
                            "@keyframes menuSlideIn": {
                              "0%": {
                                opacity: 0,
                                transform: "translateY(-10px)"
                              },
                              "100%": {
                                opacity: 1,
                                transform: "translateY(0)"
                              }
                            }
                          },
                          '& .MuiMenuItem-root': {
                         
                          }
                        }}
                        anchorEl={anchorEl}
                        open={openMenuIndex === i}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem
                          sx={{ 
                            fontSize: "15px", 
                            color: "#525A65",
                            fontWeight: "400",
                            ":hover":{
                                  fontWeight:"500",
                            background:"unset!important"
                            }
                          }}
                          onClick={() => {
                            setTrainingPlanOpen(true);
                            setSelectedPlan(plan);
                            handleClose();
                          }}
                        >
                          View/Edit
                        </MenuItem>
                        <Divider sx={{ my: 1, mr: 1, ml: 1 }} />
                        <MenuItem
                          sx={{ 
                            fontSize: "15px", 
                            color: "#EC0B43",
                            ":hover": {
                                 fontWeight:"500",
                            background:"unset!important"
                            }
                          }}
                          onClick={() => {
                            setPlanToDelete(plan);
                            setDeleteDialogOpen(true);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </Card>
                  </Grid>
                </Fade>
              ))}
            </Grid>
          )}
          {isLoading && (
            <Box sx={{ 
              display: "flex", 
              justifyContent: "center", 
              alignItems: "center",
              height: "300px",
              '@keyframes pulse': {
                '0%': { opacity: 0.6 },
                '50%': { opacity: 1 },
                '100%': { opacity: 0.6 }
              },
              animation: 'pulse 1.5s infinite ease-in-out'
            }}>
              <CircularProgress sx={{ color: '#013B12' }} />
            </Box>
          )}
        </Box>
      </Paper>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setPlanToDelete(null);
        }}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            width: '400px',
            p: 1,
            animation: 'warningSlide 0.3s ease-out',
            "@keyframes warningSlide": {
              "0%": {
                transform: "translateY(-20px)",
                opacity: 0
              },
              "100%": {
                transform: "translateY(0)",
                opacity: 1
              }
            }
          }
        }}
      >
        <DialogTitle sx={{ 
          fontSize: '18px', 
          fontWeight: '600',
          color: '#525A65',
          textAlign: 'center',
          pt: 3,
        }}>
          Delete Workout
        </DialogTitle>
        <DialogContent sx={{ 
          textAlign: 'center', 
          pb: 3,
          "& svg": {
            animation: "warningPulse 1s ease-in-out infinite"
          },
          "@keyframes warningPulse": {
            "0%": {
              transform: "scale(1)"
            },
            "50%": {
              transform: "scale(1.05)"
            },
            "100%": {
              transform: "scale(1)"
            }
          }
        }}>
          <Typography sx={{ color: '#525A65', fontSize: '15px' }}>
            Are you sure you want to delete "{planToDelete?.name}"?
            <br />
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ 
          justifyContent: 'center',
          pb: 3,
          gap: 2,
        }}>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
              setPlanToDelete(null);
            }}
            sx={{
              color: '#525A65',
              fontSize: '15px',
              fontWeight: '500',
              textTransform: 'none',
              transition: 'all 0.3s ease',
              ":hover":{
                background:"unset",

                transform:"scale(1.05)"

              }
           
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            sx={{
              backgroundColor: '#EC0B43',
              color: 'white',
              fontSize: '15px',
              fontWeight: '500',
              textTransform: 'none',
              px: 3,
              transition: 'all 0.3s ease',":hover":{
                background:"#EC0B43",
                transform:"scale(1.05)"
              }
            
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {trainingPlanOpen && (
        <TrainingPlanModal
          open={trainingPlanOpen}
          plan={selectedPlan}
          handleClose={() => {
            setTrainingPlanOpen(false);
            setSelectedPlan(null);
          }}
          onSuccess={() => loadPlans()}
        />
      )}
    </>
  );
});
