import { useState, useEffect } from "react";
import { UploadCloud, Loader2 } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { supabase } from "../../services/supabase";
import { Box, Button, Typography } from "@mui/material";

export default function VideoUpload({ setVideos, videos, isSubmit, handleClose, setWaitingVideos, setVideoSubmitted }) {
  const [videoFile, setVideoFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileVideos, setFileVideos] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const truncateFilename = (filename) => {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) return filename.length > 34 ? filename.substring(0, 31) + '...' : filename;
    
    const name = filename.substring(0, lastDotIndex);
    const extension = filename.substring(lastDotIndex);
    
    return name.length > 34 ? `${name.substring(0, 31)}...${extension}` : filename;
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const files = event.target.files;
    
    if (files && files.length > 0) {
      const file = files[0];
      // Reset the input value to ensure the same file can be selected again
      event.target.value = '';
      setVideoFile(file);
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type.startsWith('video/')) {
        setVideoFile(file);
      } else {
        alert('Please drop a video file');
      }
    }
  };

  const uploadVideo = async () => {
    if (!videoFile) return alert("Please select a video!");
    if (loading) return;
    setLoading(true);
    
    const fileExtension = videoFile.name.split(".").pop() || "mp4";
    const uniqueId = uuidv4();
    const fileName = `custom_video_${uniqueId}`; 
  
    setVideos((prev) => [
      ...prev,
      {
        id: fileName,
        url: fileName,
        fileExtension: fileExtension,
        originalName: videoFile.name
      },
    ]);
  
    setFileVideos((prev) => [
      ...prev,
      {
        id: fileName,
        file: videoFile,
        fileExtension: fileExtension,
        originalName: videoFile.name
      },
    ]);
    setWaitingVideos((prev) => [
      ...prev,
      {
        id: fileName,
        file: videoFile,
        fileExtension: fileExtension,
        originalName: videoFile.name
      },
    ]);
  
    setLoading(false);
    setVideoFile(null);
  };

  const onDelete = () => {
    setVideos([]);
    setWaitingVideos([]);
    setFileVideos([]);
    setVideoFile(null);
    // Reset the file input
    const fileInput = document.getElementById('video-upload');
    if (fileInput) {
      fileInput.value = '';
    }
  };
  
  useEffect(() => {
    if (isSubmit) {
      const uploadCustomVideos = async () => {
        const updatedVideos = [...videos]; 
  
        for (const video of videos) {
          const matchedFile = fileVideos.find((fv) => fv.id === video.id);
          if (!matchedFile) continue; 
          
          const file = matchedFile.file;
          const fileExtension = file.name.split(".").pop() || "mp4";
          const fileName = `custom_video_${video.id}`;
  
          const { data, error } = await supabase.storage
            .from("custom_videos")
            .upload(fileName, file);
  
          if (error) {
            console.error("Upload error:", error.message);
            continue;
          }
  
          const { data: publicData } = supabase.storage
            .from("custom_videos")
            .getPublicUrl(fileName);
  
          updatedVideos.forEach((v) => {
            if (v.id === video.id) {
              v.url = publicData.publicUrl;
            }
          });
        }
        
        setVideos(updatedVideos);
      };
  
      uploadCustomVideos();
      setTimeout(() => {
        setVideoSubmitted(true);
      }, 1000);
    }
  }, [isSubmit]);
  
  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Typography variant="h6" sx={{ 
        fontSize: "14px", 
        fontWeight: 500, 
        color: "#525A65",
        mb: 1 
      }}>
        Upload your video
      </Typography>
      
      <Box sx={{
        position:"relative",
        border: "1px dashed",
        borderColor: isDragging ? "#02BD36" : "#DAD9D8",
        borderRadius: "12px",
        p: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isDragging ? "rgba(2, 189, 54, 0.05)" : "#fff",
        height: "120px",
        cursor: "pointer",
        transition: "all 0.2s ease",
        '&:hover': {
          borderColor: "#02BD36",
          backgroundColor: "rgba(2, 189, 54, 0.05)"
        }
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      >
        <input
          style={{ display: "none" }}
          type="file"
          accept="video/*"
          onChange={handleFileChange}
          id="video-upload"
        />
        <label htmlFor="video-upload" style={{ cursor: "pointer", textAlign: "center" }}>
          {
            videos.length===0&&
          
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 0H4C1.8048 0 0 1.8048 0 4V28C0 30.1952 1.8048 32 4 32H28C30.1952 32 32 30.1952 32 28V4C32 1.8048 30.1952 0 28 0ZM29.3312 27.0592C29.3312 27.8208 28.7616 28.3968 28 28.3968H4C3.2384 28.3968 2.6688 27.8272 2.6688 27.0592V16.7808V4C2.6688 3.2384 3.2384 2.6624 4 2.6624H28C28.7616 2.6624 29.3312 3.232 29.3312 4V14.2784V27.0592Z" fill="#CFCFCF"/>
            <path d="M15.9981 7.2832C11.1853 7.2832 7.28125 11.1872 7.28125 16C7.28125 20.8128 11.1853 24.7168 15.9981 24.7168C20.8109 24.7168 24.7148 20.8128 24.7148 16C24.7148 11.1872 20.8109 7.2832 15.9981 7.2832ZM13.6237 11.744C13.7965 11.744 13.9757 11.7824 14.1485 11.8784L19.7357 15.1104C20.0429 15.2896 20.2477 15.6096 20.2477 15.9936C20.2477 16.3776 20.0429 16.6976 19.7357 16.8768L14.1485 20.1152C13.4509 20.4928 12.6637 20.0128 12.6445 19.2192V12.768C12.6573 12.1728 13.1053 11.7568 13.6237 11.7504V11.744Z" fill="#CFCFCF"/>
          </svg>
          }
          <Typography sx={{ 
            fontSize: "12px", 
            color: "#525A65",
            display: "block",
            height:"100%",
          }}>
            {videos.length > 0&&  (
        <Box sx={{minHeight:"100px", width:"100%",borderRadius: '8px',position:"absolute",left:"0",top:"10px",overflow:"scroll",maxHeight:"75%"}}>
          {videos.map((video, index) => (
            video?.url.startsWith("custom_video")?
            <Box key={video.id} sx={{padding:"3px 15px",width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: index !== videos.length - 1 ? 1 : 0 }}>
              <Typography sx={{   
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "11px",
                  lg: "12px",
                  xl: "12px",
                },
                color: '#525A65',
                padding:"0",
                textAlign:"left",
                wordBreak: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                padding:"0",
              }}>
                {truncateFilename(video.originalName || `${video.id.replace('custom_video_', '')}.${video.fileExtension}`)}
              </Typography>
              <Box sx={{
                animation: 'successIconAnimation 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                '@keyframes successIconAnimation': {
                  '0%': {
                    transform: 'scale(0)',
                    opacity: 0
                  },
                  '100%': {
                    transform: 'scale(1)',
                    opacity: 1
                  }
                }
              }}>
                <svg width="16" height="12" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11649 0.000576902C8.89608 0.0161488 8.69119 0.120481 8.54839 0.288658L3.69467 5.94128L1.41915 3.58368C1.10095 3.25511 0.576304 3.24889 0.250342 3.56967C0.250342 3.56967 0.24879 3.57122 0.247238 3.57278C-0.0771712 3.89356 -0.08338 4.41522 0.23482 4.74223L3.13588 7.74762C3.45408 8.07619 3.97717 8.08553 4.30468 7.76631C4.32331 7.74918 4.34038 7.73049 4.3559 7.71181L9.79946 1.36779C10.0975 1.02054 10.0587 0.497322 9.71409 0.198341C9.548 0.0566359 9.3338 -0.0134379 9.11649 0.0021341V0.000576902Z" fill="#02BD36"/>
                </svg>
              </Box>
            </Box>:
             <Box key={video.id} sx={{padding:"3px 15px",width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: index !== videos.length - 1 ? 1 : 0 }}>
             <Typography sx={{   
               fontSize: {
                 xs: "11px",
                 sm: "11px",
                 md: "11px",
                 lg: "12px",
                 xl: "12px",
               },
               color: '#525A65',
               padding:"0",
               textAlign:"left",
               wordBreak: "break-word",
               overflowWrap: "break-word",
               whiteSpace: "normal",
               padding:"0",
             }}>
               {truncateFilename(video.url)}
             </Typography>
             <Box sx={{
               animation: 'successIconAnimation 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
               '@keyframes successIconAnimation': {
                 '0%': {
                   transform: 'scale(0)',
                   opacity: 0
                 },
                 '100%': {
                   transform: 'scale(1)',
                   opacity: 1
                 }
               }
             }}>
               <svg width="16" height="12" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11649 0.000576902C8.89608 0.0161488 8.69119 0.120481 8.54839 0.288658L3.69467 5.94128L1.41915 3.58368C1.10095 3.25511 0.576304 3.24889 0.250342 3.56967C0.250342 3.56967 0.24879 3.57122 0.247238 3.57278C-0.0771712 3.89356 -0.08338 4.41522 0.23482 4.74223L3.13588 7.74762C3.45408 8.07619 3.97717 8.08553 4.30468 7.76631C4.32331 7.74918 4.34038 7.73049 4.3559 7.71181L9.79946 1.36779C10.0975 1.02054 10.0587 0.497322 9.71409 0.198341C9.548 0.0566359 9.3338 -0.0134379 9.11649 0.0021341V0.000576902Z" fill="#02BD36"/>
               </svg>
             </Box>
           </Box>
          ))}
         {(videoFile) && 

            <Box sx={{padding:"3px 15px",width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Typography sx={{   
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "11px",
                  lg: "12px",
                  xl: "12px",
                },
                color: '#525A65',
                padding:"0",
                textAlign:"left",
                wordBreak: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                padding:"0",
              }}>
                {(videoFile &&videoFile.name) }
              </Typography>
              <Box sx={{
                animation: videoFile ? 'none' : 'spin 2s linear infinite',
                '@keyframes spin': {
                  '0%': { transform: 'rotate(0deg)' },
                  '100%': { transform: 'rotate(360deg)' }
                }
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8" cy="8" r="7" stroke="#525A65" strokeWidth="1.5"/>
                  <path d="M8 4.5V8L10.5 9.5" stroke="#525A65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </Box>
            </Box>
          }
        </Box>
      )}
  <Typography
  sx={{
    fontSize: {
      xs: "11px",  // Extra small screens
      sm: "11px",  // Small screens
      md: "11px",  // Medium screens
      lg: "12px",  // Large screens
      xl: "12px",  // Extra large screens
    },
    wordBreak: "break-word", // Breaks long words
    overflowWrap: "break-word", // Ensures wrapping
    whiteSpace: "normal", // Allows multiline 
    padding:"0",
  }}
>
      {videos.length===0&&(videoFile ? videoFile.name : "Drop your video or browse")}

      </Typography>
          </Typography>
        </label>
      </Box>

      
 
      <Box sx={{display:"flex", justifyContent:"space-between",mt:2}} >
        <Box sx={{display:"flex",gap:2,alignItems:"center"}}>

      <Box
          component="button"
          onClick={uploadVideo}
          disabled={loading || !videoFile}
          sx={{
            background: "#525A65",
            color: "#E3E2E0",
            border: "none",
            borderRadius: "16px",
            px: 1,
            py: 1,
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: 500,
            transition: "background-color 0.2s ease",
            '&:disabled': {
              backgroundColor: "#ccc",
              cursor: "not-allowed"
            },
            '&:hover:not(:disabled)': {
              backgroundColor: "#029c2e"
            }
          }}
          >
          {loading ? <Loader2 style={{ animation: "spin 1s linear infinite" }} /> : "Upload"}
          
        </Box>
        {videos.length>0&&(
          (videos.some(video => video.originalName === "youtubevideo") ? 
            ((videos.length-1) === 1 ? (videos.length-1) + " file" : (videos.length-1) + " files") :
            (videos.length === 1 ? videos.length + " file" : videos.length + " files")
          )
        )}
          </Box>

      {videos.length > 0 && (
        <Button 
          onClick={onDelete}
          sx={{
            minWidth: 'unset',
            padding: 0,
            transition: 'transform 0.2s ease',
            '&:hover': {
              transform: 'scale(1.1)',
              backgroundColor: 'transparent'
            }
          }}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z" fill="#EC0B43"/>
            <g clip-path="url(#clip0_3306_11904)">
              <path d="M6.47287 5L9.99648 8.52361L13.5201 5L15 6.47287L11.4764 9.99648L15 13.5201L13.5201 15L9.99648 11.4764L6.47287 15L5 13.5201L8.52361 9.99648L5 6.47287L6.47287 5Z" fill="#F1F3F4"/>
            </g>
            <defs>
              <clipPath id="clip0_3306_11904">
                <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
              </clipPath>
            </defs>
          </svg>
        </Button>
      )}
      </Box>
    </Box>
  );
}