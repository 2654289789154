import { PageContainer } from "../../components/PageContainer/PageContainer";
import { AthletesTable } from "../../components/AthletesTable/AthletesTable";
// import { CoachProfileCard } from "../../components/CoachProfileCard/CoachProfileCard";
// import { DashboardCalendarComponent } from "../../components/DashboardCalendar/dashboardCalendar";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
// import { CreateEventModal } from "../../components/CreateEventModal/CreateEventModal";
// import { getCalendarsByCoachId } from "../../services/query";
import { useSupabase } from "../../providers/AuthContextProvider";
import { useAuth } from "../../providers/AuthContextProvider";
import {
  Box,
  // Button,
  // Modal,
  // Typography,
} from "@mui/material";

export const DashboardComponent = ({ user }) => {
  const [athletes, setAthletes] = useState([]);
  // const [eventModalOpen, setEventModalOpen] = useState(false);
  // const [calendars, setCalendars] = useState([]);
  // const [open, setOpen] = useState(false);
  const supabase = useSupabase();
  // const handleClose = () => setOpen(false);

  // const reloadCalendars = () =>
  //   getCalendarsByCoachId(supabase, user.id).then((calendars) => {
  //     setCalendars(calendars.data);
  //   });

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   const from = params.get("from");
  //   const status = params.get("status");

  //   if (from === "otp" && status === "success") {
  //     setOpen(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!user) {
  //     return;
  //   }
  //   reloadCalendars();
  // }, [user]);

  if (!user) {
    return null;
  }

  return (
    <PageContainer>
      <Grid2 container spacing={2} sx={{ position: "relative" }}>
        <Grid2 xs={12}>
          <AthletesTable
            onAthletesLoad={(athletes) =>
              setAthletes(athletes.map((a) => a.athletes))
            }
          />
        </Grid2>
      </Grid2>
    </PageContainer>
  );
};

 export const SuccessIcon = () => {
   return (
     <svg
       width="90"
      height="90"
       viewBox="0 0 90 90"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
      <circle cx="45" cy="45" r="45" fill="#013B12" />
       <path
         fill-rule="evenodd"
         clip-rule="evenodd"
         d="M66.8721 23.8585C65.701 23.9408 64.6123 24.4923 63.8536 25.3814L38.0645 55.2619L25.9741 42.7993C24.2834 41.0625 21.4959 41.0295 19.764 42.7252C19.764 42.7252 19.7557 42.7335 19.7475 42.7417C18.0238 44.4374 17.9908 47.195 19.6815 48.9236L35.0955 64.8105C36.7862 66.5474 39.5655 66.5968 41.3057 64.9093C41.4047 64.8187 41.4954 64.72 41.5779 64.6212L70.5008 31.0858C72.0843 29.2502 71.8781 26.4844 70.0473 24.9039C69.1648 24.1549 68.0267 23.7844 66.8721 23.8667V23.8585Z"
         fill="#02BD36"
       />
     </svg>
   );
 };
