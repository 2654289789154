import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { 
  Box, 
  Paper, 
  Typography, 
  Grid, 
  IconButton, 
  Tooltip, 
  Chip,
  Divider,
  Card,
  CardContent,
  Modal,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Backdrop,
  Button,
  Avatar
} from "@mui/material";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { ProtectedAdmin } from "./ProtectedAdmin";
import { LoadingSpinner } from "./components/LoadingSpinner";
import PersonIcon from '@mui/icons-material/Person';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

export const Viewcoach = () => {
  const { id } = useParams();
  const supabase = useSupabase();
  const navigate = useNavigate();
  const [stats, setStats] = useState({
    numberOfClients: 0,
    lastDashboardAccess: null,
    numberOfExercises: 0,
    numberOfTrainingPlans: 0,
    totalAssignedTrainings: 0,
    currentReferrals: 0,
    currentPaidReferrals: 0,
    totalSubscriptions: 0,
    totalLifetimeReferrals: 0,
  });
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [coachDetails, setCoachDetails] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    type: '',
  });
  const [copySuccess, setCopySuccess] = useState({
    phone: false,
    email: false
  });
  
  // State for modal display
  const [openModal, setOpenModal] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  
  // Handle modal open
  const handleOpenModal = async (type) => {
    setOpenModal(type);
    setModalLoading(true);
    
    try {
      let data = [];
      
      switch(type) {
        case 'clients':
          const { data: clientsData, error: clientsError } = await supabase
            .from('coach_athletes')
            .select('*, athletes(full_name, email, phone_number)')
            .eq('coach_id', id);
          
          if (clientsError) throw clientsError;
          
          data = clientsData?.map(item => ({
            name: item.athletes?.full_name || ( item.athletes?.first_name + " " + item.athletes?.last_name) || 'Unknown',
            email: item.athletes?.email || '-',
            phone: item.athletes?.phone_number || '-',
          })) || [];
          break;
          
        case 'exercises':
          // First try to load all exercises for this coach
          const { data: exercisesData, error: exercisesError } = await supabase
            .from('exercises')
            .select('*')  // Select all fields to ensure we get data
            .eq('coach_id', id)
            .order('created_at', { ascending: false });
            
          
          if (exercisesError) throw exercisesError;
          
          // Log the first exercise to see its actual structure
          if (exercisesData && exercisesData.length > 0) {
          }
          
          // Map to needed format - check for both name and title fields
          data = exercisesData?.map(exercise => {
            // Log each exercise object to see what fields it has
            
            const exerciseName = 
              exercise.title || // Try title first (seems to be used in some cases)
              exercise.name ||  // Then try name
              'Unnamed Exercise';
              
            return {
              name: exerciseName,
              description: exercise.description || '',
              created_at: exercise.created_at
            };
          }) || [];
          
          // If no data found, try fetching with different casing for coach_id
          if (data.length === 0) {
            const { data: altExercisesData } = await supabase
              .from('exercises')
              .select('*');
              
            // Log all exercises to diagnose any issues
            
            if (altExercisesData && altExercisesData.length > 0) {
            }
            
            // Find exercises that might belong to this coach (case-insensitive)
            const matchingExercises = altExercisesData?.filter(ex => 
              ex.coach_id && ex.coach_id.toString().toLowerCase() === id.toString().toLowerCase()
            );
            
            if (matchingExercises?.length > 0) {
              data = matchingExercises.map(exercise => {
                const exerciseName = 
                  exercise.title || // Try title first
                  exercise.name ||  // Then try name
                  'Unnamed Exercise';
                  
                return {
                  name: exerciseName,
                  description: exercise.description || '',
                  created_at: exercise.created_at
                };
              });
            }
          }
          break;
          
        case 'plans':
          // First try to load all plans for this coach
          const { data: plansData, error: plansError } = await supabase
            .from('plans')
            .select('*')  // Select all fields to ensure we get data
            .eq('coach_id', id)
            .order('created_at', { ascending: false });
            
          
          if (plansError) throw plansError;
          
          // Map to needed format
          data = plansData?.map(plan => ({
            name: plan.name || 'Unnamed Plan',
            description: plan.description || '',
            created_at: plan.created_at
          })) || [];
          
          // If no data found, try fetching with different approach
          if (data.length === 0) {
            const { data: altPlansData } = await supabase
              .from('plans')
              .select('*');
              
            // Log all plans to diagnose any issues
            
            // Find plans that might belong to this coach (case-insensitive)
            const matchingPlans = altPlansData?.filter(plan => 
              plan.coach_id && plan.coach_id.toString().toLowerCase() === id.toString().toLowerCase()
            );
            
            if (matchingPlans?.length > 0) {
              data = matchingPlans.map(plan => ({
                name: plan.name || 'Unnamed Plan',
                description: plan.description || '',
                created_at: plan.created_at
              }));
            }
          }
          break;
          
        case 'referrals':
          const { data: referralCodeData, error: referralError } = await supabase
            .from('users')
            .select('referral_code')
            .eq('id', id)
            .single();
            
          if (referralError) throw referralError;
            
          if (referralCodeData?.referral_code) {
            const { data: referralsData, error: referralsDataError } = await supabase
              .from('users')
              .select('id, full_name, email, created_at') // Include id field
              .eq('referred_by', referralCodeData.referral_code);
              
            if (referralsDataError) throw referralsDataError;
            data = referralsData || [];
          }
          break;
      }
      
      setModalData(data);
      console.log(`Modal data for ${type}:`, data);
    } catch (error) {
      console.error(`Error fetching ${type} data:`, error);
    } finally {
      setModalLoading(false);
    }
  };
  
  // Handle modal close
  const handleCloseModal = () => {
    setOpenModal(null);
    setModalData([]);
  };

  // Render modal content based on type
  const renderModalContent = () => {
    if (modalLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress size={40} />
        </Box>
      );
    }
    
    if (modalData.length === 0) {
      return (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography color="text.secondary">No data available</Typography>
        </Box>
      );
    }
    
    switch(openModal) {
      case 'clients':
        return (
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {modalData.map((client, index) => (
              <ListItem key={index} divider={index < modalData.length - 1}>
                <ListItemText
                  primary={client.name}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="text.primary">
                        {client.email}
                      </Typography>
                      {client.phone && <Typography component="span" variant="body2" display="block">{client.phone}</Typography>}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        );
        
      case 'exercises':
        return (
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {modalData.map((exercise, index) => (
              <ListItem key={index} divider={index < modalData.length - 1}>
                <ListItemText
                  primary={exercise.name}
                  secondary={
                    <>
                      {exercise.description && (
                        <Typography component="span" variant="body2" color="text.secondary" sx={{ display: 'block' }}>
                          {exercise.description.length > 100 
                            ? `${exercise.description.substring(0, 100)}...` 
                            : exercise.description}
                        </Typography>
                      )}
                      <Typography component="span" variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
                        Created: {new Date(exercise.created_at).toLocaleDateString()}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        );
        
      case 'plans':
        return (
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {modalData.map((plan, index) => (
              <ListItem key={index} divider={index < modalData.length - 1}>
                <ListItemText
                  primary={plan.name}
                  secondary={
                    <>
                      {plan.description && (
                        <Typography component="span" variant="body2" color="text.secondary" sx={{ display: 'block' }}>
                          {plan.description.length > 100 
                            ? `${plan.description.substring(0, 100)}...` 
                            : plan.description}
                        </Typography>
                      )}
                      <Typography component="span" variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
                        Created: {new Date(plan.created_at).toLocaleDateString()}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        );
        
      case 'referrals':
        return (
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {modalData.map((referral, index) => (
              <ListItem 
                key={index} 
                divider={index < modalData.length - 1}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpenModal(null);
                  navigate(`/coach/${referral.id}`);
                }}
              >
                <ListItemText
                  primary={
                    <Typography 
                      component="span" 
                      variant="body1" 
                      color="primary"
                      sx={{ 
                        '&:hover': { 
                          textDecoration: 'underline' 
                        } 
                      }}
                    >
                      {referral.full_name}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="text.primary">
                        {referral.email}
                      </Typography>
                      <Typography component="span" variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
                        Joined: {new Date(referral.created_at).toLocaleDateString()}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        );
        
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchCoachDetails = async () => {
      setLoading(true);
      
      try {
        // Get coach basic info
        const { data: coachData, error: coachError } = await supabase
          .from('users')
          .select('first_name, last_name, email, phone_number, type, referred_by')
          .eq('id', id)
          .single();

        if (coachError) throw coachError;
        
        // Set initial coach details
        setCoachDetails(coachData);
        
        
        
        try {
          const { data: avatarUrl, error: avatarError } = await supabase
            .rpc('get_user_avatar', { user_id: id });
          if (!avatarError && avatarUrl) {
            setCoachDetails(prev => ({
              ...prev,
              avatar_url: avatarUrl
            }));
          } else {
            console.error("Could not fetch user avatar:", avatarError);
          }
        } catch (rpcError) {
          console.error("Error fetching user avatar:", rpcError);
        }

        if (coachData.referred_by) {
          const { data: referringCoachData } = await supabase
            .from('users')
            .select('id, full_name, referral_code')
            .eq('referral_code', coachData.referred_by)
            .single();
            
          if (referringCoachData) {
            setCoachDetails(prev => ({
              ...prev,
              referringCoach: {
                id: referringCoachData.id,
                name: referringCoachData.full_name
              }
            }));
          }
        }

        // Get number of clients
        const { count: clientCount } = await supabase
          .from('coach_athletes')
          .select('*', { count: 'exact' })
          .eq('coach_id', id);

        // Get last dashboard access
        const { data: userData } = await supabase
          .from('users')
          .select('last_dashboard_access')
          .eq('id', id)
          .single();

        // Get number of exercises
        const { count: exerciseCount } = await supabase
          .from('exercises')
          .select('*', { count: 'exact' })
          .eq('coach_id', id);

        // Get number of training plans
        const { count: planCount } = await supabase
          .from('plans')
          .select('*', { count: 'exact' })
          .eq('coach_id', id);

        // Get total assigned trainings
        const { data: assignedTrainingsCount } = await supabase
          .from('events')
          .select('plan_id')
          .eq('coach_id', id)
          .not('plan_id', 'is', null)
          .then(result => {
            // Get unique plan_ids
            const uniquePlanIds = [...new Set(result.data.map(event => event.plan_id))];
            return { data: uniquePlanIds.length };
          });

        // Get referral stats
        const { data: referralData } = await supabase
          .from('users')
          .select('referral_code')
          .eq('id', id)
          .single();

        const { count: totalReferrals } = await supabase
          .from('users')
          .select('*', { count: 'exact' })
          .eq('referred_by', referralData?.referral_code);

        setStats({
          numberOfClients: clientCount || 0,
          lastDashboardAccess: userData?.last_dashboard_access || null,
          numberOfExercises: exerciseCount || 0,
          numberOfTrainingPlans: planCount || 0,
          totalAssignedTrainings: assignedTrainingsCount || 0,
          currentReferrals: totalReferrals || 0,
          totalLifetimeReferrals: totalReferrals || 0,
        });
      } catch (error) {
        console.error("Error fetching coach data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchCoachDetails();
    }
  }, [id, supabase]);

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(coachDetails.email);
    setCopySuccess({ ...copySuccess, email: true });
    setTimeout(() => setCopySuccess({ ...copySuccess, email: false }), 2000);
  };

  const handleCopyPhone = () => {
    navigator.clipboard.writeText(coachDetails.phone_number ? `+${coachDetails.phone_number}` : '');
    setCopySuccess({ ...copySuccess, phone: true });
    setTimeout(() => setCopySuccess({ ...copySuccess, phone: false }), 2000);
  };

  const getAvatarUrl = (firstName, lastName) => {
    // placeholder URL for avatar
    return `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=0D8ABC&color=fff`;
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (user === undefined || user === null) {
    return <LoadingSpinner />;
  }

  if (!ProtectedAdmin(user)) {
    return <Navigate to="/" replace />;
  }

  const lastAccessTime = stats.lastDashboardAccess ? new Date(stats.lastDashboardAccess) : null;

  // URL'i bir CORS proxy üzerinden geçirin
  const avatarUrlWithProxy = coachDetails.avatar_url 
    ? `https://images.weserv.nl/?url=${encodeURIComponent(coachDetails.avatar_url)}`
    : null;

  return (
    <PageContainer>
      <Box 
        sx={{ 
          mb: 4,
          width: '100%',
          maxWidth: '1000px',
          mx: 'auto'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          mb: 3,
          gap: 2
        }}>
          <IconButton 
            onClick={() => navigate('/admin')}
            sx={{ 
              color: '#333',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.05)' }
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        <Typography 
            variant="h5" 
          sx={{
              fontWeight: 600,
              color: '#333',
            }}
          >
            Coach Profile
        </Typography>
        </Box>

        {/* Coach Profile Card */}
        <Card 
          elevation={0}
          sx={{ 
            mb: 3, 
            borderRadius: 2,
            border: '1px solid #eee',
            overflow: 'visible'
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'flex-start', sm: 'center' },
              gap: 2.5
            }}>
            <Box 
              sx={{ 
                  width: 60, 
                  height: 60, 
                borderRadius: '50%',
                overflow: 'hidden',
                  backgroundColor: '#f5f5f5',
                  flexShrink: 0
              }}
            >
              <Avatar 
                src={avatarUrlWithProxy || getAvatarUrl(coachDetails.first_name, coachDetails.last_name)}
                alt={`${coachDetails.first_name} ${coachDetails.last_name}`}
                sx={{ 
                  width: '100%',
                  height: '100%',
                  '& .MuiAvatar-img': {
                    objectFit: 'cover'
                  }
                }}
              />
            </Box>
              
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 0.5 }}>
                {coachDetails.first_name} {coachDetails.last_name}
              </Typography>
                
                <Box sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap',
                  gap: 1,
                  alignItems: 'center'
                }}>
                  {lastAccessTime && (
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5
                    }}>
                      {(() => {
                        if (!lastAccessTime) return null;
                        
                        const now = new Date();
                        const diffMinutes = Math.floor((now - lastAccessTime) / (1000 * 60));
                        const diffHours = Math.floor(diffMinutes / 60);
                        
                        let timeAgoText = '';
                        if (diffMinutes < 60) {
                          timeAgoText = `${diffMinutes}m ago`;
                        } else if (diffHours < 24) {
                          timeAgoText = `${diffHours}h ago`;
                        } else {
                          const days = Math.floor(diffHours / 24);
                          timeAgoText = `${days}d ago`;
                        }
                        
                        if (diffMinutes < 5) {
                          return (
                            <>
                              <Box
                                sx={{
                                  width: 8,
                                  height: 8,
                                  backgroundColor: '#02BD36',
                                  borderRadius: '50%',
                                  display: 'inline-block',
                                }}
                              />
                              <Typography 
                                variant="body2" 
                                sx={{ 
                                  fontSize: '0.8rem',
                                  color: '#02BD36',
                                  fontWeight: 500
                                }}
                              >
                                Online
                              </Typography>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <Box
                                sx={{
                                  width: 8,
                                  height: 8,
                                  backgroundColor: '#525A65',
                                  borderRadius: '50%',
                                  display: 'inline-block',
                                }}
                              />
                              <Typography 
                                variant="body2"
                                sx={{ 
                                  fontSize: '0.8rem',
                                  color: '#525A65'
                                }}
                              >
                                {timeAgoText}
              </Typography>
                            </>
                          );
                        }
                      })()}
                    </Box>
                  )}
                  
                  <Chip 
                    label={coachDetails.type || "Regular"} 
                    size="small"
                    sx={{ 
                      height: 22, 
                      fontSize: '0.7rem',
                      bgcolor: coachDetails.type === 'Premium' ? 'rgba(2, 189, 54, 0.1)' : '#f0f0f0',
                      color: coachDetails.type === 'Premium' ? '#02BD36' : '#666',
                      fontWeight: coachDetails.type === 'Premium' ? 600 : 400,
                    }}
                  />
                </Box>
              </Box>
            </Box>
            
            {/* Add referring coach information */}
            {coachDetails.referringCoach && (
              <Box sx={{ mt: 2, mb: -0.5 }}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: '#666',
                    fontSize: '0.75rem',
                    mb: 0.5
                  }}
                >
                  REFERRED BY
                </Typography>
                <Button
                  variant="text"
                  onClick={() => navigate(`/coach/${coachDetails.referringCoach.id}`)}
                  sx={{
                    fontSize: '0.875rem',
                    textTransform: 'none',
                    p: 0,
                    color: '#1976d2',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                    }
                  }}
                >
                  {coachDetails.referringCoach.name}
                </Button>
          </Box>
            )}
            
            <Divider sx={{ my: 2.5 }} />
            
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
            <Box>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: '#666',
                      fontSize: '0.75rem',
                      textTransform: 'uppercase',
                      letterSpacing: '0.5px',
                      mb: 0.5
                    }}
                  >
                Email
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                    gap: 1
              }}>
                <Typography 
                      variant="body2" 
                  sx={{
                        fontSize: '0.875rem',
                        color: '#333',
                    fontFamily: 'monospace',
                  }}
                >
                  {coachDetails.email}
                </Typography>
                <Tooltip title={copySuccess.email ? "Copied!" : "Copy email"}>
                  <IconButton 
                    onClick={handleCopyEmail}
                    size="small"
                    sx={{ 
                          p: 0.5,
                          color: copySuccess.email ? '#02BD36' : '#666',
                        }}
                      >
                        <ContentCopyIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
            <Box>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: '#666',
                      fontSize: '0.75rem',
                      textTransform: 'uppercase',
                      letterSpacing: '0.5px',
                      mb: 0.5
                    }}
                  >
                    Phone
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                    gap: 1
              }}>
                <Typography 
                      variant="body2" 
                  sx={{
                        fontSize: '0.875rem',
                        color: '#333',
                    fontFamily: 'monospace',
                  }}
                >
                  {coachDetails.phone_number ? `+${coachDetails.phone_number}` : 'Not provided'}
                </Typography>
                    {coachDetails.phone_number && (
                      <Tooltip title={copySuccess.phone ? "Copied!" : "Copy number"}>
                  <IconButton 
                    onClick={handleCopyPhone}
                    size="small"
                    sx={{ 
                            p: 0.5,
                            color: copySuccess.phone ? '#02BD36' : '#666',
                          }}
                        >
                          <ContentCopyIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                </Tooltip>
                    )}
              </Box>
            </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Stats Cards */}
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 2, 
            fontWeight: 600, 
            color: '#333',
            fontSize: '1rem'
          }}
        >
          Coach Activity
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Card 
              elevation={0}
              sx={{ 
                borderRadius: 2,
                border: '1px solid #eee',
                height: '100%',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                '&:hover': {
                  borderColor: '#1976d2',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }
              }}
              onClick={() => handleOpenModal('clients')}
            >
              <CardContent sx={{ p: 2 }}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  height: '100%',
                }}>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1
                  }}>
                    <PersonIcon sx={{ color: '#666', fontSize: 18, mr: 1 }} />
                    <Typography variant="body2" sx={{ color: '#666', fontSize: '0.75rem' }}>
                      CLIENTS
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: 600, my: 'auto' }}>
                    {stats.numberOfClients}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Card 
              elevation={0}
              sx={{ 
                borderRadius: 2,
                border: '1px solid #eee',
                height: '100%',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                '&:hover': {
                  borderColor: '#1976d2',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }
              }}
              onClick={() => handleOpenModal('exercises')}
            >
              <CardContent sx={{ p: 2 }}>
                <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
                  height: '100%',
                }}>
                  <Box sx={{ 
                    display: 'flex',
            alignItems: 'center',
                    mb: 1
                  }}>
                    <FitnessCenterIcon sx={{ color: '#666', fontSize: 18, mr: 1 }} />
                    <Typography variant="body2" sx={{ color: '#666', fontSize: '0.75rem' }}>
                      EXERCISES
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: 600, my: 'auto' }}>
                    {stats.numberOfExercises}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Card 
              elevation={0}
              sx={{ 
                borderRadius: 2,
                border: '1px solid #eee',
                height: '100%',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
            '&:hover': {
                  borderColor: '#1976d2',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }
              }}
              onClick={() => handleOpenModal('plans')}
            >
              <CardContent sx={{ p: 2 }}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  height: '100%',
                }}>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1
                  }}>
                    <EventNoteIcon sx={{ color: '#666', fontSize: 18, mr: 1 }} />
                    <Typography variant="body2" sx={{ color: '#666', fontSize: '0.75rem' }}>
                      TRAINING PLANS
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: 600, my: 'auto' }}>
                    {stats.numberOfTrainingPlans}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Card 
              elevation={0}
            sx={{ 
                borderRadius: 2,
                border: '1px solid #eee',
                height: '100%',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                '&:hover': {
                  borderColor: '#1976d2',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }
              }}
              onClick={() => handleOpenModal('referrals')}
            >
              <CardContent sx={{ p: 2 }}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  height: '100%',
                }}>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1
                  }}>
                    <GroupAddIcon sx={{ color: '#666', fontSize: 18, mr: 1 }} />
                    <Typography variant="body2" sx={{ color: '#666', fontSize: '0.75rem' }}>
                      REFERRALS
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: 600, my: 'auto' }}>
                    {stats.currentReferrals}
          </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
        {/* Last Activity */}
        <Card 
          elevation={0}
            sx={{ 
            mt: 3,
            borderRadius: 2,
            border: '1px solid #eee',
          }}
        >
          <CardContent sx={{ p: 2.5 }}>
            <Box sx={{ 
              display: 'flex',
              alignItems: 'center',
              mb: 1
            }}>
              <AccessTimeIcon sx={{ color: '#666', fontSize: 18, mr: 1 }} />
              <Typography variant="body2" sx={{ color: '#666', fontSize: '0.75rem' }}>
                LAST DASHBOARD ACCESS
              </Typography>
            </Box>
            <Typography 
              variant="body1" 
              sx={{ 
                fontWeight: 500,
                color: '#333',
                fontSize: '0.9rem'
              }}
            >
            {stats.lastDashboardAccess 
              ? (() => {
                  const now = new Date();
                  const lastAccess = new Date(stats.lastDashboardAccess);
                  const diffTime = Math.abs(now - lastAccess);
                  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
                  const diffMinutes = Math.floor(diffTime / (1000 * 60));

                  // Check if it's today
                  if (lastAccess.toDateString() === now.toDateString()) {
                    return `Today at ${lastAccess.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`;
                  }
                  // Check if it's yesterday
                  const yesterday = new Date(now);
                  yesterday.setDate(yesterday.getDate() - 1);
                  if (lastAccess.toDateString() === yesterday.toDateString()) {
                    return `Yesterday at ${lastAccess.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`;
                  }
                  // If less than 24 hours ago
                  if (diffHours < 24) {
                    return `${diffHours}h ago`;
                  }
                  // If less than 7 days ago
                  if (diffDays < 7) {
                    return `${diffDays}d ago`;
                  }
                  // If more than 7 days ago, show the date
                  return lastAccess.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  });
                })()
              : 'Never Accessed'}
          </Typography>
          </CardContent>
        </Card>
      </Box>
      
      {/* Modal for displaying details */}
      <Modal
        open={!!openModal}
        onClose={handleCloseModal}
        aria-labelledby="detail-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 500 },
          maxHeight: '80vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Box sx={{ 
            p: 2, 
            borderBottom: '1px solid #eee',
            backgroundColor: '#f8f8f8' 
          }}>
            <Typography id="detail-modal-title" variant="h6" component="h2">
              {openModal === 'clients' && 'Clients'}
              {openModal === 'exercises' && 'Exercises'}
              {openModal === 'plans' && 'Training Plans'}
              {openModal === 'referrals' && 'Referrals'}
            </Typography>
          </Box>
          
          <Box sx={{ 
            overflowY: 'auto',
            maxHeight: 'calc(80vh - 60px)' 
          }}>
            {renderModalContent()}
          </Box>
          
          <Box sx={{ 
            p: 2, 
            borderTop: '1px solid #eee',
            display: 'flex',
            justifyContent: 'flex-end' 
          }}>
            <Button
              onClick={handleCloseModal} 
              variant="outlined" 
              size="small"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </PageContainer>
  );
};