import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const AthleteDetail = ({ athlete, setModals }) => {
  if (!athlete) return null;

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      gap: '40px',
      fontFamily: 'Bricolage Grotesque'
    }}>
      <Box
        aria-label="close"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px'
        }}>
          <Typography sx={{ 
            fontSize: '16px', 
            color: '#0F0F0F',
            fontWeight: '600',
            lineHeight: '19px',
            fontFamily: 'Bricolage Grotesque'
          }}>
            {athlete.first_name} {athlete.last_name}
          </Typography>
          <Typography sx={{ 
            fontSize: '16px', 
            color: '#525A65',
            fontWeight: '400',
            lineHeight: '19px',
            fontFamily: 'Bricolage Grotesque'
          }}>
            Joined on {new Date(athlete.created_at).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric'
            })}
          </Typography>
        </Box>

        <svg
          onClick={() => setModals(prev => ({ ...prev, contact: false }))}
          style={{
            cursor: "pointer"
          }}
          width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32 18.3353L29.6647 16L24 21.6647L18.3353 16L16 18.3353L21.6647 24L16 29.6647L18.3353 32L24 26.3353L29.6647 32L32 29.6647L26.3353 24L32 18.3353Z" fill="#525A65"/>
          <circle cx="24" cy="24" r="24" fill="#EEEEEE"/>
          <path d="M17.1462 17.1428C17.7774 16.5117 18.8007 16.5117 19.4319 17.1428L30.8603 28.5712C31.4915 29.2024 31.4915 30.2257 30.8603 30.8569C30.2291 31.4881 29.2058 31.4881 28.5746 30.8569L17.1462 19.4285C16.515 18.7973 16.5151 17.774 17.1462 17.1428Z" fill="#525A65"/>
          <path d="M17.1428 30.8577C16.5117 30.2265 16.5117 29.2032 17.1428 28.572L28.5712 17.1436C29.2024 16.5124 30.2257 16.5124 30.8569 17.1436C31.4881 17.7748 31.4881 18.7981 30.8569 19.4293L19.4285 30.8577C18.7973 31.4889 17.774 31.4889 17.1428 30.8577Z" fill="#525A65"/>
        </svg>
      </Box>
      
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: "100%",
      }}>
        <Typography sx={{ 
          fontSize: {xs: "18px", md: '24px'},
          color: '#0F0F0F',
          fontWeight: '500',
          lineHeight: '29px',
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Bricolage Grotesque',
        }}>
          {athlete.email}
        </Typography>
        <Button
          onClick={() => window.location.href = `mailto:${athlete.email}`}
          sx={{
            maxWidth: '128px',
            width: "100%",
            height: '32px',
            color: '#525A65',
            backgroundColor: '#EEEEEE',
            borderRadius: '16px',
            padding: '8px 12px',
            textTransform: 'none',
            fontSize: '14px',
            border: '1px solid #525A65',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            '&:hover': {
              backgroundColor: '#E0E0E0'
            }
          }}
        >
          Send an email
        </Button>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}>
        <Typography sx={{ 
          fontSize: {xs: "18px", md: '24px'},
          color: '#0F0F0F',
          fontWeight: '500',
          lineHeight: '29px',
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Bricolage Grotesque'
        }}>
          {athlete.phone_number}
        </Typography>
        <Button
          onClick={() => window.location.href = `tel:${athlete.phone_number}`}
          sx={{
            width: 'fit-content',
            height: '32px',
            color: '#525A65',
            backgroundColor: '#EEEEEE',
            borderRadius: '16px',
            padding: '8px 12px',
            textTransform: 'none',
            fontSize: '14px',
            border: '1px solid #525A65',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            '&:hover': {
              backgroundColor: '#E0E0E0'
            }
          }}
        >
          Give a call
        </Button>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}>
        <Typography sx={{ 
          fontSize: '14px',
          color: '#525A65',
          fontWeight: '400',
          lineHeight: '19px',
          fontFamily: 'Bricolage Grotesque'
        }}>
          Timezone
        </Typography>
        <Typography sx={{ 
          fontSize: {xs: "18px", md: '24px'},
          color: '#0F0F0F',
          fontWeight: '500',
          lineHeight: '29px',
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Bricolage Grotesque'
        }}>
          {athlete.timezone || 'Not set'}
        </Typography>
      </Box>

      <Box 
        sx={{
          display: "flex",
          justifyContent: "end"
        }}>
        <Button
          onClick={() => {
            setModals(prev => ({ ...prev, contact: false }));
          }}
          sx={{
            width: 'fit-content',
            height: '49px',
            color: '#EC0B43',
            backgroundColor: '#FFF2F5',
            borderRadius: '20px',
            padding: '15px 22px',
            textTransform: 'none',
            fontSize: '14px',
            border: '1px solid #525A65',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            '&:hover': {
              backgroundColor: '#FFE4E4'
            }
          }}
        >
          Delete client
        </Button>
      </Box>
    </Box>
  );
};

export default AthleteDetail;
    