import { Delete, Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { sportsEmojis } from "../../routes/OnboardingForms/constants";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { upsertOnboardingForm } from "../../services/query";
import CloseIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Textarea from '@mui/joy/Textarea';
import { addnewquestionstyle, createupdatestyle, close } from "./style"
import WarningIcon from '@mui/icons-material/Warning';

export default function OnboardingFormModal({
  open,
  handleClose,
  onSuccess,
  formData,
}) {
  const [loading, setLoading] = useState(false);
  const [newFields, setNewFields] = useState([]);
  const [formName, setFormName] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [emoji, setEmoji] = useState(sportsEmojis[0]);
  const [openAlert, setOpenAlert] = useState(false);
  const [formNameError, setFormNameError] = useState(false);
  const [isSent,setSent]=useState(false)
  const [newFieldIndex, setNewFieldIndex] = useState(null);
  const lastFieldRef = useRef(null);

  useEffect(() => {
    if (open) {
      setNewFields(formData?.custom_questions || []);
      setFormName(formData?.title || "");
      setWelcomeMessage(formData?.welcome_message || "");
      setEmoji(formData?.icon || sportsEmojis[0]);
    }
  }, [open]);

  useEffect(() => {
    if (newFieldIndex !== null) {
      const timer = setTimeout(() => {
        setNewFieldIndex(null);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [newFieldIndex]);

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const { user } = useAuth();
  const supabase = useSupabase();

  const addField = () => {
    const newIndex = newFields.length;
    setNewFields([...newFields, { name: "", type: "text" }]);
    setNewFieldIndex(newIndex);
    setTimeout(() => {
      lastFieldRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 100);
  };

  const removeField = (index) => {
    setNewFields(newFields.filter((_, i) => i !== index));
  };

  const setFieldName = (index, name) => {
    setNewFields(
      newFields.map((field, i) => (i === index ? { ...field, name } : field))
    );
  };
  const handleSuccessClose = () => {
    setSent(false);
    handleClose();
    if (onSuccess) {
      onSuccess();
    }
  };
  const validateForm = () => {
    let isValid = true;
    
    // Validate form name
    if (!formName.trim()) {
      setFormNameError(true);
      isValid = false;
    } else {
      setFormNameError(false);
    }

    // Validate custom questions
    const hasEmptyQuestions = newFields.some(field => field.name.trim() === "");
    if (hasEmptyQuestions) {
      setOpenAlert(true);
      isValid = false;
    }

    return isValid;
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth keepMounted={false} sx={{
      ".MuiPaper-root": {
        borderRadius: "24px",
        width: "529px",
        minHeight: "525px",
        backgroundColor: "#FBFBFB",
      },
    }}>
                    <SuccessModal open={isSent} onClose={handleSuccessClose} text={!formData.title ? "created" : "updated"}/>

      <Box sx={{ 
        padding: { xs: "20px 16px", sm: "30px 20px", md: "40px 23px 16px" },
        minHeight: { xs: "75h", sm: "525px" },
        maxHeight: { xs: "100vh", sm: "670px" },
        display: "flex",
        flexDirection: "column"
      }}>
        <DialogTitle sx={{ 
          color: "#000000", 
          fontWeight: "600 !important", 
          fontSize: { xs: "14px", sm: "16px" }, 
          p: { xs: 0, sm: 1 },
          mb: { xs: 1, sm: 2 }
        }}>
          <Grid container spacing={0}>
            <Grid container alignItems="center" >
              <Grid item xs={8} fontWeight={"600"}>
                {!formData.title ? "Create a new form" : `Edit ${formData.title}`}
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Box onClick={handleClose} sx={{ 
                  cursor: "pointer",
                  padding: { xs: "4px", sm: "8px" },
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}>
                  <CloseIconComponent />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ flex: 1, overflow: "auto" }}>
          <DialogContentText sx={{ 
            mb: { xs: 1.5, sm: 2 }, 
            fontSize: { xs: "12px", sm: "14px" }, 
            color: "#000000", 
            fontWeight: "400 !important", 
            py: { xs: "8px", sm: "10px" }
          }}>
            Design a form tailored to your client's needs. Add a name for your form, include a welcome message, and add any questions you'd like your clients to answer
          </DialogContentText>
          <Box sx={{ mb: { xs: 2, sm: 3 } }} >
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: { xs: "16px", sm: "24px" },
              color: "#838F99",
              fontWeight: "400 !important",
              fontSize: { xs: "12px", sm: "14px" }
            }}>
              Form name
            </InputLabel>
            <TextField
              value={formName}
              required
              error={formNameError}
              helperText={formNameError ? "Form name is required" : ""}
              variant="outlined"
              placeholder="Form name"
              onChange={(e) => {
                setFormName(e.target.value);
                if (e.target.value.trim()) {
                  setFormNameError(false);
                }
              }}
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "20px",
                  border: "0px solid #DAD9D8"
                },
                ".MuiInputBase-input": {
                  fontSize: { xs: "11px", sm: "12px" },
                  padding: { xs: "12px 16px", sm: "14px 24px" },
                  color: "#525A65",
                  fontWeight: "400 !important"
                },
                "& .Mui-error": {
                  color: "#d32f2f"
                },
                "& .MuiFormHelperText-root": {
                  marginLeft: { xs: "16px", sm: "24px" },
                  fontSize: { xs: "10px", sm: "11px" }
                }
              }}
              fullWidth
            />
          </Box>
          <Box sx={{ mb: { xs: 2, sm: 3 } }} >
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: { xs: "16px", sm: "24px" },
              color: "#838F99",
              fontWeight: "400 !important",
              fontSize: { xs: "12px", sm: "14px" }
            }}>
              Welcome message
            </InputLabel>
            <TextField
              variant="outlined"
              type="textarea"
              value={welcomeMessage}
              placeholder="Type here..."
              onChange={(e) => setWelcomeMessage(e.target.value)}
              minRows={3}
              multiline
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "20px",
                  border: "0px solid #DAD9D8"
                },
                ".MuiInputBase-input": {
                  fontSize: { xs: "11px", sm: "12px" },
                  padding: { xs: "8px", sm: "10px" },
                  color: "#525A65",
                  fontWeight: "400 !important"
                }
              }}
              fullWidth
            />
          </Box>
          {newFields.length > 0 && (
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: { xs: "16px", sm: "24px" },
              color: "#838F99",
              fontSize: { xs: "12px", sm: "14px" }
            }}>
              Custom field
            </InputLabel>
          )}
          {newFields.map((field, index) => (
            <Box 
              ref={index === newFields.length - 1 ? lastFieldRef : null}
              display="flex" 
              sx={{ 
                mb: { xs: 2, sm: 3 },
                gap: { xs: 1, sm: 2 },
                transition: 'transform 1s ease',
                transform: newFieldIndex === index ? 'scale(1.05)' : 'scale(1)',
                '& .MuiInputBase-root': {
                  animation: newFieldIndex === index ? 'colorfulBorder 2s ease' : 'none'
                },
                '@keyframes colorfulBorder': {
                  '0%': { borderColor: '#02BD36', borderWidth: '2px' },
                  '25%': { borderColor: '#C9FE58', borderWidth: '2px' },
                  '50%': { borderColor: '#02BD36', borderWidth: '2px' },
                  '100%': { borderColor: '#DAD9D8', borderWidth: '1px' }
                }
              }} 
              key={index}
            >
              <TextField
                value={field.name}
                variant="outlined"
                placeholder="Type your question here..."
                required
                onChange={(e) => setFieldName(index, e.target.value)}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "20px",
                    border: "0px solid #DAD9D8"
                  },
                  ".MuiInputBase-input": {
                    fontSize: { xs: "11px", sm: "12px" },
                    padding: { xs: "12px 16px", sm: "14px 24px" },
                    color: "#525A65",
                    fontWeight: "400 !important"
                  }
                }}
                fullWidth
              />
              <IconButton 
                onClick={() => removeField(index)}
                sx={{
                  padding: { xs: "4px", sm: "8px" },
                  "& .MuiSvgIcon-root": {
                    fontSize: { xs: "20px", sm: "24px" }
                  }
                }}
              >
               <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z" fill="#EC0B43"/>
<g clip-path="url(#clip0_4065_954)">
<path d="M6.47287 5L9.99648 8.52361L13.5201 5L15 6.47287L11.4764 9.99648L15 13.5201L13.5201 15L9.99648 11.4764L6.47287 15L5 13.5201L8.52361 9.99648L5 6.47287L6.47287 5Z" fill="#F1F3F4"/>
</g>
<defs>
<clipPath id="clip0_4065_954">
<rect width="10" height="10" fill="white" transform="translate(5 5)"/>
</clipPath>
</defs>
</svg>

              </IconButton>
            </Box>
          ))}
        </DialogContent>
        <Box sx={{ 
          paddingLeft: { xs: "16px", sm: "23px" },
          paddingBottom: { xs: "8px", sm: "16px" }
        }}>
          <Button 
            onClick={addField} 
            sx={{
              ...addnewquestionstyle,
              fontSize: { xs: "11px", sm: "12px" },
              padding: { xs: "6px 7px", sm: "10px 20px" },

            }}
          >
            + Add a new question
          </Button>
        </Box>
        <DialogActions sx={{ 
          padding: { xs: "8px 16px", sm: "16px 23px" },
          justifyContent: "flex-end"
        }}>
          <Button
            disabled={loading}
            sx={{
              ...createupdatestyle,
              color: "#0F0F0F",
              background: "#EEEEEE",
              border: "1px solid #525A65",
              borderRadius: "20px",
              fontSize: { xs: "11px", sm: "12px" },
              padding: { xs: "8px 16px", sm: "10px 20px" },
              minWidth: { xs: "80px", sm: "100px" }
            }}
            onClick={async () => {
              setLoading(true);

              if (!validateForm()) {
                setLoading(false);
                return;
              }

              const id = formData.id ? { id: formData.id } : {};
              const payload = {
                ...id,
                user_id: user.id,
                custom_questions: newFields,
                title: formName,
                welcome_message: welcomeMessage,
                icon: emoji,
              };

              try {
                const { data } = await upsertOnboardingForm(supabase, payload);
                setLoading(false);

                if (data && data.length > 0) {
                  setSent(true);
                }
              } catch (error) {
                console.error("Error submitting form:", error);
                setLoading(false);
              }
            }}
          >
            {!formData.title ? "Create" : "Update"}
          </Button>
        </DialogActions>
      </Box>

      <Dialog open={openAlert} onClose={handleAlertClose}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <WarningIcon sx={{ color: 'orange', marginRight: 1 }} />
          Alert
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Please ensure that all custom questions are filled out before proceeding.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}




const SuccessModal = ({ open, onClose,text }) => (
  <Modal 
    open={open} 
    onClose={onClose}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "450px",
        height: "510px",
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        outline: "black 1px solid",
        borderRadius: "24px",
        width:{xs:"100%",sm:"50%",md:"40%",lg:"30%"},
      }}
    >
      <Typography sx={{ fontSize: "24px",color:"#525A65", mt:4}} variant="h6" component="h2">
      Another Step Forward!                
           </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" component="h2">
          <SuccessIcon />
        </Typography>
        <Typography
          sx={{
            mt: 5,
            fontSize: "14px",
            fontFamily: "'Bricolage Grotesque'",
            textAlign: "center",
            color:"#0F0F0F"
          }}
        >
Your form has been successfully {text}!                    <br />
          <p style={{ fontWeight: "500" }}>
          You may now send it to any of your clients for  <br /> gathering feedback and progress updates.                    </p>
        </Typography>
      </Box>
      <Button
        variant="outlined"
        sx={{
          width: "55px",
          height: "40px",
          borderRadius: "20px",
          padding: "20px 35px",
          fontFamily: "Bricolage Grotesque",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "19px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#EEEEEE",
          border: "1px,solid,#525A65",
          textTransform:"capitalize",
          color: "#0F0F0F",
        
        }}
        onClick={onClose}
      >
        Okay!
      </Button>
    </Box>
  </Modal>
);
export const SuccessIcon = () => {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45" cy="45" r="45" fill="#013B12" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M66.8721 23.8585C65.701 23.9408 64.6123 24.4923 63.8536 25.3814L38.0645 55.2619L25.9741 42.7993C24.2834 41.0625 21.4959 41.0295 19.764 42.7252C19.764 42.7252 19.7557 42.7335 19.7475 42.7417C18.0238 44.4374 17.9908 47.195 19.6815 48.9236L35.0955 64.8105C36.7862 66.5474 39.5655 66.5968 41.3057 64.9093C41.4047 64.8187 41.4954 64.72 41.5779 64.6212L70.5008 31.0858C72.0843 29.2502 71.8781 26.4844 70.0473 24.9039C69.1648 24.1549 68.0267 23.7844 66.8721 23.8667V23.8585Z"
        fill="#02BD36"
      />
    </svg>
  );
};
export const CloseIconComponent=()=>{
  return(
<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32 17.9173L29.6647 15.582L24 21.2468L18.3353 15.582L16 17.9173L21.6647 23.582L16 29.2468L18.3353 31.582L24 25.9173L29.6647 31.582L32 29.2468L26.3353 23.582L32 17.9173Z" fill="#525A65"/>
<circle cx="24" cy="24" r="24" fill="#EEEEEE"/>
<path d="M17.1423 16.7249C17.7735 16.0937 18.7968 16.0937 19.428 16.7249L30.8564 28.1533C31.4876 28.7844 31.4876 29.8078 30.8564 30.4389C30.2252 31.0701 29.2019 31.0701 28.5707 30.4389L17.1423 19.0105C16.5111 18.3794 16.5111 17.356 17.1423 16.7249Z" fill="#525A65"/>
<path d="M17.1428 30.4397C16.5117 29.8085 16.5117 28.7852 17.1428 28.154L28.5712 16.7256C29.2024 16.0945 30.2257 16.0945 30.8569 16.7256C31.4881 17.3568 31.4881 18.3801 30.8569 19.0113L19.4285 30.4397C18.7973 31.0709 17.774 31.0709 17.1428 30.4397Z" fill="#525A65"/>
</svg>)


}