import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Button, Container, Divider, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, InputLabel, Autocomplete, TextField, Skeleton, Fade, Grow } from '@mui/material';
import { ExpandMore, Add, MoreHoriz, AccessTime } from '@mui/icons-material';
import { useSupabase } from '../../providers/AuthContextProvider';
import { format } from 'date-fns';
import { useAuth } from '../../providers/AuthContextProvider';
import { getCoachAthletes, getOnboardingFormsByUserId } from '../../services/query';
import { SuccessIcon } from '../OnboardingFormModal/OnboardingFormModal';
import OnboardingFormModal from '../../components/OnboardingFormModal/OnboardingFormModal';
import ViewFormModal from '../../components/ViewFormModal/ViewFormModal';

const LoadingSkeleton = () => (
  <Container 
    sx={{ 
      maxWidth: '568px!important',
      pb: 5,
      height: 'auto',
      overflow: 'visible',
      padding: '0px!important'
    }}
  >
    <Box sx={{ mb: 5 }}>
      {/* Header Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ flex: 1 }}>
          <Skeleton 
            variant="text" 
            width="90%" 
            height={20} 
            sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)' }} 
          />
          <Skeleton 
            variant="text" 
            width="75%" 
            height={20} 
            sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)', mt: 1 }} 
          />
        </Box>
        <Skeleton 
          variant="rounded" 
          width={95} 
          height={36} 
          sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)', borderRadius: '16px' }} 
        />
      </Box>

      <Skeleton 
        variant="text" 
        width="100%" 
        height={2} 
        sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)', my: 3 }} 
      />

      {/* Form Cards */}
      <Box sx={{ mt: 4, mb: 5, height: 'auto', overflow: 'visible'}}>
        {[1, 2, 3].map((item) => (
          <Paper
            key={item}
            sx={{
              mb: 2,
              backgroundColor: 'rgba(241, 244, 241, 0.4)',
              borderRadius: '4px',
              height: '60px',
              position: 'relative',
              display: 'flex',
              border:"none",
              boxShadow:"none",
              alignItems: 'center',
              padding: '16px 24px',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              width: '100%'
            }}
          >
            <Box sx={{ width: '100%' }}>
              {/* Date and Status */}
              <Skeleton 
                variant="text" 
                width={100} 
                height={16} 
                sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }} 
              />
              
              {/* Form Title */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                <Skeleton 
                  variant="text" 
                  width="60%" 
                  height={22} 
                  sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }} 
                />
                <Skeleton 
                  variant="circular" 
                  width={24} 
                  height={24} 
                  sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }} 
                />
              </Box>
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  </Container>
);

const Forms = ({ athleteId }) => {
  const [loading, setLoading] = useState(true);
  const [formResponses, setFormResponses] = useState([]);
  const [forms, setForms] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newFormAnchorEl, setNewFormAnchorEl] = useState(null);
  const [sendFormOpen, setSendFormOpen] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showResendSuccess, setShowResendSuccess] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedFormResponse, setSelectedFormResponse] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [viewFormOpen, setViewFormOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [animationReady, setAnimationReady] = useState(false);
  const supabase = useSupabase();
  const { user } = useAuth();

  useEffect(() => {
    if (athleteId) {
      fetchFormData();
    }
    getAthletes();
    getForms();
    
    // Set animation ready after a short delay
    const timer = setTimeout(() => {
      setAnimationReady(true);
    }, 300);
    
    return () => clearTimeout(timer);
  }, [athleteId]);

  const getAthletes = async () => {
    const { data } = await getCoachAthletes(supabase, user.id);
    setAthletes(data || []);
  };

  const getForms = async () => {
    try {
      const { data: _forms } = await getOnboardingFormsByUserId(
        supabase,
        user.id
      );
      const sortedForms = (_forms || []).sort((a, b) => {
        if (a.icon === "🏌️‍♀️") return -1;
        if (b.icon === "🏌️‍♀️") return 1;
        return 0;
      });
      setForms(sortedForms);

    }catch(error){
      console.error(error)
    }
  };

  const handleSendForm = async () => {
    if (!selectedForm) {
      return;
    }

    setIsSending(true);
    try {
      // Create form response
      const { data: newResponse, error } = await supabase
        .from('onboarding_form_response')
        .insert({
          athlete_id: athleteId,
          coach_id: user.id,
          form_id: selectedForm.id,
          status: "sent",
          type: "questionnaire"
        })
        .select()
        .single();

      if (error) throw error;

      // Get the current athlete
      const currentAthlete = athletes.find(a => a.id === athleteId);

      // Store questions in onboarding_forms_question_responses
      const questionPromises = selectedForm.custom_questions.map(question => 
        supabase
          .from('onboarding_forms_question_responses')
          .insert({
            form_id: selectedForm.id,
            coach_id: user.id,
            athlete_id: athleteId,
            question: question.name,
            question_type: question.type || 'text',
            response: null,
            form_response_id: newResponse.id
          })
      );
      
      await Promise.all(questionPromises);

      // Send welcome message with correct phone number format
      const phone = currentAthlete.phone_number.startsWith("+")
        ? currentAthlete.phone_number
        : `+${currentAthlete.phone_number}`;

      await supabase.functions.invoke("welcome-wp", {
        body: {
          phone: phone,
          onboarding_url: `https://app.coachplus.club/question-form/${newResponse.id}`,
          coach_name: user.full_name,
          client_name: currentAthlete.full_name,
        },
      });

      // Reset states
      setSendFormOpen(false);
      setSelectedForm(null);
      setShowSuccess(true);
      
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
    window.location.reload();
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    // Prevent default scrolling behavior
    event.preventDefault();
    event.stopPropagation();
    
    // Use setTimeout to prevent immediate re-render which can cause flickering
    setTimeout(() => {
      setExpanded(isExpanded ? panel : false);
    }, 10);
  };

  const handleMenuClick = (event, formResponse) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedFormResponse(formResponse);
  };

  const handleNewFormMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setNewFormAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNewFormMenuClose = () => {
    setNewFormAnchorEl(null);
  };

  const handleDelete = async () => {
    if (!selectedFormResponse) {
      return;
    }
    
    setIsDeleting(true);
    try {
      // First delete any associated question responses
      const { error: questionResponsesError } = await supabase
        .from('onboarding_forms_question_responses')
        .delete()
        .eq('form_response_id', selectedFormResponse.id);

      if (questionResponsesError) {
        console.error("Error deleting question responses:", questionResponsesError);
        throw questionResponsesError;
      }

      // Then delete the form response
      const { error: formResponseError } = await supabase
        .from('onboarding_form_response')
        .delete()
        .eq('id', selectedFormResponse.id)
        .select();

      if (formResponseError) {
        console.error("Error deleting form response:", formResponseError);
        throw formResponseError;
      }

      // Refresh the form data
      await fetchFormData();
      setDeleteModalOpen(false);
      setSelectedFormResponse(null); // Clear the selected form response after successful deletion
    } catch (error) {
      console.error("Error in delete process:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleResend = async () => {
    if (!selectedFormResponse) return;
    
    setIsSending(true);
    try {
      // Get the current athlete
      const currentAthlete = athletes.find(a => a.id === athleteId);

      // Determine the URL based on form type or age
      // let formUrl;
      // if (selectedFormResponse.type) {
      //   // If type exists, use it to determine URL
      //   formUrl = selectedFormResponse.type === "questionnaire" ? "question-form" : "forms";
      // } else {
      //   // If no type, check if it's the oldest form
      //   const athleteForms = formResponses.filter(fr => fr.athlete_id === athleteId);
      //   const oldestForm = athleteForms.reduce((oldest, current) => {
      //     return new Date(current.created_at) < new Date(oldest.created_at) ? current : oldest;
      //   });
        
      //   formUrl = selectedFormResponse.id === oldestForm.id ? "forms" : "question-form";
      // }

      // Send welcome message with correct phone number format
      const phone = currentAthlete.phone_number.startsWith("+")
        ? currentAthlete.phone_number
        : `+${currentAthlete.phone_number}`;
        
      await supabase.functions.invoke("welcome-wp", {
        body: {
          phone: phone,
          onboarding_url: `https://app.coachplus.club/question-form/${selectedFormResponse.id}`,
          coach_name: user.full_name,
          client_name: currentAthlete.full_name,
        },
      });

      handleMenuClose();
      setShowResendSuccess(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const handleResendSuccessClose = () => {
    setShowResendSuccess(false);
  };

  const fetchFormData = async () => {
    try {
      setLoading(true);
      
      // Step 1: Get all form responses for this athlete
      const { data: formResponsesData, error: formResponsesError } = await supabase
        .from('onboarding_form_response')
        .select(`
          id,
          created_at,
          status,
          custom_responses,
          form_id,
          athlete_id,
          type
        `)
        .eq('athlete_id', athleteId)
        .order('created_at', { ascending: false });
      
      if (formResponsesError) throw formResponsesError;
      
      if (!formResponsesData || formResponsesData.length === 0) {
        setLoading(false);
        return;
      }
      
      // Step 2: Get form details for each form response
      const formDetailsPromises = formResponsesData.map(async (response) => {
        const { data: formData, error: formError } = await supabase
          .from('onboarding_forms')
          .select(`
            id,
            title,
            icon,
            custom_questions
          `)
          .eq('id', response.form_id)
          .single();
        
        if (formError) {
          console.error("Error fetching form details:", formError);
          return { ...response, form: null };
        }
        
        // Step 3: Always get question responses from onboarding_forms_question_responses
        const { data: questionResponses, error: questionResponsesError } = await supabase
          .from('onboarding_forms_question_responses')
          .select(`
            id,
            question,
            response
          `)
          .eq('form_response_id', response.id);
        
        if (questionResponsesError) {
          console.error("Error fetching question responses:", questionResponsesError);
          return { ...response, form: formData, questionResponses: [] };
        }
        
        return { ...response, form: formData, questionResponses };
      });
      
      const formDetails = await Promise.all(formDetailsPromises);
      setFormResponses(formDetails);
      
    } catch (error) {
      console.error("Error fetching form data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSuccess = (form) => {
    setFormOpen(false);
    getForms();
  };

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (formResponses.length === 0) {
    return (
      <Fade in={true} timeout={600}>
        <Container maxWidth="md">
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="body1">No forms available for this athlete.</Typography>
          </Paper>
        </Container>
      </Fade>
    );
  }

  return (
    <Container 
      sx={{ 
        maxWidth: '568px!important',
        pb: 5,
        height: 'auto',
        overflow: 'visible',
        padding: '0px!important'
      }}
    >
      <Fade in={animationReady} timeout={600}>
        <Box sx={{ mb: 5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography sx={{ fontWeight: 400,fontSize:{xs:"12px",sm:"14px"},color:"#000000" }}>
              Send forms and questionnaires to your client for regular <Box sx={{display:{xs:"none",sm:"unset"},margin:"0",padding:"0",height:"auto",width:"auto"}}> <br /></Box> check-ins, progress tracking, and feedback.
            </Typography>
            <Button 
              onClick={handleNewFormMenuClick}
              sx={{
                background: Boolean(newFormAnchorEl) ? "#FBFBFB" : "#EEEEEE",
                border: Boolean(newFormAnchorEl) ? "1px solid #D9D9D9" : "1px solid #525A65",
                borderRadius: "16px",
                color: Boolean(newFormAnchorEl) ? "#CECECE" : "#0F0F0F",
                fontSize: "12px",
                minWidth:"fit-content",
                padding: "6px 11px",
                fontWeight: "500",
                textTransform: "none",
                fontFamily: "Bricolage Grotesque",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                lineHeight: "20px",
                transition: "all 0.3s ease-in-out",
                '&:hover': {
                  background: Boolean(newFormAnchorEl) ? "#FBFBFB" : "#EEEEEE",
                  border: Boolean(newFormAnchorEl) ? "1px solid #D9D9D9" : "1px solid #525A65",
                  transform: "translateY(-2px)",
                }
              }}
            >
              +New form
            </Button>

            <Menu
              anchorEl={newFormAnchorEl}
              open={Boolean(newFormAnchorEl)}
              onClose={handleNewFormMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              TransitionComponent={Fade}
              transitionDuration={300}
              PaperProps={{
                sx: {
                  backgroundColor: "#F1F3F4",
                  borderRadius: "16px",
                  width: { xs: "150px", sm: "auto" },
                  "& .MuiMenuItem-root": {
                    fontSize: { xs: "14px", sm: "14px" },
                    color: "#525A65",
                    padding: { xs: "4px 5px", sm: "10px 8px" },
                    position: "relative",
                    margin: "0px 4px",
                    transition: "all 0.2s ease-in-out",
                    "&:not(:last-child)::after": {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: '8px',
                      right: '8px',
                      height: '1px',
                      backgroundColor: '#CECECE'
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      transform: "translateX(2px)",
                    },
                  },
                  animation: 'menuFadeIn 0.2s ease-out',
                  "@keyframes menuFadeIn": {
                    "0%": {
                      opacity: 0,
                      transform: "translateY(-10px)"
                    },
                    "100%": {
                      opacity: 1,
                      transform: "translateY(0)"
                    }
                  }
                },
              }}
            >
              <MenuItem 
                onClick={() => {
                  setSendFormOpen(true);
                  handleNewFormMenuClose();
                }}
                sx={{
                  position: 'relative',
                  fontSize: { xs: "14px", sm: "14px" },
                  color: "#525A65",
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: '16px',
                    right: '16px',
                    height: '1px',
                    backgroundColor: '#E3E2E0'
                  }
                }}
              >
                Send a form
              </MenuItem>
              <MenuItem 
                sx={{
                  fontSize: { xs: "14px", sm: "14px" },
                  color: "#525A65",
                }} 
                onClick={() => {
                  setFormOpen(true);
                  handleNewFormMenuClose();
                }}
              >
                Create a new form
              </MenuItem>
            </Menu>
          </Box>
          
          <Divider sx={{ my: 3 }} />
          
          <Box sx={{ 
            mt: 4,
            mb: 5,
            height: 'auto',
            overflow: 'visible'
          }}>
            {formResponses.map((formResponse, index) => (
              <Grow
              
                in={animationReady}
                timeout={600 + index * 100}
                key={formResponse.id}
                style={{ transformOrigin: '0 0 0' }}
              >
                <Accordion 
                  expanded={formResponse.status === "sent" ? false : expanded === `panel-${formResponse.id}`}
                  onChange={formResponse.status === "sent" ? undefined : handleAccordionChange(`panel-${formResponse.id}`)}
                  sx={{ 
                    mb: index === formResponses.length - 1 ? 5 : 2,
                    backgroundColor: '#F1F4F1',
                    boxShadow: 'none',
                    border: 'none',
                    borderRadius: '4px',
                    width: '100%',
                    position: 'relative',
                    zIndex: expanded === `panel-${formResponse.id}` ? 10 : 1,
                    '&:before': {
                      display: 'none',
                    },
                    '&.Mui-expanded': {
                      margin: index === formResponses.length - 1 ? '0 0 40px 0' : '0 0 16px 0',
                    },
                    '& .MuiCollapse-root': {
                      transition: 'none !important'
                    },
                    '& .MuiCollapse-wrapper': {
                      transition: 'none !important'
                    },
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.05)',
                    }
                  }}
                  disableGutters
                  TransitionProps={{ 
                    timeout: 0,
                    unmountOnExit: false,
                    addEndListener: () => {}
                  }}
                >
                  <AccordionSummary
                    expandIcon={formResponse.status === "sent" ? (
                      <IconButton
                        onClick={(e) => handleMenuClick(e, formResponse)}
                        size="small"
                        sx={{
                          padding: "2px",
                          "& .MuiSvgIcon-root": {
                            fontSize: "20px",
                          },
                        }}
                      >
                        <MoreHoriz sx={{ color: '#000' }} />
                      </IconButton>
                    ) : (
                      <ExpandMore sx={{ color: '#333' }} />
                    )}
                    aria-controls={`panel-${formResponse.id}-content`}
                    id={`panel-${formResponse.id}-header`}
                    onClick={(e) => {
                      if (formResponse.status === "sent") {
                        e.preventDefault();
                        e.stopPropagation();
                        setViewFormOpen(formResponse.form);
                      }
                    }}
                    sx={{
                      padding: '16px 24px',
                      height: '60px',
                      '& .MuiAccordionSummary-content': {
                        margin: 0
                      },
                      cursor: formResponse.status === "sent" ? 'pointer' : 'pointer'
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Typography sx={{ 
                        color: formResponse.status === "sent" ? '#999999' : '#02BD36', 
                        fontWeight: 500, 
                        fontSize: '12px',
                        margin: "0px",
                        padding: "0"
                      }}>
                        {formResponse.status === "sent" ? (
                          "Not filled yet"
                        ) : formResponse.status === "completed" ? (
                          formResponse.updated_at ? 
                            format(new Date(formResponse.updated_at), 'MMMM dd, yyyy') :
                            format(new Date(formResponse.created_at), 'MMMM dd, yyyy')
                        ) : (
                          formResponse.created_at && format(new Date(formResponse.created_at), 'MMMM dd, yyyy')
                        )}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 500, color: '#525A65', mt: 0.5, fontSize:"14px" }}>
                          {formResponse.form?.title || 'Untitled Form'}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  
                  <AccordionDetails sx={{ padding: '0 24px 24px 24px',mt:3}}>
                    {formResponse.custom_responses && Object.keys(formResponse.custom_responses).length > 0 ? (
                      // Always display custom_responses from onboarding_form_response if available
                      <Box>
                        {Object.entries(formResponse.custom_responses).map(([key, value], index) => (
                          <Box key={key} sx={{ mb: 3}}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 400, color: '#838F99',fontSize:"10px", mb: 1 }}>
                              Question ({index + 1})
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: 400, color: '#525A65', mb: 1,fontSize:"12px"}}>
                              {key}
                            </Typography>
                            <Typography 
                              variant="body1" 
                              sx={{ 
                                px:2,
                                py:1,
                                marginLeft:"3px", 
                                backgroundColor: '#E9FFCE', 
                                borderRadius: '12px',
                                
                                color: '#0F0F0F'
                              }}
                            >
                              {value}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    ) : formResponse.questionResponses && formResponse.questionResponses.length > 0 ? (
                      // If no custom_responses, display question responses from onboarding_forms_question_responses
                      <Box>
                        {formResponse.questionResponses.map((item, index) => (
                          <Box key={item.id} sx={{ mb: 3 }}>
                            <Typography  sx={{ fontWeight: 400, color: '#838F99',fontSize:"10px", mb: 1 }}>
                              Question ({index + 1})
                            </Typography>
                            <Typography  sx={{ fontWeight: 500, color: '#525A65',fontSize:"12px", mb: 1 }}>
                              {item.question}
                            </Typography>
                            <Typography 
                              sx={{ 
                                px:2,
                                py:1,
                                marginLeft:"3px", 
                                backgroundColor: '#E9FFCE', 
                                borderRadius: '12px',
                                
                                color: '#0F0F0F'
                              }}
                            >
                              {item.response || 'No response'}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      // Fallback for other cases
                      <Typography>No response data available</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grow>
            ))}
          </Box>
        </Box>
      </Fade>

      <Dialog
        open={sendFormOpen}
        onClose={() => setSendFormOpen(false)}
        fullWidth
        TransitionComponent={Fade}
        transitionDuration={400}
        PaperProps={{
          sx: {
            borderRadius: { xs: "16px", sm: "24px" },
            width: { xs: "95%", sm: "90%", md: "529px" },
            maxWidth: "529px",
            backgroundColor: "#FBFBFB",
            padding: { xs: "16px", sm: "24px", md: "27px" },
            margin: { xs: "8px", sm: "16px", md: "32px" },
            animation: 'dialogFadeIn 0.4s ease',
            "@keyframes dialogFadeIn": {
              "0%": {
                opacity: 0,
                transform: "translateY(20px)"
              },
              "100%": {
                opacity: 1,
                transform: "translateY(0)"
              }
            },
            "& .MuiDialogTitle-root": {
              padding: { xs: "8px 0", sm: "12px 0" }
            },
            "& .MuiDialogContent-root": {
              padding: { xs: "16px 0", sm: "24px 0" }
            },
            "& .MuiDialogActions-root": {
              padding: { xs: "8px 0", sm: "16px 0" }
            }
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 0,
            paddingBottom: { xs: 1, sm: 2 },
          }}
        >
          <Typography
            variant="subtitle"
            sx={{
              color: "#000000",
              fontWeight: "600 !important",
              fontSize: { xs: "16px", sm: "16px" },
            }}
          >
            Send form to client
          </Typography>
          <IconButton
            onClick={() => setSendFormOpen(false)}
            sx={{ 
              padding: 0,
              "& svg": {
                width: { xs: "36px", sm: "48px" },
                height: { xs: "36px", sm: "48px" }
              }
            }}
          >
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M32 18.3353L29.6647 16L24 21.6647L18.3353 16L16 18.3353L21.6647 24L16 29.6647L18.3353 32L24 26.3353L29.6647 32L32 29.6647L26.3353 24L32 18.3353Z" fill="#525A65"/>
              <circle cx="24" cy="24" r="24" fill="#EEEEEE"/>
              <path d="M17.1423 17.1428C17.7735 16.5117 18.7968 16.5117 19.428 17.1428L30.8564 28.5712C31.4876 29.2024 31.4876 30.2257 30.8564 30.8569C30.2252 31.4881 29.2019 31.4881 28.5707 30.8569L17.1423 19.4285C16.5111 18.7973 16.5111 17.774 17.1423 17.1428Z" fill="#525A65"/>
              <path d="M17.1428 30.8577C16.5117 30.2265 16.5117 29.2032 17.1428 28.572L28.5712 17.1436C29.2024 16.5124 30.2257 16.5124 30.8569 17.1436C31.4881 17.7748 31.4881 18.7981 30.8569 19.4293L19.4285 30.8577C18.7973 31.4889 17.774 31.4889 17.1428 30.8577Z" fill="#525A65"/>
            </svg>
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          <Typography
            sx={{
              color: "#000000",
              fontSize: { xs: "12px", sm: "14px" },
              marginY: 3,
            }}
          >
            Send forms and questionnaires to clients for regular check-ins,
            progress tracking, and feedback.
          </Typography>

          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{
                color: "#838F99",
                fontSize: { xs: "9px", sm: "10px" },
                marginBottom: 1,
                paddingLeft: "10px",
              }}
            >
              Client name
            </Typography>
            <Typography
              sx={{
                color: "#525A65",
                fontSize: "12px",
                padding: "15px 16px",
                backgroundColor: "#F0F0F0",
                borderRadius: "20px",
                border: "1px solid #E3E2E0",
                fontWeight: "bold",
                opacity: 0.7,
                cursor: "not-allowed",
              }}
            >
              {athletes.find(a => a.id === athleteId)?.full_name || "Loading..."}
            </Typography>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{
                color: "#838F99",
                fontSize: { xs: "9px", sm: "10px" },
                marginBottom: 1,
                paddingLeft: "10px",
              }}
            >
              Select Form
            </Typography>
            <Autocomplete
              options={forms}
              value={selectedForm}
              onChange={(event, newValue) => {
                setSelectedForm(newValue);
              }}
              getOptionLabel={(option) => option.title || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a form"
                  sx={{
                    ".MuiInputBase-root": {
                      borderRadius: "20px",
                      backgroundColor: "#FFFFFF",
                    },
                    ".MuiInputBase-input": {
                      fontSize: { xs: "11px", sm: "12px" },
                      padding: { xs: "10px 16px", sm: "14px 24px" },
                      color: "#525A65",
                      fontWeight: "400 !important",
                    },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Typography sx={{ fontSize: { xs: "11px", sm: "12px" }, color: "#525A65" }}>
                    {option.title}
                  </Typography>
                </Box>
              )}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  sx={{
                    borderRadius: "16px",
                    backgroundColor: "#FBFBFB",
                    marginTop: "4px",
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    padding: { xs: "8px 16px", sm: "10px 24px" },
                    borderBottom: "1px solid #CECECE",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  },
                },
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ padding: 0, marginTop: 2 }}>
          <Button
            onClick={handleSendForm}
            disabled={isSending || !selectedForm}
            sx={{
              marginLeft: "auto",
              color: "#02BD36",
              border: "1px solid #02BD36",
              borderRadius: "20px",
              textTransform: "none",
              padding: { xs: "8px 14px", sm: "11px 18px" },
              fontSize: { xs: "12px", sm: "14px" },
              "&:hover": {
                backgroundColor: "transparent",
                opacity: 0.8,
              },
            }}
          >
            {isSending ? (
              <CircularProgress size={20} sx={{ color: "#02BD36", mr: 1 }} />
            ) : null}
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showSuccess}
        onClose={handleSuccessClose}
        TransitionComponent={Fade}
        transitionDuration={400}
        PaperProps={{
          sx: {
            borderRadius: "24px",
            padding: "24px",
            maxWidth: "450px",
            width: "90%",
            animation: 'successPop 0.5s ease',
            "@keyframes successPop": {
              "0%": {
                opacity: 0,
                transform: "scale(0.9)"
              },
              "70%": {
                transform: "scale(1.05)"
              },
              "100%": {
                opacity: 1,
                transform: "scale(1)"
              }
            }
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography 
            sx={{ 
              fontSize: { xs: "20px", sm: "24px" },
              color: "#525A65", 
              mt: { xs: 2, sm: 4 }
            }} 
            variant="h6" 
            component="h2"
          >
            Well Done!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              py: { xs: 2, sm: 3 }
            }}
          >
            <Typography variant="h6" component="h2">
              <SuccessIcon />
            </Typography>
            <Typography
              sx={{
                mt: { xs: 3, sm: 5 },
                fontSize: { xs: "12px", sm: "14px" },
                fontFamily: "'Bricolage Grotesque'",
                textAlign: "center",
                color: "#0F0F0F",
                px: { xs: 1, sm: 2 }
              }}
            >
              Your form has been sent to your client.  <br />
              <p style={{ fontWeight: "500" }}>
                You will receive a notification once they have <br /> completed it.
              </p>
            </Typography>
          </Box>
          <Button
            variant="outlined"
            sx={{
              minWidth: "55px",
              height: { xs: "36px", sm: "40px" },
              borderRadius: "20px",
              padding: { xs: "16px 28px", sm: "20px 35px" },
              fontFamily: "Bricolage Grotesque",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: { xs: "12px", sm: "14px" },
              lineHeight: "19px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#EEEEEE",
              border: "1px solid #525A65",
              textTransform: "capitalize",
              color: "#0F0F0F",
              mb: { xs: 2, sm: 3 }
            }}
            onClick={handleSuccessClose}
          >
            Okay!
          </Button>
        </Box>
      </Dialog>

      <OnboardingFormModal
        open={!!formOpen}
        setOpen={setFormOpen}
        handleClose={() => setFormOpen(false)}
        formData={formOpen === true ? {} : formOpen}
        onSuccess={handleFormSuccess}
      />

      <ViewFormModal 
        open={!!viewFormOpen}
        selectedForm={selectedFormResponse?.form}
        handleClose={() => setViewFormOpen(false)}
        formData={viewFormOpen}
        hideSendToClient={true}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Fade}
        transitionDuration={300}
        PaperProps={{
          sx: {
            backgroundColor: "#F1F3F4",
            borderRadius: "16px",
            width: { xs: "150px", sm: "180px" },
            animation: 'menuFadeIn 0.2s ease-out',
            "@keyframes menuFadeIn": {
              "0%": {
                opacity: 0,
                transform: "translateY(-10px)"
              },
              "100%": {
                opacity: 1,
                transform: "translateY(0)"
              }
            },
            "& .MuiMenuItem-root": {
              fontSize: { xs: "14px", sm: "14px" },
              color: "#525A65",
              padding: { xs: "4px 5px", sm: "10px 8px" },
              position: "relative",
              margin: "0px 4px",
              transition: "all 0.2s ease-in-out",
              "&:not(:last-child)::after": {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '8px',
                right: '8px',
                height: '1px',
                backgroundColor: '#CECECE'
              },
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                transform: "translateX(2px)",
              },
            },
          },
        }}
      >
        <MenuItem onClick={() => {
          setViewFormOpen(selectedFormResponse?.form);
          handleMenuClose();
        }}>
          View
        </MenuItem>
        <MenuItem onClick={handleResend}>
          Resend
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteModalOpen(true);
            handleMenuClose();
          }}
          sx={{
            color: "#EC0B43 !important",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        TransitionComponent={Fade}
        transitionDuration={400}
        PaperProps={{
          sx: {
            borderRadius: "24px",
            padding: "24px",
            maxWidth: "450px",
            width: "90%",
            animation: 'dialogFadeIn 0.4s ease',
            "@keyframes dialogFadeIn": {
              "0%": {
                opacity: 0,
                transform: "translateY(20px)"
              },
              "100%": {
                opacity: 1,
                transform: "translateY(0)"
              }
            }
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography 
            sx={{ 
              fontSize: { xs: "20px", sm: "24px" },
              color: "#525A65", 
              mt: { xs: 2, sm: 4 }
            }} 
            variant="h6" 
            component="h2"
          >
            Are you sure?
          </Typography>
          <Typography
            sx={{
              mt: { xs: 3, sm: 5 },
              fontSize: { xs: "12px", sm: "14px" },
              fontFamily: "'Bricolage Grotesque'",
              textAlign: "center",
              color: "#0F0F0F",
              px: { xs: 1, sm: 2 }
            }}
          >
            This action cannot be undone. <strong>{selectedFormResponse?.form?.title || 'Untitled Form'}</strong> will be permanently deleted.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
            <Button
              variant="outlined"
              onClick={() => setDeleteModalOpen(false)}
              sx={{
                minWidth: "55px",
                height: { xs: "36px", sm: "40px" },
                borderRadius: "20px",
                padding: { xs: "16px 28px", sm: "20px 35px" },
                fontFamily: "Bricolage Grotesque",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "12px", sm: "14px" },
                lineHeight: "19px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#EEEEEE",
                border: "1px solid #525A65",
                textTransform: "capitalize",
                color: "#0F0F0F",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              disabled={isDeleting}
              sx={{
                minWidth: "55px",
                height: { xs: "36px", sm: "40px" },
                borderRadius: "20px",
                padding: { xs: "16px 28px", sm: "20px 35px" },
                fontFamily: "Bricolage Grotesque",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "12px", sm: "14px" },
                lineHeight: "19px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#EC0B43",
                textTransform: "capitalize",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#EC0B43",
                  opacity: 0.9,
                },
                "&:disabled": {
                  backgroundColor: "#EC0B43",
                  opacity: 0.5,
                },
              }}
            >
              {isDeleting ? (
                <CircularProgress size={20} sx={{ color: "#FFFFFF", mr: 1 }} />
              ) : null}
              Delete
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={showResendSuccess}
        onClose={handleResendSuccessClose}
        TransitionComponent={Fade}
        transitionDuration={400}
        PaperProps={{
          sx: {
            borderRadius: "24px",
            padding: "24px",
            maxWidth: "450px",
            width: "90%",
            animation: 'successPop 0.5s ease',
            "@keyframes successPop": {
              "0%": {
                opacity: 0,
                transform: "scale(0.9)"
              },
              "70%": {
                transform: "scale(1.05)"
              },
              "100%": {
                opacity: 1,
                transform: "scale(1)"
              }
            }
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography 
            sx={{ 
              fontSize: { xs: "20px", sm: "24px" },
              color: "#525A65", 
              mt: { xs: 2, sm: 4 }
            }} 
            variant="h6" 
            component="h2"
          >
            Form Resent!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              py: { xs: 2, sm: 3 }
            }}
          >
            <Typography variant="h6" component="h2">
              <SuccessIcon />
            </Typography>
            <Typography
              sx={{
                mt: { xs: 3, sm: 5 },
                fontSize: { xs: "12px", sm: "14px" },
                fontFamily: "'Bricolage Grotesque'",
                textAlign: "center",
                color: "#0F0F0F",
                px: { xs: 1, sm: 2 }
              }}
            >
              The form has been resent to your client. <br />
              <p style={{ fontWeight: "500" }}>
                You will receive a notification once they have <br /> completed it.
              </p>
            </Typography>
          </Box>
          <Button
            variant="outlined"
            sx={{
              minWidth: "55px",
              height: { xs: "36px", sm: "40px" },
              borderRadius: "20px",
              padding: { xs: "16px 28px", sm: "20px 35px" },
              fontFamily: "Bricolage Grotesque",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: { xs: "12px", sm: "14px" },
              lineHeight: "19px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#EEEEEE",
              border: "1px solid #525A65",
              textTransform: "capitalize",
              color: "#0F0F0F",
              mb: { xs: 2, sm: 3 }
            }}
            onClick={handleResendSuccessClose}
          >
            Okay!
          </Button>
        </Box>
      </Dialog>
    </Container>
  );
};

export default Forms; 