import React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Box,
  Typography,
} from "@mui/material";

const SuccessIcon = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="50" fill="#013B12"/>
    <path d="M71.6665 33.75L41.6665 63.75L28.3332 50.4167" stroke="#02BD36" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const SuccessDialog = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: "24px",
          padding: "0",
          width: "529px",
          height: "580px",
          background: "#FBFBFB",
          position: "relative",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 20px",
          height: "100%",
          position: "relative",
        }}
      >
        
        <Box sx={{ 
          marginBottom: "30px", 
          display: "flex", 
          justifyContent: "center",
          flexDirection:"column",
          alignItems:"center",  
          marginTop: "60px"
        }}>
               <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 600,
            color: "#525A65",
            fontFamily: "'Bricolage Grotesque', sans-serif",
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          Nicely Done!
        </Typography>
          <SuccessIcon />
        </Box>

     

        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            color: "#0F0F0F",
            fontFamily: "'Bricolage Grotesque', sans-serif",
            textAlign: "center",
            maxWidth: "393px",
            marginBottom: "50px",
            lineHeight: "19px",
          }}
        >
          An exercise has been successfully assigned to <Box sx={{width:"auto",height:"auto",display:{xs:"none",sm:"unset"}}}> <br /></Box>  your clients' calendar.<Box sx={{width:"auto",height:"auto",display:{xs:"none",sm:"unset"}}}> <br /></Box> <Typography sx={{mt:2}}></Typography>  They will receive an automatic WhatsApp <Box sx={{width:"auto",height:"auto",display:{xs:"none",sm:"unset"}}}> <br /></Box> message for feedback on the night of the training day.
        </Typography>

        <Box 
          sx={{ 
            display: "flex", 
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={onClose}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px 22px",
              background: "#EEEEEE",
              border: "1px solid #525A65",
              borderRadius: "20px",
              width: "80px",
              height: "48px",
              fontSize: "16px",
              fontWeight: 500,
              color: "#0F0F0F",
              fontFamily: "'Bricolage Grotesque', sans-serif",
              textTransform: "none",
            }}
          >
            Okay!
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}; 