import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
  Snackbar,
  Alert,
  Skeleton,
  Grow,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { supabase } from "../../services/supabase";
import { deleteSBEvent, deletePlanClient } from "../../services/query";
import { useAuth } from "../../providers/AuthContextProvider";
import { useCalendar } from "../../providers/CalendarProvider";
import { buttonStyle, MenuIcon } from "./style";
import { AddNewTraining } from "./AddNewTrainnig";
import { ViewExerciseAndPlan } from "./ViewExerciseAndPlan";
import CircularProgress from "@mui/material/CircularProgress";
import { ViewFeedback } from "./ViewFeedback";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DoneIcon from '@mui/icons-material/Done';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import Chip from '@mui/material/Chip';
import { SuccessDialog } from "./SuccessDialog";
import AssignActivityModal from "./AssignActivityModal";
import { TrainingPlanRoute } from "../../routes/TrainingPlan/TrainingPlan.route";
import { ExerciseList } from "../../routes/TrainingPlan/ExerciseList";

const getStatus = (eventStatus, eventDate) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const eventDateObj = new Date(eventDate);
  eventDateObj.setHours(0, 0, 0, 0);

  // If no status and future date, show as upcoming
  if (!eventStatus || eventStatus === null) {
    if (eventDateObj >= today) {
      return { label: "Upcoming", color: "#FBFBFB" };
    }
    return { label: "No feedback yet", color: "#FBFBFB" };
  }

  return {
      '0': { label: "Completed", color: "#02BD36" },
      '1': { label: "Didn't Finish", color: "#EDFE58" },
      '2': { label: "Skipped", color: "#FE585A" }
  }[eventStatus.charAt(0)] || { label: "Unknown", color: "#FBFBFB" };
};

// Convert 24h time to AM/PM format
const convertTo12Hour = (time24h) => {
  if (!time24h) return '';
  
  const [hours, minutes] = time24h.split(':');
  const hour = parseInt(hours, 10);
  const period = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour % 12 || 12; // Convert 0 to 12 for midnight
  
  return `${hour12}:${minutes}${period}`;
};

const loadingOverlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(251, 251, 251, 0.7)",
  zIndex: 1000,
  borderRadius: "24px",
};

export const ClientDetailTraining = ({
  events,
  height,
  date,
  onNewEventClick,
  onEventDelete = () => null,
  currentAthlete,
  reload,
  user,
  droppedPlan,
  droppedDate,
  showAddTraining,
  onAddTrainingClose
}) => {
  const [anchorEls, setAnchorEls] = useState({});
  const [openMenuIndices, setOpenMenuIndices] = useState([]);
  const { deleteEvent } = useCalendar();
  const [SelectedPlan, setSelectedPlan] = useState([]);
  const [newTraining, setnewTraining] = useState(false);
  const [newExercisePlan, setnewExercisePlan] = useState(false);
  const [animationsComplete, setAnimationsComplete] = useState(false);

  const [Title, setTitle] = useState("");
  const [prevLength, setPrevLength] = useState(0);
  const [feedbackStatuses, setFeedbackStatuses] = useState({});
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [showAssignActivityModal, setShowAssignActivityModal] = useState(false);
  const [reloadInProgress, setReloadInProgress] = useState(false);

  const deleteCEvent = async (calId, evId, gc_id, plcid) => {
    try {
      // Set reload flag to prevent duplicate reloads
      setReloadInProgress(true);
      
      // Start with showing loading state in the dialog
      setIsDeleting(true);
      
      // Perform delete operations
      await deleteEvent(calId, gc_id);
      await deleteSBEvent(supabase, evId);
      await deletePlanClient(supabase, plcid);
      onEventDelete(calId, evId);
      
      // Close dialog AFTER setting loading state
      setTimeout(() => {
        // Close the dialog
        setOpenConfirmDialog(false);
        // Stop the loading indicator
        setIsDeleting(false);
        
        // Show success message
        setSnackbar({
          open: true,
          message: "Workout deleted successfully!",
          severity: "success",
        });
        
        // Trigger reload AFTER dialog is closed
        reload();
        
        // Reset reload flag after a delay
        setTimeout(() => {
          setReloadInProgress(false);
        }, 1000);
      }, 500);
      
    } catch (error) {
      console.error("Error deleting training plan:", error);
      
      setIsDeleting(false);
      setOpenConfirmDialog(false);
      setReloadInProgress(false);
      
      // Show error message
      setSnackbar({
        open: true,
        message: "Error deleting training plan",
        severity: "error",
      });
    }
  };

  const handleClick = (event, index) => {
    setAnchorEls(prev => ({
      ...prev,
      [index]: event.currentTarget
    }));
    setOpenMenuIndices(prev => {
      if (!prev.includes(index)) {
        return [...prev, index];
      }
      return prev;
    });
  };

  const handleClose = (index) => {
    setAnchorEls(prev => {
      const newAnchors = { ...prev };
      delete newAnchors[index];
      return newAnchors;
    });
    setOpenMenuIndices(prev => prev.filter(i => i !== index));
  };

  const handleCloseAll = () => {
    setAnchorEls({});
    setOpenMenuIndices([]);
  };

  useEffect(() => {
    setPrevLength(events.length);

    setnewTraining(false);
    setnewExercisePlan(false);
    setOpenMenuIndices([]);
    setSelectedPlan([]);
    
    // Reset animation state when events change
    setAnimationsComplete(false);
    const timer = setTimeout(() => {
      setAnimationsComplete(true);
    }, 1500); // Give enough time for all animations to complete

    // If a new training was added
    if (prevLength < events.length && prevLength !== 0 && newTraining) {
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: "Workout assigned successfully!",
        severity: "success",
      });
    } else {
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 500);

      // Cleanup function
      return () => {
        clearTimeout(timeout);
        clearTimeout(timer);
      };
    }
  }, [events, prevLength]);

  // Handle dropped plan from CardDaysAtheletesPlan
  useEffect(() => {
    if (showAddTraining && droppedPlan) {
      console.log("Processing dropped plan:", droppedPlan.name, "for date:", droppedDate);
      
      if (droppedPlan.is_single_exercise) {
        setnewExercisePlan(true);
      } else {
        setnewTraining(true);
      }
    }
    
    // Clean up function to run when component unmounts or dependencies change
    return () => {
      if (!showAddTraining && (newTraining || newExercisePlan)) {
        console.log("Cleaning up dropped state in ClientDetailTraining");
        if (onAddTrainingClose) {
          onAddTrainingClose();
        }
      }
    };
  }, [showAddTraining, droppedPlan, newTraining, newExercisePlan, onAddTrainingClose]);

  // Sort events by creation date (newest first)
  const sortedEvents = [...events].sort((a, b) => {
    const dateA = new Date(a?.events?.created_at || a?.plan?.[0]?.created_at || 0);
    const dateB = new Date(b?.events?.created_at || b?.plan?.[0]?.created_at || 0);
    return dateB - dateA; // Newest first
  });

  useEffect(() => {
    // Skip this effect if a   is already in progress
    if (reloadInProgress) {
      return;
    }
    
    const fetchFeedbackStatuses = async () => {
      setIsFeedbackLoading(true);
      try {
        if (events && events.length > 0) {
          const statuses = {};
          for (const event of events) {
            const { data, error } = await supabase
              .from("check_ins")
              .select("*")
              .eq("event_id", event.eventId)
              .single();
            if (!error && data) {
              statuses[event.eventId] = data;
            }
          }
          setFeedbackStatuses(statuses);
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsFeedbackLoading(false);
      }
    };
    fetchFeedbackStatuses();
  }, [events, reloadInProgress]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const fetchFeedback = async (eventId) => {
    try {
      const { data, error } = await supabase
        .from("check_ins")
        .select("*")
        .eq("event_id", eventId)
        .single();

      if (error) {
        console.error("Error fetching feedback:", error);
      
      } else if (data) {
        setSelectedFeedback([data]); // Wrap in array since ViewFeedback expects an arra
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };
  if(isFeedbackLoading)  {
    return(
      <Box bgcolor={"#EEE"}>
        <Paper
          variant="outlined"
          sx={{
            borderRadius: "24px",
            backgroundColor: "#FBFBFB",
            borderRadius: "24px 24px 0px 0px",
            borderBottom: "1px solid #D9D9D9",
            height: "auto",
            minHeight: "70dvh",
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: "45px",
              backgroundColor: "#FBFBFB",
              border: "none",
              borderTopLeftRadius: "24px",
              borderTopRightRadius: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton 
              variant="text" 
              width={120} 
              height={30}
              sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)' }}
            />
          </Box>
          <Divider />
          <Box
            pt={4}
            pb={2}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Skeleton 
              variant="text" 
              width={150} 
              height={30}
              sx={{ bgcolor: 'rgba(238, 238, 238, 0.4)' }}
            />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            width: '100%',
            padding: '0 20px',
            pb: 4
          }}>
            {[1, 2, 3].map((item) => (
              <Box
                key={item}
                sx={{
                  width: '568px',
                  height: '120px',
                  borderRadius: '12px',
                  bgcolor: 'rgba(238, 238, 238, 0.4)',
                  padding: '12px',
                  position: 'relative'
                }}
              >
                {/* Top Row */}
                <Box sx={{ 
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '8px'
                }}>
                  {/* Status - Top Left */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Skeleton 
                      variant="circular" 
                      width={20} 
                      height={20}
                      sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }}
                    />
                    <Skeleton 
                      variant="text" 
                      width={60} 
                      height={20}
                      sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }}
                    />
                  </Box>
                  {/* Menu Icon - Top Right */}
                  <Skeleton 
                    variant="circular" 
                    width={24} 
                    height={24}
                    sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }}
                  />
                </Box>

                {/* Center - Plan Name */}
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  width: '60%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '8px'
                }}>
                  <Skeleton 
                    variant="text" 
                    width={120} 
                    height={24}
                    sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }}
                  />
                  <Skeleton 
                    variant="text" 
                    width={180} 
                    height={28}
                    sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }}
                  />
                </Box>

                {/* Bottom Row */}
                <Box sx={{ 
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '8px'
                }}>
                  {/* Chat Icon - Bottom Left */}
                  <Skeleton 
                    variant="circular" 
                    width={24} 
                    height={24}
                    sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }}
                  />
                  {/* Exercise Count - Bottom Right */}
                  <Skeleton 
                    variant="text" 
                    width={120} 
                    height={20}
                    sx={{ bgcolor: 'rgba(238, 238, 238, 0.6)' }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Paper>
      </Box>
    )
  }
  return (
    <>
    <Box 
    bgcolor={"#EEE"}
    >
      <Paper
        variant="outlined"
        sx={{
          borderRadius: "24px",
          height: height,
          backgroundColor: "#FBFBFB",
          borderRadius:"24px 24px 0px 0px",
          borderBottom: "1px solid #D9D9D9",

          /* Rectangle 260 */


          height:"auto",
          minHeight:"70dvh",
          position: "relative",

        }}
      >
        {isLoading && (
          <Box sx={loadingOverlayStyle}>
            <CircularProgress
              size={40}
              sx={{
                color: "#2998E9",
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            height: "45px",
            backgroundColor: "#FBFBFB",
            border:"none",
            borderTopLeftRadius: "24px",
            borderTopRightRadius: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color={"#000"} fontSize={"17px"} sx={{fontWeight:"600"}}>
            {SelectedPlan.length === 0 && (!newTraining&&!newExercisePlan) ? date : Title}
          </Typography>
        </Box>
        <Divider mb={2} />
        {!newTraining && !newExercisePlan && SelectedPlan.length === 0 && (
          <>
            <Box
              pt={4}
              pb={2}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                maxHeight: "calc(auto + 50px)", // En fazla 100dvh + 2500px
                paddingBottom:"10px",
                
              }}
            >
              {events.length > 0 && (
                <Typography
                  color={"#525A65"}
                  fontSize={"16px"}
                  fontWeight={"600 !important"}
                >
                  Activity
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                maxHeight: "auto",
                display: "flex",
                height:
                  events.length > 0
                    ? Array.isArray(events.events) && !events.events[0]
                      ? "auto"
                      : "auto"
                    : "auto",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                pr: 1,
                pl: 1,
              }}
            >
              {events.length === 0 ? (
                <>
                  <Typography color={"#525A65"} fontSize={"20px"}>
                    Ready to schedule an activity?
                  </Typography>
                </>
              ) : (
                <>
                  <TableContainer sx={{ height: "100%" }}>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      sx={{
                        borderSpacing: "10px 11px",
                        borderCollapse: "unset",
                        border: "none !important",
                      }}
                    >
                      
                      <TableBody sx={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        minHeight: "200px",
                        position: "relative"
                      }}>
                         { sortedEvents.map((plan, i) => (
                            <Grow
                              key={i}
                              in={true}
                              style={{ transformOrigin: '0 0 0' }}
                              timeout={300 + (i * 150)}
                            >
                              <TableRow sx={{marginTop:"20px",padding:"0",display:"flex"}} role="checkbox" tabIndex={-1}>
                                <TableCell
                                  onClick={(e) => {
                                    // Only trigger view if we're not closing a menu
                                    if (!openMenuIndices.length) {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      setSelectedPlan([plan]);
                                    }
                                  }}
                                  sx={{
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                    background: plan?.plan[0]?.is_single_exercise
                                    ? "linear-gradient(180deg, #E9FFCE 0%, #C9FE58 100%)"
                                    : "linear-gradient(180deg, #E9FFCE 0%, #E3E2E0 100%)",                                  padding: "12px",
                                    width: ["90vw", "90vw", "568px", "568px"], 
                                    height: "120px",
                                    borderBottom: "none !important",
                                    lineHeight: "0px !important",
                                  }}
                                >

                                  <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
                                    {/* Top Row */}
                                    <Box sx={{ 
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      padding: '8px'
                                    }}>
                                      {/* Status - Top Left */}
                                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                        <Box sx={{ 
                                          width: '20px', 
                                          height: '20px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                        }}>
                                          {!isFeedbackLoading && (
                                            <Box sx={{
                                              width: '20px',
                                              height: '20px',
                                              backgroundColor: '#525A65',
                                              borderRadius: '50%',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center'
                                            }}>
                                              
                                              <Box sx={{
                                                width: '10px',
                                                height: '10px',
                                                backgroundColor: feedbackStatuses[plan.eventId] 
                                                  ? getStatus(feedbackStatuses[plan.eventId].event_status,plan?.events?.date).color
                                                  : getStatus(null, plan?.events?.date).color,
                                                borderRadius: '50%'
                                              }} />
                                            </Box>
                                          )}
                                        </Box>
                                        {!isFeedbackLoading && (
                                          <Typography
                                            sx={{
                                              color: '#525A65',
                                              fontSize: '10px',
                                              fontFamily: 'Bricolage Grotesque',
                                              fontWeight: 500,
                                              lineHeight: '12px'
                                            }}
                                          >
                                            {feedbackStatuses[plan.eventId] 
                                              ? getStatus(feedbackStatuses[plan.eventId].event_status, plan?.events?.date).label
                                              : getStatus(null, plan?.events?.date).label}
                                          </Typography>
                                        )}
                                      </Box>

                                      {/* Menu - Top Right */}
                                      <IconButton
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        handleClick(event, i);
                                      }}
                                        sx={{ padding: '4px' }}
                                      >
                                        <MenuIcon />
                                      </IconButton>
                                    </Box>

                                    {/* Center - Plan Name */}
                                    <Box sx={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      textAlign: 'center'
                                    }}>
                                      <Typography
                                        sx={{
                                          color: "#525A65",
                                          fontSize: { md: "14px", xs: "10px" },
                                          fontWeight: "500 !important",
                                          lineHeight: "1 !important",
                                          marginBottom: '4px'
                                        }}
                                      >
                                      </Typography>
                                      <Typography
                                        sx={{
                                          color: "#0F0F0F",
                                          fontSize: { md: "20px", xs: "16px" },
                                          fontWeight: "600 !important",
                                          /* Upperbody */




                                          lineHeight: "1 !important",
                                          display:"flex",
                                          alignItems:"center",
                                          flexDirection:"column",
                                          justifyContent:"center"
                                        }}
                                      >
                              <Typography
                                        sx={{
                                          height:"auto",
                                          width:"auto",
                                          color: "#0F0F0F",
                                          fontSize: { md: "20px", xs: "16px" },
                                          fontWeight: "600 !important",
                                          lineHeight: "1 !important",

                                    
                                        }}
                                        >
                                        {plan.plan[0]?.name}


                                        </Typography>

                                        <Typography
                                        sx={{
                                          height:"auto",
                                          width:"auto",
                                          color:"#525A65",
                                          fontSize:"14px",
                                        }}
                                        >
                                        {plan?.plan?.[0]?.start_time && plan?.plan?.[0]?.end_time ? (
                      <Box sx={{fontWeight:"400"}}>
                      
                        {convertTo12Hour(plan.plan[0].start_time.slice(0, 5))} - {convertTo12Hour(plan.plan[0].end_time.slice(0, 5)) +` (GMT${plan.plan[0].start_time.slice(-3).replace(/([+-])0(\d)/, "$1$2")})`}
                      </Box>
                    ) : null}
                                        </Typography>
                                      </Typography>
                                    </Box>

                                    {/* Bottom Row */}
                                    <Box sx={{ 
                                      position: 'absolute',
                                      bottom: 0,
                                      left: 0,
                                      right: 0,
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      padding: '8px'
                                    }}>
                                      {/* Chat Icon - Bottom Left */}
                                      <Box 
                                        sx={{ 
                                          cursor: feedbackStatuses[plan.eventId] ? 'pointer' : 'default',
                                          '&:hover': {
                                            opacity: feedbackStatuses[plan.eventId] ? 0.8 : 1
                                          }
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          if (feedbackStatuses[plan.eventId]) {
                                            fetchFeedback(plan.eventId);
                                          }
                                        }}
                                      >
                                        {!isFeedbackLoading && (feedbackStatuses[plan.eventId] ? (
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.2257 24C12.3202 24 20 19.962 20 12.9057C20 7.99711 15.5131 4 10 4C4.48685 4 0 8.11015 0 12.8985C0 17.6869 4.66605 20.8124 10 21C9.96526 21.1632 9.89143 21.4172 9.80902 21.7008C9.6485 22.2531 9.45543 22.9174 9.45543 23.24C9.45543 23.6609 9.79753 24 10.2257 24ZM6 14C6.55228 14 7 13.5523 7 13C7 12.4477 6.55228 12 6 12C5.44772 12 5 12.4477 5 13C5 13.5523 5.44772 14 6 14ZM11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13C9 12.4477 9.44771 12 10 12C10.5523 12 11 12.4477 11 13ZM14 14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12C13.4477 12 13 12.4477 13 13C13 13.5523 13.4477 14 14 14Z" fill="#525A65"/>
                                            <circle cx="19" cy="5" r="5" fill="#02BD36"/>
                                          </svg>
                                        ) : (
                                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.2257 20C12.3202 20 20 15.962 20 8.90572C20 3.99711 15.5131 0 10 0C4.48685 0 0 4.11015 0 8.89851C0 13.6869 4.66605 16.8124 10 17C9.96526 17.1632 9.89143 17.4172 9.80902 17.7008C9.6485 18.2531 9.45543 18.9174 9.45543 19.24C9.45543 19.6609 9.79753 20 10.2257 20ZM6 10C6.55228 10 7 9.55229 7 9C7 8.44771 6.55228 8 6 8C5.44772 8 5 8.44771 5 9C5 9.55229 5.44772 10 6 10ZM11 9C11 9.55229 10.5523 10 10 10C9.44771 10 9 9.55229 9 9C9 8.44771 9.44771 8 10 8C10.5523 8 11 8.44771 11 9ZM14 10C14.5523 10 15 9.55229 15 9C15 8.44771 14.5523 8 14 8C13.4477 8 13 8.44771 13 9C13 9.55229 13.4477 10 14 10Z" fill="#525A65"/>
                                          </svg>
                                        ))}
                                      </Box>

                                      {/* Total Exercises - Bottom Right */}
                                      <Typography
                                        sx={{
                                          color: '#525A65',
                                          fontSize: '12px',
                                          fontWeight: 500,
                                        }}
                                      >
                                        {plan.plan[0]?.exercise_plans?.length === 0 
                                          ? 'No exercise' 
                                          : `${plan.plan[0]?.is_single_exercise ? "" : `${plan.plan[0]?.exercise_plans?.length} ${plan.plan[0]?.exercise_plans?.length === 1 ? 'Exercise' : 'Exercises'} in total`}`  }
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Menu
                                    id={`menu-${i}`}
                                    anchorEl={anchorEls[i]}
                                    open={openMenuIndices.includes(i)}
                                    onClose={(e) => {
                                      e.stopPropagation();
                                      handleClose(i);
                                    }}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button',
                                      onClick: (e) => {
                                        e.stopPropagation();
                                      }
                                    }}
                                    PaperProps={{
                                      sx: {
                                        width: '150px',
                                        height: '89px',
                                        background: '#E3E2E0',
                                        border: '1px solid #E3E2E0',
                                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
                                        borderRadius: '16px',
                                        padding: '12px 18px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        overflow: 'hidden'
                                      }
                                    }}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    <MenuItem
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setSelectedPlan([plan]);
                                        handleClose(i);
                                      }}
                                      sx={{
                                        fontFamily: 'Bricolage Grotesque',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: '17px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#525A65',
                                        padding: '0',
                                        
                                        minHeight: '17px',
                                        '&:hover': {
                                          background: 'transparent',
                                          opacity: 0.8
                                        }
                                      }}
                                    >
                                      View
                                    </MenuItem>
                                    <Divider
                                      sx={{
                                        margin: "8px 0",
                                        background: "#CECECE",
                                        width: "120px",
                                      }}
                                    />
                                    <MenuItem
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setEventToDelete(plan);
                                        setOpenConfirmDialog(true);
                                        handleClose(i);
                                      }}
                                      sx={{
                                        fontFamily: 'Bricolage Grotesque',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: '17px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#525A65',
                                        padding: '0',
                                        minHeight: '17px',
                                        '&:hover': {
                                          background: 'transparent',
                                          opacity: 0.8
                                        }
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </TableCell>
                              </TableRow>
                            </Grow>
                          ))}
                        
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            
                {!isFeedbackLoading&&
                <Button
                  sx={{...buttonStyle,marginBottom:"30px",background:"#EEEEEE",color:"#0F0F0F",border:"1px solid #525A65",paddingX:"10px"}}
                  disabled={date === null}
                  onClick={() => {
                    if (!date) return alert("Select a Date");
                    setShowAssignActivityModal(true);
                  }}
                >

                  Assign an activity
                </Button>
                }
            </Box>
          </>
        )}
        
        {(newTraining && !(showAddTraining && droppedPlan && droppedPlan.is_single_exercise)) && (
          <AddNewTraining
            back={() => {
              setnewTraining(false);
              if (onAddTrainingClose) onAddTrainingClose();
            }}
            onEventDelete={onEventDelete}
            athlete={currentAthlete}
            title={setTitle}
            date={showAddTraining && droppedPlan ? droppedDate : date}
            reload={() => {}} // Pass empty function to prevent immediate reload
            onSuccess={() => {
              setSuccessDialogOpen(true);
              if (onAddTrainingClose) onAddTrainingClose();
            }}
            initialPlan={droppedPlan && !droppedPlan.is_single_exercise ? droppedPlan : null}
          />
        )}
         {newExercisePlan && (
          <ExerciseList
            back={() => setnewExercisePlan(false)}
            onEventDelete={onEventDelete}
            athlete={currentAthlete}
            title={setTitle}
            date={showAddTraining && droppedPlan && droppedPlan.is_single_exercise ? droppedDate : date}
            reload={() => {}}
            onSuccess={() => {
              setSuccessDialogOpen(true);
            }}
            user={user}
            fromAthleteRoute={true}
            initialPlan={droppedPlan && droppedPlan.is_single_exercise ? droppedPlan : null}
          />
        )}
        {SelectedPlan.length > 0 && (
          <ViewExerciseAndPlan
            athelete={SelectedPlan}
            back={() => setSelectedPlan([])}
            title={setTitle}
            reload={reload}
          />
        )}
      </Paper>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{
            width: "100%",
            "& .MuiAlert-message": {
              fontSize: "14px",
              fontWeight: 500,
            },
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {selectedFeedback && (
        <ViewFeedback
          feedback={selectedFeedback}
          onClose={() => setSelectedFeedback(null)}
        />
      )}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        PaperProps={{
          sx: {
            width: '529px',
            height: '525px',
            overflow: 'hidden',
            background: '#FBFBFB',
            borderRadius: '24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '40px 20px'
          }
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          width: '100%',
          flex: 1
        }}>
          <Typography sx={{
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '29px',
            color: '#525A65',
            marginTop: '54px'
          }}>
            Heads Up!
          </Typography>

          <Box sx={{
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            background: '#EC0B43',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
          }}>
           <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="50" cy="50" r="50" fill="#EC0B43"/>
<path d="M49.9557 78.9265C47.5282 78.9265 45.3537 77.467 44.4155 75.233C43.4921 72.999 43.9985 70.4225 45.7261 68.6949C47.4388 66.9822 50.0153 66.4609 52.2493 67.3992C54.4833 68.3226 55.9428 70.5119 55.9428 72.9395C55.9428 76.2457 53.262 78.9265 49.9557 78.9265ZM53.6938 57.6462C53.5448 59.21 51.9066 60.4312 49.9407 60.4312C47.9748 60.4312 46.3365 59.21 46.1876 57.6462L43.3281 26.4004C43.3281 23.4813 46.2919 21.1133 49.9556 21.1133C51.7874 21.1133 53.4406 21.709 54.632 22.6622C55.8235 23.6153 56.5681 24.9408 56.5681 26.4004L53.6938 57.6462Z" fill="#FBFBFB"/>
<path d="M53.6938 57.6462C53.5448 59.21 51.9066 60.4312 49.9407 60.4312C47.9748 60.4312 46.3365 59.21 46.1876 57.6462L43.3281 26.4004C43.3281 23.4813 46.2919 21.1133 49.9556 21.1133C51.7874 21.1133 53.4406 21.709 54.632 22.6622C55.8235 23.6153 56.5681 24.9408 56.5681 26.4004L53.6938 57.6462Z" fill="#FBFBFB"/>
</svg>

          </Box>

          <Typography sx={{
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            textAlign: 'center',
            color: '#000000',
            maxWidth: '359px',
            marginTop: '20px'
          }}>
            Are you sure you want to delete it? This will remove the activity from your client.
          </Typography>

          <Box sx={{
            display: 'flex',
            gap: '30px',
            paddingTop: '13%',
            paddingBottom: '40px',
          }}>
            <Button
              onClick={() => setOpenConfirmDialog(false)}
              disabled={isDeleting}
              sx={{
                padding: '15px 22px',
                background: '#EEEEEE',
                border: '1px solid #525A65',
                borderRadius: '20px',
                fontFamily: 'Bricolage Grotesque',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#0F0F0F',
                textTransform: 'none',
                minWidth: '96px',
                '&:hover': {
                  background: '#E0E0E0'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (eventToDelete) {
                  deleteCEvent(
                    eventToDelete.calendarId,
                    eventToDelete.eventId,
                    eventToDelete.resource.id,
                    eventToDelete.plan[0].id
                  );
                }
              }}
              disabled={isDeleting}
              sx={{
                padding: '15px 22px',
                background: '#FFF2F5',
                border: '1px solid #525A65',
                borderRadius: '20px',
                fontFamily: 'Bricolage Grotesque',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#EC0B43',
                textTransform: 'none',
                minWidth: '93px',
                '&:hover': {
                  background: '#FFE5EB'
                }
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
        {isDeleting && (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '24px',
            zIndex: 1,
          }}>
            <CircularProgress size={24} sx={{ color: "#02BD39" }} />
          </Box>
        )}
      </Dialog>
      <SuccessDialog 
        open={successDialogOpen} 
        onClose={() => {
          handleSuccessDialogClose();
          setnewTraining(false);
          if (!reloadInProgress) {
            reload();
          }
        }}
      />
      <AssignActivityModal
        open={showAssignActivityModal}
        onClose={() => setShowAssignActivityModal(false)}
        onWorkoutClick={() => {
          setShowAssignActivityModal(false);
          setnewTraining(true);
        }}
        onExerciseClick={() => {
          setShowAssignActivityModal(false);
          setnewExercisePlan(true);
        }}
      />
    </>
  );
};