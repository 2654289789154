import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Autocomplete,
  Snackbar,
  Alert,
  Container,
  Modal,
  Grow,
  Skeleton,
} from "@mui/material";
import { Add, Delete, Edit, Close } from "@mui/icons-material";
import ResponsesModal from "../../components/ResponsesModal/ResponsesModal";
import { useEffect, useState } from "react";
import OnboardingFormModal from "../../components/OnboardingFormModal/OnboardingFormModal";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import {
  deleteOnboardingForm,
  getOnboardingFormsByUserId,
  getCoachAthletes,
  getOnboardingFormById,
  insertOnboardingFormResponse,
} from "../../services/query";
import {
  searchstyle,
  addformstyle,
  Svgedit,
  paper,
  Typography1,
  table,
  Svgdelete,
  editstyle,
} from "./style";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { CoachProfileCard } from "../../components/CoachProfileCard/CoachProfileCard";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SearchBar from "./SearchBar";
import ViewFormModal from "../../components/ViewFormModal/ViewFormModal";

export const OnboardingFormsRoute = () => {
  const [forms, setForms] = useState([]);
  const [responsesOpen, setResponseOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [athletes, setAthletes] = useState([]);
  const supabase = useSupabase();
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [sendFormOpen, setSendFormOpen] = useState(false);
  const [selectedFormDetails, setSelectedFormDetails] = useState(null);
  const [isSent, setSent] = useState(false);
  const location = useLocation();

  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [viewFormOpen, setViewFormOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const getForms = async () => {
    try {
      setIsLoading(true);
      const { data: _forms } = await getOnboardingFormsByUserId(
        supabase,
        user.id
      );
      const sortedForms = (_forms || []).sort((a, b) => {
        if (a.icon === "🏌️‍♀️") return -1;
        if (b.icon === "🏌️‍♀️") return 1;
        return 0;
      });
      setForms(sortedForms);
    } finally {
      setIsLoading(false);
    }
  };

  const getAthletes = async () => {
    const { data } = await getCoachAthletes(supabase, user.id);
    setAthletes(data || []);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    getForms();
    getAthletes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const checkUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const shouldFormModal = urlParams.get('openFormModal');
    
    if (shouldFormModal === 'true') {
      setFormOpen(true);
      urlParams.delete("openFormModal");
      window.history.replaceState({}, "", `${location.pathname}?${urlParams}`);
      return true;
    }
    
    
    return false;
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    
    checkUrlParams();
  }, [user, location]);
  const handleMenuClick = (event, form) => {
    setAnchorEl(event.currentTarget);
    setSelectedForm(form);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedForm(null);
  };

  const handleEdit = () => {
    setFormOpen(selectedForm);
    handleMenuClose();
  };

  const handleDelete = async () => {
    if (selectedForm) {
      setIsDeleting(true);
      try {
        await deleteOnboardingForm(supabase, selectedForm.id);
        await getForms();
      } finally {
        setIsDeleting(false);
        handleMenuClose();
      }
    }
  };

  const handleSendToClient = async () => {
    if (selectedForm) {
      const { data: formDetails } = await getOnboardingFormById(
        supabase,
        selectedForm.id
      );
      setSelectedFormDetails(formDetails);
    }
    setSendFormOpen(true);
    handleMenuClose();
  };

  const handleView = () => {
    getOnboardingFormById(supabase, selectedForm.id)
      .then(({ data }) => {
        setViewFormOpen(data);
        setSelectedForm(selectedForm);
      });
    handleMenuClose();
  };

  const handleSend = async () => {
    if (!selectedAthlete || !selectedFormDetails) {
      setSnackbar({
        open: true,
        message: "Please select both client and form",
        severity: "warning",
      });
      return;
    }

    setIsSending(true);
    try {
      // Create onboarding_form_response with questions
      const { data: newResponse, error } = await insertOnboardingFormResponse(
        supabase,
        {
          athlete_id: selectedAthlete.id,
          coach_id: user.id,
          form_id: selectedFormDetails.id,
          status: "sent",
          type: "questionnaire"
        }
      );

      if (error) throw error;

      // Send welcome message with correct phone number format
      const phone = selectedAthlete.phone_number.startsWith("+")
        ? selectedAthlete.phone_number
        : `+${selectedAthlete.phone_number}`;

      const err = await supabase.functions.invoke("welcome-wp", {
        body: {
          phone: phone,
          onboarding_url: `https://app.coachplus.club/question-form/${newResponse.id}`,
          coach_name: user.full_name,
          client_name: selectedAthlete.full_name,
        },
      });

      // Reset all relevant states
      setSendFormOpen(false);
      setSelectedFormDetails(null);
      setSelectedAthlete(null);
      setViewFormOpen(false);
      setSelectedForm(null);
      setSent(true)
     
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        message: "Failed to send form. Please try again.",
        severity: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleSuccessClose = () => {
    setSent(false);
    window.location.reload();
  };
  const handleFormSuccess = (form) => {
    setFormOpen(false);
    getForms();
  
  };

  return (
    <Box sx={{
      width: "100%",
      background: "#fff",
      alignItems: "center",
      justifyContent: "center",
      display:{xs:"block",md:"flex"},
      p: { xs: 1, sm: 2, md: 3 },
    }}>
      <SuccessModal open={isSent} onClose={handleSuccessClose} />

      <PageContainer>
        <Paper variant="outlined" mb={3} sx={{
          ...paper,
          maxWidth: { xs: "100%", sm: "568px" },
          mx: "auto",
      minHeight:"100dvh!important",
      overflow:"hidden",
      maxHeight: "calc(auto + 50px)", // En fazla 100dvh + 2500px
      borderRadius:"0"


        }}>
          <Box sx={{
            width: { xs: "100%", sm: "100%", md: "568px" },
            pb: "20px"
          }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-start", sm: "center" },
                gap: { xs: 2, sm: 0 },
                justifyContent: "space-between",
                width:"100%",

              }}
            >
              <Typography variant="subtitle" sx={{
                ...Typography1,
                fontSize: { xs: "12px", sm: "14px" },
                color: "#000000",
                fontWeight: "400",
         
              }}>
Send forms and questionnaires to clients for regular 
<Box sx={{ display: { xs: "none", md: "block" ,m:0,p:0,lineHeight:0} }}>
<br />
</Box>

<Box sx={{ display: { xs: "block", md: "none" ,m:0,p:0,lineHeight:0} }}>
&nbsp;</Box>check-ins, progress tracking, and feedback.
              </Typography>
              <Button
                startIcon={<Add />}
                size="small"
                sx={{
                  ...addformstyle,
                  mr:1,
                  ml:0,
                  background: "#EEEEEE",
                  color: "#0F0F0F",
                  border:"1px solid #525A65",
                  padding:"8px",
                 
                }}
                onClick={() => setFormOpen(true)}
              >
                Create a new form
              </Button>
            </Box>
            <Box sx={{ }}>
            <SearchBar search={search} setSearch={setSearch} />
            <Divider sx={{marginTop:"10px"}}/>

            </Box>
            <TableContainer sx={{
              maxHeight: { xs: 550, sm: 600, md: 800 },
              borderRadius: "8px"
            }}>
              <Table stickyHeader aria-label="sticky table" sx={{...table}}>
                <TableBody>
                  {forms
                    .filter((form) =>
                      form.title.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((form) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={form.id}
                        sx={{
                        }}
                      >
                        <TableCell
                          key={form.id}
                          sx={{
                            p: {
                              xs: 1,
                              sm: 2,
                            },
                            backgroundColor: "#F1F3F4",
                            padding: { xs: "13px", sm: "15px" },
                            borderBottom: "0px",

                          }}
                          id={form.id}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: { xs: 1, sm: 0 },

                            }}
                          >
                            <Box sx={{ 
                              display: "flex", 
                              alignItems: "center",
                              width: { xs: "100%", sm: "auto" }
                            }}>
                              <span
                                style={{
                                  marginRight: "19px",
                                  marginTop: "7px",
                                  marginLeft: "8px",
                                }}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M5 0C2.23858 0 0 2.23858 0 5V15C0 17.7614 2.23858 20 5 20H15C17.7614 20 20 17.7614 20 15V5C20 2.23858 17.7614 0 15 0H5ZM5 5C4.44772 5 4 5.44772 4 6C4 6.55228 4.44771 7 5 7H15C15.5523 7 16 6.55228 16 6C16 5.44772 15.5523 5 15 5H5ZM5 9C4.44772 9 4 9.44772 4 10C4 10.5523 4.44771 11 5 11H15C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9H5ZM4 14C4 13.4477 4.44772 13 5 13H9C9.55228 13 10 13.4477 10 14C10 14.5523 9.55228 15 9 15H5C4.44772 15 4 14.5523 4 14Z"
                                    fill="#02BD36"
                                  />
                                </svg>
                              </span>
                              <Typography
                                variant="subtitle2"
                                sx={{ 
                                  color: "#525A65", 
                                  fontSize: { xs: "14px", sm: "14px" },
                                  flex: 1,
                                  wordBreak: "break-word"
                                }}
                              >
                                {form.title}
                              </Typography>
                            </Box>
                            <IconButton
                              onClick={(e) => handleMenuClick(e, form)}
                              size="small"
                              sx={{
                                padding: { xs: "2px", sm: "4px" },
                                "& .MuiSvgIcon-root": {
                                  fontSize: { xs: "16px", sm: "20px" },
                                },
                              }}
                            >
                              <MoreHorizIcon sx={{ color: "#525A65" }} />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  {!isLoading &&
                    (!forms.length ||
                      !forms.filter((form) =>
                        form.title.toLowerCase().includes(search.toLowerCase())
                      ).length) && (
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: "#F1F3F4",
                            padding: "24px",
                            borderBottom: "0px",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#838F99",
                              fontSize: "14px",
                              fontStyle: "italic",
                            }}
                          >
                            {!forms.length
                              ? "No forms available"
                              : "No forms found matching your search"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  {isLoading && (
                    <TableRow>
                      <TableCell
                        sx={{
                          backgroundColor: "#F1F3F4",
                          padding: 0,
                          borderBottom: "0px",
                        }}
                      >
                        <FormListSkeleton />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  backgroundColor: "#F1F3F4",
                  borderRadius: "16px",
                  width: { xs: "150px", sm: "auto" },
                  "& .MuiMenuItem-root": {
                    fontSize: { xs: "14px", sm: "14px" },
                    color: "#525A65",
                    padding: { xs: "4px 5px", sm: "10px 8px" },
                    borderBottom: "1px solid #CECECE",
                    margin: "0px 4px",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  },
                },
              }}
            >
              <MenuItem onClick={handleView}>View</MenuItem>
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem onClick={handleSendToClient}>Send to client</MenuItem>

              {selectedForm && selectedForm.icon !== "🏌️‍♀️" && (
                <MenuItem
                  onClick={handleDelete}
                  sx={{
                    color: "#EC0B43 !important",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  disabled={isDeleting}
                >
                  Delete
                  {isDeleting && (
                    <CircularProgress
                      size={16}
                      sx={{
                        color: "#EC0B43",
                        marginLeft: 1,
                      }}
                    />
                  )}
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Paper>
        <ViewFormModal 
          open={!!viewFormOpen}
          selectedForm={selectedForm}
          setSendFormOpen={setSendFormOpen}
          handleClose={() => setViewFormOpen(false)}
          formData={viewFormOpen}
          setSelectedFormDetails={setSelectedFormDetails}
        />
      </PageContainer>
      <ResponsesModal
        open={!!responsesOpen}
        formData={responsesOpen}
        handleClose={() => setResponseOpen(false)}
      />
      <OnboardingFormModal
        open={!!formOpen}
        setOpen={setFormOpen}
        handleClose={() => setFormOpen(false)}
        formData={formOpen === true ? {} : formOpen}
        onSuccess={handleFormSuccess}
      />
      <Dialog
        open={sendFormOpen}
        onClose={() => setSendFormOpen(false)}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: { xs: "16px", sm: "24px" },
            width: { xs: "95%", sm: "90%", md: "529px" },
            maxWidth: "529px",
            backgroundColor: "#FBFBFB",
            padding: { xs: "16px", sm: "24px", md: "27px" },
            margin: { xs: "8px", sm: "16px", md: "32px" },
            "& .MuiDialogTitle-root": {
              padding: { xs: "8px 0", sm: "12px 0" }
            },
            "& .MuiDialogContent-root": {
              padding: { xs: "16px 0", sm: "24px 0" }
            },
            "& .MuiDialogActions-root": {
              padding: { xs: "8px 0", sm: "16px 0" }
            }
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 0,
            paddingBottom: { xs: 1, sm: 2 },
          }}
        >
          <Typography
            variant="subtitle"
            sx={{
              color: "#000000",
              fontWeight: "600 !important",
              fontSize: { xs: "20px", sm: "24px" },
            }}
          >
            Send form to client
          </Typography>
          <IconButton
            onClick={() => setSendFormOpen(false)}
            sx={{ 
              padding: 0,
              "& svg": {
                width: { xs: "36px", sm: "48px" },
                height: { xs: "36px", sm: "48px" }
              }
            }}
          >
<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32 18.3353L29.6647 16L24 21.6647L18.3353 16L16 18.3353L21.6647 24L16 29.6647L18.3353 32L24 26.3353L29.6647 32L32 29.6647L26.3353 24L32 18.3353Z" fill="#525A65"/>
<circle cx="24" cy="24" r="24" fill="#EEEEEE"/>
<path d="M17.1423 17.1428C17.7735 16.5117 18.7968 16.5117 19.428 17.1428L30.8564 28.5712C31.4876 29.2024 31.4876 30.2257 30.8564 30.8569C30.2252 31.4881 29.2019 31.4881 28.5707 30.8569L17.1423 19.4285C16.5111 18.7973 16.5111 17.774 17.1423 17.1428Z" fill="#525A65"/>
<path d="M17.1428 30.8577C16.5117 30.2265 16.5117 29.2032 17.1428 28.572L28.5712 17.1436C29.2024 16.5124 30.2257 16.5124 30.8569 17.1436C31.4881 17.7748 31.4881 18.7981 30.8569 19.4293L19.4285 30.8577C18.7973 31.4889 17.774 31.4889 17.1428 30.8577Z" fill="#525A65"/>
</svg>


          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          <Typography
            sx={{
              color: "#000000",
              fontSize: { xs: "12px", sm: "14px" },
              marginY: 3,
            }}
          >
            Send forms and questionnaires to clients for regular check-ins,
            progress tracking, and feedback.
          </Typography>

          <Box sx={{ mb: 3 }}>
            <InputLabel
              sx={{
                color: "#838F99",
                fontSize: { xs: "9px", sm: "10px" },
                marginBottom: 1,
                paddingLeft: "10px",
              }}
            >
              Client name
            </InputLabel>
            <Autocomplete
              options={(athletes || []).filter(
                (athlete) =>
                  athlete.full_name && athlete.full_name.trim() !== ""
              )}
              value={selectedAthlete}
              onChange={(event, newValue) => {
                setSelectedAthlete(newValue);
              }}
              getOptionLabel={(option) => option.full_name || ""}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Typography sx={{ fontSize: { xs: "11px", sm: "12px" }, color: "#525A65" }}>
                    {option.full_name}
                  </Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select client"
                  sx={{
                    ".MuiInputBase-root": {
                      borderRadius: "20px",
                      backgroundColor: "#FFFFFF",
                    },
                    ".MuiInputBase-input": {
                      fontSize: { xs: "11px", sm: "12px" },
                      padding: { xs: "10px 16px", sm: "14px 24px" },
                      color: "#525A65",
                      fontWeight: "400 !important",
                    },
                  }}
                />
              )}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  sx={{
                    borderRadius: "16px",
                    backgroundColor: "#FBFBFB",
                    marginTop: "4px",
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    padding: { xs: "8px 16px", sm: "10px 24px" },
                    borderBottom: "1px solid #CECECE",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  },
                },
              }}
            />
          </Box>

          <Box sx={{ mb: 3 }}>
            <InputLabel
              sx={{
                color: "#838F99",
                fontSize: { xs: "9px", sm: "10px" },
                marginBottom: 1,
                paddingLeft: "10px",
              }}
            >
              Selected Form
            </InputLabel>
            <Autocomplete
              options={forms || []}
              value={selectedFormDetails}
              onChange={(event, newValue) => {
                setSelectedFormDetails(newValue);
              }}
              getOptionLabel={(option) => option?.title || ""}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Typography sx={{ fontSize: { xs: "11px", sm: "12px" }, color: "#525A65" }}>
                    {option.title}
                  </Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select form"
                  sx={{
                    ".MuiInputBase-root": {
                      borderRadius: "20px",
                      backgroundColor: "#FFFFFF",
                    },
                    ".MuiInputBase-input": {
                      fontSize: { xs: "11px", sm: "12px" },
                      padding: { xs: "10px 16px", sm: "14px 24px" },
                      color: "#525A65",
                      fontWeight: "400 !important",
                    },
                  }}
                />
              )}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  sx={{
                    borderRadius: "16px",
                    backgroundColor: "#FBFBFB",
                    marginTop: "4px",
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    padding: { xs: "8px 16px", sm: "10px 24px" },
                    borderBottom: "1px solid #CECECE",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  },
                },
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ padding: 0, marginTop: 2 }}>
          <Button
            onClick={handleSend}
            disabled={isSending}
            sx={{
              marginLeft: "auto",
              color: "#02BD36",
              border: "1px solid #02BD36",
              borderRadius: "20px",
              textTransform: "none",
              padding: { xs: "8px 14px", sm: "11px 18px" },
              fontSize: { xs: "12px", sm: "14px" },
              "&:hover": {
                backgroundColor: "transparent",
                opacity: 0.8,
              },
            }}
          >
            {isSending ? (
              <CircularProgress size={20} sx={{ color: "#02BD36", mr: 1 }} />
            ) : null}
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box
          sx={{
            minWidth: "320px",
            backgroundColor:
              snackbar.severity === "success" ? "#E7F7EA" : "#FFEBEF",
            borderRadius: "16px",
            padding: "16px 24px",
            display: "flex",
            alignItems: "center",
            gap: "12px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          {snackbar.severity === "success" ? (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                fill="#02BD36"
              />
            </svg>
          ) : (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM16.3 16.3C15.91 16.69 15.28 16.69 14.89 16.3L12 13.41L9.11 16.3C8.72 16.69 8.09 16.69 7.7 16.3C7.31 15.91 7.31 15.28 7.7 14.89L10.59 12L7.7 9.11C7.31 8.72 7.31 8.09 7.7 7.7C8.09 7.31 8.72 7.31 9.11 7.7L12 10.59L14.89 7.7C15.28 7.31 15.91 7.31 16.3 7.7C16.69 8.09 16.69 8.72 16.3 9.11L13.41 12L16.3 14.89C16.68 15.28 16.68 15.91 16.3 16.3Z"
                fill="#EC0B43"
              />
            </svg>
          )}
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: snackbar.severity === "success" ? "#02BD36" : "#EC0B43",
              flex: 1,
            }}
          >
            {snackbar.message}
          </Typography>
        </Box>
      </Snackbar>
    </Box>
  );
};
const SuccessModal = ({ open, onClose }) => (
  <Modal 
    open={open} 
    onClose={onClose}
    TransitionComponent={Grow}
    TransitionProps={{
      timeout: 400
    }}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    <Box
      sx={{
        position: "relative",
        width: { xs: "90%", sm: "450px" },
        minHeight: { xs: "400px", sm: "510px" },
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: { xs: "16px", sm: "24px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        outline: "none",
        border: "1px solid black",
        p: { xs: 2, sm: 3, md: 4 },
        mx: { xs: 2, sm: 0 },
        "& svg": {
          width: { xs: "70px", sm: "90px" },
          height: { xs: "70px", sm: "90px" }
        }
      }}
    >
      <Typography 
        sx={{ 
          fontSize: { xs: "20px", sm: "24px" },
          color: "#525A65", 
          mt: { xs: 2, sm: 4 }
        }} 
        variant="h6" 
        component="h2"
      >
        Well Done!
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: { xs: 2, sm: 3 }
        }}
      >
        <Typography variant="h6" component="h2">
          <SuccessIcon />
        </Typography>
        <Typography
          sx={{
            mt: { xs: 3, sm: 5 },
            fontSize: { xs: "12px", sm: "14px" },
            fontFamily: "'Bricolage Grotesque'",
            textAlign: "center",
            color: "#0F0F0F",
            px: { xs: 1, sm: 2 }
          }}
        >
          Your form has been sent to your client.  <br />
          <p style={{ fontWeight: "500" }}>
            You will receive a notification once they have <br /> completed it.
          </p>
        </Typography>
      </Box>
      <Button
        variant="outlined"
        sx={{
          minWidth: "55px",
          height: { xs: "36px", sm: "40px" },
          borderRadius: "20px",
          padding: { xs: "16px 28px", sm: "20px 35px" },
          fontFamily: "Bricolage Grotesque",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: { xs: "12px", sm: "14px" },
          lineHeight: "19px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#EEEEEE",
          border: "1px solid #525A65",
          textTransform: "capitalize",
          color: "#0F0F0F",
          mb: { xs: 2, sm: 3 }
        }}
        onClick={onClose}
      >
        Okay!
      </Button>
    </Box>
  </Modal>
);
export const SuccessIcon = () => {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45" cy="45" r="45" fill="#013B12" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M66.8721 23.8585C65.701 23.9408 64.6123 24.4923 63.8536 25.3814L38.0645 55.2619L25.9741 42.7993C24.2834 41.0625 21.4959 41.0295 19.764 42.7252C19.764 42.7252 19.7557 42.7335 19.7475 42.7417C18.0238 44.4374 17.9908 47.195 19.6815 48.9236L35.0955 64.8105C36.7862 66.5474 39.5655 66.5968 41.3057 64.9093C41.4047 64.8187 41.4954 64.72 41.5779 64.6212L70.5008 31.0858C72.0843 29.2502 71.8781 26.4844 70.0473 24.9039C69.1648 24.1549 68.0267 23.7844 66.8721 23.8667V23.8585Z"
        fill="#02BD36"
      />
    </svg>
  );
};

const FormListSkeleton = () => {
  return (
    <Box sx={{backgroundColor:"#fff",width:"100%"}}>
      {[...Array(10)].map((_, index) => (
        <TableRow 
          key={index}
          sx={{
            marginBottom: "12px",
            "& > *": { // This ensures the margin works with table layout
              paddingBottom: "12px",

            }
          }}
        >
          <TableCell
            sx={{
            width:"100vw",

              backgroundColor: "#F1F3F4",
              padding: { xs: "13px", sm: "15px" },
              borderBottom: "0px",
              
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: { xs: 1, sm: 0 },

              }}
            >
              <Box sx={{ 
                display: "flex", 
                alignItems: "center",
                width: { xs: "100%", sm: "auto" },
                flex: 1,

              }}>
                <Box
                  sx={{
                    marginRight: "19px",
                    marginLeft: "8px",
                    width: 20,
                    height: 20,
                  }}
                >
                  <Skeleton 
                    variant="rectangular" 
                    width={20} 
                    height={20} 
                    sx={{ 
                      borderRadius: "4px",
                      backgroundColor: "#E5E5E5"
                    }}
                  />
                </Box>
                <Skeleton 
                  variant="circle"
                  width={"40%"} 
                  height={14}
                  sx={{ 
                    backgroundColor: "#E5E5E5",
                    borderRadius: "2px"
                  }}
                />
              </Box>
              <Skeleton 
                variant="text" 
                width={24} 
                height={24}
                sx={{ 
                  backgroundColor: "#E5E5E5"
                }}
              />
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </Box>
  );
};