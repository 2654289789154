export const formtextfieldStyle = {
    ".MuiInputBase-root": {
        borderRadius: "20px",
        border: "0px solid #DAD9D8"
    },
    ".MuiInputBase-input": {
        fontSize: "12px",
        padding: "16px  24px",
        color: "#525A65",
        backgroundColor: "#FBFBFB",
        borderRadius: "20px",
        fontWeight: "400 !important"
    },
}
export const formtextfiel2dStyle = {
    width: { xl: "83%", lg: "80%", md: "73%", sm: "70%", xs: "70%" },
    ".MuiInputBase-root": {
        borderRadius: "20px",
        border: "0px solid #DAD9D8"
    },
    ".MuiInputBase-input": {
        fontSize: "12px",
        padding: "16px  24px",
        color: "#525A65",
        backgroundColor: "#FBFBFB",
        borderRadius: "20px",
        fontWeight: "400 !important"
    },
}

export const formtextselectStyle = {
    width: "100%",
    borderRadius: "20px",
    backgroundColor: "#FBFBFB",
    ".MuiInputBase-root": {
        borderRadius: "20px",
        border: "0px solid #DAD9D8",
        paddingLeft: "16px",

    },
    ".MuiInputBase-input": {
        fontSize: "12px",
        padding: "16px  24px",
        
        color: "#525A65",
        borderRadius: "20px",
        fontWeight: "400 !important",
    },
   
}


export const formDescriptionStyle = {
    backgroundColor: "#FBFBFB",
    borderRadius: "20px",
    ".MuiInputBase-root": {
        borderRadius: "20px",
        border: "0px solid #DAD9D8"
    },
    ".MuiInputBase-input": {
        fontSize: "12px",
        padding: "0px 10px",
        fontWeight: "400 !important",
        color: "#525A65",
        backgroundColor: "#FBFBFB",
    }
}

export const close = {
    cursor:"pointer",
    position:"absolute",
    top:"20px",
   backgroundColor:"transparent",
   border:"none",
    ".MuiSvgIcon-root": {
        fontSize: "18px !important",
        paddingLeft: "0px",
        color: "#525A65"
    },
    "&:hover": {
        color: "#525A65",
        backgroundColor:"unset",
    },
    ".MuiButton-icon": {
        widht: "10px",
        paddingLeft: "3px",
        marginRight: "0px"
    },
    "&:active": {   // Ensures it does not stay hovered after clicking
        color: "#525A65",
        backgroundColor: "unset"
    },
}



export const AddVidoeandImageStyle = {
    float: "right",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "16px",
    maxHeight: "48px",
    padding: "11px 8px !important",
    minWidth: "75px !important",
    // Default state (no input/no video)
    color: "#A9A9A9",
    border: "1px solid #A9A9A9",
    backgroundColor: "#E3E2E0 !important",
    // States for when there's input but no video
    "&.hasInput:not(.hasVideo)": {
        color: "#A9A9A9",
        border: "1px solid #A9A9A9",
        backgroundColor: "#E3E2E0 !important",
    },
    // States for when there's both input and video
    "&.hasInput.hasVideo": {
        color: "#0F0F0F",
        border: "1px solid #525A65",
        backgroundColor: "#E3E2E0 !important",
    },
    "&:hover": {
        backgroundColor: "#E3E2E0 !important",
    },
}


export const CreateUpdateStyle = {
    float: "right",
    color: "#02BD36",
    border: "solid 1px",
    marginTop: "10px",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "16px",
    maxHeight: "48px",
    padding: "11px 8px !important",
    minWidth: "95px !important",
    backgroundColor: "unset !important",
    "&:hover": {
        color: "#02BD36",
        backgroundColor: "unset !important"
    },
}


export const DeleteIcon = ({ width, height }) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z" fill="#EC0B43" />
        <g clip-path="url(#clip0_1664_1067)">
            <path d="M6.47287 5L9.99648 8.52361L13.5201 5L15 6.47287L11.4764 9.99648L15 13.5201L13.5201 15L9.99648 11.4764L6.47287 15L5 13.5201L8.52361 9.99648L5 6.47287L6.47287 5Z" fill="#F1F3F4" />
        </g>
        <defs>
            <clipPath id="clip0_1664_1067">
                <rect width="10" height="10" fill="white" transform="translate(5 5)" />
            </clipPath>
        </defs>
    </svg>

)

export const VideoIcon = () => (
    <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            filter: "drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%))",
            transition: "all 250ms ease-in-out",
            cursor: "pointer"
        }}
        onMouseEnter={(e) => {
            e.currentTarget.style.filter = "drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%))";
            e.currentTarget.style.transform = "translate(-50%, -50%) scale(1.2)";
        }}
        onMouseLeave={(e) => {
            e.currentTarget.style.filter = "drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%))";
            e.currentTarget.style.transform = "translate(-50%, -50%) scale(1)";
        }}
    >
        <path d='M16 0C7.172 0 0 7.172 0 16C0 24.828 7.172 32 16 32C24.828 32 32 24.828 32 16C32 7.172 24.828 0 16 0ZM16 1.6C23.964 1.6 30.4 8.036 30.4 16C30.4 23.964 23.964 30.4 16 30.4C8.036 30.4 1.6 23.964 1.6 16C1.6 8.036 8.036 1.6 16 1.6ZM11.6 8.8V23.2L23.6 16L11.6 8.8Z' fill='white' />
    </svg>
)
