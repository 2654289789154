import { Box, Typography, Tabs, Tab, Divider } from "@mui/material";

export const AdminHeader = ({ activeView, setActiveView }) => {
  return (
    <Box 
      sx={{ 
        mb: 3, 
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 3,
          py: 2
        }}
      >
        <Typography 
          variant="h5" 
          sx={{ 
            fontWeight: 600, 
            color: '#333',
            fontSize: { xs: '1.25rem', md: '1.5rem' }
          }}
        >
          Admin Panel
        </Typography>
      </Box>
      
      <Divider sx={{ mb: 1 }} />
      
      <Tabs 
        value={activeView === 'allUsers' ? 0 : 1}
        onChange={(_, newValue) => setActiveView(newValue === 0 ? 'allUsers' : 'referrals')}
        sx={{ 
          px: 3,
          minHeight: '48px',
          '& .MuiTabs-indicator': {
            backgroundColor: '#02BD36',
            height: 3
          },
          '& .MuiTabs-flexContainer': {
            height: '48px'
          }
        }}
      >
        <Tab 
          label="All Users" 
          disableRipple
          sx={{ 
            textTransform: 'none',
            fontSize: '0.95rem',
            fontWeight: 500,
            color: '#555',
            '&.Mui-selected': {
              color: '#02BD36',
              fontWeight: 600,
            },
            minHeight: '48px',
            p: 0,
            mr: 3
          }}
        />
        <Tab 
          label="Referrals" 
          disableRipple
          sx={{ 
            textTransform: 'none',
            fontSize: '0.95rem',
            fontWeight: 500,
            color: '#555',
            '&.Mui-selected': {
              color: '#02BD36',
              fontWeight: 600,
            },
            minHeight: '48px',
            p: 0
          }}
        />
      </Tabs>
    </Box>
  );
}; 