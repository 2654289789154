import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { Navigate } from "react-router-dom";
import { updateCoach } from "../../services/query";
import { trimAndValidatePhone } from "../../utils/validations";
import { loops_send_sign_in } from "../../services/query";
import InputLabel from "@mui/material/InputLabel";
import { sortedCountries } from "../../components/countrycode/countrycode";
import Grid from "@mui/material/Grid";

export const CoachOnboardingComponent = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      country_code: null,
    },
  });
  const { sessionUser, syncUser, session, user, signOut } = useAuth();
  const [area_code, setArea_code] = React.useState("");
  const [buttonActive, setButtonActive] = useState(false);
  const [referralError, setReferralError] = React.useState("");
  const [fill, setFill] = React.useState({
    step2: "#CECECE",
    step3: "#CECECE",
  });
  const supabase = useSupabase();
  const stepIcons = {
    step1: <Stepicon />,
    step2: <Stepicon2 fill={fill[`step2`]} />,
    step3: <Stepicon3 fill={fill[`step3`]} />,
  };

  // Function to generate random 6 character code
  const generateReferralCode = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  // Function to check if referral code exists
  const checkReferralCode = async (code) => {
    if (!code) return false;

    const { data, error } = await supabase
      .from("users")
      .select("id")
      .eq("referral_code", code)
      .single();

    return !!data;
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    setReferralError("");

    try {
      // Validate referral code if provided
      if (data.referred_by) {
        const isValidReferral = await checkReferralCode(data.referred_by);
        if (!isValidReferral) {
          setReferralError("Invalid referral code");
          return;
        }
      }

      const trimedPhoneNumber = trimAndValidatePhone(
        `${area_code}${data.phone_number}`
      );
      if (!trimedPhoneNumber) {
        return alert(
          "Please enter a valid phone number consisting of digits only"
        );
      }

      // Generate unique referral code for new user
      let newReferralCode;
      let isUnique = false;
      while (!isUnique) {
        newReferralCode = generateReferralCode();
        const { data: existingCode } = await supabase
          .from("users")
          .select("id")
          .eq("referral_code", newReferralCode)
          .single();

        if (!existingCode) {
          isUnique = true;
        }
      }

      const st_payload = {
        email: sessionUser.email,
        name: `${data.first_name} ${data.last_name}`,
        metadata: {
          cp_id: sessionUser.id,
        },
      };

      const { data: st_user } = await supabase.functions.invoke(
        "st-create-user",
        {
          body: st_payload,
        }
      );

      const st_customer_id = st_user?.customer.id;
      await loops_send_sign_in(
        supabase,
        sessionUser.email,
        `${data.first_name}`
      );

      const payload = {
        ...data,
        phone_number: trimedPhoneNumber,
        email: sessionUser.email,
        status: "ACTIVE",
        onboarded_at: new Date().toISOString(),
        full_name: `${data.first_name} ${data.last_name}`,
        st_customer_id,
        referral_code: newReferralCode, // Add the generated referral code
        isSkippedOtp: false,
      };
      delete payload.area_code;

      await updateCoach(supabase, payload, sessionUser.id);
      await syncUser(session);
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false);
    }
  });

  const handleSignOut = async () => {
    await signOut();
  };
  const formValues = watch([
    "first_name",
    "last_name",
    "phone_number",
    "country_code",
  ]);
  useEffect(() => {
    const allFieldsFilled = formValues.every((value) => value); // Hepsi dolu mu?

    if (allFieldsFilled !== buttonActive) {
      setButtonActive(allFieldsFilled);
    }
  }, [formValues, buttonActive]);
  const handleBack = () => {
    if (activeStep === 0) {
      handleSignOut();
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep === 1) {
      setFill((prevFill) => ({ ...prevFill, step2: "#CECECE" }));
    } else if (activeStep === 2) {
      setFill((prevFill) => ({ ...prevFill, step3: "#CECECE" }));
    }
  };

  if (user && user.onboarded_at) {
    return <Navigate to="/" />;
  }

  return (
    <PageContainer backgroundColor="#EEE">
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          border: "none",
          background: "none",
          minHeight: "100vh",
          pt:{xs:"0px",md:"48px"},
          px:{xs:"10px",sm:"unset"}

        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "529px" },
            p: "27px",
            height: "100%",
            borderRadius: "24px",
            backgroundColor: "#FBFBFB",
          }}
        >
          <Typography
            variant="subtitle"
            sx={{
              color: "#525A65",
              fontWeight: "600 !important",
              fontSize: "24px",
            }}
          >
            Welcome to Coach Plus
          </Typography>
          <br />
          <Typography
            variant="subtitle"
            sx={{
              color: "#02BD39",
              fontWeight: "600 !important",
              fontSize: "12px",
            }}
          >
            We need just a few details about you
          </Typography>
          <br />
          <form onSubmit={onSubmit}>
            <Box sx={{ mb: 2, mt: 2 }}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontSize: "16px",
                  fontWeight: "400 !important",
                }}
                required
              >
                First name
              </InputLabel>
              <TextField
                {...register("first_name", {
                  required: "First name is required",
                })}
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
                variant="outlined"
                placeholder="First name"
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "20px",
                    border: "0px solid #DAD9D8",
                  },
                  ".MuiInputBase-input": {
                    fontSize: "12px",
                    padding: "14px 24px",
                    color: "#525A65",
                    fontWeight: "400 !important",
                  },
                }}
                fullWidth
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              {/* <TextField {...register("last_name")} label="Last Name" fullWidth /> */}
              <InputLabel
                shrink
                required
                htmlFor="bootstrap-input"
                sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontSize: "16px",
                  fontWeight: "400 !important",
                }}
              >
                Last name
              </InputLabel>
              <TextField
                {...register("last_name", {
                  required: "Last name is required",
                })}
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
                variant="outlined"
                placeholder="Last Name"
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "20px",
                    border: "0px solid #DAD9D8",
                  },
                  ".MuiInputBase-input": {
                    fontSize: "12px",
                    padding: "14px 24px",
                    color: "#525A65",
                    fontWeight: "400 !important",
                  },
                }}
                fullWidth
              />
            </Box>
            <Box sx={{ mb: 3 }} display="flex">
              <Grid sx={{ flexWrap: "nowrap" }} container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    paddingRight: { xs: "0", sm: "16px" },
                  }}
                >
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    sx={{
                      paddingLeft: "24px",
                      color: "#838F99",
                      fontSize: "16px",
                      fontWeight: "400 !important",
                    }}
                    required
                  >
                    Code
                  </InputLabel>
                  <Controller
                    name="country_code"
                    control={control}
                    rules={{ required: "Country code is required" }}
                    render={({ field, fieldState: { error } }) => (
                      <Autocomplete
                        {...field}
                        options={sortedCountries}
                        onChange={(event, value) => {
                          field.onChange(value);
                          setArea_code(value?.phone || "");
                        }}
                        sx={{
                          width: "84%",
                          ".MuiSvgIcon-root": {
                            fontSize: "14px",
                          },
                          ".MuiInputBase-root": {
                            borderRadius: "20px",
                            padding: "0px !important",
                            height: "50px",
                            border: "0px solid #DAD9D8",
                          },
                          ".MuiInputBase-input": {
                            fontSize: "12px",
                            padding: "7.5px 5px 7.5px 18px !important",
                            color: "#525A65",
                            fontWeight: "400 !important",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!error}
                            helperText={error?.message}
                            placeholder="Code"
                            required
                          />
                        )}
                        getOptionLabel={(option) =>
                          `+${option.phone} | ${option.label}`
                        }
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "14px", color: "#525A65" }}
                              >
                                +{option.phone}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        PaperComponent={(props) => (
                          <Paper
                            {...props}
                            sx={{
                              borderRadius: "16px",
                              backgroundColor: "#FBFBFB",
                            }}
                          />
                        )}
                        ListboxProps={{
                          sx: {
                            "& .MuiAutocomplete-option": {
                              color: "#525A65",
                              fontSize: "14px",
                              borderBottom: "1px solid #CECECE",
                              padding: "10px 10px",
                              margin: "0px 10px",
                              "&:last-child": {
                                borderBottom: "none",
                              },
                            },
                          },
                        }}
                        popupIcon={
                          <div
                            style={{
                              display: "flex",
                              width: "15px",
                              height: "15px",
                              alignItems: "center",
                              paddingRight: "10px !important",
                              pointerEvents: "none",
                            }}
                          >
                            <svg
                              width="13"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.782 0L6.5 5.475 1.218 0 0 1.2625 5.282 6.7375 6.5 8 13 1.2625 11.782 0Z"
                                fill="#DAD9D8"
                              />
                            </svg>
                          </div>
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={8} sx={{ paddingLeft: { xs: "0", sm: "0" } }}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    sx={{
                      paddingLeft: "24px",
                      color: "#838F99",
                      fontSize: "16px",
                      fontWeight: "400 !important",
                    }}
                    required
                  >
                    Telephone Number
                  </InputLabel>
                  <TextField
                    type="number"
                    placeholder="Telephone number"
                    {...register("phone_number", {
                      required: "Phone number is required",
                    })}
                    error={!!errors.phone_number}
                    helperText={errors.phone_number?.message}
                    sx={formautodStyle2}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                ".css-cmlpbm": {
                  width: "100% !important", // Fixed typo here, changed "widht" to "width"
                },
                ".MuiFormControl-root": {
                  width: "100% !important", // Fixed typo here as well, changed "widht" to "width"
                },
                padding: { xs: "30px", sm: "30px" },
                paddingX: { xs: "15px", sm: "30px" },

                background: "#EEEEEE",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mb: 3,
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "5px",
                }}
              >
                <Typography
                  sx={{
                    color: "#02BD36",
                    fontSize: {
                      xs: "12px", // Mobil (extra small)
                      sm: "13px", // Tablet (small)
                      md: "13px", // Laptop (medium)
                      lg: "14px",
                    },

                    width: "100px",
                    fontWeight: "600",
                  }}
                >
                  Referral code
                </Typography>
                <Typography
                  sx={{
                    color: "#02BD36",
                    fontSize: {
                      xs: "11px", // Mobil (extra small)
                      sm: "11px", // Tablet (small)
                      md: "11px", // Laptop (medium)
                      lg: "12px",
                    },
                  }}
                >
                  Optional
                </Typography>
              </Typography>
              <TextField
                type="text"
                defaultValue={sessionStorage.getItem("referral_code")}
                placeholder="Enter Code"
                {...register("referred_by")}
                error={!!referralError}
                helperText={referralError}
                sx={{
                  ...formautodStyle2,
                  marginLeft: "15px",
                  width: "90%!important",

                  ".MuiFormHelperText-root": {
                    color: "#d32f2f",
                    marginLeft: "24px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                sx={{
                  ...backstyle,
                  "&:hover": activeStep === 0 && {
                    color: "#red",
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleBack}
              >
                {activeStep === 0 ? <div>Back</div> : "Back"}
              </Button>
              <Button
                sx={nextstyle}
                onClick={onSubmit}
                disabled={!buttonActive || isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} sx={{ color: "#02BD36" }} />
                ) : (
                  "Next"
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>
    </PageContainer>
  );
};

export const backstyle = {
  color: "#525A65",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "20px",
  fontSize: "16px",
  backgroundColor: "FBFBFB",
  maxWidth: "80px",
  minHeight: "48",
  padding: "11px 8px !important",
  minWidth: "80px !important",
  "&:hover": {
    color: "#525A65",
    backgroundColor: "unset",
  },
};

export const nextstyle = {
  marginLeft: "auto",
  color: "#02BD36",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "20px",
  fontSize: "16px",
  backgroundColor: "FBFBFB",
  maxWidth: "80px",
  minHeight: "48",
  padding: "11px 8px !important",
  minWidth: "80px !important",
  "&:hover": {
    color: "#02BD36",
    backgroundColor: "unset",
  },
};

export const formDateFieldStyle = {
  ".css-10o2lyd-MuiStack-root": {
    paddingTop: "0px !important",
  },
  ".MuiInputBase-root": {
    borderRadius: "20px",
    border: "0px solid #DAD9D8",
  },
  ".MuiInputBase-input": {
    fontSize: "12px",
    padding: "16px  24px",
    color: "#525A65",
    fontWeight: "400 !important",
  },
};

export const formautodStyle1 = {
  width: "100%",
  ".css-feqhe6": {
    paddingRight: "8px !important",
  },
  ".MuiInputBase-root": {
    borderRadius: "20px",
    padding: "7px",
    border: "0px solid #DAD9D8",
  },
  ".MuiInputBase-input": {
    fontSize: "12px",
    padding: "7.5px 5px 7.5px 18px !important",
    color: "#525A65",
    fontWeight: "400 !important",
  },
};

export const formautodStyle2 = {
  mr: 1,
  "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  ".MuiInputBase-root": {
    borderRadius: "20px",
    maxHeight: "45.5px",
    backgroundColor: "#FBFBFB",
    border: "1px solid DAD9D8",
  },
  ".MuiInputBase-input": {
    fontSize: "12px",
    padding: "14 24px",
    color: "#525A65",
    fontWeight: "400 !important",
  },
};

export const Stepicon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H17C19.7614 0 22 2.23858 22 5V17C22 19.7614 19.7614 22 17 22H5C2.23858 22 0 19.7614 0 17V5Z"
        fill="#525A65"
      />
      <path
        d="M10.792 15V8.42H9.434V6.866C9.87267 6.866 10.232 6.79133 10.512 6.642C10.8013 6.49267 10.988 6.19867 11.072 5.76H12.57V15H10.792Z"
        fill="#FBFBFB"
      />
    </svg>
  );
};

export const Stepicon2 = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H17C19.7614 0 22 2.23858 22 5V17C22 19.7614 19.7614 22 17 22H5C2.23858 22 0 19.7614 0 17V5Z"
        fill={fill}
      />
      <path
        d="M7.63 15V13.124L11.116 10.716C11.2933 10.5853 11.4753 10.4453 11.662 10.296C11.8487 10.1373 12.0213 9.96 12.18 9.764C12.3387 9.568 12.4693 9.358 12.572 9.134C12.6747 8.90067 12.726 8.65333 12.726 8.392C12.726 8.112 12.6607 7.874 12.53 7.678C12.4087 7.482 12.2267 7.328 11.984 7.216C11.7507 7.104 11.4613 7.048 11.116 7.048C10.808 7.048 10.542 7.09467 10.318 7.188C10.1033 7.28133 9.92133 7.42133 9.772 7.608C9.632 7.78533 9.52933 8.00933 9.464 8.28C9.39867 8.55067 9.366 8.86333 9.366 9.218L7.672 8.882C7.65333 8.20067 7.77467 7.61267 8.036 7.118C8.30667 6.614 8.70333 6.22667 9.226 5.956C9.74867 5.676 10.388 5.536 11.144 5.536C11.8067 5.536 12.3853 5.64333 12.88 5.858C13.384 6.07267 13.776 6.38067 14.056 6.782C14.336 7.18333 14.476 7.678 14.476 8.266C14.476 8.72333 14.3967 9.13867 14.238 9.512C14.0887 9.876 13.8927 10.2073 13.65 10.506C13.4073 10.8047 13.1413 11.0707 12.852 11.304C12.5627 11.528 12.2827 11.7287 12.012 11.906L9.66 13.488V13.6L14.644 13.53V15H7.63Z"
        fill="#FBFBFB"
      />
    </svg>
  );
};

export const Stepicon3 = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H17C19.7614 0 22 2.23858 22 5V17C22 19.7614 19.7614 22 17 22H5C2.23858 22 0 19.7614 0 17V5Z"
        fill={fill}
      />
      <path
        d="M10.99 15.196C10.3927 15.196 9.87467 15.1307 9.436 15C8.99733 14.8787 8.62867 14.706 8.33 14.482C8.04067 14.2487 7.81667 13.964 7.658 13.628C7.49933 13.292 7.40133 12.9187 7.364 12.508L8.988 12.004C8.99733 12.3307 9.058 12.606 9.17 12.83C9.282 13.054 9.43133 13.2407 9.618 13.39C9.80467 13.53 10.0193 13.6327 10.262 13.698C10.5047 13.7633 10.7567 13.796 11.018 13.796C11.4007 13.796 11.7413 13.7447 12.04 13.642C12.3387 13.53 12.5767 13.362 12.754 13.138C12.9313 12.9047 13.02 12.606 13.02 12.242C13.02 11.794 12.8707 11.4347 12.572 11.164C12.2827 10.884 11.858 10.6927 11.298 10.59C10.738 10.4873 10.0567 10.464 9.254 10.52V9.47L12.418 7.286V7.174L7.798 7.202V5.746H14.196V7.468L11.242 9.568V9.694C12.1193 9.65667 12.8147 9.75467 13.328 9.988C13.8413 10.2213 14.21 10.5387 14.434 10.94C14.658 11.3413 14.77 11.7893 14.77 12.284C14.77 12.872 14.63 13.3853 14.35 13.824C14.0793 14.2627 13.664 14.6033 13.104 14.846C12.544 15.0793 11.8393 15.196 10.99 15.196Z"
        fill="#FBFBFB"
      />
    </svg>
  );
};
