// Helper function to get flag emoji from country code
const getFlagEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

export const countries = [
  { code: "AD", label: "Andorra", phone: "376", flag: getFlagEmoji("AD") },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
    flag: getFlagEmoji("AE"),
  },
  { code: "AF", label: "Afghanistan", phone: "93", flag: getFlagEmoji("AF") },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
    flag: getFlagEmoji("AG"),
  },
  { code: "AI", label: "Anguilla", phone: "1-264", flag: getFlagEmoji("AI") },
  { code: "AL", label: "Albania", phone: "355", flag: getFlagEmoji("AL") },
  { code: "AM", label: "Armenia", phone: "374", flag: getFlagEmoji("AM") },
  { code: "AO", label: "Angola", phone: "244", flag: getFlagEmoji("AO") },
  { code: "AQ", label: "Antarctica", phone: "672", flag: getFlagEmoji("AQ") },
  { code: "AR", label: "Argentina", phone: "54", flag: getFlagEmoji("AR") },
  { code: "AS", label: "American Samoa", phone: "1-684", flag: getFlagEmoji("AS") },
  { code: "AT", label: "Austria", phone: "43", flag: getFlagEmoji("AT") },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
    flag: getFlagEmoji("AU"),
  },
  { code: "AW", label: "Aruba", phone: "297", flag: getFlagEmoji("AW") },
  { code: "AX", label: "Alland Islands", phone: "358", flag: getFlagEmoji("AX") },
  { code: "AZ", label: "Azerbaijan", phone: "994", flag: getFlagEmoji("AZ") },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
    flag: getFlagEmoji("BA"),
  },
  { code: "BB", label: "Barbados", phone: "1-246", flag: getFlagEmoji("BB") },
  { code: "BD", label: "Bangladesh", phone: "880", flag: getFlagEmoji("BD") },
  { code: "BE", label: "Belgium", phone: "32", flag: getFlagEmoji("BE") },
  { code: "BF", label: "Burkina Faso", phone: "226", flag: getFlagEmoji("BF") },
  { code: "BG", label: "Bulgaria", phone: "359", flag: getFlagEmoji("BG") },
  { code: "BH", label: "Bahrain", phone: "973", flag: getFlagEmoji("BH") },
  { code: "BI", label: "Burundi", phone: "257", flag: getFlagEmoji("BI") },
  { code: "BJ", label: "Benin", phone: "229", flag: getFlagEmoji("BJ") },
  { code: "BL", label: "Saint Barthelemy", phone: "590", flag: getFlagEmoji("BL") },
  { code: "BM", label: "Bermuda", phone: "1-441", flag: getFlagEmoji("BM") },
  { code: "BN", label: "Brunei Darussalam", phone: "673", flag: getFlagEmoji("BN") },
  { code: "BO", label: "Bolivia", phone: "591", flag: getFlagEmoji("BO") },
  { code: "BR", label: "Brazil", phone: "55", flag: getFlagEmoji("BR") },
  { code: "BS", label: "Bahamas", phone: "1-242", flag: getFlagEmoji("BS") },
  { code: "BT", label: "Bhutan", phone: "975", flag: getFlagEmoji("BT") },
  { code: "BV", label: "Bouvet Island", phone: "47", flag: getFlagEmoji("BV") },
  { code: "BW", label: "Botswana", phone: "267", flag: getFlagEmoji("BW") },
  { code: "BY", label: "Belarus", phone: "375", flag: getFlagEmoji("BY") },
  { code: "BZ", label: "Belize", phone: "501", flag: getFlagEmoji("BZ") },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
    flag: getFlagEmoji("CA"),
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
    flag: getFlagEmoji("CC"),
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
    flag: getFlagEmoji("CD"),
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
    flag: getFlagEmoji("CF"),
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
    flag: getFlagEmoji("CG"),
  },
  { code: "CH", label: "Switzerland", phone: "41", flag: getFlagEmoji("CH") },
  { code: "CI", label: "Cote d'Ivoire", phone: "225", flag: getFlagEmoji("CI") },
  { code: "CK", label: "Cook Islands", phone: "682", flag: getFlagEmoji("CK") },
  { code: "CL", label: "Chile", phone: "56", flag: getFlagEmoji("CL") },
  { code: "CM", label: "Cameroon", phone: "237", flag: getFlagEmoji("CM") },
  { code: "CN", label: "China", phone: "86", flag: getFlagEmoji("CN") },
  { code: "CO", label: "Colombia", phone: "57", flag: getFlagEmoji("CO") },
  { code: "CR", label: "Costa Rica", phone: "506", flag: getFlagEmoji("CR") },
  { code: "CU", label: "Cuba", phone: "53", flag: getFlagEmoji("CU") },
  { code: "CV", label: "Cape Verde", phone: "238", flag: getFlagEmoji("CV") },
  { code: "CW", label: "Curacao", phone: "599", flag: getFlagEmoji("CW") },
  { code: "CX", label: "Christmas Island", phone: "61", flag: getFlagEmoji("CX") },
  { code: "CY", label: "Cyprus", phone: "357", flag: getFlagEmoji("CY") },
  { code: "CZ", label: "Czech Republic", phone: "420", flag: getFlagEmoji("CZ") },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
    flag: getFlagEmoji("DE"),
  },
  { code: "DJ", label: "Djibouti", phone: "253", flag: getFlagEmoji("DJ") },
  { code: "DK", label: "Denmark", phone: "45", flag: getFlagEmoji("DK") },
  { code: "DM", label: "Dominica", phone: "1-767", flag: getFlagEmoji("DM") },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
    flag: getFlagEmoji("DO"),
  },
  { code: "DZ", label: "Algeria", phone: "213", flag: getFlagEmoji("DZ") },
  { code: "EC", label: "Ecuador", phone: "593", flag: getFlagEmoji("EC") },
  { code: "EE", label: "Estonia", phone: "372", flag: getFlagEmoji("EE") },
  { code: "EG", label: "Egypt", phone: "20", flag: getFlagEmoji("EG") },
  { code: "EH", label: "Western Sahara", phone: "212", flag: getFlagEmoji("EH") },
  { code: "ER", label: "Eritrea", phone: "291", flag: getFlagEmoji("ER") },
  { code: "ES", label: "Spain", phone: "34", flag: getFlagEmoji("ES") },
  { code: "ET", label: "Ethiopia", phone: "251", flag: getFlagEmoji("ET") },
  { code: "FI", label: "Finland", phone: "358", flag: getFlagEmoji("FI") },
  { code: "FJ", label: "Fiji", phone: "679", flag: getFlagEmoji("FJ") },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
    flag: getFlagEmoji("FK"),
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
    flag: getFlagEmoji("FM"),
  },
  { code: "FO", label: "Faroe Islands", phone: "298", flag: getFlagEmoji("FO") },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
    flag: getFlagEmoji("FR"),
  },
  { code: "GA", label: "Gabon", phone: "241", flag: getFlagEmoji("GA") },
  { code: "GB", label: "United Kingdom", phone: "44", flag: getFlagEmoji("GB") },
  { code: "GD", label: "Grenada", phone: "1-473", flag: getFlagEmoji("GD") },
  { code: "GE", label: "Georgia", phone: "995", flag: getFlagEmoji("GE") },
  { code: "GF", label: "French Guiana", phone: "594", flag: getFlagEmoji("GF") },
  { code: "GG", label: "Guernsey", phone: "44", flag: getFlagEmoji("GG") },
  { code: "GH", label: "Ghana", phone: "233", flag: getFlagEmoji("GH") },
  { code: "GI", label: "Gibraltar", phone: "350", flag: getFlagEmoji("GI") },
  { code: "GL", label: "Greenland", phone: "299", flag: getFlagEmoji("GL") },
  { code: "GM", label: "Gambia", phone: "220", flag: getFlagEmoji("GM") },
  { code: "GN", label: "Guinea", phone: "224", flag: getFlagEmoji("GN") },
  { code: "GP", label: "Guadeloupe", phone: "590", flag: getFlagEmoji("GP") },
  { code: "GQ", label: "Equatorial Guinea", phone: "240", flag: getFlagEmoji("GQ") },
  { code: "GR", label: "Greece", phone: "30", flag: getFlagEmoji("GR") },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
    flag: getFlagEmoji("GS"),
  },
  { code: "GT", label: "Guatemala", phone: "502", flag: getFlagEmoji("GT") },
  { code: "GU", label: "Guam", phone: "1-671", flag: getFlagEmoji("GU") },
  { code: "GW", label: "Guinea-Bissau", phone: "245", flag: getFlagEmoji("GW") },
  { code: "GY", label: "Guyana", phone: "592", flag: getFlagEmoji("GY") },
  { code: "HK", label: "Hong Kong", phone: "852", flag: getFlagEmoji("HK") },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
    flag: getFlagEmoji("HM"),
  },
  { code: "HN", label: "Honduras", phone: "504", flag: getFlagEmoji("HN") },
  { code: "HR", label: "Croatia", phone: "385", flag: getFlagEmoji("HR") },
  { code: "HT", label: "Haiti", phone: "509", flag: getFlagEmoji("HT") },
  { code: "HU", label: "Hungary", phone: "36", flag: getFlagEmoji("HU") },
  { code: "ID", label: "Indonesia", phone: "62", flag: getFlagEmoji("ID") },
  { code: "IE", label: "Ireland", phone: "353", flag: getFlagEmoji("IE") },
  { code: "IL", label: "Israel", phone: "972", flag: getFlagEmoji("IL") },
  { code: "IM", label: "Isle of Man", phone: "44", flag: getFlagEmoji("IM") },
  { code: "IN", label: "India", phone: "91", flag: getFlagEmoji("IN") },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
    flag: getFlagEmoji("IO"),
  },
  { code: "IQ", label: "Iraq", phone: "964", flag: getFlagEmoji("IQ") },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
    flag: getFlagEmoji("IR"),
  },
  { code: "IS", label: "Iceland", phone: "354", flag: getFlagEmoji("IS") },
  { code: "IT", label: "Italy", phone: "39", flag: getFlagEmoji("IT") },
  { code: "JE", label: "Jersey", phone: "44", flag: getFlagEmoji("JE") },
  { code: "JM", label: "Jamaica", phone: "1-876", flag: getFlagEmoji("JM") },
  { code: "JO", label: "Jordan", phone: "962", flag: getFlagEmoji("JO") },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
    flag: getFlagEmoji("JP"),
  },
  { code: "KE", label: "Kenya", phone: "254", flag: getFlagEmoji("KE") },
  { code: "KG", label: "Kyrgyzstan", phone: "996", flag: getFlagEmoji("KG") },
  { code: "KH", label: "Cambodia", phone: "855", flag: getFlagEmoji("KH") },
  { code: "KI", label: "Kiribati", phone: "686", flag: getFlagEmoji("KI") },
  { code: "KM", label: "Comoros", phone: "269", flag: getFlagEmoji("KM") },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
    flag: getFlagEmoji("KN"),
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
    flag: getFlagEmoji("KP"),
  },
  { code: "KR", label: "Korea, Republic of", phone: "82", flag: getFlagEmoji("KR") },
  { code: "KW", label: "Kuwait", phone: "965", flag: getFlagEmoji("KW") },
  { code: "KY", label: "Cayman Islands", phone: "1-345", flag: getFlagEmoji("KY") },
  { code: "KZ", label: "Kazakhstan", phone: "7", flag: getFlagEmoji("KZ") },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
    flag: getFlagEmoji("LA"),
  },
  { code: "LB", label: "Lebanon", phone: "961", flag: getFlagEmoji("LB") },
  { code: "LC", label: "Saint Lucia", phone: "1-758", flag: getFlagEmoji("LC") },
  { code: "LI", label: "Liechtenstein", phone: "423", flag: getFlagEmoji("LI") },
  { code: "LK", label: "Sri Lanka", phone: "94", flag: getFlagEmoji("LK") },
  { code: "LR", label: "Liberia", phone: "231", flag: getFlagEmoji("LR") },
  { code: "LS", label: "Lesotho", phone: "266", flag: getFlagEmoji("LS") },
  { code: "LT", label: "Lithuania", phone: "370", flag: getFlagEmoji("LT") },
  { code: "LU", label: "Luxembourg", phone: "352", flag: getFlagEmoji("LU") },
  { code: "LV", label: "Latvia", phone: "371", flag: getFlagEmoji("LV") },
  { code: "LY", label: "Libya", phone: "218", flag: getFlagEmoji("LY") },
  { code: "MA", label: "Morocco", phone: "212", flag: getFlagEmoji("MA") },
  { code: "MC", label: "Monaco", phone: "377", flag: getFlagEmoji("MC") },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
    flag: getFlagEmoji("MD"),
  },
  { code: "ME", label: "Montenegro", phone: "382", flag: getFlagEmoji("ME") },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
    flag: getFlagEmoji("MF"),
  },
  { code: "MG", label: "Madagascar", phone: "261", flag: getFlagEmoji("MG") },
  { code: "MH", label: "Marshall Islands", phone: "692", flag: getFlagEmoji("MH") },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
    flag: getFlagEmoji("MK"),
  },
  { code: "ML", label: "Mali", phone: "223", flag: getFlagEmoji("ML") },
  { code: "MM", label: "Myanmar", phone: "95", flag: getFlagEmoji("MM") },
  { code: "MN", label: "Mongolia", phone: "976", flag: getFlagEmoji("MN") },
  { code: "MO", label: "Macao", phone: "853", flag: getFlagEmoji("MO") },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
    flag: getFlagEmoji("MP"),
  },
  { code: "MQ", label: "Martinique", phone: "596", flag: getFlagEmoji("MQ") },
  { code: "MR", label: "Mauritania", phone: "222", flag: getFlagEmoji("MR") },
  { code: "MS", label: "Montserrat", phone: "1-664", flag: getFlagEmoji("MS") },
  { code: "MT", label: "Malta", phone: "356", flag: getFlagEmoji("MT") },
  { code: "MU", label: "Mauritius", phone: "230", flag: getFlagEmoji("MU") },
  { code: "MV", label: "Maldives", phone: "960", flag: getFlagEmoji("MV") },
  { code: "MW", label: "Malawi", phone: "265", flag: getFlagEmoji("MW") },
  { code: "MX", label: "Mexico", phone: "52", flag: getFlagEmoji("MX") },
  { code: "MY", label: "Malaysia", phone: "60", flag: getFlagEmoji("MY") },
  { code: "MZ", label: "Mozambique", phone: "258", flag: getFlagEmoji("MZ") },
  { code: "NA", label: "Namibia", phone: "264", flag: getFlagEmoji("NA") },
  { code: "NC", label: "New Caledonia", phone: "687", flag: getFlagEmoji("NC") },
  { code: "NE", label: "Niger", phone: "227", flag: getFlagEmoji("NE") },
  { code: "NF", label: "Norfolk Island", phone: "672", flag: getFlagEmoji("NF") },
  { code: "NG", label: "Nigeria", phone: "234", flag: getFlagEmoji("NG") },
  { code: "NI", label: "Nicaragua", phone: "505", flag: getFlagEmoji("NI") },
  { code: "NL", label: "Netherlands", phone: "31", flag: getFlagEmoji("NL") },
  { code: "NO", label: "Norway", phone: "47", flag: getFlagEmoji("NO") },
  { code: "NP", label: "Nepal", phone: "977", flag: getFlagEmoji("NP") },
  { code: "NR", label: "Nauru", phone: "674", flag: getFlagEmoji("NR") },
  { code: "NU", label: "Niue", phone: "683", flag: getFlagEmoji("NU") },
  { code: "NZ", label: "New Zealand", phone: "64", flag: getFlagEmoji("NZ") },
  { code: "OM", label: "Oman", phone: "968", flag: getFlagEmoji("OM") },
  { code: "PA", label: "Panama", phone: "507", flag: getFlagEmoji("PA") },
  { code: "PE", label: "Peru", phone: "51", flag: getFlagEmoji("PE") },
  { code: "PF", label: "French Polynesia", phone: "689", flag: getFlagEmoji("PF") },
  { code: "PG", label: "Papua New Guinea", phone: "675", flag: getFlagEmoji("PG") },
  { code: "PH", label: "Philippines", phone: "63", flag: getFlagEmoji("PH") },
  { code: "PK", label: "Pakistan", phone: "92", flag: getFlagEmoji("PK") },
  { code: "PL", label: "Poland", phone: "48", flag: getFlagEmoji("PL") },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
    flag: getFlagEmoji("PM"),
  },
  { code: "PN", label: "Pitcairn", phone: "870", flag: getFlagEmoji("PN") },
  { code: "PR", label: "Puerto Rico", phone: "1", flag: getFlagEmoji("PR") },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
    flag: getFlagEmoji("PS"),
  },
  { code: "PT", label: "Portugal", phone: "351", flag: getFlagEmoji("PT") },
  { code: "PW", label: "Palau", phone: "680", flag: getFlagEmoji("PW") },
  { code: "PY", label: "Paraguay", phone: "595", flag: getFlagEmoji("PY") },
  { code: "QA", label: "Qatar", phone: "974", flag: getFlagEmoji("QA") },
  { code: "RE", label: "Reunion", phone: "262", flag: getFlagEmoji("RE") },
  { code: "RO", label: "Romania", phone: "40", flag: getFlagEmoji("RO") },
  { code: "RS", label: "Serbia", phone: "381", flag: getFlagEmoji("RS") },
  { code: "RU", label: "Russian Federation", phone: "7", flag: getFlagEmoji("RU") },
  { code: "RW", label: "Rwanda", phone: "250", flag: getFlagEmoji("RW") },
  { code: "SA", label: "Saudi Arabia", phone: "966", flag: getFlagEmoji("SA") },
  { code: "SB", label: "Solomon Islands", phone: "677", flag: getFlagEmoji("SB") },
  { code: "SC", label: "Seychelles", phone: "248", flag: getFlagEmoji("SC") },
  { code: "SD", label: "Sudan", phone: "249", flag: getFlagEmoji("SD") },
  { code: "SE", label: "Sweden", phone: "46", flag: getFlagEmoji("SE") },
  { code: "SG", label: "Singapore", phone: "65", flag: getFlagEmoji("SG") },
  { code: "SH", label: "Saint Helena", phone: "290", flag: getFlagEmoji("SH") },
  { code: "SI", label: "Slovenia", phone: "386", flag: getFlagEmoji("SI") },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
    flag: getFlagEmoji("SJ"),
  },
  { code: "SK", label: "Slovakia", phone: "421", flag: getFlagEmoji("SK") },
  { code: "SL", label: "Sierra Leone", phone: "232", flag: getFlagEmoji("SL") },
  { code: "SM", label: "San Marino", phone: "378", flag: getFlagEmoji("SM") },
  { code: "SN", label: "Senegal", phone: "221", flag: getFlagEmoji("SN") },
  { code: "SO", label: "Somalia", phone: "252", flag: getFlagEmoji("SO") },
  { code: "SR", label: "Suriname", phone: "597", flag: getFlagEmoji("SR") },
  { code: "SS", label: "South Sudan", phone: "211", flag: getFlagEmoji("SS") },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
    flag: getFlagEmoji("ST"),
  },
  { code: "SV", label: "El Salvador", phone: "503", flag: getFlagEmoji("SV") },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
    flag: getFlagEmoji("SX"),
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
    flag: getFlagEmoji("SY"),
  },
  { code: "SZ", label: "Swaziland", phone: "268", flag: getFlagEmoji("SZ") },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
    flag: getFlagEmoji("TC"),
  },
  { code: "TD", label: "Chad", phone: "235", flag: getFlagEmoji("TD") },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
    flag: getFlagEmoji("TF"),
  },
  { code: "TG", label: "Togo", phone: "228", flag: getFlagEmoji("TG") },
  { code: "TH", label: "Thailand", phone: "66", flag: getFlagEmoji("TH") },
  { code: "TJ", label: "Tajikistan", phone: "992", flag: getFlagEmoji("TJ") },
  { code: "TK", label: "Tokelau", phone: "690", flag: getFlagEmoji("TK") },
  { code: "TL", label: "Timor-Leste", phone: "670", flag: getFlagEmoji("TL") },
  { code: "TM", label: "Turkmenistan", phone: "993", flag: getFlagEmoji("TM") },
  { code: "TN", label: "Tunisia", phone: "216", flag: getFlagEmoji("TN") },
  { code: "TO", label: "Tonga", phone: "676", flag: getFlagEmoji("TO") },
  { code: "TR", label: "Turkey", phone: "90", flag: getFlagEmoji("TR") },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
    flag: getFlagEmoji("TT"),
  },
  { code: "TV", label: "Tuvalu", phone: "688", flag: getFlagEmoji("TV") },
  {
    code: "TW",
    label: "Taiwan",
    phone: "886",
    flag: getFlagEmoji("TW"),
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
    flag: getFlagEmoji("TZ"),
  },
  { code: "UA", label: "Ukraine", phone: "380", flag: getFlagEmoji("UA") },
  { code: "UG", label: "Uganda", phone: "256", flag: getFlagEmoji("UG") },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
    flag: getFlagEmoji("US"),
  },
  { code: "UY", label: "Uruguay", phone: "598", flag: getFlagEmoji("UY") },
  { code: "UZ", label: "Uzbekistan", phone: "998", flag: getFlagEmoji("UZ") },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
    flag: getFlagEmoji("VA"),
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
    flag: getFlagEmoji("VC"),
  },
  { code: "VE", label: "Venezuela", phone: "58", flag: getFlagEmoji("VE") },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
    flag: getFlagEmoji("VG"),
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
    flag: getFlagEmoji("VI"),
  },
  { code: "VN", label: "Vietnam", phone: "84", flag: getFlagEmoji("VN") },
  { code: "VU", label: "Vanuatu", phone: "678", flag: getFlagEmoji("VU") },
  { code: "WF", label: "Wallis and Futuna", phone: "681", flag: getFlagEmoji("WF") },
  { code: "WS", label: "Samoa", phone: "685", flag: getFlagEmoji("WS") },
  { code: "XK", label: "Kosovo", phone: "383", flag: getFlagEmoji("XK") },
  { code: "YE", label: "Yemen", phone: "967", flag: getFlagEmoji("YE") },
  { code: "YT", label: "Mayotte", phone: "262", flag: getFlagEmoji("YT") },
  { code: "ZA", label: "South Africa", phone: "27", flag: getFlagEmoji("ZA") },
  { code: "ZM", label: "Zambia", phone: "260", flag: getFlagEmoji("ZM") },
  { code: "ZW", label: "Zimbabwe", phone: "263", flag: getFlagEmoji("ZW") },
];

export const sortedCountries = [...countries].sort((a, b) => {
  // Put Turkey (TR) at the top
  if (a.code === "TR") return -1;
  if (b.code === "TR") return 1;
  
  // Put Spain (ES) second
  if (a.code === "ES") return -1;
  if (b.code === "ES") return 1;
  
  // Keep suggested items at the top (after Turkey and Spain)
  if (a.suggested && !b.suggested) return -1;
  if (!a.suggested && b.suggested) return 1;
  
  // Sort rest by label
  return a.label.localeCompare(b.label);
});
