import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { sideBarConfig } from "./sideBar.config";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { ProtectedAdmin } from "../../routes/Admin/ProtectedAdmin";
import {
  CustomizedListItemButton,
  CustomizedListItemIcon,
  CustomizedListItemText,
  CustomizedListItem,
  CustomizedToolbar,
  signoutStyle,
} from "./style";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState, useCallback } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import { Badge, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { useStateManager } from "react-select";
import { Subscription } from "./Subscription";
import { ChevronLeftIcon } from "lucide-react";
import { NotificationsPopup } from "../NotificationsPopup/NotificationsPopup";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useWalkthroughState } from "../WelcomeModal/ToolsStateContext";

// Create a custom hook for notifications
const useNotifications = (user, supabase) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNotifications = useCallback(async () => {
    if (!user) return;
    
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("notifications")
        .select("*")
        .eq("user_id", user.id)
        .is("isHidden", false)
        .order("created_at", { ascending: false });

      if (error) throw error;
      setNotifications(data);
      
      // Update unread count based on the fetched data
      const unreadNotifications = data.filter(n => !n.isRead);
      setUnreadCount(unreadNotifications.length);

    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setIsLoading(false);
    }
  }, [user, supabase]);

  useEffect(() => {
    if (user) {
      fetchNotifications();
    }
  }, [user, fetchNotifications]);

  return {
    notifications,
    setNotifications,
    unreadCount,
    isLoading,
    fetchNotifications
  };
};

export const SideBar = ({
  drawerOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
  handleDrawerToggle,
}) => {
  const { session, user, signOut, updateLastAccess } = useAuth();
  const [selectedItem, setSelectedItem] = useState(null);
  const [subData, setSubData] = useState(null);
  const [open, setOpen] = useState(false);
  const [quickActionMenuOpen, setQuickActionMenuOpen] = useState(false);
  const [notificationAnchor, setNotificationAnchor] = useState(null);
  const [animateItems, setAnimateItems] = useState([false, false, false, false]);
  const [lastActivityTimestamp, setLastActivityTimestamp] = useState(Date.now());
  const navigate = useNavigate();
  const supabase = useSupabase();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const drawerWidth = 300;
  const { isToolsWalkthroughOpen, isClientsWalkthroughOpen } = useWalkthroughState();
  
  // Either modal is open
  const isWalkthroughOpen = isToolsWalkthroughOpen;

  const { notifications, setNotifications, unreadCount, isLoading, fetchNotifications } = useNotifications(user, supabase);

  const getPageTitle = (pathname) => {
    if (!pathname || pathname.includes("athlete")) return "Clients";
    if (pathname.includes("calendar")) return "Calendar";
    if (pathname.includes("training-plan")) return "Library";
    if (pathname.includes("settings")) return "Profile";
    if (pathname.includes("form")) return "Forms";
    if (pathname.includes("privacy-policy")) return "Privacy Policy";
    if (pathname.includes("terms-conditions")) return "Terms & Conditions";
    if (pathname.includes("import-exercises")) return "Import Exercises";

    return "Dashboard"; // Default fallback
  };
  const location = useLocation();
  const title = getPageTitle(location.pathname);

  const getSubStatus = async () => {
    try {
      const { data } = await supabase.functions.invoke("st-sub-status");
      setSubData(data);
    } catch (error) {
      console.error("Subscription status error:", error.message);
    }
  };
  useEffect(() => {
    getSubStatus();
  }, [user]);

  // Kullanıcı aktivitesi izleme ve son erişim güncellemesi
  useEffect(() => {
    if (!user?.id) return;
    
    // İlk render'da güncelle
    updateLastAccess();
    
    // Kullanıcı aktivitesini dinle
    const activityEvents = ['mousedown', 'keydown', 'scroll', 'touchstart'];
    let lastUpdateTime = Date.now();
    
    const handleActivity = () => {
      const now = Date.now();
      // Son güncelleme üzerinden 2 dakikadan fazla geçtiyse güncelle
      if (now - lastUpdateTime > 2 * 60 * 1000) {
        updateLastAccess();
        lastUpdateTime = now;
        setLastActivityTimestamp(now);
      }
    };
    
    activityEvents.forEach(event => {
      window.addEventListener(event, handleActivity);
    });
    
    return () => {
      activityEvents.forEach(event => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, [user?.id, updateLastAccess]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };
  
    document.addEventListener("keydown", handleEsc);
    return () => document.removeEventListener("keydown", handleEsc);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close quick action menu when clicking outside
      if (quickActionMenuOpen && !event.target.closest('[data-quick-action="true"]')) {
        setQuickActionMenuOpen(false);
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [quickActionMenuOpen]);
  
  const handleNotificationClick = useCallback((event) => {
    setNotificationAnchor(event.currentTarget);
  }, []);
  useEffect(()=>{
    setOpen(false)
  },[window.location.pathname])

  useEffect(() => {
    setQuickActionMenuOpen(false);
  }, [location.pathname]);
  
  const handleItemClick = useCallback((itemName) => {
    setSelectedItem(itemName);
    handleDrawerClose();
  }, [handleDrawerClose]);

  const handleLogoClick = useCallback((e) => {
    e.preventDefault();
    setSelectedItem(null);
    navigate("/");
    handleDrawerClose();
  }, [navigate, handleDrawerClose]);

  const handleDraweCloseFunction = useCallback((e) => {
    setOpen(false);
  }, []);

  // Animation effect for staggered appearance of quick action menu items
  useEffect(() => {
    if (quickActionMenuOpen) {
      // Reset animations when menu opens
      setAnimateItems([false, false, false, false]);
      
      // Staggered animation timing
      const timers = [];
      timers.push(setTimeout(() => setAnimateItems(prev => [true, prev[1], prev[2], prev[3]]), 100));
      timers.push(setTimeout(() => setAnimateItems(prev => [prev[0], true, prev[2], prev[3]]), 200));
      timers.push(setTimeout(() => setAnimateItems(prev => [prev[0], prev[1], true, prev[3]]), 300));
      timers.push(setTimeout(() => setAnimateItems(prev => [prev[0], prev[1], prev[2], true]), 400));
      
      return () => timers.forEach(timer => clearTimeout(timer));
    } else {
      // Reset animation state when menu closes
      setAnimateItems([false, false, false, false]);
    }
  }, [quickActionMenuOpen]);

  if (!session) {
    return null;
  }

  const drawer = (
    <Box
    
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        background: "#013B12",
        position: "relative", // Add this for absolute positioning of close button
      }}
    >
      <Box
      
        onClick={handleDraweCloseFunction}
        sx={{
          backgroundColor: "transparent!important", // Arkaplan rengini sıfırla
          color: "inherit", // Varsayılan rengi koru
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          width: "48px!important",
          height: "48px!important",
          marginLeft: {xs:"10px",sm:"21px"},
          marginTop: "20px"
        }}
      >
        <Box
          component="svg"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          sx={{
            width: "48px!important",
            height: "48px!important",
          }}
        >
          <rect
            x="0.5"
            y="0.5"
            width="47"
            height="47"
            rx="23.5"
            fill="#013B12"
          />
          <rect
            x="0.5"
            y="0.5"
            width="47"
            height="47"
            rx="23.5"
            stroke="#02BD36"
          />
          <rect
            x="17.6367"
            y="16.2227"
            width="20"
            height="2"
            rx="1"
            transform="rotate(45 17.6367 16.2227)"
            fill="#E3E2E0"
          />
          <rect
            x="16.2227"
            y="30.3633"
            width="20"
            height="2"
            rx="1"
            transform="rotate(-45 16.2227 30.3633)"
            fill="#E3E2E0"
          />
        </Box>
      </Box>
      <List>
        {sideBarConfig.map((item, index) =>
          item.name === "Admin" ? (
            ProtectedAdmin(user) ? (
              <CustomizedListItem key={item.name}>
                <CustomizedListItemButton
                  component={Link}
                  disabled={
                    window.location.pathname === "/coach-onboarding"
                      ? true
                      : false
                  }
                  to={item.path}
                  onClick={() => handleItemClick(item.name)}
                >
                  <CustomizedListItemIcon>{item.icon}</CustomizedListItemIcon>
                  <CustomizedListItemText primary={item.name} />
                </CustomizedListItemButton>
              </CustomizedListItem>
            ) : (
              <div key={item.name}></div>
            )
          ) : (
            <CustomizedListItem key={item.name}>
              <CustomizedListItemButton
                component={Link}
                to={item.path}
                onClick={() => handleItemClick(item.name)}
              >
                <CustomizedListItemIcon
                  sx={{
                    ...(window.location.pathname === item.path && {
                      "& path": {
                        fill: "#c9fe58 !important",
                      },
                      "& circle": {
                        fill: "#c9fe58 !important",
                      },
                      "& rect": {
                        fill: "#c9fe58 !important",
                      },
                      color: "#c9fe58 !important",
                    }),
                  }}
                >
                  {item.icon}
                </CustomizedListItemIcon>
                <CustomizedListItemText
                  sx={{
                    ".MuiTypography-root": {
                      color:
                        window.location.pathname === item.path
                          ? "#c9fe58 !important"
                          : selectedItem === item.name && "#c9fe58 !important",
                    },
                  }}
                  primary={item.name}
                />
              </CustomizedListItemButton>
            </CustomizedListItem>
          )
        )}
      </List>

      {/* Footer style'ını güncelliyoruz */}
      {!subData?.isSubscribed && <Subscription />}

      <Box
        style={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Button
          sx={{
            ...signoutStyle,
            border: "#0F0F0F 1px solid",
            backgroundColor: "rgba(2, 189, 54, 0.15)",
          }}
          type="submit"
          onClick={signOut}
        >
          Sign out
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "10px", sm: "11px", md: "13px", lg: "20px" },
     
          }}
        >
          <Link
            to="/blog"
            style={{
              color: "#E3E2E0",
              textDecoration: "underline",
              display: "block",
              fontSize: "14px",
              fontFamily: "Bricolage Grotesque",
            }}
          >
Blog & Articles          </Link>
          <Link
            to="/privacy-policy"
            style={{
              color: "#E3E2E0",
              textDecoration: "underline",
              display: "block",
              fontSize: "14px",
              fontFamily: "Bricolage Grotesque",
            }}
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms-conditions"
            style={{
              color: "#E3E2E0",
              textDecoration: "underline",
              display: "block",
              fontSize: "14px",
              fontFamily: "Bricolage Grotesque",
            }}
          >
            Terms & Conditions
          </Link>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {/* Add overlay div */}
      {open && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1200,
            transition: "opacity 0.3s",
          }}
          onClick={() => setOpen(false)}
        />
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          py:"21px",
          px:{xs:"10px",sm:"20px"},
          backgroundColor: "#EEEEEE!important",
          position: "relative",
          zIndex: 1100,
        }}
      >
        {/* Left - Hamburger Menu */}

                {isWalkthroughOpen ? (
                 <Box
                sx={{
                  width:"48px",
                  height:"48px",
             
                }}
                >

                 </Box>
                ) : 
        (<IconButton
          sx={{
            visibility: open ? "hidden" : "visible", // İkonun yerini koru
            display: "flex", // "block" yerine "flex" veya "block" kullan
            alignItems: "center",
            justifyContent: "center",
            background: "transparent!important",
            padding:"0",
            width:"48px",
            height:"48px",
            paddingTop:"0!important",
            minWidth: "48px",
            minHeight: "48px",
            maxWidth: "48px",
            maxHeight: "48px"
          }}
        >
          <Box
            onClick={() => setOpen(!open)}
            sx={{
              display: "flex", // "block" yerine "flex" veya "block" kullan
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: { xs: "transparent", sm: "transparent" }, // 600px altı şeffaf, üstü yeşil
              width: "48px",
              height: "48px"
            }}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }} // Kullanıcıya tıklanabilir olduğunu göster
            >
              <rect
                x="0.5"
                y="0.5"
                width="47"
                height="47"
                rx="23.5"
                fill="#013B12"
              />
              <rect
                x="0.5"
                y="0.5"
                width="47"
                height="47"
                rx="23.5"
                stroke="#02BD36"
              />
              <rect x="14" y="17" width="20" height="2" rx="1" fill="#C9FE58" />
              <rect x="14" y="23" width="20" height="2" rx="1" fill="#C9FE58" />
              <rect x="14" y="29" width="20" height="2" rx="1" fill="#C9FE58" />
            </svg>
            {/* <Box
              sx={{
                fontSize: "14px",
                marginLeft: "5px",
                color: "#0F0F0F",
                fontWeight: "600",
                display: { xs: "none", sm: "block" }, // 600px altı gizle, üstü göster
              }}
            >
              {title}
            </Box> */}
          </Box>
        </IconButton>)
}
        {/* Center - Logo */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            margin: "0 auto",
            position: {xs:"block",sm:"absolute"},
            
            left: {xs:"41.5%",sm:"50%",md:"50%",lg:"50%"},
            transform: {xs:"unset",sm:"translateX(-50%)"},
          }}
        >
          <Box
            component="img"
            src={require("../../assets/Logofoteer.png")}
            alt="Dashboard Logo"
            sx={{
              width: "100%",
              padding:"0",
              objectFit:"contain",
              maxWidth: { xs: 180, sm: 206, md: 209, lg: 218 },
              height: "auto",
              cursor: "pointer",
            }}
            onClick={handleLogoClick}
          />
        </Box>

        <Box sx={{
            display:"flex",
            alignItems:"center",
            gap:"10px"
          }}>       
          <Box
            onClick={handleNotificationClick}
           sx={{
            cursor:"pointer",
       
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding:"0px"
           }}
          >
           
              {
                unreadCount>0?<UnreadNotificationIcon/>:
              <NotificationIcon/>

              }
          </Box>

          <Box 
            sx={{
              cursor:"pointer",
              position: "relative",
              zIndex: 1400,
              width: "48px",
              height: "48px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            onClick={() => setQuickActionMenuOpen(!quickActionMenuOpen)}
            data-quick-action="true"
          >
            <Box
            sx={{
              position:"relative",
              zIndex:"1500!important",
              width: "48px",
              height: "48px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            >
            {
              isClientsWalkthroughOpen ? 
              <Box
              sx={{
                width:"48px",
                height:"48px",
           
              }}
              >
                </Box>             :
            quickActionMenuOpen ? (
              
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="23.5" fill="#02BD36" stroke="#02BD36"/>
                <rect x="14" y="23" width="20" height="2" rx="1" fill="#0F0F0F"/>
                <rect x="23" y="34" width="20" height="2" rx="1" transform="rotate(-90 23 34)" fill="#0F0F0F"/>
              </svg>
            ) : (
            
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="23.5" fill="#E3E2E0" stroke="#02BD36"/>
                <rect x="14" y="23" width="20" height="2" rx="1" fill="#0F0F0F"/>
                <rect x="23" y="34" width="20" height="2" rx="1" transform="rotate(-90 23 34)" fill="#0F0F0F"/>
              </svg>
            )}
            </Box>

            {quickActionMenuOpen && (
              <>
                {/* Overlay to darken the body */}
                <Box
                  sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    zIndex: 1300,
                  }}
                  onClick={() => setQuickActionMenuOpen(false)}
                />
                <Box
                  sx={{
                    position: "absolute",
                    width: "250px",
                    right: 0,
                    top: {xs:"44px",sm:"60px"},
                    borderRadius: "8px",
                    padding: "16px",
                    paddingRight:"0",
                    zIndex: 1500,
                    overflow:"hidden"

                  }}
                  data-quick-action="true"
                >
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    {/* First menu item with animation */}
                    <Box 
                      sx={{ 
                        display: "flex", 
                        justifyContent: "end", 
                        gap:"10%",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "8px",
                        borderRadius: "4px",
                        transform: animateItems[0] ? "translateX(0)" : "translateX(50px)",
                        opacity: animateItems[0] ? 1 : 0,
                        transition: "transform 0.3s ease, opacity 0.3s ease",
                        "&:hover": {
                          scale: "1.02"
                        }
                      }}
                      onClick={() => {
                        window.location.href="/athlete/default?openInviteModal=true";
                        setQuickActionMenuOpen(false);
                      }}
                    >
                      <Typography 
                        sx={{ 
                          color: "#E3E2E0", 
                          fontFamily: "Bricolage Grotesque",
                          fontWeight: 500,
                          fontSize: "18px"
                        }}
                      >
                        Invite client
                      </Typography>
                      <Box sx={{ 
                        width: "24px", 
                        height: "24px", 
                        borderRadius: "50%",
                        backgroundColor: "#E3E2E0",
                        border: "1px solid #02BD36" 
                      }} />
                    </Box>
                    
                    {/* Second menu item with animation */}
                    <Box 
                      sx={{ 
                        display: "flex", 
                        justifyContent: "end", 
                        gap:"10%",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "8px",
                        borderRadius: "4px",
                        transform: animateItems[1] ? "translateX(0)" : "translateX(50px)",
                        opacity: animateItems[1] ? 1 : 0,
                        transition: "transform 0.3s ease, opacity 0.3s ease",
                        "&:hover": {
                          scale: "1.02"
                        }
                      }}
                      onClick={() => {
                        window.location.href="/training-plan?openExerciseModal=true"
                        setQuickActionMenuOpen(false);
                      }}
                    >
                      <Typography 
                        sx={{ 
                          color: "#E3E2E0", 
                          fontFamily: "Bricolage Grotesque",
                          fontWeight: 500,
                          fontSize: "18px"
                        }}
                      >
                        Create an exercise
                      </Typography>
                      <Box sx={{ 
                        width: "24px", 
                        height: "24px", 
                        borderRadius: "50%",
                        backgroundColor: "#E3E2E0",
                        border: "1px solid #02BD36" 
                      }} />
                    </Box>
                    
                    {/* Third menu item with animation */}
                    <Box 
                      sx={{ 
                        display: "flex", 
                        justifyContent: "end", 
                        gap:"10%",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "8px",
                        borderRadius: "4px",
                        transform: animateItems[2] ? "translateX(0)" : "translateX(50px)",
                        opacity: animateItems[2] ? 1 : 0,
                        transition: "transform 0.3s ease, opacity 0.3s ease",
                        "&:hover": {
                          scale: "1.02"
                        }
                      }}
                      onClick={() => {
                        window.location.href="/training-plan?openWorkoutBuilder=true";
                        setQuickActionMenuOpen(false);
                      }}
                    >
                      <Typography 
                        sx={{ 
                          color: "#E3E2E0", 
                          fontFamily: "Bricolage Grotesque",
                          fontWeight: 500,
                          fontSize: "18px"
                        }}
                      >
                        Build a workout
                      </Typography>
                      <Box sx={{ 
                        width: "24px", 
                        height: "24px", 
                        borderRadius: "50%",
                        backgroundColor: "#E3E2E0",
                        border: "1px solid #02BD36" 
                      }} />
                    </Box>
                    
                    {/* Fourth menu item with animation */}
                    <Box 
                      sx={{ 
                        display: "flex", 
                        justifyContent: "end", 
                        gap:"10%",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "8px",
                        borderRadius: "4px",
                        transform: animateItems[3] ? "translateX(0)" : "translateX(50px)",
                        opacity: animateItems[3] ? 1 : 0,
                        transition: "transform 0.3s ease, opacity 0.3s ease",
                        "&:hover": {
                          scale: "1.02"
                        }
                      }}
                      onClick={() => {
                       window.location.href="/forms?openFormModal=true"
                        setQuickActionMenuOpen(false);
                      }}
                    >
                      <Typography 
                        sx={{ 
                          color: "#E3E2E0", 
                          fontFamily: "Bricolage Grotesque",
                          fontWeight: 500,
                          fontSize: "18px"
                        }}
                      >
                        Create a form
                      </Typography>
                      <Box sx={{ 
                        width: "24px", 
                        height: "24px", 
                        borderRadius: "50%",
                        backgroundColor: "#E3E2E0",
                        border: "1px solid #02BD36" 
                      }} />
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <NotificationsPopup
            anchorEl={notificationAnchor}
            onClose={() => setNotificationAnchor(null)}
            notifications={notifications}
            setNotifications={setNotifications}
            user={user}
            isLoading={isLoading}
            onSeeAll={() => {
              navigate("/notifications");
              setNotificationAnchor(null);
            }}
            onUpdate={() => {
              fetchNotifications();
            }}
          />
        </Box>
        

      </Box>

      <Drawer
        variant="temporary"
        open={drawerOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant={isMobile ? "temporary" : "persistent"} // Persistent on larger screens, Temporary on mobile
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          width: isMobile ? "100px" : drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: {
              xs: "100%",
              md: 250,
              lg: 300,
            },
            boxSizing: "border-box",
            borderRight: "0px",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};
const NotificationIcon=()=>{
  return(
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="23.5" fill="#E3E2E0" stroke="#525A65"/>
    <path d="M15 24.0013C15 24.2596 15.2124 24.472 15.4706 24.472H18.1255C18.3234 24.472 18.4972 24.3489 18.5696 24.1654L21.2896 16.9191L26.2253 32.6693C26.2856 32.8672 26.4714 33 26.6742 33C26.8769 33 27.0459 32.8769 27.1183 32.6934L30.2003 24.472H32.5294C32.7876 24.472 33 24.2596 33 24.0013C33 23.743 32.7876 23.5306 32.5294 23.5306H29.8745C29.6766 23.5306 29.5028 23.6537 29.4304 23.8371L26.7104 31.0834L21.7747 15.3308C21.712 15.1377 21.5382 15.005 21.3355 15.0001C21.1327 14.9953 20.9541 15.1184 20.8817 15.3067L17.7997 23.5282H15.4706C15.2124 23.5282 15 23.7406 15 24.0013Z" fill="#0F0F0F" stroke="#0F0F0F"/>
    </svg>
    

  )
}


const UnreadNotificationIcon=()=>{
  return(
<svg width="52" height="48" viewBox="0 0 52 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="24" r="23.5" fill="#E3E2E0" stroke="#525A65"/>
<path d="M15 24.0013C15 24.2596 15.2124 24.472 15.4706 24.472H18.1255C18.3234 24.472 18.4972 24.3489 18.5696 24.1654L21.2896 16.9191L26.2253 32.6693C26.2856 32.8672 26.4714 33 26.6742 33C26.8769 33 27.0459 32.8769 27.1183 32.6934L30.2003 24.472H32.5294C32.7876 24.472 33 24.2596 33 24.0013C33 23.743 32.7876 23.5306 32.5294 23.5306H29.8745C29.6766 23.5306 29.5028 23.6537 29.4304 23.8371L26.7104 31.0834L21.7747 15.3308C21.712 15.1377 21.5382 15.005 21.3355 15.0001C21.1327 14.9953 20.9541 15.1184 20.8817 15.3067L17.7997 23.5282H15.4706C15.2124 23.5282 15 23.7406 15 24.0013Z" fill="#0F0F0F" stroke="#0F0F0F"/>
<g filter="url(#filter0_d_3987_2258)">
<circle cx="40" cy="8" r="8" fill="#525A65"/>
</g>
<circle cx="40" cy="8" r="4" fill="#C9FE58"/>
<defs>
<filter id="filter0_d_3987_2258" x="28" y="0" width="24" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.789132 0 0 0 0 0.99554 0 0 0 0 0.343724 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3987_2258"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3987_2258" result="shape"/>
</filter>
</defs>
</svg>

    

  )
}