import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Link,
  Alert,
  Snackbar,
  Zoom
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { supabase } from '../../services/supabase';
 
const CloseButtonIcon = () => (
  <Box sx={{ position: 'relative' }}>
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#013B12"/>
    </svg>
    <Box sx={{
      position: 'absolute',
      top: '47%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <CloseIcon sx={{ color: '#02BD36', fontSize: '24px', fontWeight: "800!important" }} />
    </Box>
  </Box>
);

export const ContactModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { error } = await supabase
        .from('contact_messages')
        .insert([
          {
            name: formData.name,
            email: formData.email,
            message: formData.message
          }
        ]);

      if (error) {
        console.error('Detailed error:', error);
        throw error;
      }

      setSnackbar({
        open: true,
        message: 'Message sent successfully!',
        severity: 'success'
      });

      setFormData({
        name: '',
        email: '',
        message: ''
      });

      setTimeout(() => {
        onClose();
      }, 2000);

    } catch (error) {
      console.error('Error details:', {
        message: error.message,
        details: error.details,
        hint: error.hint
      });
      
      setSnackbar({
        open: true,
        message: `Failed to send message: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #D9D9D9',
      outline: "none",
      '&.Mui-focused': {
        borderColor: '#02BD36',
        outline: 'none',
        border: '1px solid #D9D9D9',
      },
      '& fieldset': {
        border: 'none',
      }
    },
    '& .MuiInputLabel-root': {
      color: '#525A65',
      outline: 'none',
      fontSize: '12px',
      fontWeight: '400',
      transform: 'translate(14px, 20px)',
      '&.Mui-focused': {
        fontSize: '12px'
      }
    },
    '& .MuiInputLabel-shrink': {
      fontSize: '12px',
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    mb: 2
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        TransitionComponent={Zoom}
        TransitionProps={{
          timeout: {
            enter: 300,
            exit: 200
          }
        }}
        PaperProps={{
          sx: {
            borderRadius: '24px',
            maxWidth: '500px',
            width: '100%',
            bgcolor: '#E3E2E0',
            p: 2,
            border: '1px solid #D9D9D9',
            '& .MuiDialogContent-root': {
              padding: '20px'
            }
          }
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton 
            onClick={onClose}
            sx={{
              padding: 0,
              '&:hover': { bgcolor: 'transparent' }
            }}
          >
            <CloseButtonIcon />
          </IconButton>
        </Box>

        <DialogTitle sx={{ 
          color: '#013B12', 
          fontWeight: '900 !important',
          fontSize: '36px',
          pb: 0,
          fontFamily: 'Barlow Condensed, sans-serif !important'
        }}>
          GET IN TOUCH
        </DialogTitle>

        <DialogContent>
          <Typography sx={{ color: '#525A65', mb: 3, fontSize: '14px' }}>
            Get in touch with us using the contact form below. Our team will respond to your inquiries promptly.
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Your Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              sx={inputStyles}
              required
            />
            
            <TextField
              fullWidth
              label="Your email address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              sx={inputStyles}
              required
            />
            
            <TextField
              fullWidth
              name="message"
              label={(focused || formData.message) ? "Message" : "Write here"}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}  
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
              sx={{
                ...inputStyles,
                mb: 3,
              }}
              required
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                variant="outlined"
                disabled={loading}
                sx={{
                  bgcolor: '#FFFFFF',
                  color: '#02BD36',
                  border: '1px solid #02BD36',
                  borderRadius: '20px',
                  px: 3,
                  py:"10px",
                  textTransform: 'none',
                  '&:hover': {
                    bgcolor: '#FFFFFF',
                    borderColor: '#02BD36',
                  },
                  '&:focus': {
                    outline: 'none',
                  },
                  '&.Mui-disabled': {
                    bgcolor: '#E0E0E0',
                    borderColor: '#A0A0A0',
                    color: '#A0A0A0'
                  }
                }}
              >
                {loading ? 'Sending...' : 'Send'}
              </Button>
            </Box>
          </form>

          <Box sx={{ 
            mt: 4, 
            pt: 2, 
            borderTop: '1px solid #D9D9D9',
            display: 'flex',
            gap: 2,
            justifyContent: 'space-evenly'
          }}>
            <div style={{display:"flex", gap:20}}> 
            <Link href="https://www.linkedin.com/company/coachplusclub" target="_blank" sx={{fontSize:"14px", color: '#525A65' }}>
              LinkedIn
            </Link>
            <Link href="https://www.instagram.com/coachplusclub/" target="_blank" sx={{fontSize:"14px", color: '#525A65' }}>
              Instagram
            </Link>
            </div>
          
            <Link href="mailto:contact@coachplus.club" sx={{fontSize:"14px", color: '#525A65' }}>
              contact@coachplus.club
            </Link>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}; 