import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  IconButton,
  TextField,
  Typography,
  Paper,
  Autocomplete,
  Fade,
  Zoom,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useForm } from "react-hook-form";
import { SPORT_TYPES } from "../../utils/constant";
import { useCallback, useEffect, useState } from "react";
import {
  getYouTubeVideoId,
  isValidURL,
  isValidYouTubeUrl,
} from "../../utils/validate";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { upsertExercise, get_fill_exercise } from "../../services/query";
import InputLabel from "@mui/material/InputLabel";
import CloseIcon from "@mui/icons-material/Close";
import {
  VideoIcon,
  DeleteIcon,
  CreateUpdateStyle,
  AddVidoeandImageStyle,
  close,
  formDescriptionStyle,
  formtextselectStyle,
  formtextfiel2dStyle,
  formtextfieldStyle,
} from "./style";
import VideoUpload from "./Videoupload";
import { faV } from "@fortawesome/free-solid-svg-icons";
import ImageUpload from "./Imageupload";
import Dialog from '@mui/material/Dialog';
import { SuccessIcon } from "../../routes/Dashboard/Dashboard.component";

// Warning Modal Component
const WarningModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      TransitionComponent={Zoom}
      TransitionProps={{
        timeout: {
          enter: 300,
          exit: 200
        }
      }}
      PaperProps={{
        style: {
          borderRadius: '24px',
          padding: '32px',
          maxWidth: '450px',
          width: '90%',
  
        
        }
      }}
    >
      <Box sx={{ textAlign: 'center',
                display:"flex",
                flexDirection:"column",
                gap:"30px",
                mt:4
       }}>
        <Typography variant="h5" sx={{ 
          color: '#525A65',
          fontSize: '24px',
          fontWeight: 600,
          mb: 3
        }}>
          Heads Up!
        </Typography>
        
        <Box sx={{ 
          mb: 4,
          '@keyframes warningPulse': {
            '0%': {
              transform: 'scale(1)',
              opacity: 1
            },
            '50%': {
              transform: 'scale(1.1)',
              opacity: 0.8
            },
            '100%': {
              transform: 'scale(1)',
              opacity: 1
            }
          },
          '& svg': {
            animation: 'warningPulse 2s ease-in-out infinite'
          }
        }}>
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="50" cy="50" r="50" fill="#EC0B43"/>
<path d="M49.9557 78.9265C47.5282 78.9265 45.3537 77.467 44.4155 75.233C43.4921 72.999 43.9985 70.4225 45.7261 68.6949C47.4388 66.9822 50.0153 66.4609 52.2493 67.3992C54.4833 68.3226 55.9428 70.5119 55.9428 72.9395C55.9428 76.2457 53.262 78.9265 49.9557 78.9265Z" fill="#FBFBFB"/>
<path d="M53.6938 57.6462C53.5448 59.21 51.9066 60.4312 49.9407 60.4312C47.9748 60.4312 46.3365 59.21 46.1876 57.6462L43.3281 26.4004C43.3281 23.4813 46.2919 21.1133 49.9556 21.1133C51.7874 21.1133 53.4406 21.709 54.632 22.6622C55.8235 23.6153 56.5681 24.9408 56.5681 26.4004L53.6938 57.6462Z" fill="#FBFBFB"/>
</svg>
        </Box>

        <Typography sx={{ 
          mb: 4,
          color: '#000000',
          fontSize: '16px'
        }}>
          If you go back your progress won't be saved.
          <br />
          Do you want to continue?
        </Typography>

        <Box sx={{ 
          display: 'flex', 
          gap: 2,
          justifyContent: 'center'
        }}>
          <Button
            onClick={onClose}
            sx={{
              color: '#0F0F0F',
              backgroundColor: '#EEEEEE',
              border:"1px solid #525A65",
              textTransform:"none",

              borderRadius: '20px',
              padding: '8px 16x',
              '&:hover': {
                backgroundColor: '#E0E0E0'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            sx={{
              border:"1px solid #525A65",
              color: '#EC0B43',
              fontWeight:"500!important",
              textTransform:"none",
              backgroundColor: '#FFF2F5',
              borderRadius: '20px',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: '#FFD6DE'
              }
            }}
          >
            Leave anyway
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

// Add Success Modal Component
const SuccessModal = ({ open, onClose, isUpdate }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      TransitionComponent={Zoom}
      TransitionProps={{
        timeout: {
          enter: 300,
          exit: 200
        }
      }}
      PaperProps={{
        style: {
          borderRadius: '24px',
          padding: '32px',
          maxWidth: '450px',
          width: '90%',
        }
      }}
    >
      <Box sx={{ 
        textAlign: 'center',
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        mt: 4
      }}>
        <Typography variant="h5" sx={{ 
          color: '#525A65',
          fontSize: '24px',
          fontWeight: 600,
          mb: 3
        }}>
          Nicely Done!
        </Typography>
        
        <Box sx={{ 
          mb: 4,
          animation: 'successIconAnimation 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
          '@keyframes successIconAnimation': {
            '0%': {
              transform: 'scale(0)',
              opacity: 0
            },
            '100%': {
              transform: 'scale(1)',
              opacity: 1
            }
          }
        }}>
          <SuccessIcon />
        </Box>
        <Typography sx={{ 
          mb: 4,
          color: '#0F0F0F',
          fontSize: {
            xs: "13px",  // Extra small screens
            sm: "14px",  // Small screens
            md: "14px",  // Medium screens
            lg: "16px",  // Large screens
            xl: "16px",  // Extra large screens
          },
        }}>
         Your custom exercise has been successfully 
         <br />
         {isUpdate ? "updated" : "created"}.
          
          You can add it to your workouts.
        </Typography>

        <Box
        sx={{
          display:"flex",
          justifyContent:"center",
          width:"100%",
        }}>

       
        <Button
          onClick={onClose}
          sx={{
            color: '#0F0F0F',
            backgroundColor: '#EEEEEE',
            border: "1px solid #525A65",
            textTransform: "none",
            fontSize:"16px",
            borderRadius: '20px',
            mt:2,
            padding: '9px 17px',
            '&:hover': {
              backgroundColor: '#E0E0E0'
            }
          }}
        >
          Okay!
        </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const WarningIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#EC0B43" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 10V16" stroke="#EC0B43" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 21.5C16.5523 21.5 17 21.0523 17 20.5C17 19.9477 16.5523 19.5 16 19.5C15.4477 19.5 15 19.9477 15 20.5C15 21.0523 15.4477 21.5 16 21.5Z" fill="#EC0B43"/>
  </svg>
);

// Alert Modal Component
const AlertModal = ({ open, onClose, message }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      TransitionComponent={Fade}
      TransitionProps={{
        timeout: 300
      }}
      PaperProps={{
        style: {
          borderRadius: '20px',
          padding: '24px',
          maxWidth: '300px',
          width: '90%',
          background: '#FFF2F5',
          animation: 'alertAppear 0.3s ease-out'
        }
      }}
    >
      <Box sx={{ 
        textAlign: 'center',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        '@keyframes alertAppear': {
          '0%': {
            transform: 'scale(0.9)',
            opacity: 0
          },
          '100%': {
            transform: 'scale(1)',
            opacity: 1
          }
        }
      }}>
        <Box sx={{
          animation: 'warningBounce 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
          '@keyframes warningBounce': {
            '0%': {
              transform: 'scale(0)',
              opacity: 0
            },
            '100%': {
              transform: 'scale(1)',
              opacity: 1
            }
          }
        }}>
          <WarningIcon />
        </Box>
        
        <Typography sx={{ 
          color: '#525A65',
          fontSize: '15px',
          fontWeight: 500,
          lineHeight: 1.4,
          animation: 'fadeIn 0.3s ease-out',
          '@keyframes fadeIn': {
            '0%': {
              opacity: 0,
              transform: 'translateY(10px)'
            },
            '100%': {
              opacity: 1,
              transform: 'translateY(0)'
            }
          }
        }}>
          {message}
        </Typography>
        
        <Button
          onClick={onClose}
          sx={{
            color: '#EC0B43',
            backgroundColor: '#FFFFFF',
            border: "1px solid #EC0B43",
            textTransform: "none",
            borderRadius: '20px',
            padding: '6px 24px',
            fontSize: '14px',
            fontWeight: 500,
            minWidth: '100px',
            transition: 'all 0.2s ease',
            animation: 'buttonAppear 0.4s ease-out forwards',
            '@keyframes buttonAppear': {
              '0%': {
                opacity: 0,
                transform: 'translateY(10px)'
              },
              '100%': {
                opacity: 1,
                transform: 'translateY(0)'
              }
            },
            '&:hover': {
              backgroundColor: '#FFE6EB',
              transform: 'translateY(-2px)',
              boxShadow: '0 2px 4px rgba(236, 11, 67, 0.2)'
            }
          }}
        >
          Got it
        </Button>
      </Box>
    </Dialog>
  );
};

export const ExerciseModal = ({ open, handleClose, exercise, onSuccess,fromAthleteRoute }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: exercise || {},
  });

  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [videoText, setVideoText] = useState("");
  const [isYoutubeVideo,setIsYoutubeVideo]=useState(false)
  const [imageText, setImageText] = useState("");
  const [description, setDescription] = useState("");
  const [Tag, setTags] = useState("");
  const [title, setTitle] = useState([]);
  const [Workout, setWorkout] = useState([]);
  const [waitingVideos, setWaitingVideos] = useState([]);
  const [isVideoSubmitted,setVideoSubmitted]=useState(false)
  const [isImageSubmitted,setImageSubmitted]=useState(false)

  const [waitingImages, setWaitingImages] = useState([]); 
  const [isSubmit,setIsSubmit]=useState(false)
  const [Workoutvalue, setWorkoutvalue] = useState(null);
  const [Require, setRequire] = useState([
    { require: true, requires_equipment: "Yes" },
    { require: false, requires_equipment: "No" },
  ]);
  const [RequireValue, setRequireValue] = useState(null);
  const [sportTypes, setSportTypes] = useState([]);
  const { user } = useAuth();
  const supabase = useSupabase();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {

    if (!exercise) {
      return;
    }
    setTitle(exercise.title || "");
    setDescription(exercise.description || "");
    setTags(exercise.tags || "");
    setRequireValue(
      exercise.requires_equipment === true
        ? { require: true, requires_equipment: "Yes" } || null
        : { require: false, requires_equipment: "No" } || null
    );
// Assuming exercise.videos is an array of video objects
setVideos(
  (exercise.videos || [])
);
const youtubeVideo = (exercise.videos || []).find(video => video.url && video.url.includes("youtube"))
if(youtubeVideo){
  setVideoText(youtubeVideo.url)
  setIsYoutubeVideo(true)
}

    setImages(exercise.images || []);
  }, [exercise]);
  useEffect(()=>{

    if(isVideoSubmitted===true&&isImageSubmitted===true){
      setShowSuccessModal(true)
    }
  },[isVideoSubmitted,isImageSubmitted])
  useEffect(() => {
    const fetchExercises = async () => {
      const { data: _fillexercise } = await get_fill_exercise(supabase);

      if (_fillexercise && _fillexercise.length > 0) {
        // Custom başlığına sahip öğeyi bul
        const customExercise = _fillexercise.find(
          (exercise) => exercise.title === "Custom"
        );

        // Custom başlığına sahip öğeyi diziden çıkar
        const filteredExercises = _fillexercise.filter(
          (exercise) => exercise.title !== "Custom"
        );

        // Eğer Custom başlığına sahip bir öğe varsa, onu ilk sıraya ekleyin
        if (customExercise) {
          setWorkout([customExercise, ...filteredExercises]);
        } else {
          setWorkout(filteredExercises);
        }
      } else {
        setWorkout([]);
      }
    };

    fetchExercises();
  }, []);

  // Track changes
  useEffect(() => {
   
    const hasChanges = 
      title[0]!== (exercise?.title[0] || ""||undefined) ||
      description !== (exercise?.description || "") ||
      Tag !== (exercise?.tags || "") ||
      videos.length !== (exercise?.videos?.length || 0) ||
      images.length !== (exercise?.images?.length || 0);
    setHasUnsavedChanges(hasChanges);
  }, [title, description, Tag, videos, images, exercise]);

  // Handle close with warning
  const handleCloseWithWarning = () => {
    if (hasUnsavedChanges) {
      setShowWarningModal(true);
    } else {
      handleClose();
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const id = exercise && exercise.id ? { id: exercise.id } : {};
    
    try {
      if (exercise) {
        const payload = {
          ...id,
          description: description,
          title: title,
          tags: Tag,
          requires_equipment: false,
          videos,
          images,
          coach_id: user.id,
          is_my_exercise: true,
        };

        await upsertExercise(supabase, payload);
        setIsSubmit(true);

      } else {
        if (!Workoutvalue) {
          const payload = {
            ...id,
            description: description,
            title: title,
            tags: Tag,
            workout: "Custom",
            requires_equipment: false,
            videos,
            images,
            coach_id: user.id,
            is_my_exercise: true,
          };

          await upsertExercise(supabase, payload);
          setIsSubmit(true);
          setShowSuccessModal(true);
          return;
        }
        const payload = {
          ...id,
          description: description,
          title: title,
          tags: Tag,
          workout: Workoutvalue ? Workoutvalue.title : "",
          requires_equipment: false,
          videos,
          images,
          coach_id: user.id,
          is_my_exercise: true,
        };

        await upsertExercise(supabase, payload);
        setIsSubmit(true);
        setShowSuccessModal(true); // Show success modal after creation
      }
      
      setLoading(false);
    } catch (error) {
      console.error("Error saving exercise:", error);
      setLoading(false);
    }
  });
  const handleVideoToggle = () => {
    if (isYoutubeVideo && videoText) {
      setVideoText("");
      setIsYoutubeVideo(false);
  
      setVideos(prevVideos => 
        prevVideos.filter(video => video.url !== videoText)
      );
    } else {
      if (!isValidYouTubeUrl(videoText)) {
        setVideoText("");
        setAlertMessage("Invalid YouTube URL");
        setShowAlert(true);
        return;
      }
  
      setVideos(prevVideos => [
        ...prevVideos,
        {
          url: videoText,
          id: getYouTubeVideoId(videoText),
          originalName: "youtubevideo",
        },
      ]);
      setIsYoutubeVideo(true);
    }
  };
  
  const changeTitle = (newValue) => {
    if (newValue !== null) {
      setTitle(newValue.title === "Custom" ? "" : newValue.title);
      setWorkoutvalue(newValue);
      setDescription(newValue.description);
      setTags(newValue.tags);

      // Video link kontrolü ve videos array'ine ekleme
      if (newValue.video_link) {
        const videoId = getYouTubeVideoId(newValue.video_link);
        if (videoId) {
          setVideos([
            {
              url: newValue.video_link,
              id: videoId,
            },
          ]);
        }
      } else {
        setVideos(newValue.videos || []);
      }

      if (newValue.requires_equipment !== null) {
        setRequireValue(
          newValue.requires_equipment === true
            ? { require: true, requires_equipment: "Yes" }
            : { require: false, requires_equipment: "No" }
        );
      } else {
        setRequireValue(null);
      }
    } else {
      setTitle("");
      setWorkoutvalue(null);
      setRequireValue(null);
      setDescription("");
      setTags("");
      setVideos([]);
      setRequire([
        { require: true, requires_equipment: "Yes" },
        { require: false, requires_equipment: "No" },
      ]);
    }
  };

  return (
    <>
      <Paper
      
        sx={{
          borderRadius: "24px",
          border: "none",
          boxShadow: "unset",
          background:"unset",
          margin:"auto",
          width: ["90vw", "90vw", "568px", "568px"], 
          margin:fromAthleteRoute ? "auto" : "0",
          position:"relative",
          transform: "scale(1)",
          animation: "modalScale 0.3s ease-out",
          "@keyframes modalScale": {
            "0%": {
              transform: "scale(0.95)",
              opacity: 0
            },
            "100%": {
              transform: "scale(1)",
              opacity: 1
            }
          }
        }}
      >
        <Box pt={3} sx={{ paddingBottom: "0px",paddingX:"16px"}}>
          <Box display={"flex"} alignItems={"baseline"}>
            <Typography
              sx={{
                fontSize: {
                  xl: "16px",
                  lg: "16px",
                  md: "13px",
                  sm: "13px",
                  xs: "13px",
                },
                fontWeight: "600 !important",
                color: "#525A65",
              }}
            >
              {exercise ? "Update" : "Create New"} Exercise
              <Box
                onClick={handleCloseWithWarning}
                size="small"
                sx={{...close,right:{xs:"16px",sm:"0"}}}
              >
                <IconClose/>

              </Box>
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography
              sx={{
                fontSize: {
                  xl: "12px",
                  lg: "12px",
                  md: "10px",
                  sm: "9px",
                  xs: "9px",
                },
                fontWeight: "400 !important",
                color: "#525A65",
              }}
            >
              Exercises are the building blocks of your workout.<br />Create a
              new exercise to add to one of your workouts.
            </Typography>
          </Box>
          <form onSubmit={onSubmit}>
            {exercise ? (
              <></>
            ) : (
              <>
                <Box mb={3}>
                 
                  <Autocomplete
                    options={Workout}
                    sx={{...formtextselectStyle,

                    }}
                    ListboxProps={{
                      sx: {
                        "& .MuiAutocomplete-option": {
                          borderBottom: "1px solid #E0E0E0",
                          margin:"2px 10px",
                          "&:last-child": {
                            borderBottom: "none",
                          }
                        }
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Customize an exercise (optional)"
                      />
                    )}
                    onChange={(e, v) => changeTitle(v)}
                    getOptionLabel={(option) => `${option.title}`}
                    getOptionKey={(option) => option.id}
                  />
                </Box>
              </>
            )}

            <Box sx={{ mt: 3 }}>
       
              <TextField
                {...register("title")}
                placeholder="Title"
                value={title}
                onChange={(e, v) => setTitle(e.target.value)}
                sx={formtextfieldStyle}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Box>
            <Box sx={{ mt: 3 }}>
           
              <TextField
                {...register("description")}
                variant="outlined"
                type="textarea"
                placeholder="Description"
                value={description}
                onChange={(e, v) => setDescription(e.target.value)}
                minRows={4}
                multiline
                sx={formDescriptionStyle}
                fullWidth
                inputProps={{ maxLength: 1000 }}
              />
            </Box>
           
            <Box sx={{
  marginTop: "20px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: 2,
  width: "100%"
}}>        
              <VideoUpload setVideoText={setVideoText} setVideoSubmitted={setVideoSubmitted} setVideos={setVideos} videos={videos} isSubmit={isSubmit} 
      handleClose={handleClose} setWaitingVideos={setWaitingVideos}/>
          
          <ImageUpload 
                setImageSubmitted={setImageSubmitted}
                setImages={setImages} 
                images={images} 
                isSubmit={isSubmit} 
                handleClose={handleClose} 
                setWaitingImages={setWaitingImages}
                onUploadComplete={(newImages) => setImages(prevImages => [...prevImages, ...newImages])}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <Box
                sx={{
                  fontSize:"14px",
                  mb:2,
                  color: "#525A65",
                  fontWeight: "600 !important",
                }}
              >
                Add a YouTube Video
              </Box>
       
              <TextField
                variant="outlined"
                placeholder="Paste the link here..."
                value={videoText}
                onChange={(e) => setVideoText(e.target.value)}
                sx={{
                  ...formtextfiel2dStyle,
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#FBFBFB",
                    "& fieldset": {
                      border: videos.length > 0 ? "1px solid #DAD9D8" : "1px solid #DAD9D8",
                    },
                    "& input": {
                      color: videos.length > 0 ? "#525A65" : "#525A65",
                    },
                  },
                  animation: showAlert ? 'shake 0.5s cubic-bezier(.36,.07,.19,.97) both' : 'none',
                  '@keyframes shake': {
                    '0%, 100%': {
                      transform: 'translateX(0)',
                      boxShadow: '0 0 0 rgba(236, 11, 67, 0)',
                    },
                    '20%, 60%': {
                      transform: 'translateX(-6px)',
                      boxShadow: '0 0 8px rgba(236, 11, 67, 0.5)',
                    },
                    '40%, 80%': {
                      transform: 'translateX(6px)',
                      boxShadow: '0 0 8px rgba(236, 11, 67, 0.5)',
                    }
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: showAlert ? '#EC0B43' : '#DAD9D8',
                      transition: 'all 0.3s ease',
                    },
                    '&:hover fieldset': {
                      borderColor: showAlert ? '#EC0B43' : '#525A65',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: showAlert ? '#EC0B43' : '#525A65',
                    }
                  }
                }}
              />
              <Button
                sx={{...AddVidoeandImageStyle,
                  backgroundColor: isYoutubeVideo ? "#E3E2E0" : "#E3E2E0",
                  color: isYoutubeVideo ? "#0F0F0F" : (videoText.length > 0 ? "#0F0F0F" : "#A9A9A9"),
                  border: isYoutubeVideo ? "1px solid #525A65" : `1px solid ${videos.length > 0 ? "#525A65" : "#A9A9A9"}`,
                  transition: "all 0.3s ease",
                 
                }}
                onClick={handleVideoToggle}
                disabled={videoText.length===0}
              >
                {isYoutubeVideo ? "Remove" : "Add"}
              </Button>
            </Box>
          
    {  /*      <Grid2 container spacing={2} sx={{ mt: 2 }}>
          {videos.map((v, i) => (
          <Grid2
            key={i}
            xs={4}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0",
              margin: "0",
              width: "auto",
              marginRight: "10px",
              marginBottom: "15px",
            }}
          >
            <IconButton
              color="error"
              sx={{
                background: "rgba(255, 255, 255, .7) !important",
                marginRight: "8px",
                "&:hover": {
                  background: "rgba(255, 255, 255, 1) !important",
                },
              }}
              onClick={() => setVideos(videos.filter((vid) => v.id !== vid.id))}
            >
              <DeleteIcon width={"20px"} height={"20px"} />
            </IconButton>
         { /*  <div
              style={{
                width: "150px",
                height: "84px",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {waitingVideos.some((file) => file.id === v.id) ? (
                <video
                  src={URL.createObjectURL(waitingVideos.find(file => file.id === v.id).file)}
                  controls
                  style={{
                    width: "150px",
                    height: "84px",
                    objectFit: "cover",
                    borderRadius: "16px",
                  }}
                />
              ) : v.id.startsWith("custom_video") ? (
                <video
                  src={`https://zoqbnckhrsteugiuynck.supabase.co/storage/v1/object/public/custom_videos/custom_video_${v.id}`}
                  controls
                  style={{
                    width: "150px",
                    height: "84px",
                    objectFit: "cover",
                    borderRadius: "16px",
                  }}
                />
              ) : (
                <a
                  href={`https://www.youtube.com/watch?v=${v.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img
                    src={`https://img.youtube.com/vi/${v.id}/hqdefault.jpg`}
                    alt="Exercise video"
                    style={{
                      width: "150px",
                      height: "84px",
                      objectFit: "cover",
                      borderRadius: "16px",
                      transition: "transform 250ms ease-in-out",
                    }}
                    className="responsive-img"
                  />
                  <VideoIcon />
                </a>
              )}
            </div>*/}
        {/*  </Grid2>
        ))}
          
    </Grid2>}
        
    {/*   <Grid2 container spacing={2} mt={1}>
      {images.map((image, i) => (
    <Grid2
      key={i}
      xs={4}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "0",
        margin: "0",
        width: "auto",
        marginRight: "10px",
        marginBottom: "20px",
      }}
    >
      <IconButton
        color="error"
        sx={{
          "&:hover": {
            background: "rgba(255, 255, 255, 1) !important",
          },
          marginRight: "2px", // Reduced space between the button and the card
        }}
        onClick={() => setImages(images.filter((img) => image !== img))}
      >
        <DeleteIcon width={"14px"} height={"14px"} />
      </IconButton>

      <Card
        sx={{
          width: "90px",
          height: "50px",
          display: "flex",
          borderRadius: "8px",
          alignItems: "flex-end",
          margin: "0", // Ensure no margin on the card
        }}
      >
        {/* Conditionally render the image or a preview */}
        {/*}
        {waitingImages.some((file) => file.id === image.id) ? (
          <img
            src={URL.createObjectURL(waitingImages.find(file => file.id === image.id).file)}
            alt="Waiting Image"
            style={{
              width: "90px",
              height: "50px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        ) : image?.id?.startsWith("custom_image") ? (
          <img
            src={`https://zoqbnckhrsteugiuynck.supabase.co/storage/v1/object/public/custom_videos//custom_image_${image.id}`}
            alt="Custom Image"
            style={{
              width: "90px",
              height: "50px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        ) : (
          <img
            src={image}
            alt="Image"
            style={{
              width: "90px",
              height: "50px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        )}
      </Card>
    </Grid2>
  ))

  </Grid2>

  */}


            <Box mt={4} width={"100%"} height={"80px"}>
              <Button 
                type="submit" 
                disabled={loading} 
                sx={{...CreateUpdateStyle,background:"#FBFBFB!important",color:"#02BD36",borderRadius:"32px"}}
              >
                {exercise ? "Update" : "Create"}
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>

      <WarningModal 
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        onConfirm={() => {
          setShowWarningModal(false);
          handleClose();
        }}
      />

      <SuccessModal 
        open={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
          handleClose();
          onSuccess();
        }}
        isUpdate={!!exercise}
      />

      <AlertModal
        open={showAlert}
        onClose={() => setShowAlert(false)}
        message={alertMessage}
      />
    </>
  );
};
export const IconClose =()=>{
  return(<svg style={{
  }} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 18.3353L29.6647 16L24 21.6647L18.3353 16L16 18.3353L21.6647 24L16 29.6647L18.3353 32L24 26.3353L29.6647 32L32 29.6647L26.3353 24L32 18.3353Z" fill="#525A65"/>
    <circle cx="24" cy="24" r="24" fill="#EEEEEE"/>
    <path d="M17.1423 17.1428C17.7735 16.5117 18.7968 16.5117 19.428 17.1428L30.8564 28.5712C31.4876 29.2024 31.4876 30.2257 30.8564 30.8569C30.2252 31.4881 29.2019 31.4881 28.5707 30.8569L17.1423 19.4285C16.5111 18.7973 16.5111 17.774 17.1423 17.1428Z" fill="#525A65"/>
    <path d="M17.1428 30.8577C16.5117 30.2265 16.5117 29.2032 17.1428 28.572L28.5712 17.1436C29.2024 16.5124 30.2257 16.5124 30.8569 17.1436C31.4881 17.7748 31.4881 18.7981 30.8569 19.4293L19.4285 30.8577C18.7973 31.4889 17.774 31.4889 17.1428 30.8577Z" fill="#525A65"/>
    </svg>
    )

}
