import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";

import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import { AlarmAdd, CalendarMonth } from "@mui/icons-material";
import { endOfDay, startOfDay, startOfMonth } from "date-fns";
import { useEffect, useState } from "react";
import { getReadableTextColor } from "../../utils/styles/theme";
import { deleteSBEvent, deletePlanClient } from "../../services/query";
import { supabase } from "../../services/supabase";
import { useCalendar } from "../../providers/CalendarProvider";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export const CalendarComponent = ({
  defaultView,
  height,
  title,
  toggles,
  views,
  calendars,
  onNewEventClick,
  onEventSelect,
  onReloadRequest,
  onEventDelete = () => null,
  onCalendarToggle,
}) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { deleteEvent } = useCalendar();

  const deleteCEvent = async (calId, evId, gc_id, plcid) => {
    await deleteEvent(calId, gc_id);
    await deleteSBEvent(supabase, evId);
    await deletePlanClient(supabase, plcid);

    onEventDelete(calId, evId);
  };

  useEffect(() => {
    if (!calendars || calendars.length === 0) {
      return;
    }

    const allEvents = calendars
      .filter((cal) => cal.enabled)
      .map((cal) =>
        cal.events.map((e) => ({
          allDay: true,
          resource: e.payload,
          start: startOfDay(e.date),
          end: endOfDay(e.date),
          id: e.id,
          calendarId: cal.payload.calendarId,
          title: e.payload.summary,
          backgroundColor: cal.payload.color,
          foregroundColor: getReadableTextColor(cal.payload.color),
          planId: e.plan_client_id,
        }))
      )
      .flat();
    setEvents(allEvents);
  }, [calendars]);
  return (
    <>
      {!!selectedEvent && (
        <EventModal
          event={selectedEvent}
          open={!!selectedEvent}
          onClose={() => setSelectedEvent(null)}
          onDelete={(calId, gcalEvId, evId, plcid) => {
            if (window.confirm("Are you sure you want to delete this event?")) {
              deleteCEvent(calId, evId, gcalEvId, plcid);
              setSelectedEvent(null);
              onEventDelete(calId, evId, gcalEvId);
            }
          }}
        />
      )}
      <Paper variant="outlined" mb={3}>
        <Box p={2} pb={0} display="flex" alignItems="center">
          <CalendarMonth style={{ marginRight: 12 }} />
          <Typography variant="subtitle" fontWeight="900">
            {title}
          </Typography>
          {/* <Button
            startIcon={<AlarmAdd />}
            size="small"
            sx={{ marginLeft: "auto" }}
            onClick={onNewEventClick}
          >
            New Event
          </Button> */}
        </Box>
        {toggles && (
          <Box px={2} pt={2}>
            {calendars.map((cal) => (
              <Chip
                key={cal.calendarId}
                size="small"
                label={cal?.payload.title?.replace("@gmail.com", "")}
                variant={!cal.enabled ? "outlined" : "filled"}
                sx={{
                  fontWeight: 600,
                  backgroundColor: !cal.enabled
                    ? "transparent"
                    : cal.payload.color,
                  color: !cal.enabled
                    ? cal.payload.color
                    : getReadableTextColor(cal.payload.color),
                  margin: 0.5,
                  "&:hover": {
                    backgroundColor: cal.payload.color,
                    color: cal.enabled
                      ? getReadableTextColor(cal.payload.color)
                      : cal.payload.color,
                  },
                }}
                onClick={() => {
                  onCalendarToggle(cal.id);
                }}
              />
            ))}
          </Box>
        )}
        <Box sx={{ px: 1, py: 2, fontSize: 11 }}>
          {calendars.length > 0 ? (
            <Calendar
              localizer={localizer}
              events={events || []}
              startAccessor="start"
              endAccessor="end"
              defaultView={defaultView}
              style={{ height: height || 340 }}
              popup
              defaultDate={startOfMonth(new Date())}
              onSelectEvent={(event) => setSelectedEvent(event)}
              eventPropGetter={(event) => ({
                style: {
                  backgroundColor: event.backgroundColor,
                  color: event.foregroundColor,
                  cursor: "pointer",
                  fontWeight: 500,
                },
              })}
              formats={{
                agendaHeaderFormat: (date) =>
                  date.start.toLocaleString("default", {
                    month: "short",
                    day: "numeric",
                  }) +
                  "  -  " +
                  date.end.toLocaleString("default", {
                    month: "short",
                    day: "numeric",
                  }),
              }}
              allDayAccessor={(event) => event.allDay}
              views={views || ["month", "week", "day", "agenda"]}
            />
          ) : (
            <span>No Calendar</span>
          )}
        </Box>
      </Paper>
    </>
  );
};

const EventModal = ({ event, onClose, open, onDelete }) => {
  const extractHref = (str) => {
    const match = str.match(/href="([^"]+)"/);
    return match ? match[1] : null;
  };
  const date = new Date(event.start);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{
        ".MuiPaper-root": {
          maxWidht: "529px",
          height: "294px",
          borderRadius: "24px",
          backgroundColor: "#FBFBFB",
        },
      }}
    >
      <Box sx={{ padding: "60px 40px 10px 40px", height: "294px" }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "14px",
                fontWeight: "400 !important",
                color: "#525A65",
              }}
            >
              {formattedDate}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: "0px !important" }}>
            <Button
              startIcon={<CloseIcon />}
              size="small"
              onClick={onClose}
              sx={close}
            ></Button>
          </Grid>
        </Grid>
        <DialogContent sx={{ padding: "25px 0px 10px 0px" }}>
          {/* <Typography variant="subtitle2">Training Program: </Typography> */}
          {/* <div dangerouslySetInnerHTML={{ __html: event.resource.description }} /> */}
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "20px",
              fontWeight: "600 !important",
              color: "#525A65",
            }}
          >
            {event.title?.split(" - ").pop()}
          </Typography>
        </DialogContent>
        <DialogContent sx={{ padding: "10px 0px 30px 0px" }}>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "400 !important",
              color: "#525A65",
            }}
          >
            for{" "}
            <a
              href="#"
              style={{
                fontSize: "14px",
                fontWeight: "400 !important",
                color: "#2998E9",
              }}
            >
              {event.resource.organizer.displayName
                ?.split(" ")
                .slice(1)
                .join(" ")}
            </a>
          </span>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "10px 0px 0px 0px !important",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() =>
              (window.location.href = extractHref(event.resource.description))
            }
            type="button"
            style={buttonStyle2}
            className="buttonCal"
          >
            View training plan
          </Button>
          <Button
            onClick={() =>
              onDelete(
                event.calendarId,
                event.resource.id,
                event.id,
                event.planId
              )
            }
            type="button"
            style={buttonStyle3}
            className="buttonCal"
          >
            Delete event
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const buttonStyle2 = {
  color: "#525A65",
  border: "solid 1px",
  borderRadius: "30px",
  fontSize: "12px",
  backgroundColor: "unset",
  padding: "6px 9px 6px 9px",
  maxWidht: "121px",
  cursor: "pointer",
  ".buttonCal:hover": {
    backgroundColor: "unset",
    color: "#525A65",
  },
  lineHeightt: "14px",
  height: "26px",
  textTransform: "none",
};

const buttonStyle3 = {
  color: "#EC0B43",
  border: "solid 1px",
  borderRadius: "30px",
  fontSize: "12px",
  backgroundColor: "unset",
  padding: "6px 9px 6px 9px",
  maxWidht: "72px",
  cursor: "pointer",
  ".buttonCal:hover": {
    backgroundColor: "unset",
    color: "#EC0B43",
  },
  lineHeightt: "14px",
  height: "26px",
  textTransform: "none",
};

export const close = {
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  float: "right",
  textTransform: "none",
  paddingRight: "10px",
  padding: "13px",
  borderRadius: "100% !important",
  minWidth: "37px !important",
  fontSize: "12px",
  border: "0px !important",
  backgroundColor: "#EEEEEE",
  ".MuiSvgIcon-root": {
    fontSize: "18px !important",
    paddingLeft: "0px",
    color: "#525A65",
  },
  "&:hover": {
    color: "#525A65",
    backgroundColor: "#dddddd",
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px",
  },
};
