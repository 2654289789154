import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Button,
  Divider,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSupabase } from "../../providers/AuthContextProvider";
import { getOnboardingFormById } from "../../services/query";
import { useState } from "react";

const ViewFormModal = ({ open, handleClose, formData, setopen, selectedForm, setSendFormOpen, setSelectedFormDetails, hideSendToClient }) => {
  const supabase = useSupabase();
  const [isSending, setIsSending] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  if (!formData) return null;
  
  const handleSendToClient = async () => {
    setIsSending(true);
    try {
      setSelectedFormDetails(formData);
      setSendFormOpen(true);
      handleClose();
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          borderRadius: isMobile ? "0px" : "24px",
          backgroundColor: "#FBFBFB",
          borderRadius:"24px",
          maxHeight: isMobile ? "60vh" : "70vh",
          width: {
            xs: "90%",
            sm: "80%",
            md: "60%",
            lg: "40%"
          },
          display: "flex",
          flexDirection: "column",
          m: isMobile ? 0 : 2
        },
      }}
    >
      <Box
        sx={{
          padding: {
            xs: "12px",
            sm: "16px",
            md: "20px"
          },
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          position: "relative"
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 0,
            paddingBottom: {
              xs: 1,
              sm: 2
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "#000000",
              ml:{xs:1},
              fontWeight: "600 !important",
              fontSize: {
                xs: "20px",
                sm: "22px",
                md: "24px"
              },
            }}
          >
            {formData.title}
          </Typography>
    
          <IconButton 
            onClick={handleClose} 
            sx={{ 
              padding: 0,
              transform: {
                xs: "scale(0.8)",
                sm: "scale(0.9)",
                md: "scale(1)"
              }
            }}
          >
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M32 18.3353L29.6647 16L24 21.6647L18.3353 16L16 18.3353L21.6647 24L16 29.6647L18.3353 32L24 26.3353L29.6647 32L32 29.6647L26.3353 24L32 18.3353Z" fill="#525A65"/>
              <circle cx="24" cy="24" r="24" fill="#EEEEEE"/>
              <path d="M17.1423 17.1428C17.7735 16.5117 18.7968 16.5117 19.428 17.1428L30.8564 28.5712C31.4876 29.2024 31.4876 30.2257 30.8564 30.8569C30.2252 31.4881 29.2019 31.4881 28.5707 30.8569L17.1423 19.4285C16.5111 18.7973 16.5111 17.774 17.1423 17.1428Z" fill="#525A65"/>
              <path d="M17.1428 30.8577C16.5117 30.2265 16.5117 29.2032 17.1428 28.572L28.5712 17.1436C29.2024 16.5124 30.2257 16.5124 30.8569 17.1436C31.4881 17.7748 31.4881 18.7981 30.8569 19.4293L19.4285 30.8577C18.7973 31.4889 17.774 31.4889 17.1428 30.8577Z" fill="#525A65"/>
            </svg>
          </IconButton>
        </DialogTitle>
    
        <DialogContent 
          sx={{ 
            padding: 0,
            overflowY: "auto",
            maxHeight: {
              xs: "calc(100vh - 180px)",
              sm: "50vh"
            },
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#B0BEC5",
              borderRadius: "4px",
            },
            position: "relative"
          }}
        >
          {formData?.custom_questions.length > 0 ? (
            formData?.custom_questions?.map((question, index) => (
              <Box 
                key={index} 
                sx={{ 
                  mb: 3,
                  px: {
                    xs: 1,
                    sm: 0
                  }
                }}
              >
                <Typography
                  sx={{
                    color: "#525A65",
                    fontSize: {
                      xs: "13px",
                      sm: "14px"
                    },
                    fontWeight: 500,
                    mb: 1,
                  }}
                >
                  Question ({index + 1})
                </Typography>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: {
                      xs: "13px",
                      sm: "14px"
                    },
                  }}
                >
                  {question.name}
                </Typography>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                textAlign: "center",
                padding: "20px",
                color: "#525A65",
                fontSize: "14px",
                fontWeight: "500",
            

              

              }}
            >
              <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" fill="#525A65"/>
              </svg>
              <Typography sx={{ mt: 1 
                
              }}>
                You haven't added any questions yet.
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Box>
    
      <Box 
        sx={{
          display: "flex", 
          alignItems: "center", 
          justifyContent: "flex-end", 
          background: "#F5F5F5",
          width: "100%", 
          padding: {
            xs: "12px",
            sm: "16px"
          },
          borderTop: "1px solid #E0E0E0",
          flexShrink: 0
        }}
      >
        {!hideSendToClient && (
          <Button
            onClick={handleSendToClient}
            disabled={isSending}
            sx={{
              textTransform: "none",
              backgroundColor: "#EEEEEE",
              border: "1px solid #525A65",
              color: "#0F0F0F",
              "&:hover": {
                backgroundColor: "#EEEEEE",
                border: "1px solid rgb(0, 0, 0)",
                transform: "scale(1.01)"
              },
              padding: {
                xs: "6px 12px",
                sm: "8px 16px"
              },
              borderRadius: "20px",
              fontWeight: 500,
              fontSize: {
                xs: "13px",
                sm: "14px"
              }
            }}
          >
            {isSending ? (
              <CircularProgress 
                size={isMobile ? 16 : 20} 
                sx={{ color: "#525A65", mr: 1 }} 
              />
            ) : null}
            Send to Client
          </Button>
        )}
      </Box>
    </Dialog>
  );
};

export default ViewFormModal;