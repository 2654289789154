import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Paper,
  Typography,
  TextField,
  CircularProgress,
  Snackbar,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useSupabase } from "../../providers/AuthContextProvider";
import { ToolTipComponent } from "../OnboardingFormDisplay/ToolTip";

export const QuestionForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const supabase = useSupabase();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(null);
  const [questionResponses, setQuestionResponses] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [formID,setFormID]=useState("")
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    if(id===null){
      return
    }
    const fetchForm = async () => {
      try {
        const { data: formResponse } = await supabase
          .from('onboarding_form_response')
          .select(`
            *,
            onboarding_forms:form_id (
              title,
              welcome_message
            ),
            athletes:athlete_id (
              full_name
            ),
            users:coach_id (
              full_name
            )
          `)
          .eq('id', id)
          .single();
          setFormID(formResponse.id)
        if (formResponse.status === 'completed') {
          setSnackbar({
            open: true,
            message: 'This form has already been submitted.',
            severity: 'info'
          });
          // Redirect after 2 seconds
          setTimeout(() => {
            navigate('/');  // or wherever you want to redirect
          }, 2000);
          return;
        }

        // Add athlete name and coach name to formData for easy access
        const formDataWithNames = {
          ...formResponse,
          athlete_name: formResponse?.athletes?.full_name || 'Unknown Athlete',
          coach_name: formResponse?.users?.full_name || 'Unknown Coach'
        };

        // Form soruları ve cevaplarını al
        const { data: questions } = await supabase
          .from('onboarding_forms_question_responses')
          .select('*')
          .eq('form_response_id',id);

        setFormData(formDataWithNames);
        setQuestionResponses(questions || []);
      } catch (error) {
        console.error("Error fetching form:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchForm();
  }, [id, supabase, navigate]);

  const handleResponseChange = (questionId, value) => {
    setQuestionResponses(prev => 
      prev.map(qr => 
        qr.id === questionId 
          ? { ...qr, response: value }
          : qr
      )
    );
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {

      // Her soru için cevabı güncelle
      for (const qr of questionResponses) {
        const { error: updateError } = await supabase
          .from('onboarding_forms_question_responses')
          .update({ 
            response: qr.response || null,  // Boş string yerine null kullan
            updated_at: new Date().toISOString()
          })
          .eq('id', qr.id);

        if (updateError) {
          console.error("Error updating question:", updateError);
          throw updateError;
        }
      }

      // Form durumunu güncelle
      const { error: formError } = await supabase
        .from('onboarding_form_response')
        .update({ 
          status: 'completed',
        updated_at: new Date().toISOString(),

        })
        .eq('id', id);

      if (formError) {
        console.error("Error updating form status:", formError);
        throw formError;
      }

      // Create notification for form submission
      const { error: notificationError } = await supabase
        .from('notifications')
        .insert({
          title: 'New Form Response',
           message : `<strong>${formData.athlete_name}</strong> has completed the <strong>${formData.onboarding_forms.title}</strong> form.`,
          action: 'form_submit',
          user_id: formData.coach_id,
          athlete_id: formData.athlete_id,
          isRead: false,
          isHidden: false,
          related_link:`https://app.coachplus.club/athlete/${formData.athlete_id}`
        });

      if (notificationError) {
        console.error("Error creating notification:", notificationError);
        // Don't throw error here to not affect form submission
      }

      setSnackbar({
        open: true,
        message: 'Form submitted successfully!',
        severity: 'success'
      });

      // Redirect after successful submission (after 2 seconds)
      setTimeout(() => {
        navigate('/');  // or wherever you want to redirect
      }, 2000);

    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbar({
        open: true,
        message: 'Error submitting form. Please try again.',
        severity: 'error'
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }
  if (formData?.status === 'completed'||snackbar.message==="This form has already been submitted.") {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography>This form has already been submitted.</Typography>
      </Box>
    );
  }
  if (!formData && !loading &&snackbar.open!==true) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography>Form not found</Typography>
      </Box>
    );
  }

  // Disable the form if it's already completed


  return (
    <>
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="100vh"
        sx={{ backgroundColor: "#F1F3F4" }}
      >
        <Paper 
          sx={{ 
            maxWidth: 600, 
            width: "100%", 
            p: 4, 
            m: 2,
            borderRadius: "24px",
            backgroundColor: "#FBFBFB",
            position:"relative"
          }}
        >

<Box>
            <Typography sx={{mb:3, fontSize:"14px", fontWeight:"bold!important"}}>
              <div style={{fontWeight:"800",position:"relative"}} role="img" aria-label="waving hand">
                <Box sx={{fontWeight:"800",width:"50%"}}>
                👋 From your Coach{formData.coach_name ? ", " + formData.coach_name : ""}

                </Box>
                <ToolTipComponent/>
              </div>
            </Typography>
          </Box>

          <Typography 
            variant="h5" 
            sx={{ 
              mb: 3,
              color: "#000000",
              fontWeight: 600,
              fontSize: "24px"
            }}
          >
            {formData.onboarding_forms.title}
          </Typography>

          <Typography 
            sx={{ 
              mb: 4,
              color: "#000000",
              fontSize: "14px"
            }}
          >
            {formData.onboarding_forms.welcome_message}
          </Typography>

          <Box sx={{ 
            mb: 4,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            
           
          </Box>

          {questionResponses.map((qr, index) => (
            <Box key={qr.id} mb={4}>
              <Typography 
                sx={{ 
                  mb: 1,
                  color: "#525A65",
                  fontSize: "14px",
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
               <Typography
               sx={{
                marginLeft:"10px",

               }}
               >
                
                </Typography> 

              </Typography>
              
              <TextField
                fullWidth
                multiline={qr.question_type === "long_text"}
                rows={qr.question_type === "long_text" ? 4 : 1}
                label={qr.question}
                value={qr.response || ""}
                onChange={(e) => handleResponseChange(qr.id, e.target.value)}
                placeholder="Your answer here..."
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "16px",
                    backgroundColor: "#FFFFFF",
                 
                  },
                  ".MuiInputBase-input": {
                    fontSize: "14px",
                    color: "#525A65"
                  }
                }}
              />
            </Box>
          ))}

          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              onClick={handleSubmit}
              disabled={submitting}
              sx={{
                color: "#02BD36",
                border: "1px solid #02BD36",
                borderRadius: "20px",
                textTransform: "none",
                padding: "11px 32px",
                fontSize: "14px",
                minWidth: '120px',
                "&:hover": {
                  backgroundColor: "transparent",
                  opacity: 0.8
                },
                "&:disabled": {
                  borderColor: "#02BD36",
                  color: "#02BD36",
                  opacity: 0.5
                }
              }}
            >
              {submitting ? (
                <CircularProgress 
                  size={20} 
                  sx={{ 
                    color: "#02BD36"
                  }} 
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={4000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box
          sx={{
            minWidth: '320px',
            backgroundColor: snackbar.severity === 'success' ? '#E7F7EA' : '#FFEBEF',
            borderRadius: '16px',
            padding: '16px 24px',
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
          }}
        >
          {snackbar.severity === 'success' ? (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 2C6.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14C7.79 4 6 5.79 6 8h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z" 
                fill="#02BD36"/>
            </svg>
          ) : (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM16.3 16.3C15.91 16.69 15.28 16.69 14.89 16.3L12 13.41L9.11 16.3C8.72 16.69 8.09 16.69 7.7 16.3C7.31 15.91 7.31 15.28 7.7 14.89L10.59 12L7.7 9.11C7.31 8.72 7.31 8.09 7.7 7.7C8.09 7.31 8.72 7.31 9.11 7.7L12 10.59L14.89 7.7C15.28 7.31 15.91 7.31 16.3 7.7C16.69 8.09 16.69 8.72 16.3 9.11L13.41 12L16.3 14.89C16.68 15.28 16.68 15.91 16.3 16.3Z" 
                fill="#EC0B43"/>
            </svg>
          )}
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: snackbar.severity === 'success' ? '#02BD36' : '#EC0B43',
              flex: 1
            }}
          >
            {snackbar.message}
          </Typography>
        </Box>
      </Snackbar>
    </>
  );
}; 