import React, { useEffect, useRef, useState } from "react";
import {
  ListItem,
  ListItemText,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Modal,
  Button,
  Dialog,
  Grow,
  Fade,
  Skeleton,
} from "@mui/material";
import { FirstVideoImage } from "../../components/TPDisplay/TPDisplay";
import { MenuIcon } from "./style";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { getYouTubeVideoId } from "../../utils/validate";
import { TPModal } from "../../components/TPDisplay/OpenmodelTP";
import { upsertExercise } from "../../services/query";
import { ExerciseModalLibrary } from "./Exercise_libraryModal";
import CheckIcon from "@mui/icons-material/Check"; // Import CheckIcon

const SuccessIcon = () => {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45" cy="45" r="45" fill="#013B12" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M66.8721 23.8585C65.701 23.9408 64.6123 24.4923 63.8536 25.3814L38.0645 55.2619L25.9741 42.7993C24.2834 41.0625 21.4959 41.0295 19.764 42.7252C19.764 42.7252 19.7557 42.7335 19.7475 42.7417C18.0238 44.4374 17.9908 47.195 19.6815 48.9236L35.0955 64.8105C36.7862 66.5474 39.5655 66.5968 41.3057 64.9093C41.4047 64.8187 41.4954 64.72 41.5779 64.6212L70.5008 31.0858C72.0843 29.2502 71.8781 26.4844 70.0473 24.9039C69.1648 24.1549 68.0267 23.7844 66.8721 23.8667V23.8585Z"
        fill="#02BD36"
      />
    </svg>
  );
};

const ExerciseLibrarySkeleton = () => {
  return Array(5).fill(0).map((_, index) => (
    <Fade in={true} timeout={300} style={{ transitionDelay: `${index * 50}ms` }} key={index}>
      <ListItem
        sx={{
          padding: {xs:"5px",sm:"10px"},
          backgroundColor: "#F1F4F1",
          borderRadius: "8px",
          marginBottom: "8px",
          display: "flex",
          alignItems: "center",
          animation: "shimmer 1.5s infinite",
          "@keyframes shimmer": {
            "0%": {
              opacity: 1,
            },
            "50%": {
              opacity: 0.7,
            },
            "100%": {
              opacity: 1,
            }
          }
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Skeleton 
            variant="rectangular" 
            width={100} 
            height={56} 
            sx={{ 
              borderRadius: "10px",
              backgroundColor: "#E5E5E5"
            }}
          />
          <Box display={"flex"} flexDirection={"column"} sx={{ ml: 1, flex: 1 }}>
            <Skeleton 
              variant="text" 
              width="60%" 
              height={24}
              sx={{ backgroundColor: "#E5E5E5" }}
            />
            <Skeleton 
              variant="text" 
              width="80%" 
              height={20}
              sx={{ backgroundColor: "#E5E5E5" }}
            />
          </Box>
          <Skeleton 
            variant="circular" 
            width={32} 
            height={32}
            sx={{ backgroundColor: "#E5E5E5" }}
          />
        </Box>
      </ListItem>
    </Fade>
  ));
};

export const ExerciseLibrary = ({ 
  user, 
  eSearch, 
  supabase, 
  reload, 
  fromAthleteRoute, 
  handleExerciseSelection,
  athlete,
  date
}) => {
  const [displayedExercises, setDisplayedExercises] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openExerciseModal, setOpenExerciseModal] = useState(false);
  const [SelectedVideo, setSelectedVideo] = useState(null);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [SelectedExercise, setSelectedExercise] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
  };

  const observer = useRef();
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null);
    setSaveSuccess(false);
  };

  const handleClickExercise = (videoId, event) => {
    if (event) {
      event.stopPropagation();
    }
    setSelectedVideo(videoId);
    setOpenModal(true);
  };

  useEffect(() => {
    const fetchExercises = async () => {
      setLoading(true);
      const find = displayedExercises.find((e) => e.title === eSearch);
      if (!find) {
        const exercises = await getExercisesFromSupabase(
          page,
          supabase,
          eSearch
        );
        setDisplayedExercises((prev) => [...prev, ...exercises]);
      }
      setLoading(false);
    };

    fetchExercises();
  }, [page, eSearch]);

  const lastExerciseElementRef = useRef();

  useEffect(() => {
    const observerCallback = (entries) => {
      if (entries[0].isIntersecting && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const currentObserver = observer.current;
    if (currentObserver) {
      currentObserver.disconnect();
    }

    const newObserver = new IntersectionObserver(observerCallback);
    if (lastExerciseElementRef.current) {
      newObserver.observe(lastExerciseElementRef.current);
    }

    observer.current = newObserver;

    return () => {
      if (currentObserver) {
        currentObserver.disconnect();
      }
    };
  }, [loading]);

 

  return (
    <>
       {showSuccessModal && (
         <SuccessModal 
          open={showSuccessModal} 
          onClose={() => setShowSuccessModal(false)} 
        />
       )}
      {loading ? (
        <ExerciseLibrarySkeleton />
      ) : displayedExercises.length === 0 ? (
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            pt: 8,
            color: '#525A65'
          }}
        >
          <Typography 
            sx={{ 
              fontSize: '24px', 
              fontWeight: 600,
              mb: 2,
              color: '#525A65',
              fontFamily: 'Bricolage Grotesque'
            }}
          >
            No exercises found
          </Typography>
          <Typography 
            sx={{ 
              fontSize: '16px', 
              color: '#525A65',
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 400
            }}
          >
            Try searching with different keywords
          </Typography>
        </Box>
      ) : (
        displayedExercises
          .filter((e) => e.title.toLowerCase().includes(eSearch.toLowerCase()))
          .map((exercise, index) => {
            const isLastElement = displayedExercises.length === index + 1;
            return (
              <Fade in={true} timeout={300} style={{ transitionDelay: `${index * 50}ms` }}>
                <ListItem
                  key={index}
                  ref={isLastElement ? lastExerciseElementRef : null}
                  onClick={(event) => {
                    if (
                      event.target.closest("#basic-button") ||
                      event.target.closest(".MuiMenu-root")
                    ) {
                      return;
                    }
                    if (fromAthleteRoute) {
                      handleExerciseSelection(exercise);
                    } else {
                      setSelectedExercise(exercise);
                      setOpenExerciseModal(true);
                    }
                  }}
                  draggable={fromAthleteRoute}
                  onDragStart={(ev) => {
                    if (fromAthleteRoute) {
                      // Set data for the drag operation
                      ev.dataTransfer.setData("plan", JSON.stringify({
                        ...exercise,
                        is_single_exercise: true
                      }));
                      ev.dataTransfer.effectAllowed = "copy";
                      
                      // Add a class to the element while dragging
                      ev.currentTarget.classList.add("dragging");
                    }
                  }}
                  onDragEnd={(ev) => {
                    // Remove the class when dragging ends
                    ev.currentTarget.classList.remove("dragging");
                  }}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: saveSuccess === exercise.id ? "#E9FFCE" : "#F1F4F1",
                    borderRadius: "8px",
                    marginBottom: "8px",
                    transition: "all 0.3s",
                    padding: {xs:"5px",sm:"10px"},
                    "&:hover": {
                      transform: "translateY(-2px)",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.05)",
                      backgroundColor: "#E9FFCE",
                    },
                    '&.dragging': {
                      opacity: 0.7,
                      transform: 'scale(0.95)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                    }
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {exercise.video_link ? (
                          <>
                            <Box className="exercise-image" sx={{ transition: 'transform 0.2s ease-in-out' }}>
                              <FirstVideoImage
                                videoId={getYouTubeVideoId(exercise.video_link)}
                                height="56px"
                                width="100px"
                                borderRadius="10px"
                                handleClickExercise={(event) =>
                                  handleClickExercise(
                                    getYouTubeVideoId(exercise.video_link),
                                    event
                                  )
                                }
                              />
                            </Box>

                            <Box display={"flex"} flexDirection={"column"}>
                              <Typography
                                className="exercise-title"
                                component="span"
                                fontWeight="600  !important"
                                fontSize={"12px"}
                                color={"#525A65"}
                                ml={1}
                                mb={1}
                                sx={{ transition: 'color 0.2s ease' }}
                              >
                                {exercise.title}
                              </Typography>
                              <Typography
                                component="span"
                                fontWeight="400  !important"
                                fontSize={"10px"}
                                color={"#525A65"}
                                ml={1}
                              >
{exercise.description?.length > 75 
  ? exercise.description.substring(0, 75).trim() + "..." 
  : exercise.description}

                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            <img
                              src={require("../../assets/Rectangle-ccc.png")}
                              alt={"empty"}
                              style={{
                                maxWidth: 100,
                                maxHeight: 56,
                                width: "100%",
                                height: "100%",
                              }}
                            />
                            <Box display={"flex"} flexDirection={"column"}>
                              <Typography
                                component="span"
                                fontWeight="600  !important"
                                fontSize={"12px"}
                                color={"#525A65"}
                                ml={1}
                                mb={1}
                              >
                                {exercise.title}
                              </Typography>
                              <Typography
                                component="span"
                                fontWeight="400  !important"
                                fontSize={"10px"}
                                color={"#525A65"}
                                ml={1}
                              >
                                {exercise.description?.substring(0, 150)}
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Box>
                    }
                  />
                  <IconButton
                    id="basic-button"
                    aria-controls={
                      openMenuIndex === index ? "basic-menu" : undefined
                    }
                    aria-haspopup="true"
                    sx={{ 
                      padding: "16px 10px 16px 10px !important",
                      transition: 'transform 0.2s ease',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        backgroundColor: 'transparent'
                      }
                    }}
                    aria-expanded={openMenuIndex === index ? "true" : undefined}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick(event, index);
                    }}
                  >
                    <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="2" cy="14" r="2" transform="rotate(180 2 14)" fill="#525A65"/>
                      <circle cx="2" cy="8" r="2" transform="rotate(180 2 8)" fill="#525A65"/>
                      <circle cx="2" cy="2" r="2" transform="rotate(180 2 2)" fill="#525A65"/>
                    </svg>
                  </IconButton>
                  <Menu
                    sx={{
                      '& .MuiPaper-root': {
                        backgroundColor: '#F1F4F1',
                        borderRadius: '16px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        minWidth: '180px',
                        paddingX:"4px",
                        animation: 'menuSlideIn 0.2s ease-out',
                        "@keyframes menuSlideIn": {
                          "0%": {
                            opacity: 0,
                            transform: "translateY(-10px)"
                          },
                          "100%": {
                            opacity: 1,
                            transform: "translateY(0)"
                          }
                        }
                      },
                      '& .MuiMenuItem-root': {
                        height: '25px',
                        padding: '4px 16px',
                      }
                    }}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenuIndex === index}
                    onClose={(event) => {
                      event.stopPropagation();
                      handleClose();
                    }}
                    onClick={(event) => event.stopPropagation()}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      sx={{ fontSize: "14px", color: "#525A65",":hover":{
                            fontWeight:"500",
                              background:"unset!important"
                              
                      } }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setSelectedExercise(exercise);
                        setOpenExerciseModal(true);
                        handleClose();
                      }}
                    >
                      View Exercise
                    </MenuItem>
                    <Divider sx={{ my: 0.5, mr: 1, ml: 1 }} />
                    <MenuItem
                      sx={{ fontSize: "14px", color: "#525A65",
                        ":hover":{
                              fontWeight:"500",
                              background:"unset"
                        }
                       }}
                      onClick={async (event) => {
                        event.stopPropagation();
                        setSaving(true);
                        const payload = {
                          description: exercise.description,
                          title: `${exercise.title}`,
                          tags: exercise.Muscle_Equipment_type,
                          requires_equipment: false,
                          images: [],
                          videos: [
                            {
                              id: getYouTubeVideoId(exercise.video_link),
                              url: exercise.video_link,
                            },
                          ],
                          coach_id: user.id,
                        };
                        try {
                          await upsertExercise(supabase, payload);
                          handleClose();
                          setShowSuccessModal(true);
                          reload();
                        } catch (error) {
                          console.error("Error saving exercise:", error);
                        } finally {
                          setSaving(false);
                        }
                      }}
                    >
                      {saving ? (
                        <CircularProgress size={24} />
                      ) : saveSuccess ? (
                        <CheckIcon color="success" />
                      ) : (
                        "Save to My Exercises"
                      )}
                    </MenuItem>
                  </Menu>
                </ListItem>
              </Fade>
            );
          })
      )}
      {loading && (
        <Box sx={{ 
          display: "flex", 
          justifyContent: "center", 
          my: 2,
          '@keyframes pulse': {
            '0%': { opacity: 0.6 },
            '50%': { opacity: 1 },
            '100%': { opacity: 0.6 }
          },
          animation: 'pulse 1.5s infinite ease-in-out'
        }}>
          <CircularProgress sx={{ color: '#013B12' }} />
        </Box>
      )}
      
      {openExerciseModal && (
        <ExerciseModalLibrary
          handleClose={() => setOpenExerciseModal(false)}
          exercise={SelectedExercise}
          open={openExerciseModal}
        />
      )}
      {openModal && (
        <TPModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          selected={SelectedVideo}
          exerciseplan={displayedExercises}
        />
      )}
     
  
    </>
  );
};

export const getExercisesFromSupabase = async (page, supabase, eSearch) => {
  const pageSize = 10;
  const offset = (page - 1) * pageSize;

  if (eSearch !== "") {
    const { data, error } = await supabase
      .from("fill_exercise")
      .select("*")
      .eq("type", "Solo") // type'ı "Solo" olanları filtrele
      .ilike("title", `%${eSearch}%`); // title'a benzer olanları getir

    if (error) {
      console.error("Error fetching exercises:", error);
      return [];
    }
    return data || []; // Ensure data is returned as an empty array if null
  } else {
    const { data, error } = await supabase
      .from("fill_exercise")
      .select("*")
      .eq("type", "Solo")
      .order("title", { ascending: true })
      .range(offset, offset + pageSize - 1);

    if (error) {
      console.error("Error fetching exercises:", error);
      return [];
    }
    return data || []; // Ensure data is returned as an empty array if null
  }
};
 export const SuccessModal =({ open, onClose }) => {
    return (
      <Dialog
        open={open} 
        onClose={onClose}
        TransitionComponent={Grow}
        TransitionProps={{
          timeout: 400
        }}
        PaperProps={{
          style: {
            borderRadius: '24px',
            padding: '32px',
            maxWidth: '450px',
            width: '90%',
            animation: 'modalScale 0.4s ease-out',
            '@keyframes modalScale': {
              '0%': {
                transform: 'scale(0.9)',
                opacity: 0
              },
              '100%': {
                transform: 'scale(1)',
                opacity: 1
              }
            }
          }
        }}
      >
        <Box sx={{ 
          textAlign: 'center',
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          mt: 4
        }}>
          <Typography variant="h5" sx={{ 
            color: '#525A65',
            fontSize: '24px',
            fontWeight: 600,
            mb: 3,
          
          }}>
            Saved!
          </Typography>
          
          <Box sx={{ 
            mb: 4,
            animation: 'successIconAnimation 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
            '@keyframes successIconAnimation': {
              '0%': {
                transform: 'scale(0)',
                opacity: 0
              },
              '100%': {
                transform: 'scale(1)',
                opacity: 1
              }
            }
          }}>
            <SuccessIcon />
          </Box>
  
          <Typography sx={{ 
            mb: 4,
            color: '#0F0F0F',
            fontSize: {
              xs: "12px",
              sm: "14px",
              md: "14px",
              lg: "16px",
              xl: "16px",
            },
           
          }}>
            A copy of the exercise you selected has been successfully saved to your exercises.
            <br />
            
            <Typography sx={{
              color: '#0F0F0F',
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "14px",
                lg: "16px",
                xl: "16px",
              },
       
            }}>
              You can now view and edit it in 'My exercises'.     
            </Typography>
          </Typography>
  
          <Box
            sx={{
              display:"flex",
              justifyContent:"center",
              width:"100%",
            }}>
            <Button
              onClick={onClose}
              sx={{
                color: '#0F0F0F',
                backgroundColor: '#EEEEEE',
                border: "1px solid #525A65",
                textTransform: "none",
                fontSize:"16px",
                borderRadius: '20px',
                mt: 2,
                padding: '9px 17px',
                transition: 'all 0.2s ease',
                
                '&:hover': {
                  backgroundColor: '#E0E0E0',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}
            >
              Okay!
            </Button>
          </Box>
        </Box>
      </Dialog>
    );
  }