import { useState, useEffect } from "react";
import { UploadCloud, Loader2 } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { supabase } from "../../services/supabase";
import { Box, Button, Typography } from "@mui/material";

export default function ImageUpload({ setImages, images, isSubmit, handleClose, setWaitingImages, setImageSubmitted }) {
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileImages, setFileImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const truncateFilename = (filename) => {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) return filename.length > 34 ? filename.substring(0, 31) + '...' : filename;
    
    const name = filename.substring(0, lastDotIndex);
    const extension = filename.substring(lastDotIndex);
    
    return name.length > 31 ? `${name.substring(0, 31)}...${extension}` : filename;
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const files = event.target.files;
    
    if (files && files.length > 0) {
      const file = files[0];
      // Reset the input value to ensure the same file can be selected again
      event.target.value = '';
      setImageFile(file);
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type.startsWith('image/')) {
        setImageFile(file);
      } else {
        alert('Please drop an image file');
      }
    }
  };

  const uploadImage = async () => {
    if (!imageFile) return alert("Please select an image!");
    if (loading) return;
    setLoading(true);
    
    const fileExtension = imageFile.name.split(".").pop() || "jpg";
    const uniqueId = uuidv4();
    const fileName = `custom_image_${uniqueId}`; 
  
    setImages((prev) => [
      ...prev,
      {
        id: fileName,
        url: fileName,
        fileExtension: fileExtension,
        originalName: imageFile.name
      },
    ]);
  
    setFileImages((prev) => [
      ...prev,
      {
        id: fileName,
        file: imageFile,
        fileExtension: fileExtension,
        originalName: imageFile.name
      },
    ]);
    setWaitingImages((prev) => [
      ...prev,
      {
        id: fileName,
        file: imageFile,
        fileExtension: fileExtension,
        originalName: imageFile.name
      },
    ]);
  
    setLoading(false);
    setImageFile(null);
  };
  const onDelete = () => {
    setImages([]);
    setWaitingImages([]);
    setFileImages([]);
    setImageFile(null);
    // Reset the file input
    const fileInput = document.getElementById('image-upload');
    if (fileInput) {
      fileInput.value = '';
    }
  };
  
  useEffect(() => {
    if (isSubmit) {
      const uploadCustomImages = async () => {
        const updatedImages = [...images]; 
  
        for (const image of images) {
          const matchedFile = fileImages.find((fv) => fv.id === image.id);
          if (!matchedFile) continue; 
          
          const file = matchedFile.file;
          const fileExtension = file.name.split(".").pop() || "jpg";
          const fileName = `custom_image_${image.id}`;
  
          const { data, error } = await supabase.storage
            .from("custom_videos")
            .upload(fileName, file);
  
          if (error) {
            console.error("Upload error:", error.message);
            continue;
          }
  
          const { data: publicData } = supabase.storage
            .from("custom_videos")
            .getPublicUrl(fileName);
  
          updatedImages.forEach((v) => {
            if (v.id === image.id) {
              v.url = publicData.publicUrl;
            }
          });
        }
  
        setImages(updatedImages);
      };
  
      uploadCustomImages();
      setTimeout(() => {
        setImageSubmitted(true);
      }, 1000);
    }
  }, [isSubmit]);

  return (
    <Box sx={{ width: "100%", mt: 2,
      
     }}>
      <Typography variant="h6" sx={{ 
       fontSize: "14px", 
       fontWeight: 500, 
       color: "#525A65",
        mb: 1 
      }}>
        Upload your photos
      </Typography>
      
      <Box sx={{

        border: "1px dashed",
        borderColor: isDragging ? "#02BD36" : "#DAD9D8",
        borderRadius: "12px",
        p: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isDragging ? "rgba(2, 189, 54, 0.05)" : "#fff",
        height: "120px",
        cursor: "pointer",
        position:"relative",
        transition: "all 0.2s ease",
        '&:hover': {
          borderColor: "#02BD36",
          backgroundColor: "rgba(2, 189, 54, 0.05)"
        }
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      >
       
        <input
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          id="image-upload"
        />
        
     
        <label htmlFor="image-upload" style={{ cursor: "pointer", textAlign: "center" }}>

        {
          images.length===0?
          <>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 0C1.8048 0 0 1.8048 0 4V28C0 30.1952 1.8048 32 4 32H28C30.1952 32 32 30.1952 32 28V4C32 1.8048 30.1952 0 28 0H4ZM4 2.6624H28C28.7616 2.6624 29.3312 3.232 29.3312 4V27.4496L13.8816 12C13.0304 11.1424 11.872 10.6624 10.6624 10.6624C9.4528 10.6624 8.2944 11.1424 7.4432 12L2.6624 16.7808V4C2.6624 3.2384 3.232 2.6624 3.9936 2.6624H4Z" fill="#CFCFCF"/>
            <path d="M24.0008 5.33203C22.5416 5.33203 21.332 6.54163 21.332 8.00083C21.332 9.46003 22.5416 10.6696 24.0008 10.6696C25.46 10.6696 26.6696 9.46003 26.6696 8.00083C26.6696 6.54163 25.46 5.33203 24.0008 5.33203Z" fill="#CFCFCF"/>
          </svg>
          <Typography sx={{ 
          wordBreak: "break-word", // Breaks long words
          overflowWrap: "break-word", // Ensures wrapping
          whiteSpace: "normal", // Allows multiline 
 fontSize: {
  xs: "11px",  // Extra small screens
  sm: "11px",  // Small screens
  md: "11px",  // Medium screens
  lg: "12px",  // Large screens
  xl: "12px",  // Extra large screens
},            color: "#525A65",
            display: "block" 
          }}>
         
{images.length===0&&(imageFile ? imageFile.name : "Drop your image or browse")}

            </Typography>
          </>:
            
              <Box sx={{minHeight:"100px",width:"100%", borderRadius: '8px',position:"absolute",left:"0",top:"10px",overflow:"scroll",maxHeight:"75%"}}>
                {images?.map((image, index) => (
                  <Box key={image.id} sx={{overflow:"hidden",padding:"3px 15px",width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: index !== image.length - 1 ? 1 : 0 }}>
                    <Typography sx={{ fontSize: {

      xs: "11px",  // Extra small screens
      sm: "11px",  // Small screens
      md: "11px",  // Medium screens
      lg: "12px",  // Large screens
      xl: "12px",  // Extra large screens
    }, 
    wordBreak: "break-word", // Breaks long words
    overflowWrap: "break-word", // Ensures wrapping
    whiteSpace: "normal", // Allows multiline 
    padding:"0",
    
    color: '#525A65',textAlign:"left",
    }}>
                      {truncateFilename(image.originalName || `${image.id.replace('custom_image_', '')}.${image.fileExtension}`)}
                    </Typography>
                    <Box sx={{
                      animation: 'successIconAnimation 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                      '@keyframes successIconAnimation': {
                        '0%': {
                          transform: 'scale(0)',
                          opacity: 0
                        },
                        '100%': {
                          transform: 'scale(1)',
                          opacity: 1
                        }
                      }
                    }}>
                      <svg width="16" height="12" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11649 0.000576902C8.89608 0.0161488 8.69119 0.120481 8.54839 0.288658L3.69467 5.94128L1.41915 3.58368C1.10095 3.25511 0.576304 3.24889 0.250342 3.56967C0.250342 3.56967 0.24879 3.57122 0.247238 3.57278C-0.0771712 3.89356 -0.08338 4.41522 0.23482 4.74223L3.13588 7.74762C3.45408 8.07619 3.97717 8.08553 4.30468 7.76631C4.32331 7.74918 4.34038 7.73049 4.3559 7.71181L9.79946 1.36779C10.0975 1.02054 10.0587 0.497322 9.71409 0.198341C9.548 0.0566359 9.3338 -0.0134379 9.11649 0.0021341V0.000576902Z" fill="#02BD36"/>
                      </svg>
                    </Box>
                  </Box>
                ))}
                   {(imageFile) && 

<Box sx={{padding:"3px 15px",width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
  <Typography sx={{   fontSize: {
xs: "11px",  // Extra small screens
sm: "11px",  // Small screens
md: "11px",  // Medium screens
lg: "12px",  // Large screens
xl: "12px",  // Extra large screens
}, color: '#525A65', padding:"0",textAlign:"left",
wordBreak: "break-word", // Breaks long words
overflowWrap: "break-word", // Ensures wrapping
whiteSpace: "normal", // Allows multiline 
padding:"0",}}>
    {(imageFile&&imageFile.name) }
    </Typography>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7" stroke="#525A65" strokeWidth="1.5"/>
    <path d="M8 4.5V8L10.5 9.5" stroke="#525A65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

</Box>
}
              </Box>
            
           }

        </label>
      </Box>

    

      <Box sx={{display:"flex", justifyContent:"space-between",alignItems:"center",mt:2}} >
      <Box sx={{display:"flex",alignItems:"center",gap:2}}>
      <Box
            component="button"
            onClick={uploadImage}
            disabled={loading || !imageFile}
            sx={{
              background: "#525A65",
              color: "#E3E2E0",
              border: "none",
              borderRadius: "16px",
              px: 1,
              py: 1,
              cursor: "pointer",
              fontSize: "12px",
              fontWeight: 500,
              transition: "background-color 0.2s ease",
              '&:disabled': {
                backgroundColor: "#ccc",
                cursor: "not-allowed"
              },
              '&:hover:not(:disabled)': {
                backgroundColor: "#029c2e"
              }
            }}
          >
            {loading ? <Loader2 style={{ animation: "spin 1s linear infinite" }} /> : "Upload"}
          </Box>
        
          {images?.length>0&&(images?.length===1?images?.length+" file":images?.length+" files")}
          </Box>

          {images?.length > 0 && (
            <Button 
              onClick={onDelete}
              sx={{
                minWidth: 'unset',
                padding: 0,
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'scale(1.1)',
                  backgroundColor: 'transparent'
                }
              }}
            >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z" fill="#EC0B43"/>
            <g clip-path="url(#clip0_3306_11904)">
            <path d="M6.47287 5L9.99648 8.52361L13.5201 5L15 6.47287L11.4764 9.99648L15 13.5201L13.5201 15L9.99648 11.4764L6.47287 15L5 13.5201L8.52361 9.99648L5 6.47287L6.47287 5Z" fill="#F1F3F4"/>
            </g>
            <defs>
            <clipPath id="clip0_3306_11904">
            <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
            </clipPath>
            </defs>
            </svg>
            </Button>
          )}
          </Box>
    </Box>
  );
}
