import React from "react";
import {
  Box,
  Button,
  Dialog,
  Typography
} from "@mui/material";
import { IconClose } from "../ExerciseModal/ExerciseModal";
import { SuccessIcon } from "../OnboardingFormModal/OnboardingFormModal";

/**
 * Verification success modal shown after welcome step
 */
const VerificationSuccessModal = ({
  open,
  onClose,
  title = "Success!",
  message = "Verification has been successfully completed!  ",
  message2 = "You may now continue to the next step and access all available features.",
  buttonText = "Okay!",
  onButtonClick = null
}) => {
  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: {xs: '90%', sm: '529px'},
          height: {xs: 'auto', sm: '570px'},
          margin: 'auto',
          background: '#FBFBFB',
          borderRadius: '24px',
          display: 'flex',
          flexDirection: 'column',
          p: 6,
          position: 'relative',
          maxWidth: '90vw'
        }
      }}
    >
      <Box sx={{
        position: 'absolute',
        right: 16,
        top: 16,
        "&:hover": {
          background: "transparent",
          scale: 1.05
        },
        cursor: "pointer"
      }}
      onClick={onClose}
      >
        <IconClose />
      </Box>
      
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        pt: 4,
      }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          width: '100%',
          maxWidth: '370px'
        }}>
          <Typography sx={{
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '29px',
            textAlign: 'center',
            color: '#525A65',
            mb: 3
          }}>
            {title}
          </Typography>
          
          {/* Success Icon */}
          <Box sx={{
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            background: '#013B12',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 4,
            mt: 2
          }}>
            <SuccessIcon/>
          </Box>
          
          <Typography sx={{
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'center',
            color: '#0F0F0F',
          }}>
            {message}
          </Typography>
          <Typography sx={{
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'center',
            color: '#0F0F0F',
            mt: 1.5,
          }}>
            {message2}
          </Typography>
        </Box>
        
        <Button
          onClick={handleButtonClick}
          sx={{
            background: '#EEEEEE',
            border: '1px solid #525A65',
            borderRadius: '20px',
            padding: '15px 22px',
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#0F0F0F',
            textTransform: 'none',
            minWidth: '80px',
            marginTop: 'auto',
            '&:hover': {
              background: '#E0E0E0'
            }
          }}
        >
          {buttonText}
        </Button>
      </Box>
    </Dialog>
  );
};

export default VerificationSuccessModal; 