import { Box, IconButton, Tooltip } from "@mui/material"

export const ToolTipComponent = ()=>{
return(<Box sx={{
    backgroundColor: '#F8F9FA',
    borderRadius: '50%',
    width: '15px',
    height: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E7E9EB',
    position:"absolute",
    right:"2%",
    top:"3%"
  }}>
    <Tooltip
      title="Your answers will help us tailor our coaching approach to your specific needs and goals"
      placement="left"
      arrow
    
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#525A65',
            fontSize: '12px',
            maxWidth: '220px', // default max width
            padding: '8px 16px',
            borderRadius: '8px',
            '@media (max-width: 600px)': { // for small screens
              fontSize: '13px',
              maxWidth: '180px', // smaller max width
              padding: '6px 12px', // adjust padding
            },
            '@media (max-width: 400px)': { // for very small screens
              fontSize: '13px',
              maxWidth: '150px', 
              padding: '4px 8px',
            }
          }
        }
      }}
      
    >
      <IconButton
        size="small"
        sx={{ 
          padding: 0,
          width: '16px',
          height: '16px',
          '&:hover': { backgroundColor: 'transparent' }
        }}
      >
        <svg width="16" height="16" viewBox="0 0 20 20" fill="none">
          <path d="M9 16h2v-2H9v2zm1-16C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14C7.79 4 6 5.79 6 8h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z" fill="#838F99"/>
        </svg>
      </IconButton>
    </Tooltip>
  </Box>)}