import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography
} from "@mui/material";
import { IconClose } from "../ExerciseModal/ExerciseModal";
import ToolsWalkthroughModal from "./ToolsWalkthroughModal";
import ClientsWalkthroughModal from "./ClientsWalkthroughModal";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";

/**
 * A reusable welcome/onboarding modal component
 */
const WelcomeModal = ({
  open,
  onClose,
  title = "Hello!",
  message = "Welcome to Coach Plus.",
  description = "Simplify your workflow, enhance client engagement, and focus on growth.",
  steps = [
    { number: 1, text: "Explore library and build workouts" },
    { number: 2, text: "Invite first client" }
  ],
  buttonText = "Let's go!",
  onButtonClick = null
}) => {
  const { user } = useAuth();
  const supabase = useSupabase();
  
  // Flow control states
  const [showWelcome, setShowWelcome] = useState(true); // Set to true by default
  const [showClients, setShowClients] = useState(false);
  const [showTools, setShowTools] = useState(false);

  // Scroll to top whenever the modal state changes
  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0);
    }
  }, [open, showWelcome, showTools, showClients]);

  const handleWelcomeComplete = () => {
    setShowWelcome(false);
    setShowTools(true);
  };

  const handleToolsComplete = () => {
    setShowTools(false);
    setShowClients(true);
  };

  const handleClientsComplete = async () => {
    try {
      // Update is_first_login to false in the database
      const { error } = await supabase
        .from('users')
        .update({ is_first_login: false })
        .eq('id', user.id);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating is_first_login:', error);
    }finally{
      window.location.reload();
    }
    onClose();
  };

  const handleSkipFlow = async () => {
    try {
      // Update is_first_login to false in the database
      const { error } = await supabase
        .from('users')
        .update({ is_first_login: false })
        .eq('id', user.id);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating is_first_login:', error);
    }
    onClose();
  };

  const handleClose = async () => {
    try {
      // Update is_first_login to false in the database
      const { error } = await supabase
        .from('users')
        .update({ is_first_login: false })
        .eq('id', user.id);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating is_first_login:', error);
    }
    setShowWelcome(false);
    setShowTools(false);
    setShowClients(false);
    onClose();
  };

  return (
    <>
      {/* Welcome Modal (shown first) */}
      <Dialog
        open={open && showWelcome && !showTools && !showClients}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
          }
        }}
        PaperProps={{
          sx: {
            width: {xs: '90%', sm: '529px'},
            height: {xs: 'auto', sm: '525px'},
            margin: 'auto',
            background: '#FBFBFB',
            borderRadius: '24px',
            display: 'flex',
            overflow:"hidden",
            flexDirection: 'column',
            p: {xs:3,sm:4},
            position: 'relative',
          }
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            "&:hover": {
              background: "transparent",
              scale: 1.05
            }
          }}
        >
          <IconClose />
        </IconButton>
        
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          pt: 4
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '370px'
          }}>
            <Typography sx={{
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 600,
              fontSize: {xs: '48px', sm: '64px'},
              lineHeight: '77px',
              textAlign: 'center',
              color: '#000000',
              mb: 3
            }}>
              {title}
            </Typography>
            
            <Typography sx={{
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '24px',
              textAlign: 'center',
              color: '#02BD36',
              maxWidth: '370px',
            }}>
              {message}
              <br />
              <Typography 
                sx={{
                  fontSize: {xs:"15px",sm:"16px"},
                  fontWeight: 400,
                  lineHeight: "24px",
                  color: "#000000",
                  marginTop: "10px"
                }}
              >
                {description}
              </Typography>

              {steps && steps.length > 0 && (
                <>
                  <Box component="span" sx={{ 
                    display: 'block', 
                    mt: 1.5, 
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: "#000000"
                  }}>
                    What's next?
                  </Box>
                  
                  {steps.map((step, index) => (
                    <Box 
                      key={index}
                      component="span" 
                      sx={{ 
                        display: 'block', 
                        mt: index === 0 ? 2 : 1, 
                        textAlign: 'center',
                        color: "#525A65",
                        fontWeight: "500",
                        fontSize:{xs:"14px",sm:"16px"}
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "24px",
                          color: "#02BD36",
                          marginTop: "10px",
                          display: "inline-block"
                        }}
                      >
                        Step {step.number}: 
                      </Typography>
                      {" "}{step.text}
                    </Box>
                  ))}
                </>
              )}
            </Typography>
          </Box>
          
          <Button
            onClick={handleWelcomeComplete}
            sx={{
              background: '#C9FE58',
              border: '1px solid #02BD36',
              borderRadius: '20px',
              padding: '15px 22px',
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              color: '#02BD36',
              textTransform: 'none',
              marginTop: 'auto',
              mt: 4,
              mb: 2,
              '&:hover': {
                background: '#B9EE48'
              }
            }}
          >
            {buttonText}
          </Button>
        </Box>
      </Dialog>

      {/* Tools Walkthrough Modal (shown second) */}
      <ToolsWalkthroughModal
        open={open && showTools && !showClients}
        onClose={onClose}
        onNext={handleToolsComplete}
        onSkip={handleSkipFlow}
      />

      {/* Clients Walkthrough Modal (shown last) */}
      <ClientsWalkthroughModal
        open={open && showClients}
        onClose={onClose}
        onNext={handleClientsComplete}
        onSkip={handleSkipFlow}
      />
    </>
  );
};

export default WelcomeModal; 