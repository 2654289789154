import { BorderColor } from "@mui/icons-material";
import { transform } from "framer-motion";

export const button3Style = {
    marginLeft: "auto",
    color: "#2998E9",
    border: "solid 1px",
    borderRadius: "30px",
    fontSize: "12px",
    backgroundColor: "unset",
    padding: "6px 9px 6px 9px",
    cursor: "pointer",
    ":hover": {
        backgroundColor: "#E9FFCE",
    },
    lineHeight: "1",
    height: "26px",
    textTransform: "none"
}


export const searchStyle = {
    ".MuiFormLabel-root": {
        fontFamily: "Bricolage Grotesque,sans-serif",
        fontOpticalSizing: "auto",
        fontStyle: "normal",
        fontWeight: 600,
        paddingLeft: "7px",
        fontSize: "12px",
    },
    ".MuiInputBase-root": {
        borderRadius: "30px",
        height: "40px",
    },
    ".css-1ut4ayd-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
        padding: "0",
        fontSize: "15px",
    },
    ".MuiOutlinedInput-notchedOutline": {
        bottom: "-3px",
        top: "-2px",
        border: "solid 1px #525A65",
    },
    ".MuiInputBase-input": {
        fontFamily: "Bricolage Grotesque,sans-serif",
        fontOpticalSizing: "auto",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        color: "#525A65 !important",
        padding: "10px 24px",
        marginTop: "4px"
    }
}
export const close = {
    marginLeft: "auto",
    color: "#525A65",
    border: "solid 1px",
    float: "right",
    textTransform: "none",
    paddingRight: "10px",
    padding: "13px",
    borderRadius: "100% !important",
    minWidth: "37px !important",
    fontSize: "12px",
    border: "0px !important",
    backgroundColor: "#EEEEEE",
    ".MuiSvgIcon-root": {
        fontSize: "18px !important",
        paddingLeft: "0px",
        color: "#525A65"
    },
    "&:hover": {
        color: "#525A65",
        backgroundColor: "#dddddd"
    },
    ".MuiButton-icon": {
        widht: "10px",
        paddingLeft: "3px",
        marginRight: "0px"
    },
}
export const buttonStyle = {
    color: "#2998E9",
    mt: 2,
    borderRadius: "30px",
    fontSize: "12px",
    backgroundColor: "unset",
    padding: "6px 9px 6px 9px",
    cursor: "pointer",
    ":hover": {
       fontWeight:"600",
       borderColor:"#000",
       transform:"scale(1.00)"
       
    },
    lineHeight: "1",
    height: "26px",
    textTransform: "none"
};


export const MenuIcon = () => {
    return (
        <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#525A65" />
            <circle cx="8" cy="2" r="2" transform="rotate(-90 8 2)" fill="#525A65" />
            <circle cx="14" cy="2" r="2" transform="rotate(-90 14 2)" fill="#525A65" />
        </svg>)
}

export const LeftArrow = () => {
    return (
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 3.99851L3.54872 4.00852L5.92821 1.72512C6.33846 1.34455 6.33846 0.703597 5.94872 0.303C5.55897 -0.0975974 4.90256 -0.0975974 4.49231 0.28297L0.307692 4.28894C0.102564 4.46921 0 4.7296 0 5.01002C0 5.2704 0.102564 5.53079 0.307692 5.73109L4.49231 9.71703C4.90256 10.0976 5.53846 10.0976 5.94872 9.697C6.33846 9.2964 6.33846 8.67548 5.92821 8.27488L3.54872 6.01151L12 6.00149V3.99851Z" fill="#525A65" />
        </svg>)
}

export const InfoIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z" fill="#CECECE" />
            <g clip-path="url(#clip0_2167_995)">
                <path d="M7.95745 10.6705C8.06212 10.2668 7.95184 10.0773 7.54063 9.95335C7.44343 9.92324 7.3425 9.90731 7.24343 9.88252C6.98549 9.81877 6.92754 9.65232 7.09764 9.45576C7.14624 9.40087 7.20605 9.35129 7.2696 9.31233C8.01726 8.84485 8.82474 8.54736 9.73128 8.51903C10.436 8.49955 10.8976 8.72975 11.0341 9.2291C11.0958 9.45399 11.092 9.71429 11.036 9.94272C10.8079 10.9025 10.5462 11.8569 10.3032 12.8131C10.1855 13.2753 10.3126 13.4683 10.8004 13.5923C10.849 13.6047 10.9032 13.6082 10.9481 13.6312C11.0528 13.6826 11.2341 13.7357 11.2397 13.8012C11.249 13.9075 11.178 14.0651 11.0846 14.1288C10.2079 14.7273 9.23409 15.0691 8.1425 14.9894C7.40418 14.9345 7.0154 14.3395 7.20044 13.6117C7.44904 12.6307 7.70698 11.6515 7.96119 10.6705H7.95745ZM12.0004 5.58664C12.0023 4.70302 11.2808 4.00534 10.3612 4.00003C9.42474 3.99472 8.67334 4.69948 8.68082 5.57601C8.68829 6.449 9.421 7.13606 10.3518 7.13783C11.2584 7.14137 12.0004 6.44192 12.0023 5.58487L12.0004 5.58664Z" fill="#F5F5F5" />
            </g>
            <defs>
                <clipPath id="clip0_2167_995">
                    <rect width="5" height="11" fill="white" transform="translate(7 4)" />
                </clipPath>
            </defs>
        </svg>)
}

