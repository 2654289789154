import { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { G_SCOPE, LSN_SESSION_KEY } from "../utils/constant";

const AuthContext = createContext(undefined);
const SupabaseContext = createContext(undefined);

const google = window.google;

export const AuthContextProvider = ({ children, supabase }) => {
  const [localSession, setLocalSession] = useLocalStorage(
    LSN_SESSION_KEY,
    null
  );

  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tokenClient, setTokenClient] = useState(null);
  const [googleToken, setGoogleToken] = useState(null);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const _setSession = (session) => {
    setSession(session);
    setLocalSession(session);
    setLoading(false);
    if (session && session?.user?.id === localSession?.user?.id) {
      console.log("session", session);
      syncUser(session);
    } else {
      // alert("user NULL")
      setUser(null);
    }
  };

  const updateLastAccess = async () => {
    try {
      if (!user || !user.id) return;
      
      const now = new Date().toISOString(); // Tam timestamp (tarih + saat)
      
      const { error } = await supabase
        .from("users")
        .update({
          last_dashboard_access: now
        })
        .eq("id", user.id);
        
      if (error) {
        console.error("Error updating last_dashboard_access:", error);
      }
    } catch (err) {
      console.error("Failed to update last_dashboard_access:", err);
    }
  };

  const syncUser = async (session) => {
    const { data: existing_user, error } = (
      await supabase.functions.invoke("auth-get", {
        body: {
          id: session.user.id,
        },
      })
    ).data;

    if (error) {
      console.log("error", error);

      // Eğer token yenilemesi gerekiyorsa
      // const { session: refreshedSession, error: refreshError } = await supabase.auth.signInWithOAuth({
      //   provider: "google",
      //   options: {
      //     redirectTo: window.location.origin,
      //     queryParams: {
      //       access_type: "offline",
      //       prompt: "consent",
      //       scope: G_SCOPE, // İhtiyacınıza göre bu kısmı güncelleyebilirsiniz.
      //     },
      //   },
      // });
      // console.log("refreshedSession", refreshedSession);
      // if (refreshError) {
      //   console.log("Token yenileme hatası:", refreshError);
      //   // Hata durumunda, kullanıcıyı çıkış yapmaya yönlendirebilirsiniz.
      //   await supabase.auth.signOut();
      //   window.location.href = "/login";  // veya başka bir yönlendirme yapılabilir.
      // } else {
      //   // Token başarıyla yenilendi
      //   _setSession(refreshedSession);
      // }
    }
    if (existing_user === undefined) {
      return (
        <button onClick={() => (window.location.href = "/login")}>
          Redirect login
        </button>
      );
    }
    if (existing_user.length === 1) {
      await supabase.functions.invoke("auth-update", {
        body: {
          google_refresh_token: session.provider_refresh_token,
        },
      });

      return setUser({
        ...existing_user[0],
        sessionUser: session && session.user,
      });
    }

    const { data: new_user } = (
      await supabase.functions.invoke("auth-insert", {
        body: {
          id: session.user.id,
          full_name: session.user.user_metadata.full_name,
          type: "coach",
          google_access_token: session.provider_token,
          google_refresh_token: session.provider_refresh_token,
        },
      })
    ).data;
    setUser({ ...new_user, sessionUser: session && session.user });
  };

  window.sb = supabase;
  useEffect(() => {
    supabase.auth.getSession();
    console.log("session", session);
      console.log("localSession", localSession);
    console.log("user", user);
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      _setSession(session);
  

      if (_event === "SIGNED_IN") {
        _setSession(session);
      } else if (_event === "SIGNED_OUT" || _event === "USER_DELETED" || _event === "TOKEN_REFRESHED") {
        window.location.href = "/login";
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supabase.auth, setLocalSession]);

  useEffect(() => {
    setTokenClient(
      google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: G_SCOPE,
        prompt: "",
        callback: (token) => {
          setLocalSession({
            ...localSession,
            provider_token: token.access_token,
          });

          setGoogleToken({
            ...token,
            expires_at: Date.now() + token.expires_in * 1000,
          });
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Aktivite dinleyicisi
  useEffect(() => {
    if (!user?.id) return;

    // İlk oturumda güncelle
    updateLastAccess();
    
    // Kullanıcı aktivitesini dinle
    const activityEvents = ['mousedown', 'keydown', 'scroll', 'touchstart'];
    let activityTimeout;
    let lastUpdateTime = Date.now();
    
    const handleActivity = () => {
      const now = Date.now();
      // Son güncelleme üzerinden 2 dakikadan fazla geçtiyse güncelle
      if (now - lastUpdateTime > 2 * 60 * 1000) {
        updateLastAccess();
        lastUpdateTime = now;
        setLastActivityTime(now);
      }
    };
    
    activityEvents.forEach(event => {
      window.addEventListener(event, handleActivity);
    });
    
    return () => {
      clearTimeout(activityTimeout);
      activityEvents.forEach(event => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, [user?.id]);

  window.authContextValue = {
    session,
    localSession,
    sessionUser: session && session.user,
    user: user,
    tokenClient,
    googleToken,
    loading: loading,
    syncUser: syncUser,
    updateLastAccess,
    signIn: async () => {
      const { session, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: window.location.origin,
          queryParams: {
            access_type: "offline",
            prompt: "consent",
            scope: G_SCOPE,
          },
        },
      });

      if (error) {
        // alert(`signIn, ${error}`)
        throw error;
      }

      _setSession(session);
    },

    signOut: async () => {
      const { error } = await supabase.auth.signOut();

      if (error) {
        // alert(`signOut, ${error}`)
        throw error;
      }
      _setSession(null);
    },
  };

  return (
    <SupabaseContext.Provider value={supabase}>
      <AuthContext.Provider value={window.authContextValue}>
        {children}
      </AuthContext.Provider>
    </SupabaseContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    // alert("context ,useAuth must be used within a AuthContextProvider")
    throw new Error("useAuth must be used within a AuthContextProvider");
  }
  return context;
};

export const useSupabase = () => {
  const context = useContext(SupabaseContext);
  if (context === undefined) {
    // alert("context, useSupabase must be used within a AuthContextProvider")
    throw new Error("useSupabase must be used within a AuthContextProvider");
  }
  return context;
};