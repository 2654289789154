import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Paper, ButtonGroup, Button, Toolbar } from "@mui/material";
import { useState, useCallback } from "react";
import { GridToolbar } from "@mui/x-data-grid";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// Status filter options with simpler structure
const STATUS_FILTER_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'online', label: 'Online', color: '#02BD36' },
  { value: 'today', label: 'Today', color: '#FFA500' },
  { value: 'offline', label: 'Offline', color: '#525A65' },
];

// Custom styled toolbar
const CustomGridToolbar = (props) => {
  return (
    <Toolbar 
      sx={{ 
        p: '0 16px !important',
        minHeight: '56px !important',
        borderBottom: '1px solid #f0f0f0'
      }}
    >
      <GridToolbar 
        {...props}
        sx={{
          '& .MuiButton-root': {
            color: '#555',
            fontSize: '0.875rem',
            fontWeight: 400,
            textTransform: 'none',
            px: 1.5,
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.04)'
            }
          }
        }}
      />
    </Toolbar>
  );
};

export const UsersTable = ({ 
  data, 
  columns, 
  loading, 
  activeView 
}) => {
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [statusFilter, setStatusFilter] = useState('all'); // Default: all users

  // Status filter change handler
  const handleStatusFilterChange = useCallback((newStatus) => {
    setStatusFilter(newStatus);
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  const rows = data.map((i, index) => {
    if (activeView === 'referrals') {
      return {
        id: index,
        userId: i.id,
        referralCoach: i.referral_coach,
        coachname: i.full_name,
        invitedCoachStatus: i.status,
        referralCode: i.referral_code,
        referredBy: i.referred_by,
        lastSubscription: i.last_subscription,
        subscriptionCount: i.subscription_count,
      };
    }

    return {
      id: index,
      userId: i.id,
      coachname: i.full_name,
      email: i.email,
      referralCode: i.referral_code,
      phonenumber: i.phone_number,
      lastDashboardAccess: i.last_dashboard_access,
      isFreeAccount: i.free_account || false,
      client_number: i.client_number,
      status: i.last_dashboard_access
    };
  });

  // Status filtresine göre satırları filtrele
  const filteredRows = statusFilter === 'all' 
    ? rows 
    : rows.filter(row => {
        const lastAccessTime = row.lastDashboardAccess ? new Date(row.lastDashboardAccess) : null;
        
        if (!lastAccessTime) {
          return statusFilter === 'offline';
        }
        
        const now = new Date();
        const diffMinutes = Math.floor((now - lastAccessTime) / (1000 * 60));
        const diffHours = Math.floor(diffMinutes / 60);
        
        if (statusFilter === 'online') {
          return diffMinutes < 5;
        } else if (statusFilter === 'today') {
          return diffHours < 12 && diffMinutes >= 5; // 5 dk ile 12 saat arası
        } else if (statusFilter === 'offline') {
          return diffHours >= 12;
        }
        
        return true;
      });

  return (
    <Box sx={{ width: "100%", px: 3, maxWidth: "1600px", mx: "auto" }}>
      <Paper sx={{ 
        height: "100%", 
        width: "100%",
        '& .MuiDataGrid-root': { border: 'none' },
        '& .MuiDataGrid-cell': { borderBottom: '1px solid #f0f0f0' },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: '#f8f8f8',
          borderBottom: 'none',
        },
        borderRadius: 1,
        border: '1px solid #e0e0e0',
        boxShadow: 'none',
        overflow: 'hidden'
      }}>
        {/* Modern minimal status filter */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          py: 1.5,
          px: 2,
          borderBottom: '1px solid #f0f0f0'
        }}>
          <ButtonGroup 
            variant="text" 
            disableElevation 
            disableRipple 
            sx={{ 
              '& .MuiButton-root': {
                border: 'none',
                borderRadius: '4px',
                minWidth: 'auto',
                px: 1.5,
                py: 0.5,
                mr: 0.5,
                transition: 'all 0.2s ease',
                fontSize: '0.875rem',
                fontWeight: 400,
                color: '#555',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.04)',
                  border: 'none',
                },
              }
            }}
          >
            {STATUS_FILTER_OPTIONS.map((option) => (
              <Button 
                key={option.value} 
                onClick={() => handleStatusFilterChange(option.value)}
                sx={{ 
                  color: statusFilter === option.value 
                    ? (option.value !== 'all' ? option.color : '#333')
                    : '#555',
                  fontWeight: statusFilter === option.value ? 600 : 400,
                  backgroundColor: statusFilter === option.value 
                    ? 'rgba(0,0,0,0.03)' 
                    : 'transparent',
                  '&:hover': {
                    backgroundColor: statusFilter === option.value 
                      ? 'rgba(0,0,0,0.06)' 
                      : 'rgba(0,0,0,0.04)',
                  }
                }}
              >
                {option.value !== 'all' && (
                  <FiberManualRecordIcon 
                    sx={{ 
                      fontSize: 8, 
                      mr: 0.7, 
                      color: option.color,
                      opacity: statusFilter === option.value ? 1 : 0.7
                    }} 
                  />
                )}
                {option.label}
              </Button>
            ))}
          </ButtonGroup>
          
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#666',
              fontSize: '0.825rem',
              fontWeight: 500
            }}
          >
            {filteredRows.length} users
          </Typography>
        </Box>

        {data.length > 0 ? (
          <DataGrid
            rows={filteredRows}
            columns={columns[activeView]}
            initialState={{ 
              pagination: { 
                paginationModel: { page: 0, pageSize: 20 } 
              },
              sorting: {
                sortModel: [{ field: 'lastDashboardAccess', sort: 'desc' }],
              },
              filter: {
                filterModel: {
                  items: [],
                },
              }
            }}
            filterModel={filterModel}
            onFilterModelChange={(newModel) => setFilterModel(newModel)}
            pageSizeOptions={[20, 25]}
            disableRowSelectionOnClick
            slots={{ toolbar: CustomGridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            sx={{ 
              border: 0,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 600,
                color: '#333'
              },
              '& .MuiDataGrid-footerContainer': {
                borderTop: '1px solid #f0f0f0'
              },
              '& .MuiDataGrid-virtualScroller': {
                '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '8px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#e0e0e0',
                  borderRadius: '4px'
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f5f5f5'
                }
              }
            }}
          />
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography>No data available</Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};