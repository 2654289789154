import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export const CustomizedListItemButton = styled(ListItemButton)`
  height: 51px;
  width: 276px !important;
  border-radius: 10px;
  padding-top: 0;
  background: unset !important;
  @media (max-width: 1200px) {
    height: 42px;
  }

  @media (max-width: 992px) {
    height: 42px;
  }

  @media (max-width: 900px) {
    height: 40px;
  }
  :hover {
    path {
      fill: #c9fe58 !important;
    }
    circle {
      fill: #c9fe58 !important;
    }
    rect {
      fill: #c9fe58 !important;
    }
    .MuiTypography-root {
      color: #c9fe58 !important;
    }
  }
`;

export const CustomizedListItemIcon = styled(ListItemIcon)`
  color: #013b12;
`;

export const CustomizedListItemText = styled(ListItemText)`
  color: #013b12;
  .MuiTypography-root {
    font-family: "Bricolage Grotesque", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500 !important;
    font-style: normal;
    font-variation-settings: "wdth" 87.5;
    font-size: 15px;
    color: #e3e2e0;
    @media (max-width: 1200px) {
      font-size: 14px;
    }

    @media (max-width: 992px) {
      font-size: 13px;
    }

    @media (max-width: 900px) {
      font-size: 14px;
    }
  }
`;

export const CustomizedListItem = styled(ListItem)`
  padding-bottom: 0px !important;
  color: #013b12;
`;

export const CustomizedToolbar = styled(Toolbar)`
  padding: 70px 70px 40px 50px;
  display: flex;
  justify-content: center;
`;

export const signoutStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 22px",
  gap: "10px",
  width: "107px",
  height: "48px",
  borderRadius: "20px",
  boxSizing: "border-box",
  fontFamily: "Bricolage Grotesque",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "19px",
  color: "#E3E2E0",
  textTransform: "none",
  whiteSpace: "nowrap",
  "@media (min-width: 900px) and (max-width: 1200px)": {
    padding: "12px 18px",
    width: "95px",
    height: "42px",
    fontSize: "14px",
    lineHeight: "17px",
  },
  
};
