import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Typography, Paper, IconButton, Avatar, Button, TextField, Chip, Tooltip, Divider } from '@mui/material';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { useAuth, useSupabase } from '../../providers/AuthContextProvider';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ShareIcon from '@mui/icons-material/Share';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import { Logo } from './Blog.route';

export const BlogPost = () => {
    const [post, setPost] = useState(null);
    const [author, setAuthor] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [editedTitle, setEditedTitle] = useState('');
    const [editedContent, setEditedContent] = useState('');
    const [editedCoverImage, setEditedCoverImage] = useState(null);
    const [isPublic, setIsPublic] = useState(true);
    const quillRef = useRef(null);
    const { id } = useParams();
    const { user } = useAuth();
    const supabase = useSupabase();
    const navigate = useNavigate();
    const location = useLocation();
    const [readingTime, setReadingTime] = useState('');

    useEffect(() => {
        fetchPost();
    }, [id, location]);
   
    useEffect(() => {
        // Only allow editing if user is the author and we're in edit mode
        if (post && user) {
            setIsEditing((location.pathname.includes('/edit/') && user.id === post.author_id));
        }
    }, [location.pathname, user, post]);

    useEffect(() => {
        if (post?.content) {
            // Calculate reading time based on content
            const text = post.content.replace(/<[^>]*>/g, ''); // Remove HTML tags
            const wordCount = text.split(/\s+/).length;
            const readingTimeMinutes = Math.ceil(wordCount / 200); // Assuming 200 words per minute
            setReadingTime(`${readingTimeMinutes} min read`);
        }
    }, [post?.content]);

    const fetchPost = async () => {
        try {
            const { data: postData, error: postError } = await supabase
                .from('blog_posts')
                .select('*')
                .eq('id', id)
                .single();
            if (postError) throw postError;

            if (postData) {
                // Check if user has access to this post
                if (!postData.isPublic && (!user || user.id !== postData.author_id)) {
                    navigate('/blog');
                    return;
                }

                // If trying to edit but not the author, redirect to view mode
                if (location.pathname.includes('/edit/') && user?.id !== postData.author_id) {
                    navigate(`/blog/${id}`);
                    return;
                }

                // Increment total_read count if not in edit mode and not the author
                if (!location.pathname.includes('/edit/') && user?.id != postData.author_id) {
                    let totalRead = (Number(postData?.totalRead) ?? 0) + 1; // Ensure it's a number
                
                
                    const { data: updatedData, error: updateError } = await supabase
                        .from('blog_posts')
                        .update({ totalRead: totalRead }) // Ensure you're passing an integer
                        .eq('id', id)
                        .select(); // Ensure updated data is returned
                
                    if (updateError) {
                        console.error('Error updating read count:', updateError);
                    }
                }
                

                setPost(postData);
                setEditedTitle(postData.title);
                setEditedContent(postData.content);
                setEditedCoverImage(postData.cover_image);
                setIsPublic(postData.isPublic ?? true);
                
                // Fetch author details
                const { data: authorData, error: authorError } = await supabase
                    .from('users')
                    .select('*')
                    .eq('id', postData.author_id)
                    .single();

                if (authorError) throw authorError;
                setAuthor(authorData);
            }
        } catch (error) {
            console.error('Error fetching blog post:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this post?')) return;

        try {
            const { error } = await supabase
                .from('blog_posts')
                .delete()
                .eq('id', id);

            if (error) throw error;
            navigate('/blog');
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    const handleCoverImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        try {
            setLoading(true);
            const fileExt = file.name.split('.').pop();
            const fileName = `${Math.random()}.${fileExt}`;
            const filePath = `blog-covers/${fileName}`;

            const { error: uploadError } = await supabase.storage
                .from('blog-images')
                .upload(filePath, file);

            if (uploadError) throw uploadError;

            const { data: { publicUrl } } = supabase.storage
                .from('blog-images')
                .getPublicUrl(filePath);

            setEditedCoverImage(publicUrl);
        } catch (error) {
            console.error('Error uploading cover image:', error);
            alert('Failed to upload cover image. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = async () => {
        try {
            setLoading(true);
            const { error } = await supabase
                .from('blog_posts')
                .update({
                    title: editedTitle,
                    content: editedContent,
                    cover_image: editedCoverImage,
                    isPublic: isPublic,
                    updated_at: new Date().toISOString()
                })
                .eq('id', id);

            if (error) throw error;
            setPost({ ...post, title: editedTitle, content: editedContent, cover_image: editedCoverImage, isPublic: isPublic });
            setIsEditing(false);
            navigate(`/blog/${id}`);
        } catch (error) {
            console.error('Error updating post:', error);
        } finally {
            setLoading(false);
        }
    };

    // Add custom handler for image upload
    const imageHandler = async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                try {
                    setLoading(true);
                    const fileExt = file.name.split('.').pop();
                    const fileName = `${Math.random()}.${fileExt}`;
                    const filePath = `blog-content/${fileName}`;

                    const { error: uploadError } = await supabase.storage
                        .from('blog-images')
                        .upload(filePath, file);

                    if (uploadError) throw uploadError;

                    const { data: { publicUrl } } = supabase.storage
                        .from('blog-images')
                        .getPublicUrl(filePath);

                    const editor = quillRef.current.getEditor();
                    const range = editor.getSelection(true);
                    editor.insertEmbed(range.index, 'image', publicUrl);
                } catch (error) {
                    console.error('Error uploading image:', error);
                    alert('Failed to upload image. Please try again.');
                } finally {
                    setLoading(false);
                }
            }
        };
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'font': [] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'direction': 'rtl' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],
                ['blockquote', 'code-block'],
                ['link', 'image', 'video', 'formula'],
                ['clean']
            ],
            handlers: {
                image: imageHandler
            }
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    }), []);

    const formats = [
        'header',
        'font',
        'size',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'check',
        'script',
        'indent',
        'direction',
        'align',
        'color', 'background',
        'blockquote', 'code-block',
        'link', 'image', 'video', 'formula'
    ];

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: post.title,
                text: `Check out this blog post: ${post.title}`,
                url: window.location.href,
            })
            .catch((error) => console.log('Error sharing', error));
        } else {
            // Fallback - copy to clipboard
            navigator.clipboard.writeText(window.location.href)
                .then(() => alert('Link copied to clipboard!'))
                .catch((err) => console.error('Could not copy text: ', err));
        }
    };

    if (loading) {
        return (
            <PageContainer>
                <Typography>Loading...</Typography>
            </PageContainer>
        );
    }

    if (!post) {
        return (
            <PageContainer>
                <Typography>Post not found</Typography>
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <Logo/>

            <Paper sx={{
                maxWidth: '800px',
                margin: '0 auto',
                p: { xs: 2, md: 4 },
                backgroundColor: '#fff',
                boxShadow: 'none'
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                    <IconButton
                        onClick={() => navigate('/blog')}
                        sx={{ 
                            mr: 2,
                            color: '#292929'
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    {user?.id === post.author_id && !isEditing && (
                        <Box sx={{ ml: 'auto', display: 'flex', gap: 1 }}>
                            <Button
                                startIcon={<EditIcon />}
                                onClick={() => navigate(`/blog/edit/${id}`)}
                                sx={{
                                    color: '#292929',
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(41, 41, 41, 0.1)'
                                    }
                                }}
                            >
                                Edit post
                            </Button>
                            <Button
                                startIcon={<DeleteIcon />}
                                onClick={handleDelete}
                                sx={{
                                    color: '#d32f2f',
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(211, 47, 47, 0.1)'
                                    }
                                }}
                            >
                                Delete
                            </Button>
                        </Box>
                    )}
                </Box>

                {!isEditing && (
                    <>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h1" sx={{
                                fontSize: { xs: '32px', md: '40px' },
                                fontWeight: 700,
                                color: '#292929',
                                lineHeight: 1.2,
                                mb: 3
                            }}>
                                {post.title}
                            </Typography>
                            
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 2, 
                                mb: 2,
                                flexWrap: { xs: 'wrap', sm: 'nowrap' }
                            }}>
                                <Avatar
                                    src={author?.avatar_url}
                                    alt={author?.full_name}
                                    sx={{ width: 48, height: 48 }}
                                />
                                <Box sx={{ flex: 1 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography sx={{
                                            fontWeight: 500,
                                            color: '#292929',
                                            fontSize: '14px'
                                        }}>
                                            {author?.full_name}
                                        </Typography>
                                        {user?.id === post.author_id && (
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: post.isPublic ? '#02BD36' : '#FF9800',
                                                    backgroundColor: post.isPublic ? 'rgba(2, 189, 54, 0.1)' : 'rgba(255, 152, 0, 0.1)',
                                                    px: 1,
                                                    py: 0.5,
                                                    borderRadius: '12px',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {post.isPublic ? 'Public' : 'Private'}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        gap: 1,
                                        color: '#757575',
                                        fontSize: '14px'
                                    }}>
                                        <Typography component="span">
                                            {moment(post.created_at).format('MMM D')} · {moment(post.created_at).fromNow()}
                                        </Typography>
                                        <Box 
                                            component="span" 
                                            sx={{ 
                                                display: 'inline-flex', 
                                                alignItems: 'center',
                                                gap: 0.5
                                            }}
                                        >
                                            <AccessTimeIcon sx={{ fontSize: 14 }} />
                                            {readingTime}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                   
                                    <Tooltip title="Share">
                                        <IconButton 
                                            size="small"
                                            onClick={handleShare}
                                            sx={{ 
                                                color: '#757575',
                                                '&:hover': { color: '#1a8917' }
                                            }}
                                        >
                                            <ShareIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                            
                            <Divider sx={{ my: 2 }} />

                            {post.tags && post.tags.length > 0 && (
                                <Box sx={{ 
                                    display: 'flex', 
                                    gap: 1, 
                                    flexWrap: 'wrap',
                                    mb: 4,
                                    mt: 3
                                }}>
                                    {post.tags.map((tag) => (
                                        <Chip
                                            key={tag}
                                            label={tag}
                                            size="small"
                                            sx={{
                                                backgroundColor: 'rgba(26, 137, 23, 0.08)',
                                                borderRadius: '16px',
                                                color: '#1a8917',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                transition: 'all 0.2s ease',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(26, 137, 23, 0.15)',
                                                    transform: 'translateY(-2px)',
                                                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                                                }
                                            }}
                                        />
                                    ))}
                                </Box>
                            )}
                        </Box>

                        {post.cover_image && (
                          <Box
                          sx={{
                            mb: 6,
                            position: 'relative',
                            bgcolor: '#f5f5f5',
                            borderRadius: '12px',
                            overflow: 'hidden',
                            boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                            transition: 'transform 0.3s ease',
                            '&:hover': {
                              transform: 'scale(1.01)',
                            }
                          }}
                        >
                          <Box
                            component="img"
                            src={post.cover_image}
                            alt={post.title}
                            sx={{
                              width: '100%',
                              height: 'auto',
                              maxHeight: '500px',
                              objectFit: 'cover',
                              display: 'block',
                              transition: 'opacity 0.3s ease',
                              '&:hover': {
                                opacity: 0.95,
                              },
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.style.display = 'none';
                            }}
                          />
                        </Box>
                        )}

                        <Box sx={{
                            '& .ql-editor': {
                                padding: 0,
                                fontSize: '18px',
                                lineHeight: 1.8,
                                color: '#292929',
                                '& h1, & h2, & h3': {
                                    fontWeight: 700,
                                    color: '#292929',
                                    my: 2
                                },
                                '& p': {
                                    marginBottom: '2em'
                                }
                            },
                            '& img': {
                                maxWidth: '100%',
                                height: 'auto',
                                display: 'block',
                                margin: '2em auto',
                                borderRadius: '8px',
                                boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                            },
                            '& blockquote': {
                                borderLeft: '3px solid #1a8917',
                                paddingLeft: '1em',
                                marginLeft: 0,
                                fontStyle: 'italic',
                                color: '#555'
                            },
                            '& a': {
                                color: '#1a8917',
                                textDecoration: 'underline',
                                transition: 'color 0.2s ease',
                                '&:hover': {
                                    color: '#156912'
                                }
                            },
                            '& code': {
                                backgroundColor: '#f5f5f5',
                                padding: '2px 4px',
                                borderRadius: '4px',
                                fontFamily: 'monospace'
                            }
                        }}>
                            <div
                                dangerouslySetInnerHTML={{ __html: post.content }}
                            />
                        </Box>
                        
                        <Divider sx={{ my: 4 }} />
                        
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Avatar
                                    src={author?.avatar_url}
                                    alt={author?.full_name}
                                    sx={{ width: 40, height: 40 }}
                                />
                                <Typography sx={{ fontWeight: 500 }}>
                                    Written by {author?.full_name}
                                </Typography>
                            </Box>
                            <Button
                                variant="outlined"
                                onClick={handleShare}
                                startIcon={<ShareIcon />}
                                sx={{
                                    borderColor: '#1a8917',
                                    color: '#1a8917',
                                    textTransform: 'none',
                                    borderRadius: '20px',
                                    '&:hover': {
                                        borderColor: '#156912',
                                        backgroundColor: 'rgba(26, 137, 23, 0.04)'
                                    }
                                }}
                            >
                                Share
                            </Button>
                        </Box>
                    </>
                )}

                {isEditing && (
                    <Box component="form" onSubmit={(e) => { e.preventDefault(); handleUpdate(); }}>
                        <TextField
                            fullWidth
                            placeholder="Title"
                            value={editedTitle}
                            onChange={(e) => setEditedTitle(e.target.value)}
                            required
                            variant="standard"
                            sx={{
                                mb: 3,
                                '& .MuiInputBase-input': {
                                    fontSize: '32px',
                                    fontWeight: 700,
                                    color: '#292929',
                                    '&::placeholder': {
                                        color: '#757575',
                                        opacity: 1
                                    }
                                },
                                '& .MuiInput-underline:before': {
                                    borderBottom: 'none'
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottom: 'none'
                                }
                            }}
                        />

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                            <Button
                                variant={isPublic ? "contained" : "outlined"}
                                onClick={() => setIsPublic(!isPublic)}
                                sx={{
                                    minWidth: '120px',
                                    borderRadius: '20px',
                                    textTransform: 'none',
                                    backgroundColor: isPublic ? '#1a8917' : 'transparent',
                                    borderColor: '#1a8917',
                                    color: isPublic ? 'white' : '#1a8917',
                                    '&:hover': {
                                        backgroundColor: isPublic ? '#156912' : 'rgba(26, 137, 23, 0.1)',
                                        borderColor: '#1a8917'
                                    }
                                }}
                            >
                                {isPublic ? 'Public' : 'Private'}
                            </Button>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="cover-image-upload"
                                    type="file"
                                    onChange={handleCoverImageUpload}
                                />
                                <label htmlFor="cover-image-upload">
                                    <Button
                                        component="span"
                                        startIcon={<AddPhotoAlternateIcon />}
                                        sx={{
                                            color: '#292929',
                                            textTransform: 'none',
                                            fontSize: '14px',
                                            '&:hover': {
                                                backgroundColor: 'rgba(41, 41, 41, 0.1)'
                                            }
                                        }}
                                    >
                                        {editedCoverImage ? 'Change cover image' : 'Add cover image'}
                                    </Button>
                                </label>
                            </Box>
                        </Box>

                        {editedCoverImage && (
                            <Box sx={{ 
                                mb: 4,
                                mx: { xs: -2, md: -4 },
                                height: { xs: '200px', md: '400px' }
                            }}>
                                <img
                                    src={editedCoverImage}
                                    alt="Cover"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                            </Box>
                        )}

                        <Box sx={{ mb: 3 }}>
                            <ReactQuill
                                ref={quillRef}
                                value={editedContent}
                                onChange={setEditedContent}
                                modules={modules}
                                formats={formats}
                                placeholder="Tell your story..."
                            />
                            <style>
                                {`
                                    .ql-editor {
                                        min-height: 400px;
                                        font-size: 18px;
                                        line-height: 1.8;
                                        color: #292929;
                                        padding: 20px 0;
                                    }
                                    .ql-editor p {
                                        margin-bottom: 2em;
                                    }
                                    .ql-editor img {
                                        max-width: 100%;
                                        height: auto !important;
                                        display: block;
                                        margin: 2em auto;
                                    }
                                    .ql-container {
                                        font-family: inherit;
                                        border: none;
                                    }
                                    .ql-toolbar {
                                        border: none;
                                        border-bottom: 1px solid #e6e6e6;
                                        padding: 8px 0;
                                    }
                                `}
                            </style>
                        </Box>

                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={loading}
                                sx={{
                                    backgroundColor: '#1a8917',
                                    borderRadius: '20px',
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    px: 4,
                                    '&:hover': {
                                        backgroundColor: '#156912'
                                    }
                                }}
                            >
                                {loading ? 'Publishing...' : 'Publish'}
                            </Button>
                            <Button
                                onClick={() => {
                                    setIsEditing(false);
                                    navigate(`/blog/${id}`);
                                }}
                                sx={{
                                    color: '#292929',
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(41, 41, 41, 0.1)'
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                )}
            </Paper>
        </PageContainer>
    );
}; 