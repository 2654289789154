import React, { useEffect } from "react";
import moment from "moment-timezone";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Grid,
} from "@mui/material";
import { IconClose } from "../ExerciseModal/ExerciseModal";

// Custom dropdown icon component with proper positioning
const CustomDropdownIcon = (props) => (
  <Box sx={{ position: 'absolute', right: {xs:'15px',sm:'21px'}, pointerEvents: 'none', display: 'flex', alignItems: 'center' }}>
    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.782 -5.32403e-08L6.5 5.475L1.218 -5.15008e-07L-5.51856e-08 1.2625L5.282 6.7375L6.5 8L13 1.2625L11.782 -5.32403e-08Z" fill="#DAD9D8"/>
    </svg>
  </Box>
);

// Custom error message component
const ErrorMessage = ({ message }) => (
  <Typography
    sx={{
      color: "#E53935",
      fontFamily: "'Bricolage Grotesque', sans-serif",
      fontSize: "12px",
      fontWeight: 400,
      ml: "2px",
      display: "flex",
      alignItems: "center",
    }}
  >
    <Box
      component="span"
      sx={{
        display: "inline-flex",
        mr: "6px",
        alignItems: "center",
      }}
    >
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 6 0ZM6 9C5.55 9 5.25 8.7 5.25 8.25C5.25 7.8 5.55 7.5 6 7.5C6.45 7.5 6.75 7.8 6.75 8.25C6.75 8.7 6.45 9 6 9ZM6.75 6H5.25V3H6.75V6Z" fill="#E53935"/>
      </svg>
    </Box>
    {message}
  </Typography>
);

export const TimeSelectionModal = ({ open, onClose, onSubmit, date, type, athleteTZ,planType}) => {
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const [errors, setErrors] = React.useState({
    startTime: false,
    endTime: false,
    timeOrder: false,
    timezone: false,
  });

  // Function to find a timezone that matches the UTC offset
  const findTimezoneByOffset = (offset) => {
    if (!offset) return "Europe/Paris";
    
    // Map of UTC offsets to major city timezones
    const majorCityTimezones = {
      "-12": "Pacific/Wake",
      "-11": "Pacific/Midway",
      "-10": "Pacific/Honolulu",
      "-9": "America/Anchorage",
      "-8": "America/Los_Angeles",
      "-7": "America/Denver",
      "-6": "America/Chicago",
      "-5": "America/New_York",
      "-4": "America/Halifax",
      "-3": "America/Sao_Paulo",
      "-2": "Atlantic/South_Georgia",
      "-1": "Atlantic/Azores",
      "0": "Europe/London",
      "+1": "Europe/Madrid",
      "+2": "Europe/Athens",
      "+3": "Europe/Istanbul",
      "+4": "Asia/Dubai",
      "+5": "Asia/Karachi",
      "+5.5": "Asia/Kolkata",
      "+6": "Asia/Dhaka",
      "+7": "Asia/Bangkok",
      "+8": "Asia/Shanghai",
      "+9": "Asia/Tokyo",
      "+10": "Australia/Sydney",
      "+11": "Pacific/Noumea",
      "+12": "Pacific/Auckland"
    };

    // Clean the offset string (remove any spaces and ensure it has a + if positive)
    const cleanOffset = offset.toString().trim().replace(/^\+?/, "+");
    
    // Try to get the major city timezone for this offset
    if (majorCityTimezones[cleanOffset]) {
      return majorCityTimezones[cleanOffset];
    }
    
    // Fallback to finding any timezone with this offset if no major city is mapped
    const offsetMinutes = parseInt(cleanOffset) * 60;
    const matchingTimezone = moment.tz.names().find(zoneName => {
      return moment.tz(zoneName).utcOffset() === offsetMinutes;
    });

    return matchingTimezone || "Europe/Paris";
  };

  const [timezone, setTimezone] = React.useState(findTimezoneByOffset(athleteTZ));

  useEffect(() => {
    if (athleteTZ) {
      setTimezone(findTimezoneByOffset(athleteTZ));
    }
  }, [athleteTZ]);

  // Reset errors when user makes changes
  useEffect(() => {
    if (startTime) {
      setErrors(prev => ({ ...prev, startTime: false }));
      if (endTime) setErrors(prev => ({ ...prev, timeOrder: false }));
    }
    if (endTime) {
      setErrors(prev => ({ ...prev, endTime: false }));
      if (startTime) setErrors(prev => ({ ...prev, timeOrder: false }));
    }
    if (timezone) {
      setErrors(prev => ({ ...prev, timezone: false }));
    }
  }, [startTime, endTime, timezone]);

  // Generate time options at 15-minute intervals
  const generateTimeOptions = () => {
    const timeOptions = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        const timeValue = `${formattedHour}:${formattedMinute}`;
        
        // Format display time with am/pm
        let displayHour = hour;
        const ampm = hour >= 12 ? 'pm' : 'am';
        displayHour = hour % 12;
        displayHour = displayHour ? displayHour : 12; // Convert 0 to 12 for 12 AM
        const displayTime = `${displayHour.toString().padStart(2, "0")}:${formattedMinute} ${ampm}`;
        
        timeOptions.push({ value: timeValue, label: displayTime });
      }
    }
    return timeOptions;
  };

  const timeOptions = generateTimeOptions();

  const timezones = moment.tz
    .names()
    .map((name) => {
      const offset = moment.tz(name).format("Z");
      const formattedName = `(UTC${offset}) ${name.replace(/_/g, " ")}`;
      return {
        value: name,
        label: formattedName,
      };
    })
    .sort((a, b) => {
      const offsetA = moment.tz(a.value).utcOffset();
      const offsetB = moment.tz(b.value).utcOffset();
      return offsetB - offsetA;
    });

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      startTime: false,
      endTime: false,
      timeOrder: false,
      timezone: false,
    };

    // Check if start time is selected
    if (!startTime) {
      newErrors.startTime = true;
      isValid = false;
    }
    
    // Check if end time is selected
    if (!endTime) {
      newErrors.endTime = true;
      isValid = false;
    }
    
    // Check if start time is before end time
    if (startTime && endTime) {
      const [startHours, startMinutes] = startTime.split(':').map(Number);
      const [endHours, endMinutes] = endTime.split(':').map(Number);
      
      if (startHours > endHours || 
          (startHours === endHours && startMinutes >= endMinutes)) {
        newErrors.timeOrder = true;
        isValid = false;
      }
    }
    
    // Check if timezone is selected
    if (!timezone) {
      newErrors.timezone = true;
      isValid = false;
    }
    
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    // Create time strings with PostgreSQL time with time zone format
    const offset = moment.tz(timezone).format('Z'); // Gets timezone offset like '+01:00'
    const startTimeString = `${startTime}:00${offset}`;
    const endTimeString = `${endTime}:00${offset}`;

    // Send start_time and end_time
    onSubmit({
      start_time: startTimeString,
      end_time: endTimeString
    });

    // Reset form
    setStartTime("");
    setEndTime("");
    setTimezone("Europe/Paris");
    setErrors({
      startTime: false,
      endTime: false,
      timeOrder: false,
      timezone: false,
    });
  };

  // Custom style for time selectors
  const timeSelectStyle = {
    boxSizing: "border-box",
    width: {xs:"auto",sm:"144px"},
    height: "48px",
    background: "#FBFBFB",
    border: "1px solid #DAD9D8",
    borderRadius: "20px",
    padding: 0,
    fontFamily: "'Bricolage Grotesque', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    color: "#525A65",
  };

  // Custom style for time labels
  const timeLabelStyle = {
    fontFamily: "'Bricolage Grotesque', sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#525A65",
    marginBottom: "10px",
    whiteSpace: "nowrap",
    marginLeft: "21px",
  };

  const errorSelectStyle = {
    border: "1px solid #E53935",
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "24px",
          padding: "16px",
          maxWidth: "529px",
          width:"100%",
          maxHeight:"447px",
          height:"100%"
        },
      }}
    >
      <Box>
        <DialogTitle
          sx={{
            color: "#525A65",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          <Typography
            sx={{
              display:"flex",
              alignItems:"center",
              justifyContent:"space-between",
              color:"#000",
              fontSize:"16px",
              fontWeight:"600"
            }}
          >
            Set time for 1:1  {(planType ?? "workout") === "exercise" ? "exercise" : "workout"} session
            <IconButton
              onClick={onClose}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
                width: "auto",
                height: "auto", 
                "&:hover":{
                  scale:"1.07"
                }
              }}
            >
              <IconClose />
            </IconButton>
          </Typography>
          <Typography
            sx={{
              mt:"22px",
              color:"#525A65",
              fontSize:"14px",
              fontWeight:"400"
            }}
          >
            {date && moment(date).format('dddd, D MMMM')}
          </Typography>
        </DialogTitle>
      </Box>

      <DialogContent>
        <Box sx={{ mt: 1 }}>
          {/* Time Selection Section */}
          <Box sx={{ textAlign: "left" }}>
            {/* Time selectors with labels above each input */}
            <Box sx={{ display: "flex", mt: "0px", gap: "16px",width:"100%" }}>
              {/* Start Time */}
              <Box sx={{width:{xs:"100%",sm:"unset"}}}>
                <Typography sx={{...timeLabelStyle, ml: "21px"}}>
                  Start Time
                </Typography>
                <FormControl sx={{ m: 0, p: 0,width:{xs:"100%",sm:"unset"} }}>
                  <Select
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Start Time' }}
                    IconComponent={CustomDropdownIcon}
                    sx={{
                      ...timeSelectStyle,
                      ...(errors.startTime || errors.timeOrder ? errorSelectStyle : {}),
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: {xs:"7px",sm:"0"},
                        height: '48px',
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#525A65",
                      },
                      '& .MuiOutlinedInput-notchedOutline': { 
                        border: 'none'
                      }
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 207,
                          borderRadius: 20,
                          marginTop: 0,
                          background: '#EEEEEE',
                          borderTop: 'none',
                          border: '1px solid #DAD9D8',
                        },
                      },
                      MenuListProps: {
                        sx: {
                          '& .MuiMenuItem-root:not(:last-child)::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 0,
                            left: '21px',
                            right: '21px',
                            height: '1px',
                            backgroundColor: '#DAD9D8'
                          },
                          '& .MuiMenuItem-root': {
                            paddingTop: '7px',
                            paddingBottom: '7px'
                          }
                        }
                      }
                    }}
                  >
                    {timeOptions.map(({ value, label }) => (
                      <MenuItem 
                        key={value} 
                        value={value}
                        sx={{
                          fontFamily: "'Bricolage Grotesque', sans-serif",
                          fontSize: "14px",
                          color: "#525A65",
                          justifyContent: "flex-start",
                          paddingLeft: "21px"
                        }}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.startTime && <ErrorMessage message="Required" />}
              </Box>
              
              {/* Divider - only visible on non-wrapped view */}
              <Box
                sx={{
                  width: "8px",
                  height: "2px",
                  background: "#D9D9D9",
                  alignSelf: "center",
                  mx: "4px",
                  mt: "24px"
                }}
              />
              
              {/* End Time */}
              <Box sx={{width:{xs:"100%",sm:"unset"}}}>
                <Typography sx={{...timeLabelStyle, ml: "21px"}}>
                  End Time
                </Typography>
                <FormControl sx={{ m: 0, p: 0,width:{xs:"100%",sm:"unset"}}}>
                  <Select
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'End Time' }}
                    IconComponent={CustomDropdownIcon}
                    sx={{
                      ...timeSelectStyle,
                      ...(errors.endTime || errors.timeOrder ? errorSelectStyle : {}),
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: {xs:"7px",sm:"0"},
                        height: '48px',
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#525A65",
                      },
                      '& .MuiOutlinedInput-notchedOutline': { 
                        border: 'none'
                      }
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 207,
                          borderRadius: 20,
                          marginTop: 0,
                          background: '#EEEEEE',
                          borderTop: 'none',
                          border: '1px solid #DAD9D8',
                        },
                      },
                      MenuListProps: {
                        sx: {
                          '& .MuiMenuItem-root:not(:last-child)::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 0,
                            left: '21px',
                            right: '21px',
                            height: '1px',
                            backgroundColor: '#DAD9D8'
                          },
                          '& .MuiMenuItem-root': {
                            paddingTop: '12px',
                            paddingBottom: '12px'
                          }
                        }
                      }
                    }}
                  >
                    {timeOptions.map(({ value, label }) => (
                      <MenuItem 
                        key={value} 
                        value={value}
                        sx={{
                          fontFamily: "'Bricolage Grotesque', sans-serif",
                          fontSize: "14px",
                          color: "#525A65",
                          justifyContent: "flex-start",
                          paddingLeft: "21px"
                        }}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.endTime && <ErrorMessage message="Required" />}
              </Box>
            </Box>
          </Box>

          
            <Box sx={{ ml: "3px", my: "8px", height: "20px" }}>
              {errors.timeOrder && !errors.startTime && !errors.endTime && (
                <ErrorMessage message="End time must be after start time" />
              )}
            </Box>
    

          {/* Timezone Selection */}
          <Box sx={{maxWidth:"334px",width:"100%"}}>
            <Typography sx={{...timeLabelStyle, ml: "21px"}}>
              Timezone
            </Typography>
            <FormControl fullWidth>
              <Select
                value={timezone}
                onChange={(e) => setTimezone(e.target.value)}
                IconComponent={CustomDropdownIcon}
                sx={{
                  height: "48px",
                  backgroundColor: "#FBFBFB",
                  border: "1px solid #DAD9D8",
                  borderRadius: "20px",
                  fontFamily: "'Bricolage Grotesque', sans-serif",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#525A65",
                  ...(errors.timezone ? errorSelectStyle : {}),
                  '& .MuiSelect-select': {
                    padding: "0 16px",
                  },
                  '& .MuiOutlinedInput-notchedOutline': { 
                    border: 'none'
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 290,
                      borderRadius: 20,
                      marginTop: 0,
                      background: '#EEEEEE',
                      border: '1px solid #DAD9D8',
                    },
                  },
                  MenuListProps: {
                    sx: {
                      '& .MuiMenuItem-root:not(:last-child)::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: '21px',
                        right: '21px',
                        height: '1px',
                        backgroundColor: '#DAD9D8'
                      },
                      '& .MuiMenuItem-root': {
                        paddingTop: '12px',
                        paddingBottom: '12px'
                      }
                    }
                  }
                }}
              >
                {timezones.map(({ value, label }) => (
                  <MenuItem 
                    key={value} 
                    value={value}
                    sx={{
                      fontFamily: "'Bricolage Grotesque', sans-serif",
                      fontSize: "14px",
                      color: "#525A65",
                      justifyContent: "flex-start",
                      paddingLeft: "21px"
                    }}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
              {errors.timezone && <ErrorMessage message="Required" />}
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "end", pr: "22px"}}>
       
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#EEEEEE",
            borderRadius: "30px",
            textTransform: "none",
            color: "#0F0F0F",
            fontSize: "16px",
            fontWeight: "500",
            boxShadow:"none",
            border: "1px solid #525A65",
            width: "96px",
            height: "48px",
            "&:hover": {
              backgroundColor: "#E1E1E1",
              boxShadow:"none",
            },
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};



