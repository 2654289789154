import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Box, TextField, Typography, Paper, Zoom, Fade, CircularProgress, Skeleton, Portal } from "@mui/material";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { useEffect, useState } from "react";
import {
  getAthleteByEmail,
  getCoachAthlete,
  getOnboardingFormsByUserId,
  insertAthlete,
  insertCoachAthlete,
  insertOnboardingFormResponse,
  upsertOnboardingForm,
  getAthletesByCoachId,
} from "../../services/query";
import { trimAndValidatePhone } from "../../utils/validations";
import InputLabel from "@mui/material/InputLabel";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { countries, sortedCountries } from "../countrycode/countrycode";
import { close, invitestyle } from "./style";
import { timezones } from './timezones';
import { getFlags } from 'country-flag-icons/unicode'
import Modal from "@mui/material/Modal";
import { SuccessIcon } from "../../routes/Dashboard/Dashboard.component";
import { IconClose } from "../ExerciseModal/ExerciseModal";

// Add this new component at the top of the file, before the main component
const AthleteInvitationModalSkeleton = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#FBFBFB!important",
        margin: "0!important",
        padding: "0",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: ["90vw", "90vw", "445px", "445px"],
          margin: "21px auto",
        }}
      >
        {/* Header */}
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
          <Skeleton variant="text" width={120} height={24} />
          <Skeleton variant="circular" width={48} height={48} />
        </Box>

        {/* Description */}
        <Box sx={{ px: 2, mb: 3 }}>
          <Skeleton variant="text" width="100%" height={20} sx={{ mb: 1 }} />
          <Skeleton variant="text" width="80%" height={20} />
        </Box>

        {/* First Name */}
        <Box sx={{ mb: 2, px: 2 }}>
          <Skeleton variant="text" width={80} height={12} sx={{ mb: 1, ml: 2 }} />
          <Skeleton variant="rectangular" height={50} sx={{ borderRadius: "20px" }} />
        </Box>

        {/* Last Name */}
        <Box sx={{ mb: 2, px: 2 }}>
          <Skeleton variant="text" width={80} height={12} sx={{ mb: 1, ml: 2 }} />
          <Skeleton variant="rectangular" height={50} sx={{ borderRadius: "20px" }} />
        </Box>

        {/* Email */}
        <Box sx={{ mb: 2, px: 2 }}>
          <Skeleton variant="text" width={100} height={12} sx={{ mb: 1, ml: 2 }} />
          <Skeleton variant="rectangular" height={50} sx={{ borderRadius: "20px" }} />
        </Box>

        {/* Phone Number Section */}
        <Box sx={{ mb: 2, px: 2 }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            {/* Country Code */}
            <Box sx={{ width: "40%" }}>
              <Skeleton variant="text" width={100} height={12} sx={{ mb: 1, ml: 2 }} />
              <Skeleton variant="rectangular" height={50} sx={{ borderRadius: "20px" }} />
            </Box>
            {/* Phone Number */}
            <Box sx={{ width: "60%" }}>
              <Skeleton variant="text" width={120} height={12} sx={{ mb: 1, ml: 2 }} />
              <Skeleton variant="rectangular" height={50} sx={{ borderRadius: "20px" }} />
            </Box>
          </Box>
        </Box>

        {/* Form Selection */}
        <Box sx={{ mb: 2, px: 2 }}>
          <Skeleton variant="text" width={60} height={12} sx={{ mb: 1, ml: 2 }} />
          <Skeleton variant="rectangular" height={50} sx={{ borderRadius: "20px" }} />
        </Box>

        {/* Button */}
        <Box sx={{ px: 2, mt: 4 }}>
          <Skeleton variant="rectangular" height={48} sx={{ borderRadius: "20px" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default function AthleteInviteModal({ open, handleClose, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState([]);
  const [email, setEmail] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedForm, setSelectedForm] = useState(null);
  const [default_forms, setDefaultfromst] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showWhatHappensNext, setShowWhatHappensNext] = useState(false);
  const [subCheckComplete, setSubCheckComplete] = useState(false);
  
  // Error state variables
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [formError, setFormError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [generalError, setGeneralError] = useState("");

  // Add this new state for initial loading
  const [initialLoading, setInitialLoading] = useState(true);

  // Add this new state for country code dropdown
  const [isCountryCodeOpen, setIsCountryCodeOpen] = useState(false);

  // Add this useEffect to simulate initial loading
  useEffect(() => {
    if (open) {
      setInitialLoading(true);
      // Simulate loading time
      const timer = setTimeout(() => {
        setInitialLoading(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [open]);

  // Add effect to handle body scroll
  useEffect(() => {
    if (isCountryCodeOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isCountryCodeOpen]);

  // Custom handleModalClose to reset state
  const handleModalClose = () => {
    setSubCheckComplete(false);
    // Reset error states
    setEmailError("");
    setPhoneError("");
    setFormError("");
    setFirstNameError("");
    setLastNameError("");
    setGeneralError("");
    handleClose();
  };

  const supabase = useSupabase();
  const { user } = useAuth();
  
  useEffect(()=>{
    const checkSub = async() => {
      if (!user || !open) return;
      
      try {
        const { data: subscriptionStatus } = await supabase.functions.invoke("st-sub-status");
        const { data: athletesData } = await getAthletesByCoachId(supabase, user.id);
        
        if (!user.free_account && !subscriptionStatus.isSubscribed && athletesData.length >= 3) {
          setSubCheckComplete(true);
        }
      } catch(error) {
        console.error("Error checking subscription status:", error);
      }
    };
    
    checkSub();
  }, [user, supabase, open]);
  
  useEffect(() => {
    if (subCheckComplete && open) {
      setLoading(false);
      handleModalClose();
      onSuccess();
    }
  }, [subCheckComplete, open, handleModalClose, onSuccess]);

  const handleInvite = async () => {
    // Reset all error states
    setEmailError("");
    setPhoneError("");
    setFormError("");
    setFirstNameError("");
    setLastNameError("");
    setGeneralError("");
    
    setLoading(true);
    
    // Check if user is not subscribed and has 3 or more athletes
    try {
      const { data: subscriptionStatus } = await supabase.functions.invoke("st-sub-status");
      const { data: athletesData } = await getAthletesByCoachId(supabase, user.id);
      if (!user.free_account && !subscriptionStatus.isSubscribed && athletesData.length >= 3) {
        setLoading(false);
        handleModalClose();
        onSuccess();
        return;
      }
    } catch (error) {
      console.error("Error checking subscription status:", error);
    }
    
    // Validate all fields at once
    let hasValidationErrors = false;
    
    if (!firstName.trim()) {
      setFirstNameError("First name is required");
      hasValidationErrors = true;
    }
    
    if (!lastName.trim()) {
      setLastNameError("Last name is required");
      hasValidationErrors = true;
    }
    
    if (!email || !email.match(/^[^@]+@[^@]+\.[^@]+$/)) {
      setEmailError("Please enter a valid email address");
      hasValidationErrors = true;
    }
    
    if (email === user.email) {
      setEmailError("Please do not enter your own email address");
      hasValidationErrors = true;
    }
    
    if (!selectedForm) {
      setFormError("Please select an onboarding form");
      hasValidationErrors = true;
    }

    if (!areaCode) {
      setPhoneError("Please select a country code");
      hasValidationErrors = true;
    }

    const phone_number = `${areaCode}${phone}`;
    if (trimAndValidatePhone(phone_number) === false) {
      setPhoneError("Please enter a valid phone number consisting of digits only");
      hasValidationErrors = true;
    }
    
    // Return early if there are any validation errors
    if (hasValidationErrors) {
      setLoading(false);
      return;
    }

    let athlete = {
      status: "PENDING",
      email,
      phone_number,
      first_name: firstName,
      last_name: lastName,
    };

    const { data: existingAthlete, error: _athleteError } =
      await getAthleteByEmail(supabase, email);

    if (existingAthlete.length > 0) {
      setLoading(false);
      setEmailError("An athlete with this email already exists in the system for you or another coach.");
      return;
    } else {
      const { data: newAthlete, error: nae } = await insertAthlete(
        supabase,
        athlete
      );
      if (nae) {
        setLoading(false);
        setPhoneError("An athlete with this phone number already exists in the system for you or another coach.");
        return;
      }
      if (newAthlete) {
        athlete = newAthlete[0];
        
        // Add notification for new athlete invitation
        await supabase.from("notifications").insert({
          title: "New Client Invitation",
        message : `<strong>${athlete.first_name} ${athlete.last_name}</strong> has been added to your client list.`, 
          action: "athlete_invitation",
          user_id: user.id,
          athlete_id: athlete.id,
          isRead: false,
          related_link: `https://app.coachplus.club/athlete/${athlete.id}`
        });
      }
    }

    // create "onboarding_form_response"

    const { data: newResponse, error: newResponseError } =
      await insertOnboardingFormResponse(supabase, {
        athlete_id: athlete.id,
        coach_id: user.id,
        form_id: selectedForm.id,
        status: "sent",
        type: "onboarding",
      });

    if (newResponseError) {
      const { data: default_forms } = await supabase
        .from("default_forms")
        .select("*")
        .eq("id", selectedForm.id);
      
      let lastInsertedResponse = null;
      
      for (let i of default_forms) {
        const { data: insert_default_forms, error } =
          await upsertOnboardingForm(supabase, {
            user_id: user.id,
            custom_questions: i.custom_questions,
            title: i.title,
            welcome_message: i.welcome_message,
            icon: i.icon,
          });
        
        for (let x of insert_default_forms) {
          const { data: newResponse, error: newResponseError } =
            await insertOnboardingFormResponse(supabase, {
              athlete_id: athlete.id,
              coach_id: user.id,
              form_id: x.id,
              status: "sent",
            });

          if (newResponse) {
            lastInsertedResponse = newResponse;
          }

          const { data: coachAthlete, error: coachAthleteError } =
            await getCoachAthlete(supabase, user.id, athlete.id);

          if (coachAthlete.length === 0) {
            await insertCoachAthlete(supabase, [
              {
                coach_id: user.id,
                athlete_id: athlete.id,
              },
            ]);
          }
        }
      }
      
      // Send welcome message only once after all forms are processed
      if (lastInsertedResponse) {
        await supabase.functions.invoke("welcome-wp", {
          body: {
            phone: athlete.phone_number.startsWith('+') ? athlete.phone_number : `+${athlete.phone_number}`,
            onboarding_url: `https://app.coachplus.club/forms/${lastInsertedResponse.id}`,
            coach_name: user.full_name,
            client_name: `${athlete.first_name} ${athlete.last_name}`,
          },
        });
      }
    } else {
      // Send WhatsApp message
      if (newResponse) {
        await supabase.functions.invoke("welcome-wp", {
          body: {
            phone: athlete.phone_number.startsWith('+') ? athlete.phone_number : `+${athlete.phone_number}`,
            onboarding_url: `https://app.coachplus.club/forms/${newResponse.id}`,
            coach_name: user.full_name,
            client_name: `${athlete.first_name} ${athlete.last_name}`,
          },
        });
      }

      // find coach_athlete

      const { data: coachAthlete, error: coachAthleteError } =
        await getCoachAthlete(supabase, user.id, athlete.id);

      if (coachAthlete.length === 0) {
        await insertCoachAthlete(supabase, [
          {
            coach_id: user.id,
            athlete_id: athlete.id,
          },
        ]);
      }
    }
    
    setLoading(false);
    setShowSuccess(true);

    // create coach_athletes
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    const getForms = async () => {
      const { data: _forms } = await getOnboardingFormsByUserId(
        supabase,
        user.id
      );
      if (_forms.length === 0) {
        const { data: _forms } = await supabase
          .from("default_forms")
          .select("*");
        setDefaultfromst(true);
        setSelectedForm(_forms[0]);
        setForms(_forms || []);
      } else {
        setDefaultfromst(false);
        setSelectedForm(null);
        setForms(_forms || []);
      }
    };

    getForms();
  }, [user, open, supabase]);

  // Tüm InputLabel bileşenleri için ortak stiller
  const labelStyle = {
    marginLeft: "20px",
    color: "#838F99",
    fontSize: "10px",
    fontWeight: "400!important",
    marginTop: "0px",
    marginBottom: "7px",
    paddingTop: "0px",
    paddingBottom: "0px",
    lineHeight: "12px",

  };

  // Tüm TextField bileşenleri için ortak stiller
  const inputStyle = {
    ".MuiInputBase-root": {
      borderRadius: "20px",
      border: "1px solid #DAD9D8",
      background: "#FBFBFB!important",
      boxShadow: "none",
      outline: "none",
      paddingLeft: "0px",
      "&.Mui-error": {
        border: "1px solid #d32f2f",
      },
      "&.Mui-focused": {
        border: "1px solid #838F99",
      }
    },
    ".MuiInputBase-input": {
      fontSize: "12px",
      padding: "16px 20px",
      lineHeight: "12px",
      color: "#525A65",
      "&::placeholder": {
        textIndent: "0px"
      }
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  };

  // Autocomplete için ortak stiller
  const autocompleteStyle = {
    width: "100%",
    ".MuiInputBase-root": {
      borderRadius: "20px",
      border: "1px solid #DAD9D8",
      background: "#FBFBFB",
      boxShadow: "none",
      outline: "none",
      paddingLeft: "0px",
      height: "50px",
      display: "flex",
      alignItems: "center",
      "&.Mui-error": {
        border: "1px solid #d32f2f",
      },
      "&.Mui-focused": {
        border: "1px solid #838F99",
      }
    },
    ".MuiInputBase-input": {
      fontSize: "12px",
      color: "#525A65",
      padding: "7.5px 5px 7.5px 20px !important",
    },
    ".MuiAutocomplete-endAdornment": {
      display: "none"
    },
    ".MuiAutocomplete-clearIndicator": {
      display: "none"
    },
    ".MuiAutocomplete-popupIndicator": {
      display: "none"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  };

  // Form Autocomplete için özel stiller
  const formAutocompleteStyle = {
    ...autocompleteStyle,
    ".MuiInputBase-root": {
      ...autocompleteStyle[".MuiInputBase-root"],
      height: "auto",
    },
    ".MuiInputBase-input": {
      ...autocompleteStyle[".MuiInputBase-input"],
      padding: "14px 20px 14px 20px",
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FBFBFB!important",
        margin: "0!important",
        padding: "0",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      {initialLoading ? (
        <AthleteInvitationModalSkeleton />
      ) : (
        <Box
          open={open}
          onClose={handleModalClose}
          TransitionComponent={Zoom}
          TransitionProps={{
            timeout: {
              enter: 300,
              exit: 300,
            }
          }}
          sx={{
            width: ["90vw", "90vw", "445px", "445px"],
            margin: "21px auto",
            position: 'relative',
            overflow: 'hidden'
          }}
          disableScrollLock={false}
          scroll="paper"
        >
          <Fade in={open} timeout={500}>
            <Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{}}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600 !important",
                    color: "#000000",
                  }}
                >
                  Invite your client
                </Typography>
                <Button
                  startIcon={<IconClose />}
                  size="small"
                  onClick={handleModalClose}
                  sx={close}
                ></Button>
              </Box>
              <DialogContent sx={{ padding:"0" }}>
                <DialogContentText
                  sx={{
                    fontSize: "14px",
                    color: "#000000",
                    fontWeight: "400 !important",
                    marginTop: "20px",
                    marginBottom: "35px",
                  }}
                >
                  Select an onboarding form, enter your clients Gmail address and
                  invite them to join you in Coach Plus.
                </DialogContentText>
                <Box sx={{ mb: "14px" }}>
                  <InputLabel
                    htmlFor="bootstrap-input"
                    sx={labelStyle}
                  >
                    First name
                  </InputLabel>
                  <TextField
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      if (firstNameError) setFirstNameError("");
                    }}
                    placeholder="First name"
                    sx={inputStyle}
                    fullWidth
                    error={!!firstNameError}
                  />
                  {firstNameError && (
                    <Typography sx={{ color: "#d32f2f", fontSize: "11px", mt: "4px", ml: "20px" }}>
                      {firstNameError}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mb: "14px"}}>
                  <InputLabel
                    htmlFor="bootstrap-input"
                    sx={labelStyle}
                  >
                    Last name
                  </InputLabel>
                  <TextField
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      if (lastNameError) setLastNameError("");
                    }}
                    placeholder="Last name"
                    sx={inputStyle}
                    fullWidth
                    error={!!lastNameError}
                  />
                  {lastNameError && (
                    <Typography sx={{ color: "#d32f2f", fontSize: "11px", mt: "4px", ml: "20px" }}>
                      {lastNameError}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mb: "14px"}}>
                  <InputLabel
                    htmlFor="bootstrap-input"
                    sx={labelStyle}
                  >
                    Gmail address
                  </InputLabel>
                  <TextField
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (emailError) setEmailError("");
                    }}
                    type="email"
                    sx={inputStyle}
                    fullWidth
                    error={!!emailError}
                  />
                  {emailError && (
                    <Typography sx={{ color: "#d32f2f", fontSize: "11px", mt: "4px", ml: "20px" }}>
                      {emailError}
                    </Typography>
                  )}
                </Box>
              
                <Box sx={{ mb: "14px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <InputLabel
                        htmlFor="bootstrap-input"
                        sx={labelStyle}
                      >
                        Country code
                      </InputLabel>
                      <Autocomplete
                        onChange={(e, v) => {
                          setAreaCode(v?.phone);
                          if (phoneError) setPhoneError("");
                        }}
                        onOpen={() => setIsCountryCodeOpen(true)}
                        onClose={() => setIsCountryCodeOpen(false)}
                        options={sortedCountries}
                        sx={{
                          ...autocompleteStyle,
                          width: "95%",
                          ".MuiInputBase-root": {
                            ...autocompleteStyle[".MuiInputBase-root"],
                            border: phoneError ? "1px solid #d32f2f" : "1px solid #DAD9D8"
                          }
                        }}
                        renderInput={(params) => (
                          <TextField 
                            {...params} 
                            placeholder="+00"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: areaCode && (
                                <Box sx={{ 
                                  display: 'flex', 
                                  alignItems: 'center',
                                  gap: '5px',
                                  marginLeft: "20px",
                                }}>
                                  <span style={{ fontSize: '16px' }}>
                                    {sortedCountries.find(c => c.phone === areaCode)?.flag}
                                  </span>
                                  <Typography sx={{ fontSize: '12px', color: '#525A65' }}>
                                  </Typography>
                                </Box>
                              ),
                            }}
                            sx={{
                              '.MuiInputBase-input': {
                                padding: areaCode ? '0 !important' : '14px 20px 14px 20px !important',
                              },
                            }}
                          />
                        )}
                        disableClearable={true}
                        forcePopupIcon={false}
                        blurOnSelect={true}
                        getOptionLabel={(option) => option ? `+${option.phone} ${option.label}` : ''}
                        filterOptions={(options, state) => {
                          const inputValue = state.inputValue.toLowerCase().trim();
                          return options.filter(option => 
                            option.label.toLowerCase().includes(inputValue) || 
                            option.phone.includes(inputValue) ||
                            (`+${option.phone}`).includes(inputValue)
                          );
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            <Box sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              width: '100%',
                              gap: '8px',
                            }}>
                              <span style={{ fontSize: '16px', lineHeight: 1 }}>{option.flag}</span>
                              <Typography sx={{ 
                                fontSize: "12px",
                                color: '#525A65',
                              }}>
                                +{option.phone}
                              </Typography>
                              <Typography sx={{
                                fontSize: "12px",
                                color: '#838F99',
                                ml: 1,
                                maxWidth: '150px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}>
                                {option.label}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        PaperComponent={(props) => (
                          <Paper {...props} sx={{ 
                            borderRadius: "16px", 
                            backgroundColor: "#FBFBFB",
                            maxHeight: {
                              xs: "400px", 
                              sm: "250px",
                              md: "auto"
                            },
                            position: 'fixed',
                            zIndex: 9999,
                            width: {
                              xs: '90vw',
                              sm: '445px'
                            },
                            mt: 1,
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                            '& .MuiAutocomplete-listbox': {
                              padding: '8px 0'
                            }
                          }} />
                        )}
                        ListboxProps={{
                          sx: {
                            maxHeight: {
                              xs: "300px",
                              sm: "250px",
                              md: "auto"
                            },
                            overflow: 'auto',
                            WebkitOverflowScrolling: 'touch',
                            touchAction: 'auto',
                            '-webkit-overflow-scrolling': 'touch',
                            '&::-webkit-scrollbar': {
                              width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                              background: '#f1f1f1',
                              borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: '#888',
                              borderRadius: '4px',
                            },
                            '& .MuiAutocomplete-option': {
                              color: "#525A65",
                              fontSize: "12px",
                              borderBottom: '1px solid #CECECE',
                              padding: "8px 10px",
                              margin: "0px 10px",
                              '&:last-child': {
                                borderBottom: 'none'
                              }
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{
                        paddingLeft: "0px !important",
                        ".css-cmlpbm": {
                          width: "100% !important",
                        },
                        ".MuiFormControl-root": {
                          width: "100% !important",
                        },
                      }}
                    >
                      <InputLabel
                        htmlFor="bootstrap-input"
                        sx={labelStyle}
                      >
                        Telephone number
                      </InputLabel>
                      <TextField
                        name="phone"
                        type="number"
                        placeholder="Telephone number"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                          if (phoneError) setPhoneError("");
                        }}
                        sx={{
                          ...inputStyle,
                          "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0,
                          }
                        }}
                        fullWidth
                        error={!!phoneError}
                      />
                    </Grid>
                  </Grid>
                  {phoneError && (
                    <Typography sx={{ color: "#d32f2f", fontSize: "11px", mt: "4px", ml: "20px" }}>
                      {phoneError}
                    </Typography>
                  )}
                </Box>
                {default_forms === true ? (
                  <></>
                ) : (
                  <>
                    <Box>
                      <InputLabel
                        htmlFor="bootstrap-input"
                        sx={labelStyle}
                      >
                        Form
                      </InputLabel>
                      <Autocomplete
                        options={forms}
                        sx={{
                          ...formAutocompleteStyle,
                          ".MuiInputBase-root": {
                            ...formAutocompleteStyle[".MuiInputBase-root"],
                            border: formError ? "1px solid #d32f2f" : "1px solid #DAD9D8",
                            "&.Mui-focused": {
                              border: "1px solid #838F99",
                              "&:hover": {
                                border: "1px solid #838F99"
                              }
                            },
                            "&:hover": {
                              border: "1px solid #838F99"
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField 
                            {...params} 
                            placeholder="Select a Form" 
                            InputLabelProps={{ shrink: false }} 
                            sx={{
                              '&label': { display: 'none!important' },
                              '.MuiInputBase-input': { paddingLeft: '20px !important' },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none'
                                },
                                '&:hover fieldset': {
                                  border: 'none'
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none'
                                }
                              }
                            }}
                            error={!!formError}
                          />
                        )}
                        onChange={(e, v) => {
                          setSelectedForm(v);
                          if (formError) setFormError("");
                        }}
                        getOptionLabel={(option) => ` ${option.title}`}
                        getOptionKey={(option) => option.id}
                        PaperComponent={(props) => (
                          <Paper {...props} sx={{ 
                            borderRadius: "16px", 
                            backgroundColor: "#FBFBFB",
                            maxHeight: {
                              xs: "400px", 
                              sm: "250px",
                              md: "auto"
                            },
                            position: 'fixed',
                            zIndex: 9999,
                            width: {
                              xs: '90vw',
                              sm: '445px'
                            },
                            mt: 1,
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                            '& .MuiAutocomplete-listbox': {
                              padding: '8px 0'
                            }
                          }} />
                        )}
                        ListboxProps={{
                          sx: {
                            maxHeight: {
                              xs: "250px", 
                              sm: "250px",
                              md: "auto"
                            },
                            overflow: 'auto',
                            WebkitOverflowScrolling: 'touch',
                            '&::-webkit-scrollbar': {
                              width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                              background: '#f1f1f1',
                              borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: '#888',
                              borderRadius: '4px',
                            },
                            '& .MuiAutocomplete-option': {
                              color: "#525A65",
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px"
                              },
                              borderBottom: '1px solid #CECECE',
                              padding: {
                                xs: "8px 10px", 
                                sm: "10px 10px"
                              },
                              margin: "0px 10px",
                              '&:last-child': {
                                borderBottom: 'none'
                              }
                            }
                          }
                        }}
                        popupIcon={
                          <div style={{ display: 'flex', width: "15px", height: "15px", alignItems: 'center', paddingRight: "10px !important", pointerEvents: 'none' }}>
                            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.782 0L6.5 5.475 1.218 0 0 1.2625 5.282 6.7375 6.5 8 13 1.2625 11.782 0Z" fill="#DAD9D8" />
                            </svg>
                          </div>
                        }
                      />
                    </Box>
                    {formError && (
                      <Typography sx={{ color: "#d32f2f", fontSize: "11px", mt: "4px", ml: "20px" }}>
                        {formError}
                      </Typography>
                    )}
                  </>
                )}
                {generalError && (
                  <Typography sx={{ color: "#d32f2f", fontSize: "12px", mt: "10px", textAlign: "center" }}>
                    {generalError}
                  </Typography>
                )}
              </DialogContent>
              <DialogActions sx={{paddingRight:"0px", padding:"0", marginTop:"40px"}}>
                <Button onClick={handleInvite} disabled={loading} sx={invitestyle}>
                  {loading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CircularProgress size={16} color="inherit" sx={{ mr: 1 }} />
                      Inviting...
                    </Box>
                  ) : "Invite"}
                </Button>
              </DialogActions>
            </Box>
          </Fade>
        </Box>
      )}

      <Modal 
        open={showSuccess} 
        onClose={() => setShowSuccess(false)}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Zoom in={showSuccess} timeout={500}>
          <Box
            sx={{
              position: "relative",
              maxWidth: "529px",
              width: {xs: "90%", sm: "529px"},
              maxHeight: "525px",
              height: {xs: "auto", sm: "525px"},
              margin: "auto",
              background: "#FBFBFB",
              borderRadius: "24px",
              padding: {xs: "20px", sm: "40px 20px"},
              outline: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Typography 
              sx={{ 
                fontFamily: 'Bricolage Grotesque',
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "29px",
                color: "#525A65",
                textAlign: "center",
                width: "104px",
                height: "30.45px",
                marginTop: "40px"
              }}
            >
              Success!
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
                marginTop: "60px"
              }}
            >
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  background: "#013B12",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <SuccessIcon sx={{ color: "#02BD36", fontSize: "40px" }} />
              </Box>
              <Typography
                sx={{
                  fontFamily: "Bricolage Grotesque",
                  fontSize: "16px",
                  lineHeight: "19px",
                  textAlign: "center",
                  color: "#000000",
                  fontWeight: 400,
             
                  textWrap:"wrap"
                }}
              >
                Onboarding form has been sent to your client.
              </Typography>
            </Box>

            <Button
              onClick={() => {
                setShowSuccess(false);
                setShowWhatHappensNext(true);
              }}
              sx={{
                width: "199px",
                height: "48px",
                background: "#EEEEEE",
                border: "1px solid #02BD36",
                borderRadius: "20px",
                color: "#02BD36",
                fontFamily: "Bricolage Grotesque",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "19px",
                textTransform: "none",
                mt:4,
                marginBottom: "40px",
                "&:hover": {
                  background: "#EEEEEE",
                },
              }}
            >
              What happens next?
            </Button>
          </Box>
        </Zoom>
      </Modal>

      <Modal 
        open={showWhatHappensNext} 
        onClose={() => setShowWhatHappensNext(false)}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Zoom in={showWhatHappensNext} timeout={500}>
          <Box
            sx={{
              position: "relative",
              maxWidth: "529px",
              width: {xs: "90%", sm: "529px"},
              maxHeight: "525px",
              height: {xs: "auto", sm: "auto"},
              margin: "auto",
              background: "#FBFBFB",
              borderRadius: "24px",
              padding: {xs: "20px", sm: "40px 30px"},
              outline: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
            sx={{
              display:"flex",
              alignItems:"center",
              width:"100%",justifyContent:"space-between"
            }}
            >
      <Typography 
              sx={{ 
                fontFamily: 'Bricolage Grotesque',
                fontWeight: 700,
                fontSize: "16px",
                color: "#02BD36",
                textAlign: "center",
              }}
              >
              What happens next?
            </Typography>
            <Box
              onClick={() => setShowWhatHappensNext(false)}
              sx={{
                height:"auto",
                width:"auto",
                cursor:"pointer"
              }}
              >
              
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="24" r="24" fill="#EEEEEE"/>
<path d="M17.1423 17.1428C17.7735 16.5117 18.7968 16.5117 19.428 17.1428L30.8564 28.5712C31.4876 29.2024 31.4876 30.2257 30.8564 30.8569C30.2252 31.4881 29.2019 31.4881 28.5707 30.8569L17.1423 19.4285C16.5111 18.7973 16.5111 17.774 17.1423 17.1428Z" fill="#525A65"/>
<path d="M17.1428 30.8577C16.5117 30.2265 16.5117 29.2032 17.1428 28.572L28.5712 17.1436C29.2024 16.5124 30.2257 16.5124 30.8569 17.1436C31.4881 17.7748 31.4881 18.7981 30.8569 19.4293L19.4285 30.8577C18.7973 31.4889 17.774 31.4889 17.1428 30.8577Z" fill="#525A65"/>
</svg>

            </Box>

        
          </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "40px", marginTop: "40px",width:"100%" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Box sx={{ width: "64px", height: "64px", background: "#C9FE58", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="32" cy="32" r="32" fill="#C9FE58"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.8047 46C34.6323 46 45 40.5487 45 31.0227C45 24.3961 38.9428 19 31.5 19C24.0572 19 18 24.5487 18 31.013C18 37.4773 24.2992 41.6968 31.5 41.95C31.4531 42.1703 31.3534 42.5132 31.2422 42.896L31.2422 42.8961C31.0255 43.6417 30.7648 44.5385 30.7648 44.974C30.7648 45.5422 31.2267 46 31.8047 46ZM26.1 32.4979C26.8456 32.4979 27.45 31.8934 27.45 31.1479C27.45 30.4023 26.8456 29.7979 26.1 29.7979C25.3544 29.7979 24.75 30.4023 24.75 31.1479C24.75 31.8934 25.3544 32.4979 26.1 32.4979ZM32.8509 31.1479C32.8509 31.8934 32.2465 32.4979 31.5009 32.4979C30.7553 32.4979 30.1509 31.8934 30.1509 31.1479C30.1509 30.4023 30.7553 29.7979 31.5009 29.7979C32.2465 29.7979 32.8509 30.4023 32.8509 31.1479ZM36.9018 32.4979C37.6473 32.4979 38.2518 31.8934 38.2518 31.1479C38.2518 30.4023 37.6473 29.7979 36.9018 29.7979C36.1562 29.7979 35.5518 30.4023 35.5518 31.1479C35.5518 31.8934 36.1562 32.4979 36.9018 32.4979Z" fill="#0F0F0F"/>
</svg>

            </Box>
            <Typography sx={{ fontFamily: "Bricolage Grotesque", fontWeight: 500, fontSize: "16px", lineHeight: "19px", color: "#0F0F0F",ml:2 }}>
              Client receives a WhatsApp message <Box sx={{width:"auto",height:"auto",display:{xs:"none",sm:"unset"}}}> <br /></Box>  with a link to the onboarding form.
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Box sx={{ width: "64px", height: "64px", background: "#C9FE58", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="32" cy="32" r="32" fill="#C9FE58"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25 20C22.2386 20 20 22.2386 20 25V39C20 41.7614 22.2386 44 25 44H39C41.7614 44 44 41.7614 44 39V25C44 22.2386 41.7614 20 39 20H25ZM25.9992 26C25.3365 26 24.7992 26.5373 24.7992 27.2C24.7992 27.8627 25.3365 28.4 25.9992 28.4H37.9992C38.662 28.4 39.1992 27.8627 39.1992 27.2C39.1992 26.5373 38.662 26 37.9992 26H25.9992ZM25.9992 30.8C25.3365 30.8 24.7992 31.3373 24.7992 32C24.7992 32.6627 25.3365 33.2 25.9992 33.2H37.9992C38.662 33.2 39.1992 32.6627 39.1992 32C39.1992 31.3373 38.662 30.8 37.9992 30.8H25.9992ZM24.7992 36.8C24.7992 36.1373 25.3365 35.6 25.9992 35.6H30.7992C31.462 35.6 31.9992 36.1373 31.9992 36.8C31.9992 37.4627 31.462 38 30.7992 38H25.9992C25.3365 38 24.7992 37.4627 24.7992 36.8Z" fill="#0F0F0F"/>
</svg>

            </Box>
            <Typography sx={{ fontFamily: "Bricolage Grotesque", fontWeight: 500, fontSize: "16px", lineHeight: "19px", color: "#000000",ml:2  }}>
              Client fills out the form and is  <Box sx={{width:"auto",height:"auto",display:{xs:"none",sm:"unset"}}}> <br /></Box> onboarded to CoachPlus.
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Box sx={{ width: "64px", height: "64px", background: "#C9FE58", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="32" cy="32" r="32" fill="#C9FE58"/>
<ellipse cx="25.3996" cy="25.3998" rx="5.39957" ry="5.39984" fill="#0F0F0F"/>
<rect x="20" y="33.1992" width="10.7991" height="10.7997" rx="2" fill="#0F0F0F"/>
<path d="M42.9105 30.7997H34.2779C33.9015 30.7997 33.5562 30.6052 33.3594 30.289C33.1625 29.9729 33.1453 29.5735 33.3179 29.2365L37.6342 20.5523C38.0003 19.8159 39.1985 19.8159 39.5645 20.5523L43.8808 29.2365C44.0535 29.5735 44.0328 29.9729 43.8394 30.289C43.646 30.6052 43.3007 30.7997 42.9209 30.7997H42.9105Z" fill="#0F0F0F"/>
<rect width="3.05447" height="12.2179" rx="1.52723" transform="matrix(0.707089 0.707124 -0.707089 0.707124 41.832 33.1992)" fill="#0F0F0F"/>
<rect width="3.05447" height="12.2179" rx="1.52723" transform="matrix(-0.707089 0.707124 -0.707089 -0.707125 43.9922 41.8398)" fill="#0F0F0F"/>
</svg>

            </Box>
            <Typography sx={{ fontFamily: "Bricolage Grotesque", fontWeight: 500, fontSize: "16px", lineHeight: "19px", color: "#0F0F0F",ml:2  }}>
              Manage workouts & client  <Box sx={{width:"auto",height:"auto",display:{xs:"none",sm:"unset"}}}> <br /></Box>  progress in one place.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "10px",mt:5,width:"98%",justifyContent:"space-between" }}>
          <Typography sx={{ fontFamily: "Bricolage Grotesque", fontWeight: 700, fontSize: "12px", lineHeight: "14px", color: "#000000",width:"65%" }}>
            Clients don't need to download any app -  <Box sx={{width:"auto",height:"auto",display:{xs:"none",sm:"unset"},padding:"0",margin:"0"}}> <br /></Box> it all works seamlessly.
          </Typography>
          <Button
            onClick={() => {
              setShowWhatHappensNext(false);
              onSuccess();
              handleModalClose();
            }}
            sx={{
              width: "84px",
              height: "48px",
              background: "#EEEEEE",
              border: "1px solid #525A65",
              borderRadius: "20px",
              color: "#0F0F0F",
              fontFamily: "Bricolage Grotesque",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "19px",
              textTransform: "none",
              "&:hover": {
                background: "#EEEEEE",
              },
            }}
          >
            Done
          </Button>
        </Box>
      </Box>
    </Zoom>
  </Modal>
</Box>
);
}