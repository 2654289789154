import { PageContainer } from "../../components/PageContainer/PageContainer";
import { useState } from "react";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { Navigate } from "react-router-dom";
import "./style.css";
import { resetCoach } from "../../services/query";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import VerificationSuccessModal from "../../components/WelcomeModal/VerificationSuccessModal";

export const OTP = () => {
  const [otpSent, setOtpSent] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showVerificationSuccess, setShowVerificationSuccess] = useState(false);

  const [code, setCode] = useState("");
  const supabase = useSupabase();
  const [otpError, setOtpError] = useState(false);
  const { user } = useAuth();

  const onSend = async () => {
    setOtpSent(true);
    await supabase.functions.invoke("otp-wp", {
      body: { phone: user.phone_number, user_id: user.id },
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const { data: res, error } = await supabase.functions.invoke("otp-verify", {
      body: { code },
    });
    if (res.error || error) {
      setOtpError(true);
      setCode("");
      setIsLoading(false);
      return;
    }

    // Update is_first_login to true after successful verification
    const { error: updateError } = await supabase
      .from("users")
      .update({ is_first_login: true })
      .eq("id", user.id);

    if (updateError) {
      console.error("Error updating is_first_login:", updateError);
      // Continue with the flow even if update fails
    }

    setIsLoading(false);
    setShowVerificationSuccess(true);
  };

  const handleVerificationSuccess = () => {
    setShowVerificationSuccess(false);
    const queryParams = new URLSearchParams({
      from: "otp",
      status: "success",
    });
    window.location.href = `/?${queryParams.toString()}`;
  };

  const handleSkip = async () => {
    try {
      const { error } = await supabase
        .from("users")
        .update({ isSkippedOtp: true, is_first_login: true })
        .eq("id", user.id);

      if (error) throw error;

      const queryParams = new URLSearchParams({
        from: "otp",
        status: "skipped",
      });
      window.location.href = `/?${queryParams.toString()}`;
    } catch (error) {
      console.error("Error skipping verification:", error);
      alert("Failed to skip verification");
    }
  };

  if (user && user.status === "VERIFIED") {
    return <Navigate to="/" />;
  }

  return (
    <>
      <VerificationSuccessModal
        open={showVerificationSuccess}
        onClose={() => setShowVerificationSuccess(false)}
        onButtonClick={handleVerificationSuccess}
      />
      <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        backgroundColor: "#EEEEEE",
      }}
      >

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          width: "100%",
          height: "529px",
          marginTop: {
            xs: "3%", // Extra small screens (phones)
            sm: "3%", // Small screens (tablets)
            md: "3%", // Medium screens (laptops)
            lg: "3%",
          }, // XS'de 40px, diğerlerinde 0px
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", sm: "65%", md: "60%", lg: "30%" },
            borderRadius: "24px",
            backgroundColor: "#FBFBFB",
            padding: "0px!important",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <PageContainer
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              flexDirection: "column",
              gap: "10px",
              marginTop: "3%",
            }}
          >
            {!otpSent && (
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    color: "#02BD36",
                    padding: "0px",
                    fontSize: "clamp(18px,5vw,24px)",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  Just one more step!
                </h1>
                <div
                  style={{
                    backgroundColor: "#E0F7E0",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 auto",
                  }}
                >
                  <svg
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="30" cy="30" r="30" fill="#C9FE58" />
                    <path
                      d="M43.8064 27.1103L42.2848 25.5886C41.9079 25.2117 41.6985 24.7092 41.6985 24.1787V22.3918C41.6985 20.1303 39.8697 18.3015 37.6082 18.3015H35.8213C35.2908 18.3015 34.7883 18.0921 34.4114 17.7152L32.8897 16.1936C31.2843 14.6021 28.7017 14.6021 27.1103 16.1936L25.5886 17.7152C25.2117 18.0921 24.7092 18.3015 24.1787 18.3015H22.3918C20.1303 18.3015 18.3015 20.1303 18.3015 22.3918V24.1787C18.3015 24.7092 18.0921 25.2117 17.7152 25.5886L16.1936 27.1103C14.6021 28.7157 14.6021 31.2983 16.1936 32.8897L17.7152 34.4114C18.0921 34.7883 18.3015 35.2908 18.3015 35.8213V37.6082C18.3015 39.8697 20.1303 41.6985 22.3918 41.6985H24.1787C24.7092 41.6985 25.2117 41.9079 25.5886 42.2848L27.1103 43.8064C28.7017 45.3979 31.2983 45.3979 32.8897 43.8064L34.4114 42.2848C34.7883 41.9079 35.2908 41.6985 35.8213 41.6985H37.6082C39.8697 41.6985 41.6985 39.8697 41.6985 37.6082V35.8213C41.6985 35.2908 41.9079 34.7883 42.2848 34.4114L43.8064 32.8897C45.3979 31.2843 45.3979 28.7017 43.8064 27.1103ZM36.2401 27.3755L29.1624 34.4532C28.6459 34.9698 27.8083 34.9698 27.2918 34.4532L23.7599 30.9074C23.2573 30.3909 23.2852 29.5672 23.7599 29.0368C24.2764 28.5202 25.114 28.5202 25.6305 29.0368L28.2271 31.6333L34.3695 25.4909C34.9 24.9884 35.7515 25.0163 36.2401 25.5607C36.7148 26.0633 36.7148 26.859 36.2401 27.3755Z"
                      fill="#02BD36"
                    />
                  </svg>
                </div>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#0F0F0F",
                    fontSize: "clamp(12px, 2vw, 16px)", // Minimum 10px, maksimum 16px, dinamik olarak ölçeklenir
                    padding: "0px",
                    marginTop: "10px",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "400",
                  }}
                >
                  We need to verify your phone Number <br /> with a one time code.
                </p>
                <button
                  style={{
                    borderRadius: "17px",
                    border: "1px solid #32CD32",
                    padding: "10px 20px",
                    fontSize: "16px",
                    backgroundColor: "#EEEEEE",
                    color: "#02BD36",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  disabled={loading}
                  onClick={async () => {
                    try {
                      setLoading(true);
                      await onSend();
                      setOtpSent(true);
                    } catch (error) {
                      alert("Error sending OTP");
                      setOtpSent(false);
                    }
                    setLoading(false);
                  }}
                >
                  Send the code now
                </button>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                    paddingLeft: "5px",
                    marginBottom: "30px",
                  }}
                >
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#0F0F0F",
                      fontSize: "14px",
                      fontWeight: "300",
                    }}
                    onClick={async () => {
                      await resetCoach(supabase, user.id);
                      window.location.reload();
                    }}
                  >
                    Change phone number
                  </button>
                  <span style={{ color: "0F0F0F", fontSize: "12px" }}>or</span>
                  <span style={{ color: "#525A65" }}>
                    <span
                      onClick={handleSkip}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "#525A65",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      Skip for now.
                    </span>
                  </span>
                </div>
              </Box>
            )}
            {otpSent && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  gap: "10px",
                  padding: "15px",
                }}
              >
                <h1
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                    color: "#525A65",
                    fontWeight: "600",
                    margin: "0",
                    padding: "0",
                    fontSize: "clamp(16px, 3vw, 24px)",
                  }}
                >
                  One Time Password (OTP)
                </h1>
                <Box
                  sx={{
                    backgroundColor: "#E0F7E0",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "10px 0",
                    marginTop: "30px",
                  }}
                >
                  <svg
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{}}
                  >
                    <circle
                      cx="30"
                      cy="30"
                      r="30"
                      fill={otpError ? "#EC0B43" : "#02BD36"}
                    />
                    <path
                      d="M38.8145 25.902H38.74C38.74 20.9958 34.8168 17 29.9998 17C25.1827 17 21.2595 20.9958 21.2595 25.902H21.1851C19.9684 25.902 19 26.9136 19 28.1275V38.7745C19 40.0137 19.9932 41 21.1851 41H38.8145C40.0312 41 40.9995 39.9884 40.9995 38.7745V28.1275C41.0244 26.8883 40.0312 25.902 38.8145 25.902ZM31.8372 36.7766C31.8869 36.9536 31.8372 37.156 31.7379 37.3077C31.6137 37.4594 31.4399 37.5353 31.2661 37.5353H28.7334C28.5348 37.5353 28.3858 37.4594 28.2617 37.3077C28.1375 37.156 28.1127 36.9536 28.1623 36.7766L28.9817 33.5648C28.2865 33.1855 27.8147 32.4521 27.8147 31.5922C27.8147 30.353 28.8079 29.3667 29.9998 29.3667C31.1916 29.3667 32.1848 30.3783 32.1848 31.5922C32.1848 32.4521 31.7131 33.1855 31.0178 33.5648L31.8372 36.7766ZM35.7604 25.902H24.2392C24.2392 22.6649 26.8215 20.0348 29.9998 20.0348C33.178 20.0348 35.7604 22.6649 35.7604 25.902Z"
                      fill="#FBFBFB"
                    />
                  </svg>
                </Box>
                <Typography
                  sx={{
                    color: "#0F0F0F",
                    fontWeight: "400",
                    marginTop: "15px",
                    textAlign: "center",
                    fontSize: {
                      xs: "12px", // Extra small screens (phones)
                      sm: "13px", // Small screens (tablets)
                      md: "13px", // Medium screens (laptops)
                      lg: "14px", // Large screens (desktops)
                    },
                  }}
                >
                  A verification code has been sent to your phone. <br />
                  Please enter the code below to continue.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: { xs: "10px", sm: "15px", md: "20px" }, // Reduce gap on small screens
                    width: { xs: "100%", sm: "90%", md: "100%" }, // Adjust width for responsiveness
                    marginTop: { xs: "10%", sm: "8%", md: "6%" }, // Adjust spacing
                    flexDirection: { xs: "column", sm: "row" }, // Stack elements on small screens
                    justifyContent: "space-around",
                    flexWrap: "nowrap",
                  }}
                >
                  <Box style={{ width: "100%", display: "flex", gap: "15px" }}>
                    <input
                      type="text"
                      className={`input-field ${otpError ? "error" : ""}`}
                      value={code}
                      placeholder={otpError ? "Oops!" : "Enter OTP Code"}
                      style={{
                        padding: "14px 12px",
                        width: "100%",
                        outline: "none",
                        color: "#525A65",
                        border: "1px solid #DAD9D8",
                        borderRadius: "18px",
                        background: "#FBFBFB",
                        fontSize: "14px",
                        "::placeholder": {
                          color: otpError ? "#EC0B43" : "#525A65", // Hata varsa kırmızı, yoksa gri
                        },
                      }}
                      onChange={(e) => setCode(e.target.value)}
                    />

                    <Button
                      sx={{
                        padding: "12px 16px",
                        backgroundColor: "#EEEEEE",
                        borderRadius: "20px",
                        border: "1px solid #525A65",
                        cursor: "pointer",
                        color: "#525A65",
                        fontSize: "14px", // Adjust font size
                        fontWeight: "500",
                        textTransform: "none",
                        width: "60%", // Make button full width on small screens
                        maxWidth: "100px", // Prevent too large buttons on large screens
                      }}
                      onClick={onSubmit}
                    >
                      {isLoading ? (
                        <CircularProgress size={24} sx={{ color: "#02BD36" }} />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Box>
                </Box>

                {otpError && (
                  <Typography
                    color="error"
                    variant="body2"
                    sx={{
                      color: "#EC0B43",
                      margin: "10px 0",
                      animation: "popIn 0.3s ease-in-out",
                      fontSize: {
                        xs: "12px", // Mobil (extra small)
                        sm: "12px", // Tablet (small)
                        md: "13px", // Laptop (medium)
                        lg: "14px",
                      },
                    }}
                  >
                    Invalid or expired OTP. Please try again.
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#525A65",
                    marginTop: "0px",
                    fontWeight: "400",
                    textAlign: "center",
                    gap: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    className="responsive-flex"
                    style={{
                      display: "flex",
                      gap: "5px",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>{!otpError && "Didn't receive the code"} </span>
                    <span style={{ display: "flex", gap: "6px" }}>
                      {otpError && "You can"}
                      <span
                        onClick={async () => {
                          try {
                            setLoading(true);
                            setOtpError(false);
                            setCode("");
                            await onSend();
                          } catch (error) {
                            alert("Error sending OTP");
                          }
                          setLoading(false);
                        }}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          fontWeight: "500",
                        }}
                      >
                        Resend OTP
                      </span>{" "}
                      /{" "}
                      <span
                        onClick={async () => {
                          await resetCoach(supabase, user.id);
                          window.location.reload();
                        }}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          fontWeight: "500",
                        }}
                      >
                        Change The Number
                      </span>
                    </span>
                  </span>
                  or
                  <span style={{ color: "#525A65" }}>
                    <span
                      onClick={handleSkip}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "#525A65",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      Skip for now.
                    </span>
                  </span>
                </Typography>
              </Box>
            )}
          </PageContainer>
        </Box>
      </Box>
      </Box>

    </>
  );
};
