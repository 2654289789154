import React, { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Typography,
  Link
} from "@mui/material";
import { IconClose } from "../ExerciseModal/ExerciseModal";
import { useWalkthroughState } from "./ToolsStateContext";
import { useAuth } from "../../providers/AuthContextProvider";
import { supabase } from "../../services/supabase";

/**
 * Clients walkthrough modal shown in the welcome flow
 */
const ClientsWalkthroughModal = ({
  open,
  onClose,
  onNext,
  onSkip
}) => {
  const { setIsClientsWalkthroughOpen } = useWalkthroughState();
  const { user } = useAuth();

  // Update the context state when the modal opens or closes
  useEffect(() => {
    
    // Scroll to top when modal opens
    if (open) {
    setIsClientsWalkthroughOpen(true);

      window.scrollTo(0, 0);
    }
    
    // Clean up when component unmounts
    return () => {
      setIsClientsWalkthroughOpen(false);
    };
  }, [open, setIsClientsWalkthroughOpen]);
  
  const handleClose = async() => {
    const { error } = await supabase
    .from('users')
    .update({ is_first_login: false })
    .eq('id', user.id);

  if (error) throw error;
  
    setIsClientsWalkthroughOpen(false);
    onClose();
  };
  
  const handleSkip = () => {
    setIsClientsWalkthroughOpen(false);
    onSkip();
  };
  
  const handleNext = () => {
    setIsClientsWalkthroughOpen(false);
    onNext();
  };
  if(!user){
    return
  }
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleSkip();
        }
      }}
      PaperProps={{
        sx: {
          width: {xs: '90%', sm: '529px'},
          height: {xs: '500px', sm: '525px'},
          margin: 'auto',
          background: '#FBFBFB',
          borderRadius: '24px',
          display: 'flex',
          overflow: 'hidden',
          flexDirection: 'column',
          p: {xs:4,sm:6},
          position: 'relative',
          maxWidth: '90vw'
        }
      }}
    >
        <Box
          sx={{
            position: 'fixed',
            top: {xs:"21px",sm:"21px",md:"21px",lg:"21px"},
            right: {xs:"10px",sm:"21px"},
            zIndex: 10000,
            cursor: "pointer",
            pointerEvents: "auto",
            display: "block",
            padding: 0,
            margin: 0
          }}
          onClick={handleClose}
        >
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" style={{display: "block"}}>
            <circle cx="24" cy="24" r="23.5" fill="#E3E2E0" stroke="#02BD36"/>
            <rect x="14" y="23" width="20" height="2" rx="1" fill="#0F0F0F"/>
            <rect x="23" y="34" width="20" height="2" rx="1" transform="rotate(-90 23 34)" fill="#0F0F0F"/>
          </svg>
        </Box>
    <Box>
      <Box sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Typography sx={{
          fontFamily: 'Bricolage Grotesque',
          fontWeight: 700,
          fontSize: {xs:"14px",sm:"16px"},
          lineHeight: '19px',
          color: '#000000',
        }}>
          Invite and manage your clients!
        </Typography>
      <Box sx={{
        padding: '0px',
        margin: '0px',
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '48px',
        width: '48px',
      }} onClick={handleClose}>
      <IconClose />
      </Box>
    </Box>
    
        {/* First feature */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mb: 3,
          mt: 2
        }}>
          <Box sx={{ mr: 3, position: 'relative' }}>
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="32" cy="32" r="32" fill="#02BD36"/>
<path d="M41.3445 34.693C39.5279 34.693 37.7991 35.4435 36.5535 36.7371L29.2112 33.068C29.2711 32.7127 29.2991 32.3533 29.2991 31.994C29.2991 31.6347 29.2711 31.2754 29.2112 30.924L36.5535 27.2549C37.7991 28.5485 39.5239 29.2991 41.3405 29.2991C45.0056 29.2991 47.992 26.3167 47.992 22.6475C47.992 18.9784 45.0096 16 41.3445 16C37.6793 16 34.693 18.9824 34.693 22.6515C34.693 23.0109 34.7209 23.3702 34.7808 23.7255L27.4386 27.3946C26.1929 26.0971 24.4641 25.3465 22.6475 25.3465C18.9824 25.3465 16 28.3289 16 31.998C16 35.6671 18.9824 38.6495 22.6515 38.6495C24.4681 38.6495 26.1969 37.8989 27.4425 36.6054L34.7848 40.2785C34.7249 40.6338 34.6969 40.9891 34.6969 41.3485C34.6969 45.0136 37.6794 48 41.3485 48C45.0176 48 48 45.0176 48 41.3485C48 37.6794 45.0176 34.6969 41.3485 34.6969L41.3445 34.693ZM41.3445 19.9526C42.8297 19.9526 44.0394 21.1623 44.0394 22.6475C44.0394 24.1328 42.8297 25.3425 41.3445 25.3425C39.8593 25.3425 38.6495 24.1328 38.6495 22.6475C38.6495 21.1623 39.8593 19.9526 41.3445 19.9526ZM41.3445 44.0394C39.8593 44.0394 38.6495 42.8297 38.6495 41.3445C38.6495 39.8593 39.8593 38.6495 41.3445 38.6495C42.8297 38.6495 44.0394 39.8593 44.0394 41.3445C44.0394 42.8297 42.8297 44.0394 41.3445 44.0394ZM22.6515 34.693C21.1663 34.693 19.9566 33.4832 19.9566 31.998C19.9566 30.5128 21.1663 29.3031 22.6515 29.3031C24.1367 29.3031 25.3465 30.5128 25.3465 31.998C25.3465 33.4832 24.1367 34.693 22.6515 34.693Z" fill="#E9FFCE"/>
</svg>
          </Box>
          <Typography sx={{
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 500,
            fontSize: {xs:"14px",sm:"16px"},
            lineHeight: '19px',
            color: '#0F0F0F',
            maxWidth: '266px'
          }}>
            Easily add clients & send onboarding forms.
          </Typography>
        </Box>
        
        {/* Second feature */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mb: 3
        }}>
          <Box sx={{ mr: 3, position: 'relative' }}>
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="32" cy="32" r="32" fill="#02BD36"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.5301 21.3621C23.5494 18.3428 27.5023 16.8373 31.4594 16.8373V14.5161C31.4594 14.3142 31.5687 14.1376 31.7495 14.0535C31.9346 13.9652 32.1364 13.9862 32.2962 14.1166L37.906 18.5825C38.0321 18.6834 38.0994 18.8222 38.0994 18.9862C38.0994 19.1502 38.0321 19.289 37.906 19.3899L32.2962 23.8558C32.1364 23.982 31.9346 24.0072 31.7495 23.9189C31.5645 23.8306 31.4594 23.6582 31.4594 23.4563V21.1351C28.5998 21.1351 25.7445 22.2242 23.562 24.4067C19.2012 28.7675 19.2012 35.8365 23.562 40.1972C23.8185 40.4538 24.0835 40.6935 24.3568 40.9205L21.3122 43.9609C21.0431 43.7254 20.7824 43.4857 20.5259 43.2292C14.4914 37.1947 14.4914 27.4092 20.5259 21.3705L20.5301 21.3621Z" fill="#E9FFCE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.1 44.8036L31.7097 49.2695C31.8695 49.3957 32.0714 49.4209 32.2564 49.3326C32.4414 49.2443 32.5466 49.0719 32.5466 48.87V46.5487C36.5037 46.5487 40.4566 45.0391 43.4759 42.024C49.5104 35.9895 49.5104 26.1998 43.4759 20.1653C43.2194 19.9088 42.9587 19.6649 42.6895 19.4336L39.645 22.4782C39.9183 22.7052 40.1832 22.9449 40.4397 23.2015C44.8005 27.5623 44.8005 34.6312 40.4397 38.992C38.2572 41.1745 35.4019 42.2636 32.5424 42.2636V39.9424C32.5424 39.7405 32.433 39.5639 32.2522 39.4798C32.0714 39.3957 31.8653 39.4125 31.7055 39.5429L26.0958 44.0088C25.9696 44.1097 25.9023 44.2485 25.9023 44.4125C25.9023 44.5765 25.9696 44.7153 26.0958 44.8162L26.1 44.8036Z" fill="#E9FFCE"/>
</svg>
          </Box>
          <Typography sx={{
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 500,
            fontSize: {xs:"14px",sm:"16px"},
            lineHeight: '19px',
            color: '#0F0F0F',
            maxWidth: '298px'
          }}>
            Automatically sync schedules with Google Calendar.
          </Typography>
        </Box>
        
        {/* Third feature */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mb: 3
        }}>
          <Box sx={{ mr: 3, position: 'relative' }}>
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="32" cy="32" r="32" fill="#02BD36"/>
<path d="M45.0845 16H34.3657V19.8654H40.4575L33.0318 27.3507C32.8283 27.5558 32.4939 27.5595 32.2867 27.358L29.5752 24.6247C27.8451 22.8844 25.0354 22.8844 23.3053 24.6247L15 33.004L17.7079 35.7336L26.0204 27.3543C26.2531 27.1198 26.6347 27.1198 26.8673 27.3543L29.5716 30.0803C31.2726 31.7913 34.0423 31.7913 35.7433 30.0803L43.169 22.595V28.7356H47.0036V17.9309C47.0036 16.8647 46.1422 16 45.0881 16H45.0845Z" fill="#E9FFCE"/>
<path d="M41.6125 42.982C41.6125 43.0663 41.5435 43.1359 41.4599 43.1359H37.1636V35.3099H33.329V43.1359H28.8801V33.2215H25.0455V43.1359H20.7493C20.6657 43.1359 20.5966 43.0663 20.5966 42.982V38.4425H16.7656V42.982C16.7656 45.1986 18.5539 47.0013 20.7529 47.0013H41.4599C43.6589 47.0013 45.4471 45.1986 45.4471 42.982V31.1367H41.6125V42.982Z" fill="#E9FFCE"/>
</svg>
          </Box>
          <Typography sx={{
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 500,
            fontSize: {xs:"14px",sm:"16px"},
            lineHeight: '19px',
            color: '#0F0F0F',
            maxWidth: '285px'
          }}>
            Manage workouts & client progress in one place.
          </Typography>
        </Box>

        {/* Additional info text */}
        <Typography sx={{
          fontFamily: 'Bricolage Grotesque',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '17px',
          color: '#000000',
          mt: 2,
          mb: 'auto'
        }}>
          Clients receive updates via WhatsApp & Email. No extra apps needed!
        </Typography>
        
        {/* Bottom controls */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 'auto',
          mb: 1
        }}>
          <Link
            component="button"
            onClick={handleSkip}
            sx={{
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14px',
              color: '#525A65',
              textDecorationLine: 'underline',
              cursor: 'pointer',
              border: 'none',
              background: 'transparent'
            }}
          >
            Skip for now
          </Link>
          
          <Button
            onClick={handleNext}
            sx={{
              background: '#EEEEEE',
              border: '1px solid #525A65',
              borderRadius: '20px',
              padding: '15px 22px',
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              color: '#0F0F0F',
              textTransform: 'none',
              minWidth: '80px',
              '&:hover': {
                background: '#E0E0E0'
              }
            }}
          >
            Next
          </Button>
        </Box>
        
        {/* Pagination dots - both dots are green since we're on the second page */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          gap: 1,
          position: 'absolute',
          bottom: 24,
          left: '50%',
          transform: 'translate(-50%, 0)'
        }}>
          <Box sx={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            background: '#E3E2E0',
          }}/>
          <Box sx={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            background: '#02BD36',
          }}/>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ClientsWalkthroughModal; 