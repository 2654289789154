import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Box, Typography, Button, Paper, TextField, IconButton, Chip, Stack, Tooltip, Snackbar, Alert, Drawer, Switch, Divider } from '@mui/material';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { useAuth, useSupabase } from '../../providers/AuthContextProvider';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ImageUploadIcon from '@mui/icons-material/Image';
import PreviewIcon from '@mui/icons-material/Preview';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import SearchIcon from '@mui/icons-material/Search';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ImageResize from 'quill-image-resize-module-react';
import Quill from 'quill';
import { Logo } from './Blog.route';
Quill.register('modules/imageResize', ImageResize);

export const BlogCreate = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [coverImage, setCoverImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const [isPublic, setIsPublic] = useState(true);
    const [category, setCategory] = useState('Training & Performance');
    const [subcategory, setSubcategory] = useState('');
    const { user } = useAuth();
    const supabase = useSupabase();
    const navigate = useNavigate();
    const quillRef = useRef(null);
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const [autoSaveStatus, setAutoSaveStatus] = useState('');
    const [draftId, setDraftId] = useState(null);
    const [wordCount, setWordCount] = useState(0);
    const [readingTime, setReadingTime] = useState(0);
    const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [distactionFreeMode, setDistractionFreeMode] = useState(false);
    const [showSeoPreview, setShowSeoPreview] = useState(false);
    const [autoSaveEnabled, setAutoSaveEnabled] = useState(true);
    const lastSavedContent = useRef({ title, content });

    const mainCategories = {
        'Training & Performance': [
            'Strength & Conditioning',
            'Endurance & Stamina Training',
            'Speed & Agility Drills',
            'Recovery & Injury Prevention',
            'Nutrition for Athletes',
            'Warm-up & Cool-down Techniques'
        ],
        'Coaching Strategies': [
            'Building Team Chemistry',
            'Developing Game Strategies',
            'Analyzing Opponents',
            'Effective Communication',
            'Coaching Youth vs. Professional',
            'Leadership & Motivation'
        ],
        'Mental & Psychological': [
            'Sports Psychology',
            'Performance Anxiety',
            'Building Confidence',
            'Focus & Concentration',
            'Handling Pressure',
            'Winning Mindset'
        ],
        'Sport-Specific': [
            'Football Coaching',
            'Basketball Training',
            'Tennis Coaching',
            'Combat Sports',
            'Swimming Training',
            'Running & Marathon'
        ],
        'Injury & Recovery': [
            'Common Sports Injuries',
            'Recovery Practices',
            'Rest & Sleep',
            'Stretching & Mobility',
            'Rehabilitation Training',
            'Return to Play'
        ],
        'Technology & Tools': [
            'Coaching Apps',
            'Wearable Tech',
            'Video Analysis',
            'AI & Data',
            'Online Training'
        ],
        'Business & Career': [
            'Starting a Business',
            'Personal Branding',
            'Marketing Strategies',
            'Online vs In-Person',
            'Client Acquisition',
            'Certifications'
        ]
    };

    // Auto-save functionality
    useEffect(() => {
        if (!autoSaveEnabled) return;
        
        const hasChanges = title !== lastSavedContent.current.title || 
                          content !== lastSavedContent.current.content;
        
        if (!hasChanges) return;

        const timer = setTimeout(() => {
            autoSave();
        }, 5000);

        return () => clearTimeout(timer);
    }, [title, content, autoSaveEnabled]);

    // Word count and reading time calculation
    useEffect(() => {
        const text = content.replace(/<[^>]*>/g, '');
        const words = text.trim().split(/\s+/).length;
        setWordCount(words);
        setReadingTime(Math.ceil(words / 200));
    }, [content]);

    // Keyboard shortcuts
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.ctrlKey || e.metaKey) {
                switch (e.key.toLowerCase()) {
                    case 's':
                        e.preventDefault();
                        handleSubmit(e);
                        break;
                    case 'p':
                        e.preventDefault();
                        setIsPreviewMode(!isPreviewMode);
                        break;
                    case 'd':
                        e.preventDefault();
                        setDistractionFreeMode(!distactionFreeMode);
                        break;
                    default:
                        break;
                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [isPreviewMode, distactionFreeMode]);
    useEffect(()=>{
        if(user!==null && !user.is_blog_author){
            navigate("/blog")
        }
    },[user])
    const autoSave = async () => {
        if (!title && !content) return;
        
        try {
            setAutoSaveStatus('Saving...');
            const draftData = {
                title,
                content,
                cover_image: coverImage,
                tags,
                categories: subcategory ? [subcategory] : [category],
                isPublic,
                is_draft: true,
                author_id: user.id,
                updated_at: new Date().toISOString()
            };

            if (draftId) {
                const { error: updateError } = await supabase
                    .from('blog_posts')
                    .update(draftData)
                    .eq('id', draftId);

                if (updateError) throw updateError;
            } else {
                const { data, error: insertError } = await supabase
                    .from('blog_posts')
                    .insert([draftData])
                    .select();

                if (insertError) throw insertError;
                
                if (data && data.length > 0) {
                    setDraftId(data[0].id);
                } else {
                    throw new Error('Failed to create draft: No data returned');
                }
            }
            
            lastSavedContent.current = { title, content };
            setAutoSaveStatus('Saved');
            
            setTimeout(() => {
                setAutoSaveStatus('');
            }, 2000);
        } catch (error) {
            console.error('Error auto-saving:', error);
            setAutoSaveStatus('Save failed');
            
            // Show error for longer duration
            setTimeout(() => {
                setAutoSaveStatus('');
            }, 4000);
        }
    };
    
    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        try {
            setLoading(true);
            const fileExt = file.name.split('.').pop();
            const fileName = `${Math.random()}.${fileExt}`;
            const filePath = `blog-covers/${fileName}`;

            const { error: uploadError } = await supabase.storage
                .from('blog-images')
                .upload(filePath, file);

            if (uploadError) throw uploadError;

            const { data: { publicUrl } } = supabase.storage
                .from('blog-images')
                .getPublicUrl(filePath);

            setCoverImage(publicUrl);
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!title || !content || !category) return;

        try {
            setLoading(true);
            const { error } = await supabase
                .from('blog_posts')
                .insert([
                    {
                        title,
                        content,
                        cover_image: coverImage,
                        author_id: user.id,
                        tags: tags,
                        isPublic: isPublic,
                        categories: subcategory ? [subcategory] : [category]
                    }
                ]);

            if (error) throw error;
            navigate('/blog');
        } catch (error) {
            console.error('Error creating blog post:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleTagInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const newTag = tagInput.trim();
            if (newTag && !tags.includes(newTag)) {
                setTags([...tags, newTag]);
            }
            setTagInput('');
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
    };

    const imageHandler = async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                try {
                    setLoading(true);
                    const fileExt = file.name.split('.').pop();
                    const fileName = `${Math.random()}.${fileExt}`;
                    const filePath = `blog-content/${fileName}`;

                    const { error: uploadError } = await supabase.storage
                        .from('blog-images')
                        .upload(filePath, file);

                    if (uploadError) throw uploadError;

                    const { data: { publicUrl } } = supabase.storage
                        .from('blog-images')
                        .getPublicUrl(filePath);

                    const editor = quillRef.current.getEditor();
                    const range = editor.getSelection();
                    editor.insertEmbed(range ? range.index : 0, 'image', publicUrl);
                } catch (error) {
                    console.error('Error uploading image:', error);
                    alert('Failed to upload image. Please try again.');
                } finally {
                    setLoading(false);
                }
            }
        };
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'font': [] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'direction': 'rtl' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],
                ['blockquote', 'code-block'],
                ['link', 'image', 'video', 'formula'],
                ['clean']
            ],
            handlers: {
                image: imageHandler
            }
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    }), []);

    return (
        <PageContainer>
                <Logo/>

            <Box sx={{
                display: 'flex',
                minHeight: '100vh',
                backgroundColor: '#FBFBFB',
                transition: 'all 0.3s ease'
            }}>

                {/* Main Content */}
                <Box sx={{ 
                    flex: 1,
                    p: distactionFreeMode ? 0 : 3,
                    transition: 'all 0.3s ease'
                }}>
                    {/* Header */}
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        mb: 4,
                        opacity: distactionFreeMode ? 0 : 1,
                        transition: 'opacity 0.3s ease',
                        '&:hover': {
                            opacity: 1
                        }
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <IconButton onClick={() => navigate('/blog')}>
                        <ArrowBackIcon />
                    </IconButton>
                            <Box>
                    <Typography variant="h4" sx={{
                        fontWeight: 600,
                                    color: '#292929',
                                    fontSize: { xs: '24px', md: '28px' }
                                }}>
                                    {title || 'Untitled Post'}
                                </Typography>
                                <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: 2,
                                    color: '#757575',
                                    fontSize: '14px'
                                }}>
                                    {autoSaveStatus && (
                                        <Typography variant="caption">
                                            {autoSaveStatus}
                                        </Typography>
                                    )}
                                    <Typography>
                                        {wordCount} words • {readingTime} min read
                    </Typography>
                                </Box>
                            </Box>
                </Box>

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Tooltip title="Keyboard shortcuts (⌘K)">
                                <IconButton onClick={() => setShowKeyboardShortcuts(true)}>
                                    <KeyboardIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Settings">
                                <IconButton onClick={() => setShowSettings(true)}>
                                    <SettingsIcon />
                                </IconButton>
                            </Tooltip>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={loading || !title || !content}
                                sx={{
                                    backgroundColor: '#02BD36',
                                    borderRadius: '20px',
                                    textTransform: 'none',
                                    px: 3,
                                    '&:hover': {
                                        backgroundColor: '#01a62f'
                                    }
                                }}
                            >
                                {loading ? 'Publishing...' : 'Publish'}
                            </Button>
                        </Box>
                    </Box>

                    {/* Main Editor */}
                    <Box sx={{ 
                        maxWidth: distactionFreeMode ? '800px' : '100%',
                        margin: '0 auto',
                        transition: 'all 0.3s ease'
                    }}>
                    <TextField
                        fullWidth
                            placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                            variant="standard"
                            sx={{
                                mb: 3,
                                '& .MuiInputBase-input': {
                                    fontSize: '48px',
                                    fontWeight: 700,
                                    color: '#292929',
                                    lineHeight: 1.2,
                                    '&::placeholder': {
                                        color: '#757575',
                                        opacity: 1
                                    }
                                },
                                '& .MuiInput-underline:before': {
                                    borderBottom: 'none'
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottom: 'none'
                                }
                            }}
                        />

                        <ReactQuill
                            ref={quillRef}
                            value={content}
                            onChange={setContent}
                            modules={modules}
                            placeholder="Tell your story..."
                            style={{
                                border: 'none',
                                fontSize: '18px',
                                lineHeight: '1.8'
                            }}
                        />
                    </Box>
                </Box>

                {/* Settings Drawer */}
                <Drawer
                    anchor="right"
                    open={showSettings}
                    onClose={() => setShowSettings(false)}
                    PaperProps={{
                        sx: {
                            width: 360,
                            p: 3
                        }
                    }}
                >
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Post Settings
                        </Typography>
                        <Divider />
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            Visibility
                        </Typography>
                        <Button
                            variant={isPublic ? "contained" : "outlined"}
                            onClick={() => setIsPublic(!isPublic)}
                            fullWidth
                            sx={{
                                justifyContent: 'space-between',
                                textTransform: 'none',
                                backgroundColor: isPublic ? '#02BD36' : 'transparent',
                                borderColor: '#02BD36',
                                color: isPublic ? 'white' : '#02BD36',
                                '&:hover': {
                                    backgroundColor: isPublic ? '#01a62f' : 'rgba(2, 189, 54, 0.1)',
                                    borderColor: '#02BD36'
                                }
                            }}
                        >
                            {isPublic ? 'Public' : 'Private'}
                        </Button>
                        <Typography variant="caption" sx={{ display: 'block', mt: 1, color: '#757575' }}>
                            {isPublic ? 'Anyone can view this post' : 'Only you can view this post'}
                        </Typography>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            Categories
                        </Typography>
                        <Box sx={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                        }}>
                            {/* Main Categories */}
                            <Box>
                                <Typography variant="caption" sx={{ 
                                    display: 'block', 
                                    mb: 1, 
                                    color: '#02BD36',
                                    fontWeight: 500 
                                }}>
                                    Main Category *
                                </Typography>
                                <Box sx={{ 
                                    display: 'flex',
                                    gap: 1,
                                    flexWrap: 'wrap'
                                }}>
                                    {Object.keys(mainCategories).map((cat) => (
                                        <Chip
                                            key={cat}
                                            label={cat}
                                            onClick={() => {
                                                setCategory(cat);
                                                setSubcategory('');
                                            }}
                                            color={category === cat ? "primary" : "default"}
                                            variant={category === cat ? "filled" : "outlined"}
                                            sx={{
                                                borderColor: category === cat ? '#02BD36' : undefined,
                                                backgroundColor: category === cat ? '#02BD36' : 'transparent',
                                                '&:hover': {
                                                    backgroundColor: category === cat ? '#01a62f' : 'rgba(2, 189, 54, 0.1)'
                                                }
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Box>

                            {/* Subcategories */}
                            {category && (
                                <Box>
                                    <Typography variant="caption" sx={{ 
                                        display: 'block', 
                                        mb: 1, 
                                        color: '#1976d2',
                                        fontWeight: 500 
                                    }}>
                                        Subcategory (Optional)
                                    </Typography>
                                    <Box sx={{ 
                                        display: 'flex',
                                        gap: 1,
                                        flexWrap: 'wrap'
                                    }}>
                                        {mainCategories[category].map((subcat) => (
                                            <Chip
                                                key={subcat}
                                                label={subcat}
                                                onClick={() => setSubcategory(subcat)}
                                                color={subcategory === subcat ? "info" : "default"}
                                                variant={subcategory === subcat ? "filled" : "outlined"}
                                                sx={{
                                                    borderColor: subcategory === subcat ? '#1976d2' : undefined,
                                                    backgroundColor: subcategory === subcat ? '#1976d2' : 'transparent',
                                                    '&:hover': {
                                                        backgroundColor: subcategory === subcat ? '#1565c0' : 'rgba(25, 118, 210, 0.1)'
                                                    }
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            Tags
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder="Add tags (press Enter or comma to add)"
                            value={tagInput}
                            onChange={(e) => setTagInput(e.target.value)}
                            onKeyDown={handleTagInputKeyDown}
                            sx={{ mb: 2 }}
                        />
                        <Box sx={{ 
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1
                        }}>
                            {tags.map((tag) => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    onDelete={() => handleDeleteTag(tag)}
                                    sx={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.12)'
                                        }
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            Features
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            mb: 2
                        }}>
                            <Typography>Auto-save</Typography>
                            <Switch
                                checked={autoSaveEnabled}
                                onChange={(e) => setAutoSaveEnabled(e.target.checked)}
                                color="primary"
                            />
                        </Box>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center'
                        }}>
                            <Typography>Distraction-free mode</Typography>
                            <Switch
                                checked={distactionFreeMode}
                                onChange={(e) => setDistractionFreeMode(e.target.checked)}
                                color="primary"
                            />
                        </Box>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            SEO Preview
                        </Typography>
                        <Paper sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                            <Typography sx={{ color: '#1a0dab', fontSize: '18px', mb: 0.5 }}>
                                {title || 'Your Title Here'}
                            </Typography>
                            <Typography sx={{ color: '#006621', fontSize: '14px', mb: 0.5 }}>
                                {window.location.origin}/blog/{title?.toLowerCase().replace(/\s+/g, '-')}
                            </Typography>
                            <Typography sx={{ color: '#545454', fontSize: '14px' }}>
                                {content.replace(/<[^>]*>/g, '').substring(0, 160)}...
                            </Typography>
                        </Paper>
                    </Box>

                    <Box>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            Cover Image
                        </Typography>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="cover-image-upload"
                            type="file"
                            onChange={handleImageUpload}
                        />
                        <label htmlFor="cover-image-upload">
                            <Button
                                component="span"
                                variant="outlined"
                                startIcon={<ImageUploadIcon />}
                                fullWidth
                                sx={{
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    height: coverImage ? 'auto' : '200px'
                                }}
                            >
                                {coverImage ? (
                                <img
                                    src={coverImage}
                                    alt="Cover"
                                    style={{
                                            width: '100%',
                                            height: '200px',
                                        objectFit: 'cover',
                                            borderRadius: '4px'
                                        }}
                                    />
                                ) : (
                                    'Add Cover Image'
                                )}
                            </Button>
                        </label>
                    </Box>
                </Drawer>

                {/* Keyboard Shortcuts Dialog */}
                <Drawer
                    anchor="right"
                    open={showKeyboardShortcuts}
                    onClose={() => setShowKeyboardShortcuts(false)}
                    PaperProps={{
                        sx: {
                            width: 360,
                            p: 3
                        }
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 3 }}>
                        Keyboard Shortcuts
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>Save draft</Typography>
                            <Typography sx={{ color: '#757575' }}>⌘ + S</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>Preview</Typography>
                            <Typography sx={{ color: '#757575' }}>⌘ + P</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>Distraction-free mode</Typography>
                            <Typography sx={{ color: '#757575' }}>⌘ + D</Typography>
                            </Box>
                    </Box>
                </Drawer>

                        <style>
                            {`
                                .ql-editor {
                            font-family: 'Georgia', serif;
                            font-size: 18px;
                            line-height: 1.8;
                            padding: ${distactionFreeMode ? '40px' : '20px'};
                            max-width: 800px;
                            margin: 0 auto;
                            min-height: 60vh;
                            color: #292929;
                        }
                        .ql-editor.ql-blank::before {
                            color: #757575;
                            font-style: normal;
                            font-size: 18px;
                            text-align: center;
                            width: 100%;
                            left: 0;
                            right: 0;
                            pointer-events: none;
                            position: absolute;
                            transform: translateY(-50%);
                        }
                        .ql-editor p {
                            margin-bottom: 1.5em;
                        }
                        .ql-editor h1, .ql-editor h2, .ql-editor h3 {
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                            font-weight: 700;
                            margin: 1.5em 0 0.5em;
                                }
                                .ql-editor img {
                                    max-width: 100%;
                                    height: auto !important;
                            border-radius: 4px;
                            margin: 2em auto;
                        }
                        .ql-toolbar {
                            border: none !important;
                            border-bottom: 1px solid #e6e6e6 !important;
                            position: sticky;
                            top: 0;
                            background: white;
                            z-index: 1000;
                            opacity: ${distactionFreeMode ? 0 : 1};
                            transition: opacity 0.3s ease;
                            padding: 12px 0;
                        }
                        .ql-toolbar:hover {
                            opacity: 1;
                                }
                                .ql-container {
                            border: none !important;
                        }
                        .ql-container:not(.ql-disabled) .ql-editor:focus {
                            outline: none;
                            box-shadow: none;
                        }
                        .ql-editor:focus {
                            background-color: #fafafa;
                            transition: background-color 0.3s ease;
                                }
                            `}
                        </style>
                    </Box>
        </PageContainer>
    );
}; 