import { useState, useEffect } from "react";
import {
  Popover,
  Box,
  Typography,
  List,
  ListItem,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSupabase } from "../../providers/AuthContextProvider";

const getNotificationIcon = (action) => {
  switch (action) {
    case "form_submit":
      return <AssignmentIcon sx={{ color: "#02BD36" }} />;
    case "new_athlete":
      return <PersonAddIcon sx={{ color: "#1976d2" }} />;
    case "new_calendar":
      return <CalendarMonthIcon sx={{ color: "#02BD36" }} />;
    default:
      return <NotificationsIcon sx={{ color: "#757575" }} />;
  }
};

const NotificationSkeleton = () => (
  <ListItem sx={{ p: 2 }}>
    <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%", gap: 1.5 }}>
      <Box sx={{ flexGrow: 1, borderBottom: "1px solid #CECECE" }}>
        <Skeleton variant="text" width="90%" height={20} sx={{ mb: 0.5 }} />
        <Skeleton variant="text" width="40%" height={16} />
      </Box>
      <Skeleton variant="circular" width={12} height={12} />
    </Box>
  </ListItem>
);

export const NotificationsPopup = ({
  anchorEl,
  onClose,
  notifications = [],
  onSeeAll,
  onUpdate,
  setNotifications,
  user,
  isLoading
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const navigate = useNavigate();
  const supabase = useSupabase();
  const today = moment().startOf("day");

  const todayNotifications = notifications.filter((n) =>
    moment(n.created_at).isSame(today, "day")
  );

  const olderNotifications = notifications.filter((n) =>
    moment(n.created_at).isBefore(today, "day")
  );

  const handleMenuClick = (event, notification) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setSelectedNotification(notification);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedNotification(null);
  };

  const toggleRead = async (notificationId, currentReadState) => {
    try {
      await supabase
        .from("notifications")
        .update({ isRead: !currentReadState })
        .eq("id", notificationId);

      onUpdate && onUpdate();
    } catch (error) {
      console.error("Error toggling notification read state:", error);
    }
    handleMenuClose();
  };
  const markAllAsRead = async () => {
    try {
      await supabase
        .from('notifications')
        .update({ isRead: true })
        .eq('user_id', user.id)
        .is('isHidden', false);

      setNotifications(notifications.map(notification => ({
        ...notification,
        isRead: true
      })));

      onUpdate && onUpdate();
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  const hideNotification = async (notificationId) => {
    try {
      await supabase
        .from("notifications")
        .update({ isHidden: true })
        .eq("id", notificationId);

      onUpdate && onUpdate();
    } catch (error) {
      console.error("Error hiding notification:", error);
    }
    handleMenuClose();
  };

  const renderNotificationItem = (notification) => (
    <ListItem
      key={notification.id}
      sx={{
        pt:0
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          gap: 1.5,
        }}
      >
      
        <Box sx={{ flexGrow: 1,
          borderBottom:"1px solid #CECECE"

        }}>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#525A65",
              fontWeight: notification.isRead ? 400 : 500,
              mb: 0.5,
              fontFamily: "Bricolage Grotesque",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                color: "#6B7280",
                fontWeight: notification.isRead ? 400 : 500,
                mb: 0.5,
                fontFamily: "Bricolage Grotesque",
              }}
            >
              <Box>
              <div
  style={{
    color: notification?.isRead ? "#A8A7A6" : "inherit",
  }}
  dangerouslySetInnerHTML={{
    __html: `${notification?.message || ""} 
      <span 
        style="cursor:pointer; color:${!notification?.isRead ? "#02BD36" : "#A8A7A6"}"
        onClick="window.location.href='${notification?.related_link}'"
      >Click to view.</span>`,
  }}
/>


</Box>
</Typography>
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#525A65",
              opacity: 0.7,
              fontFamily: "Bricolage Grotesque",
        color:notification?.isRead&&"#A8A7A6"

            }}
          >
            {moment(notification.created_at).fromNow()}
          </Typography>
        </Box>
        
        <Box
          onClick={(e) => toggleRead(notification?.id, notification?.isRead)}
         sx={{zIndex:"1000",cursor:"pointer"}}
        >
          
          {notification?.isRead ? <ReadIcon/> : <UnreadIcon/>}
          </Box>

      </Box>
    </ListItem>
  );

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        sx: {
          width: {
            xs: "95%",
            sm: "80%",
            md: "60%",
            lg: "35%"
          },
          height: {
            xs: "80vh",
            sm: "70vh",
            md: "60vh",
            lg: "60%"
          },
          maxHeight: {
            xs: "95vh",
            sm: "80vh"
          },
          borderRadius: {
            xs: "16px",
            sm: "24px"
          },
          mt: 1,
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          backgroundColor: "#FBFBFB",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          p: {
            xs: 1.5,
            sm: 2
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Typography
          sx={{
            fontFamily: "Bricolage Grotesque",
            fontSize: {
              xs: "14px",
              sm: "16px"
            },
            color: "#02BD36",
            fontWeight: 500,
          }}
        >
          Notifications
        </Typography>
        <Typography onClick={onClose} sx={{cursor:"pointer"}}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.14622 1.14284C1.7774 0.511667 2.80073 0.511667 3.4319 1.14284L14.8603 12.5712C15.4915 13.2024 15.4915 14.2257 14.8603 14.8569C14.2291 15.4881 13.2058 15.4881 12.5746 14.8569L1.14622 3.42852C0.515048 2.79734 0.51505 1.77401 1.14622 1.14284Z" fill="#525A65"/>
<path d="M1.14284 14.8577C0.511667 14.2265 0.511667 13.2032 1.14284 12.572L12.5712 1.1436C13.2024 0.512432 14.2257 0.512434 14.8569 1.14361C15.4881 1.77478 15.4881 2.79811 14.8569 3.42929L3.42852 14.8577C2.79734 15.4889 1.77401 15.4889 1.14284 14.8577Z" fill="#525A65"/>
</svg>

        </Typography>
      </Box>

      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        
        {false ? (
          <Box>
            <Typography
              sx={{
                px: 2,
                py: 1.5,
                fontSize: "14px",
                letterSpacing: "0.78px",
                color: "#525A65",
                fontFamily: "Bricolage Grotesque",
                fontWeight: "600",
              }}
            >
              Today
            </Typography>
            {[...Array(4)].map((_, index) => (
              <NotificationSkeleton key={index} />
            ))}
          </Box>
        ) : (
          <>
            {todayNotifications.length > 0 && (
              <>
                <Typography
                  sx={{
                    px: 2,
                    py: 1.5,
                    fontSize: "14px",
                    letterSpacing: "0.78px",
                    color: "#525A65",
                    fontFamily: "Bricolage Grotesque",
                    fontWeight: "600",
                  }}
                >
                  Today
                </Typography>
                <List sx={{ p: 0 }}>
                  {todayNotifications.map(renderNotificationItem)}
                </List>
              </>
            )}

            {olderNotifications.length > 0 && (
              <>
             {todayNotifications.length>0 && (
       <Typography
       sx={{
         px: 2,
         py: 1.5,
         fontSize: "14px",
         color: "#525A65",
         fontFamily: "Bricolage Grotesque",
         letterSpacing: "0.78px",
         fontWeight: "600",
       }}
     >
       Earlier
     </Typography>
             )} 
         
                <List sx={{ p: 0 }}>
                  {olderNotifications.map(renderNotificationItem)}
                </List>
              </>
            )}

            {notifications.length === 0 && (
              <Box
                sx={{
                  p: 4,
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <NotificationsIcon
                  sx={{ fontSize: 40, color: "#CCCCCC", background: "#FBFBFB" }}
                />
                <Typography
                  sx={{
                    color: "#525A65",
                    fontSize: "14px",
                    fontFamily: "Bricolage Grotesque",
                  }}
                >
                  You're all caught up.
                </Typography>
              </Box>
            )}
          </>
        )}
         
      </Box>
      {notifications.length > 0 && (
        <Box
          sx={{
            p: {
              xs: 1.5,
              sm: 2
            },
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          }}
        >
          <Button
          onClick={markAllAsRead}
          disabled={notifications?.filter(n => !n?.isRead).length ==0}
            sx={{
              color: (notifications?.filter(n => !n?.isRead).length ==0 ?"#DAD9D8":"#525A65"),
              fontSize: "13px",
              textTransform: "none",
              fontFamily: "Bricolage Grotesque",
              padding: "8px",
              borderRadius: "20px",
              textDecoration:(notifications?.filter(n => !n?.isRead).length ==0 ?"none":"underline"),
              mr:2,
              "&:hover": {
                background:"unset",
              textDecoration:"underline",

              },
            }}
          >
            Mark all as read
          </Button>
        </Box>
      )}
     

     
       

     
     
    </Popover>
  );
};

const UnreadIcon=()=>{
  return(
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="5.625" cy="5.625" r="5.625" fill="#02BD36"/>
</svg>



  )
}
const ReadIcon=()=>{
  return (
    <svg style={{zIndex:"1000"}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="5.625" cy="5.625" r="5.625" fill="#DAD9D8"/>
</svg>

    
    
    
  )
}