import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  CalendarMonth,
  Info,
  PersonAdd,
  Pool,
  WarningRounded,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import AthleteInviteModal from "../AthleteInvitationModal/AthleteInvitationModal";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import { getAthletesByCoachId } from "../../services/query";
import { getDistanceText } from "../../utils/calendar";
import { format } from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import { buttonStyle, AutoCompleteStyle } from "./style"


export const searchInObjects = (searchTerm, objectsArray) => {
  // Convert the search term to lowercase for case-insensitive search
  const lowerSearchTerm = searchTerm.toLowerCase();

  // Filter the array of objects based on whether the search term is found in any string value
  return objectsArray.filter((obj) => {
    return Object.keys(obj.athletes).some((key) => {
      const value = obj.athletes[key];

      // Check if the value is a string, and if the search term is a substring of it
      return (
        typeof value === "string" &&
        value.toLowerCase().includes(lowerSearchTerm)
      );
    });
  });
};

const cols = [
  {
    id: "athletes",
    label: "Athlete",
    format: (athlete) => {
      const name = athlete.first_name
        ? `${athlete.first_name} ${athlete.last_name}`
        : athlete.full_name
          ? athlete.full_name
          : athlete.email;
      return (
        <Box display="flex" alignItems="center">
          <svg width="32" height="32" viewBox="0 0 32 32" style={{ marginRight: "8px" }} fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#D9D9D9" />
            <circle cx="16" cy="16" r="10" fill={athlete.full_name
              ? "#FBFBFB"
              : "#999999"} />
          </svg>

          <span
            style={{
              color:
                athlete.full_name
                  ? "#525A65"
                  : "#999999",
              fontSize: "12px",
            }}
          >
            {name.replace("@gmail.com", "")}
          </span>
        </Box>
      );
    },
  },
  {
    id: "athletes",
    label: "Status",
    format: (athlete) => <GetAthleteStatus athlete={athlete} />,
  },
];

export const GetAthleteStatus = ({ athlete }) =>
  athlete.status === "PENDING" ? (
    <Chip
      size="small"
      label="Invited"
      sx={{ fontWeight: "500 !important", color: "#F1F3F4", fontSize: "10px", backgroundColor: "#999999" }}
    />
  ) : athlete.calendars.length === 0 ? (
    <Chip
      size="small"
      label="No Calendar"
      sx={{ pl: 0.3, fontWeight: "500 !important", color: "#F1F3F4", fontSize: "10px", backgroundColor: "#999999" }}
    />
  ) : athlete.events.filter((e) => e.date >= format(new Date(), "yyyy-MM-dd"))
    .length === 0 ? (
    <Chip
      size="small"
      label="No Schedule"
      sx={{ pl: 0.3, fontWeight: "500 !important", color: "#F1F3F4", fontSize: "10px", backgroundColor: "#999999" }}
    />
  ) : (
    <Chip
      size="small"
      label={`${getDistanceText(
        athlete.events[athlete.events.length - 1].date
      )}`}
      sx={{ pl: 0.3, fontWeight: "500 !important", color: "#F1F3F4", fontSize: "10px", backgroundColor: "#999999" }}
    />
  );

export const PaymentDialog = ({ open, handleClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const supabase = useSupabase();
  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '24px',
          maxWidth: '529px',
          width: '100%',
          background: '#013B12',
          color: '#E3E2E0',
          fontFamily: 'Bricolage Grotesque',
          height: "525px"
        }
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }
      }}
    >
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          height: '100%',
          padding: '60px 24px 40px',
          justifyContent: 'space-between',
        }}
      >
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 600,
            color: '#E3E2E0',
            fontSize: '24px',
            lineHeight: '29px',
            width: '298px',
            marginBottom: '32px',
            mt:3
          }}
        >
          Oops!
        </Typography>
        
        <Box
          sx={{
            animation: 'pulse 2s infinite ease-in-out',
            '@keyframes pulse': {
              '0%': { transform: 'scale(1)' },
              '50%': { transform: 'scale(1.1)' },
              '100%': { transform: 'scale(1)' }
            }
          }}
        >
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="#02BD36"/>
            <path d="M49.9596 78.9265C47.5321 78.9265 45.3577 77.467 44.4194 75.233C43.496 72.999 44.0024 70.4225 45.73 68.6949C47.4427 66.9822 50.0192 66.4609 52.2532 67.3992C54.4872 68.3226 55.9467 70.5119 55.9467 72.9395C55.9467 76.2457 53.2659 78.9265 49.9596 78.9265Z" fill="#E9FFCE"/>
            <path d="M53.6977 57.6462C53.5487 59.21 51.9105 60.4312 49.9446 60.4312C47.9787 60.4312 46.3405 59.21 46.1915 57.6462L43.332 26.4004C43.332 23.4813 46.2958 21.1133 49.9595 21.1133C51.7913 21.1133 53.4445 21.709 54.6359 22.6622C55.8274 23.6153 56.5721 24.9408 56.5721 26.4004L53.6977 57.6462Z" fill="#E9FFCE"/>
          </svg>
        </Box>

        <Box sx={{ marginTop: '32px', marginBottom: '40px' }}>
          <Typography 
            variant="h6"
            sx={{
              color: '#C9FE58',
              fontSize: '20px',
              lineHeight: '24px',
              fontWeight: 700,
              marginBottom: '8px'
            }}
          >
            Subscription Required
          </Typography>
          <Typography
            sx={{
              color: "#E3E2E0",
              fontSize: "16px",
              lineHeight: "20px"
            }}
          >
            You've reached your free client limit. Upgrade <Box sx={{width:"auto",height:"auto",display:{xs:"none",sm:"unset"}}}> <br /></Box>now to add more clients.
          </Typography>
        </Box>
        
        <input
          type="hidden"
          name="priceId"
          value={process.env.REACT_APP_STRIPE_PRICE}
        />
        
        <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center', width: '262px' }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              borderRadius: '20px',
              border: '1px solid #999999',
              background: 'rgba(0, 0, 0, 0.3)',
              color: '#E3E2E0',
              padding: '15px 22px',
              width: '96px',
              height: '49px',
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              textTransform: 'none',
              '&:hover': {
                border: '1px solid #999999',
                background: 'rgba(0, 0, 0, 0.4)',
              }
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
            onClick={async () => {
              setLoading(true);
              try {
                const { data } = await supabase.functions.invoke("st-checkout", {
                  body: {
                    priceId: process.env.REACT_APP_STRIPE_PRICE,
                    baseUrl: window.location.origin,
                  },
                });
                window.location = data.url;
              } catch (error) {
                alert(error.message);
              }
              setLoading(false);
            }}
            sx={{
              borderRadius: '20px',
              background: '#02BD36',
              border: '1px solid #E9FFCE',
              color: '#E9FFCE',
              padding: '15px 22px',
              height: '49px',
              width: '170px',
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '1',
              textTransform: 'none',
              '&:hover': {
                background: '#029c2d',
              }
            }}
          >
            {loading ? 'Processing...' : 'Upgrade now'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export const AthletesTable = ({ pic, name, info, onAthletesLoad }) => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const supabase = useSupabase();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getAthletes = () => {
    getAthletesByCoachId(supabase, user.id).then(({ data }) => {
      setAthletes(data || []);
      onAthletesLoad && onAthletesLoad(data || []);
    });
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    getAthletes();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Paper variant="outlined" sx={{ overflow: "hidden", borderRadius: "24px", height: "671px", backgroundColor: "#FBFBFB!important", border: "none" }}>
      <AthleteInviteModal
        onSuccess={getAthletes}
        open={open}
        handleClose={() => setOpen(false)}
      />
      <PaymentDialog
        open={paymentOpen}
        handleClose={() => {
          setPaymentOpen(false);
        }}
      />
      <Box
        p={2}
        pb={1}
        display="flex"
        alignItems="center"
        sx={{ padding: "23px" }}
      >
        <Typography
          variant="subtitle"
          sx={{
            color: "#525A65",
            fontWeight: "600 !important",
            fontSize: "16px",
          }}
        >
          Your Clients:
        </Typography>
        {loading && <CircularProgress size={20} sx={{ marginLeft: "auto" }} />}
        <Button
          sx={buttonStyle}
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            try {
              const { data } = await supabase.functions.invoke("st-sub-status");
              if (user.free_account || data.isSubscribed || athletes.length < 3) {
                setOpen(true);
              } else {
                setPaymentOpen(true);
              }
            } catch (error) {
              alert(error.message);
            }
            setLoading(false);
          }}
        >
          + Invite
        </Button>
      </Box>
      
      <Box sx={{ padding: "13px 23px 30px 23px" }}>
        <TextField
          id="outlined-search"
          label="Search for Client..."
          type="search"
          fullWidth
          value={search}
          sx={AutoCompleteStyle}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Divider />
      <TableContainer sx={{ maxHeight: 550,overflow:"auto"}}>
        <Table stickyHeader aria-label="sticky table" sx={{
          padding: "0px 20px 0px 20px", borderSpacing: "0px 11px",
          "tr td:first-child": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px"
          },
          "tr td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px"
          }
        }}>
          <TableBody>
            {searchInObjects(search, athletes).map((row, i) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={i}
                  onClick={() => navigate(`/athlete/${row.athletes.id}`)}
                  sx={{ cursor: "pointer" }}
                >
                  {cols.map((column, i) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={i}
                        align={column.align}
                        sx={{
                          p: {
                            xs: 1,
                            sm: 2,
                          },
                          backgroundColor: "#F1F3F4",
                          padding: "15px",
                          borderBottom: "0px",
                          ".MuiChip-root": {
                            float: "right"
                          }
                        }}
                        id={i}
                      >
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
