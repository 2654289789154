export const addnewquestionstyle = {
    marginLeft: "auto",
    color: "#525A65",
    border: "solid 1px",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "30px",
    fontSize: "12px",
    ".MuiSvgIcon-root": {
      fontSize: "12px !important",
      paddingLeft: "2px",
    },
    "&:hover": {
      color: "#525A65",
      backgroundColor: "unset"
    },
    ".MuiButton-icon": {
      widht: "10px",
      paddingLeft: "3px",
      marginRight: "0px"
    },
  }
  
  export const createupdatestyle = {
    marginLeft: "auto",
    color: "#2998E9",
    border: "solid 1px",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "20px",
    marginLeft: "auto",
    fontSize: "13px",
    padding: "11px 4px !important",
    minWidth: "85px !important",
    "&:hover": {
      backgroundColor: "#fff"
    },
  }
  
  export const close = {
    marginLeft: "auto",
    color: "#525A65",
    border: "solid 1px",
    float: "right",
    textTransform: "none",
    paddingRight: "10px",
    padding: "13px",
    borderRadius: "100% !important",
    minWidth: "37px !important",
    fontSize: "12px",
    border: "0px !important",
    backgroundColor: "#EEEEEE",
    ".MuiSvgIcon-root": {
      fontSize: "18px !important",
      paddingLeft: "0px",
      color: "#525A65"
    },
    "&:hover": {
      color: "#525A65",
      backgroundColor: "#dddddd"
    },
    ".MuiButton-icon": {
      widht: "10px",
      paddingLeft: "3px",
      marginRight: "0px"
    },
  }