import React, { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Typography,
  Link
} from "@mui/material";
import { IconClose } from "../ExerciseModal/ExerciseModal";
import { useWalkthroughState } from "./ToolsStateContext";
import { supabase } from "../../services/supabase";
import { useAuth } from "../../providers/AuthContextProvider";

/**
 * Tools walkthrough modal shown in the welcome flow
 */
const ToolsWalkthroughModal = ({
  open,
  onClose,
  onNext,
  onSkip
}) => {
  const { setIsToolsWalkthroughOpen } = useWalkthroughState();
  const { user } = useAuth();

  // Update the context state when the modal opens or closes
  useEffect(() => {
    setIsToolsWalkthroughOpen(open);
    
    // Scroll to top when modal opens
    if (open) {
      window.scrollTo(0, 0);
    }
    
    // Clean up when component unmounts
    return () => {
      setIsToolsWalkthroughOpen(false);
    };
  }, [open, setIsToolsWalkthroughOpen]);
  
  const handleClose = async() => {

    const { error } = await supabase
    .from('users')
    .update({ is_first_login: false })
    .eq('id', user.id);

  if (error) throw error;
  

    setIsToolsWalkthroughOpen(false);
    onClose();
  };
  

  
  const handleNext = () => {
    setIsToolsWalkthroughOpen(false);
    onNext();
  };
  if(!user){
    return
  }
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onSkip();
        }
      }}
      BackdropProps={{
        style: {
          zIndex:"9999"
        }
      }}
      PaperProps={{
        sx: {
          width: {xs: '90%', sm: '529px'},
          height: {xs: '500px', sm: '525px'},
          margin: 'auto',
          background: '#FBFBFB',
          borderRadius: '24px',
          display: 'flex',
          flexDirection: 'column',
          p: {xs:4,sm:6},
          maxWidth: '90vw',
          position:"relative",
          zIndex:"10000",
        }
        
      }
    
    }
    >
       <Box component="svg"
                  sx={{
                    position: 'fixed',
                    top:{xs:"15px",sm:"15px",md:"15px",lg:"14px"},
                    left: {xs:"3px",sm:"14px",md:"14px",lg:"0.94%",xl:"0.7%"},
                    zIndex: 1000,
                    cursor: "pointer",
                    display: "flex",
                    width: "68px",
                    height: "68px",
                    alignItems: "center",
                    borderRadius: "50px",
                  }}
                  width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_4019_1088)">
                      <rect x="6" y="6" width="48" height="48" rx="24" fill="#013B12"/>
                      <rect x="6.5" y="6.5" width="47" height="47" rx="23.5" stroke="#02BD36"/>
                      <rect x="20" y="23" width="20" height="2" rx="1" fill="#C9FE58"/>
                      <rect x="20" y="29" width="20" height="2" rx="1" fill="#C9FE58"/>
                      <rect x="20" y="35" width="20" height="2" rx="1" fill="#C9FE58"/>
                    </g>
                    <defs>
                      <filter id="filter0_d_4019_1088" x="0" y="0" width="68" height="68" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="4" dy="4"/>
                        <feGaussianBlur stdDeviation="5"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.789132 0 0 0 0 0.99554 0 0 0 0 0.343724 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4019_1088"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4019_1088" result="shape"/>
                      </filter>
                    </defs>
                  </Box>
     <Box sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 1,
      }}>
        <Typography sx={{
          fontFamily: 'Bricolage Grotesque',
          fontWeight: 700,
          fontSize: {xs:"14px",sm:"16px"},
          lineHeight: '19px',
          color: '#000000',
        }}>
Explore CoachPlus tools!        </Typography>
      <Box sx={{
        padding: '0px',
        margin: '0px',
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '48px',
        width: '48px',
      }} onClick={handleClose}>
      <IconClose />

      </Box>
    
    </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}>
      
        {/* First feature */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mb: 3,
          mt: 2, 
        }}>
          <Box sx={{ mr: 3, position: 'relative' }}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="32" cy="32" r="32" fill="#013B12"/>
              <circle cx="38.599" cy="38.599" r="5.39974" fill="#02BD36"/>
              <circle cx="25.3997" cy="25.3997" r="5.39974" fill="#02BD36"/>
              <rect x="33.1992" y="20" width="10.7995" height="10.7995" rx="2" fill="#02BD36"/>
              <rect x="20" y="33.1992" width="10.7995" height="10.7995" rx="2" fill="#02BD36"/>
            </svg>
          </Box>
          <Typography sx={{
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 500,
            fontSize: {xs:"14px",sm:"16px"},
            lineHeight: '19px',
            color: '#000000',
            maxWidth: '266px'
          }}>
            Check out the Library for 1000+ exercises or create custom ones.
          </Typography>
        </Box>
        
        {/* Second feature */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mb: 3
        }}>
          <Box sx={{ mr: 3, position: 'relative' }}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="32" cy="32" r="32" fill="#013B12"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M25 20C22.2386 20 20 22.2386 20 25V39C20 41.7614 22.2386 44 25 44H39C41.7614 44 44 41.7614 44 39V25C44 22.2386 41.7614 20 39 20H25ZM26.0008 26C25.338 26 24.8008 26.5373 24.8008 27.2C24.8008 27.8627 25.338 28.4 26.0008 28.4H38.0008C38.6635 28.4 39.2008 27.8627 39.2008 27.2C39.2008 26.5373 38.6635 26 38.0008 26H26.0008ZM26.0006 30.8006C25.3378 30.8006 24.8006 31.3378 24.8006 32.0006C24.8006 32.6633 25.3378 33.2006 26.0006 33.2006H38.0005C38.6633 33.2006 39.2006 32.6633 39.2006 32.0006C39.2006 31.3378 38.6633 30.8006 38.0005 30.8006H26.0006ZM24.8006 36.8011C24.8006 36.1384 25.3378 35.6011 26.0006 35.6011H30.8006C31.4633 35.6011 32.0006 36.1384 32.0006 36.8011C32.0006 37.4638 31.4633 38.0011 30.8006 38.0011H26.0006C25.3378 38.0011 24.8006 37.4638 24.8006 36.8011Z" fill="#02BD36"/>
            </svg>
          </Box>
          <Typography sx={{
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 500,
            fontSize: {xs:"14px",sm:"16px"},
            lineHeight: '19px',
            color: '#000000',
            maxWidth: '298px'
          }}>
            Use Forms to gather client feedback, progress updates.
          </Typography>
        </Box>
        
        {/* Third feature */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mb: 3
        }}>
          <Box sx={{ mr: 3, position: 'relative' }}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="32" cy="32" r="32" fill="#C9FE58"/>
              <rect x="35" y="44" width="6" height="24" rx="3" transform="rotate(-180 35 44)" fill="#02BD36"/>
              <rect x="20" y="35" width="6" height="24" rx="3" transform="rotate(-90 20 35)" fill="#02BD36"/>
            </svg>
          </Box>
          <Typography sx={{
            fontFamily: 'Bricolage Grotesque',
            fontWeight: 500,
            fontSize: {xs:"14px",sm:"16px"},
            lineHeight: '19px',
            color: '#000000',
            maxWidth: '285px'
          }}>
            Explore other powerful tools and new features are coming...
          </Typography>
        </Box>
        
        {/* Bottom controls */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 'auto',
          mb: 1
        }}>
          <Link
            component="button"
            onClick={onSkip}
            sx={{
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14px',
              color: '#525A65',
              textDecorationLine: 'underline',
              cursor: 'pointer',
              border: 'none',
              background: 'transparent'
            }}
          >
            Skip for now
          </Link>
          
          <Button
            onClick={handleNext}
            sx={{
              background: '#EEEEEE',
              border: '1px solid #525A65',
              borderRadius: '20px',
              padding: '15px 22px',
              fontFamily: 'Bricolage Grotesque',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              color: '#0F0F0F',
              textTransform: 'none',
              minWidth: '80px',
              '&:hover': {
                background: '#E0E0E0'
              }
            }}
          >
            Next
          </Button>
        </Box>
        
        {/* Pagination dots */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          gap: 1,
          position: 'absolute',
          bottom: 24,
          left: '50%',
          transform: 'translate(-50%, 0)'
        }}>
          <Box sx={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            background: '#02BD36',
          }}/>
          <Box sx={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            background: '#E3E2E0',
          }}/>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ToolsWalkthroughModal; 